import { useMediaQuery } from 'react-responsive'
import { internals as breakpointInternals, useBreakpoints } from '../breakpoints'
import units from 'units-css'

const breakpointInternalsMap = {
  only: breakpointInternals.only,
  between: breakpointInternals.between,
  larger: breakpointInternals.up,
  smaller: breakpointInternals.down,
}

function formatMediaQuery(mediaQuery) {
  return mediaQuery.match(/(@media )(.+)/)[2]
}

function assertSupportedHelpers(context, helper, supportedHelpers) {
  if (!supportedHelpers.some((supportedHelper) => supportedHelper === helper)) {
    throw new Error(
      `${context}(): '${helper}' is not a valid helper, use one of the following instead: ${supportedHelpers
        .map((helper) => `'${helper}''`)
        .join(', ')}`
    )
  }
}

export const useMobileMediaQuery = () => {
  const breakpoints = useBreakpoints()

  return useMediaQuery({ maxWidth: units.parse(breakpoints.sm).value - 0.02 })
}

export const useSmallMediaQuery = (helper = 'only') => {
  const breakpoints = useBreakpoints()

  assertSupportedHelpers('useSmallMediaQuery', helper, ['only', 'larger'])

  const getMediaQuery = breakpointInternalsMap[helper] ?? breakpointInternals.only
  const mediaQuery = getMediaQuery('sm')({ theme: { breakpoints } })

  return useMediaQuery({ query: formatMediaQuery(mediaQuery) })
}

export const useMediumMediaQuery = (helper = 'only') => {
  const breakpoints = useBreakpoints()

  assertSupportedHelpers('useMediumMediaQuery', helper, ['only', 'larger'])

  const getMediaQuery = breakpointInternalsMap[helper] ?? breakpointInternals.only
  const mediaQuery = getMediaQuery('md')({ theme: { breakpoints } })

  return useMediaQuery({ query: formatMediaQuery(mediaQuery) })
}

export const useLargeMediaQuery = (helper = 'only') => {
  const breakpoints = useBreakpoints()

  assertSupportedHelpers('useLargeMediaQuery', helper, ['only', 'larger'])

  const getMediaQuery = breakpointInternalsMap[helper] ?? breakpointInternals.only
  const mediaQuery = getMediaQuery('lg')({ theme: { breakpoints } })

  return useMediaQuery({ query: formatMediaQuery(mediaQuery) })
}

export const useExtraLargeMediaQuery = (helper = 'only') => {
  const breakpoints = useBreakpoints()

  assertSupportedHelpers('useExtraLargeMediaQuery', helper, ['only', 'larger'])

  const getMediaQuery = breakpointInternalsMap[helper] ?? breakpointInternals.only
  const mediaQuery = getMediaQuery('xl')({ theme: { breakpoints } })

  return useMediaQuery({ query: formatMediaQuery(mediaQuery) })
}

export const useJumboMediaQuery = () => {
  const breakpoints = useBreakpoints()
  const mediaQuery = breakpointInternals.up('jb')({ theme: { breakpoints } })

  return useMediaQuery({ query: formatMediaQuery(mediaQuery) })
}

export const Mobile = ({ children }) => {
  const isMobile = useMobileMediaQuery()
  return isMobile ? children : null
}

export const Small = ({ helper, children }) => {
  const isSmall = useSmallMediaQuery(helper)
  return isSmall ? children : null
}

export const Medium = ({ helper, children }) => {
  const isMedium = useMediumMediaQuery(helper)
  return isMedium ? children : null
}

export const Large = ({ helper, children }) => {
  const isLarge = useLargeMediaQuery(helper)
  return isLarge ? children : null
}

export const ExtraLarge = ({ helper, children }) => {
  const isExtraLarge = useExtraLargeMediaQuery(helper)
  return isExtraLarge ? children : null
}

export const Jumbo = ({ children }) => {
  const isJumbo = useJumboMediaQuery()
  return isJumbo ? children : null
}
