import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { styled, s } from '@vega/styled'
import { isFunction, isNotNilOrEmpty } from '@neo/ramda-extra'
import { useOnClickOutside } from '../hooks/useOnClickOutside'

const { object, func, bool } = PropTypes

const Container = styled.div(s('relative'))

const DropdownMenu = styled.div(({ isOpened = false }) =>
  s('bg-white inline-block py-1 absolute pin-r-0', {
    userSelect: 'none',
    opacity: isOpened ? 1 : 0,
    transition: 'opacity 150ms ease-in-out',
    borderRadius: '6px 0 6px 6px',
    boxShadow:
      '0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1);',
  })
)

function Dropdown({
  Toggler,
  children,
  containerProps,
  dropdownMenuProps,
  ...otherProps
}) {
  const [isOpened, setIsOpened] = useState(false)

  const toggle = () => setIsOpened(!isOpened)

  const onClose = () => setIsOpened(false)

  const containerRef = useRef()
  useOnClickOutside(containerRef, onClose)

  const withOnClose = (child, index) =>
    React.cloneElement(child, {
      key: index,
      onClick: () => {
        onClose()
        if (isNotNilOrEmpty(child.props.onClick) && isFunction(child.props.onClick))
          child.props.onClick()
      },
    })

  return (
    <Container ref={containerRef} {...containerProps} {...otherProps}>
      {Toggler({ toggle })}

      {isOpened && (
        <DropdownMenu isOpened={isOpened} {...dropdownMenuProps}>
          {React.Children.map(children, withOnClose)}
        </DropdownMenu>
      )}
    </Container>
  )
}

Dropdown.propTypes = {
  isOpened: bool,
  Toggler: func,
  containerProps: object,
  dropdownMenuProps: object,
}

export { Dropdown }
