/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
import React from 'react'
import { useMenuTriggerState } from '@react-stately/menu'
import { useButton } from '@react-aria/button'
import { useMenu, useMenuItem, useMenuTrigger } from '@react-aria/menu'
import { useTreeState } from '@react-stately/tree'
import { mergeProps } from '@react-aria/utils'
import { FocusScope } from '@react-aria/focus'
import { useFocus } from '@react-aria/interactions'
import { useOverlay, DismissButton } from '@react-aria/overlays'

import { ReactComponent as DropdownIcon } from '@vega/components/src/assets/images/arrow_drop_down-24px.svg'

import { s, styled } from '@vega/styled/v2'

const Button = styled.button(
  s(
    'relative flex items-center justify-between bg-white border-solid border-1 border-grey-400 py-3 px-4 rounded-lg h-full w-full',
    {
      minHeight: 52,
      whiteSpace: 'nowrap',
      outline: 'none',
      '&:hover': s('border-1 border-primary', { cursor: 'pointer' }),
      ':focus-within': s('border-1 border-primary', {
        outline: '2px solid',
        outlineColor: s('text-primary').color,
      }),
    }
  ),
  ({ hasError }) =>
    hasError &&
    s('border-error-400 text-grey-500', {
      outline: 'none !important',
      '&:hover, &:focus-within': s('border-1 border-error-400 text-grey-500'),
    }),
  ({ disabled }) =>
    disabled &&
    s('bg-grey-200 text-grey-400 border-1 border-transparent', {
      pointerEvents: 'none',
    }),
  ({ readonly }) =>
    readonly && s('bg-grey-100 text-grey-800 border-1 border-transparent')
)

const MenuContainer = styled.div(s('relative'))

const MenuList = styled.ul(
  s(
    'absolute py-3 px-0 mt-1 bg-white shadow-xl rounded-lg pin-r-0 flex flex-column items-center z-2'
  ),
  s('border-solid border-1 border-grey-200 max-h-24'),
  { overflowY: 'auto', gap: 4 }
)

const ListItem = styled.li(
  s('text-base font-lg w-12 px-3 py-1 text-grey-800'),
  s('flex items-center', {
    outline: 'none',
    lineHeight: 1.5,
  }),
  ({ isFocused }) => isFocused && { cursor: 'pointer' }
)

const Svg = styled.svg(s('flex-shrink-0 mr-2'), { fill: 'none', width: 12, height: 12 })

const Rect = styled.rect(
  {
    width: 22,
    height: 22,
    strokeWidth: 1,
    stroke: '#E2E8F0',
    x: 1,
    y: 1,
    rx: 4,
  },
  ({ isSelected }) => isSelected && { fill: 'none' }
)

const ButtonPlaceholder = styled('span', {
  shouldForwardProp: (prop) => prop !== 'isDisplayingValue',
})(
  s('text-base', {
    letterSpacing: '0.01em',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  }),
  ({ isDisplayingValue }) =>
    isDisplayingValue ? s('text-grey-900') : s('text-grey-500')
)

const DropdownCheckbox = (props) => {
  const { label } = props
  const state = useMenuTriggerState(props)
  const buttonRef = React.useRef(null)

  const { menuTriggerProps, menuProps } = useMenuTrigger({}, state, buttonRef)
  const { buttonProps } = useButton(menuTriggerProps, buttonRef)

  return (
    <MenuContainer>
      <MenuButton buttonProps={buttonProps} label={label} ref={buttonRef} {...props} />

      {state.isOpen && (
        <MenuPopup
          {...props}
          domProps={menuProps}
          autoFocus={state.focusStrategy}
          onClose={() => state.close()}
        />
      )}
    </MenuContainer>
  )
}

const MenuButton = React.forwardRef((props, ref) => {
  const { label, hasError, buttonProps, ...otherProps } = props

  return (
    <Button ref={ref} hasError={hasError} {...buttonProps} {...otherProps}>
      <ButtonPlaceholder>{label}</ButtonPlaceholder>
      <DropdownIcon
        style={s('ml-1', { width: 30, height: 25 })}
        fill={s('text-grey-700').color}
      />
    </Button>
  )
})

function MenuPopup(props) {
  const state = useTreeState({ ...props, selectionMode: 'multiple' })

  const ref = React.useRef(null)
  const { menuProps } = useMenu(props, state, ref)
  const { onClose, domProps } = props

  const overlayRef = React.useRef(null)
  const { overlayProps } = useOverlay(
    {
      onClose,
      shouldCloseOnBlur: true,
      isOpen: true,
      isDismissable: true,
    },
    overlayRef
  )

  return (
    <FocusScope restoreFocus>
      <div {...overlayProps} ref={overlayRef}>
        <DismissButton onDismiss={onClose} />
        <MenuList {...mergeProps(menuProps, domProps)} ref={ref}>
          {[...state.collection].map((item) => (
            <MenuItem
              key={item.key}
              item={item}
              state={state}
              onAction={props.onAction}
              onClose={props.onClose}
            />
          ))}
        </MenuList>
        <DismissButton onDismiss={onClose} />
      </div>
    </FocusScope>
  )
}

function MenuItem({ item, state, onAction, onClose }) {
  const ref = React.useRef(null)
  const { menuItemProps } = useMenuItem(
    {
      key: item.key,
      isDisabled: item.isDisabled,
      onAction,
      onClose,
    },
    state,
    ref
  )

  const [isFocused, setFocused] = React.useState(false)
  const { focusProps } = useFocus({ onFocusChange: setFocused })
  const isSelected = state.selectionManager.state.selectedKeys.has(item.key)

  return (
    <ListItem
      {...mergeProps(menuItemProps, focusProps)}
      ref={ref}
      isFocused={isFocused}
    >
      <Svg aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <Rect />
        {isSelected && <path strokeWidth="2" d="M5 13l4 4L19 7" stroke="#2F8982" />}
      </Svg>

      {item.rendered}
    </ListItem>
  )
}

export default DropdownCheckbox
