import { createSlice } from '@reduxjs/toolkit'
// TODO: should this dependency be in the package.json?
import { ASYNC_STATUSES } from '@vega/constants'
import { path, assoc, isNotNil } from '@solta/ramda-extra'

const { PENDING, FULFILLED, REJECTED } = ASYNC_STATUSES

/**
 * @type {import('index').AsyncRequestState}
 */
const initialState = {}

const asyncStateSlice = createSlice({
  name: 'asyncState',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      /**
       * @param {import('index').PendingAction} action
       */
      // @ts-expect-error - Redux's AnyAction is not typed right broad enough to recognize ThunkAction, even though ThunkAction correctly implements it
      (action) => {
        return action.type.endsWith(PENDING)
      },
      /**
       * @param {import('index').PendingState} state
       * @param {import('index').PendingAction} action
       */
      (state, action) => {
        const splitType = action.type.split('/')

        /** @type {import('index').ThunkActionName} */
        const baseType = `${splitType[0]}/${splitType[1]}`

        /** @type {string|undefined} */
        const requestId = path(['meta', 'arg', 'requestId'], action)

        if (isNotNil(requestId)) {
          state[baseType] = assoc(requestId, { status: PENDING }, state[baseType])
        } else {
          state[baseType] = { status: PENDING }
        }
      }
    )

    builder.addMatcher(
      /**
       * @param {import('index').FulfilledAction} action
       */
      // @ts-expect-error see comment above
      (action) => {
        return action.type.endsWith(FULFILLED)
      },
      /**
       * @param {import('index').FulfilledState} state
       * @param {import('index').FulfilledAction} action
       */
      (state, action) => {
        const splitType = action.type.split('/')

        /** @type {import('index').ThunkActionName} */
        const baseType = `${splitType[0]}/${splitType[1]}`

        /** @type {string|undefined} */
        const requestId = path(['meta', 'arg', 'requestId'], action)

        if (isNotNil(requestId)) {
          state[baseType] = assoc(requestId, { status: FULFILLED }, state[baseType])
        } else {
          state[baseType] = { status: FULFILLED }
        }
      }
    )

    builder.addMatcher(
      /**
       * @param {import('index').RejectedAction} action
       */
      // @ts-expect-error see comment above
      (action) => {
        return action.type.endsWith(REJECTED)
      },
      /**
       * @param {import('index').RejectedState} state
       * @param {import('index').RejectedAction} action
       */
      (state, action) => {
        const splitType = action.type.split('/')

        /** @type {import('index').ThunkActionName} */
        const baseType = `${splitType[0]}/${splitType[1]}`

        /** @type {string|undefined} */
        const requestId = path(['meta', 'arg', 'requestId'], action)

        if (isNotNil(requestId)) {
          state[baseType] = assoc(
            requestId,
            { status: REJECTED, error: action.payload },
            state[baseType]
          )
        } else {
          state[baseType] = { status: REJECTED, error: action.payload }
        }
      }
    )
  },
})

/**
 * @type {typeof import('index').asyncStateReducer}
 */
const asyncStateReducer = asyncStateSlice.reducer
export { asyncStateReducer }
