import { styled, s } from '@vega/styled'
import { prop } from '@neo/ramda-extra'
import ToggleBase from 'react-toggle'
import 'react-toggle/style.css'

const Toggle = styled(ToggleBase)(
  {
    '.react-toggle-track': s('bg-grey-light', { height: 31, width: 51 }),
    '&.react-toggle--checked .react-toggle-track': s('bg-warning-500'),
    '&.react-toggle--checked:hover .react-toggle-track': s('bg-warning-500'),
    '&.react-toggle--checked .react-toggle-thumb': { left: 22 },
    '&.react-toggle--focus .react-toggle-thumb': s('shadow-sm'),
    '.react-toggle-thumb': s('border-transparent shadow-md', {
      height: 27,
      width: 27,
      top: 2,
      left: 2,
    }),
    '&.react-toggle:hover:not(.react-toggle--checked) .react-toggle-track': s(
      'bg-grey'
    ),
  },
  prop('style')
)

export default Toggle
