import { isNilOrEmpty, intersection } from '@neo/ramda-extra'
import { USER_ROLES } from '@vega/constants'

const { SUPER_ADMIN } = USER_ROLES

const verifyPermittedRoles = (permittedRoles = []) => {
  if (isNilOrEmpty(permittedRoles))
    throw new Error('An app must allow at least 1 role to access')

  if (!permittedRoles.find((r) => r === SUPER_ADMIN))
    throw new Error(
      'Super admin should be able to access any apps, please put it into the permittedRoles'
    )

  // PermittedRoles items order ensures we select the most privileged role for the authenticated user
  if (permittedRoles?.[0] !== SUPER_ADMIN)
    throw new Error(
      'Super admin must be the first item in permittedRoles array as it is the most privileged role'
    )
}

const getUserCurrentRole = (permittedRoles = [], userRoles = []) => {
  function isUnauthorizedUser(permittedRoles = [], userRoles = []) {
    return isNilOrEmpty(intersection(permittedRoles, userRoles))
  }

  verifyPermittedRoles(permittedRoles)

  if (isUnauthorizedUser(permittedRoles, userRoles)) return undefined

  let userAssumedRole
  for (const permittedRole of permittedRoles) {
    userAssumedRole = userRoles.find((r) => r === permittedRole)

    if (userAssumedRole) break
  }

  return userAssumedRole
}

export { getUserCurrentRole }
