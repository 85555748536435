import React from 'react'
import { styled, s } from '@vega/styled'

const offsetX = 30

const Container = styled.div(
  s('absolute flex justify-center items-center p-3 bg-grey-600', {
    top: '50%',
    transform: 'translateY(-50%)',
    borderRadius: 6,
    transition: 'opacity 80ms ease-in-out',
    pointerEvents: 'none',

    // arrow
    '&::before': s('absolute bg-grey-600', {
      content: '""',
      width: 10,
      height: 10,
      left: 0,
      top: '50%',
      transform: 'translate(-50%, -50%) rotateZ(45deg)',
    }),
  }),
  ({ isActive }) => ({
    left: `calc(100% + ${offsetX}px)`,
    opacity: isActive ? 1 : 0,
  })
)
const TooltipText = styled.span(s('text-white', { whiteSpace: 'nowrap' }))

export const Tooltip = ({ isActive, children }) => {
  return (
    <Container className="too" isActive={isActive}>
      <TooltipText>{children}</TooltipText>
    </Container>
  )
}
