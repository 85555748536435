import React from 'react'
import PageWrapper from 'features/common/PageWrapper'

const Error = () => (
  <PageWrapper>
    <p>Something has gone wrong (public)</p>
  </PageWrapper>
)

export default Error
