import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  fetchMyProfile,
  selectIsSessionValid,
  selectIsFetchMyProfileLoading,
  selectProfileRoles,
  setUserCurrentRole,
} from '@vega/redux.profile'

import PublicApp from 'features/public/'
import CustomerApp from 'features/customer'
import AdminApp from 'features/admin'
import { Loading, ErrorPage } from '@vega/components'
import { USER_ROLES } from '@vega/constants'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import { generateRoutesForAuthenticatedUser } from '@neo/app-access-controller'
import { getUserCurrentRole } from '../../../lib/appAccessController/src/getUserCurrentRole'
import { routes } from 'routes'

const { SUPER_ADMIN } = USER_ROLES

const roleToRoutesControl = {
  [SUPER_ADMIN]: {
    routes: [
      { path: routes.admin.root, Component: AdminApp },
      { path: routes.customer.root, Component: CustomerApp },
    ],
    rootRoute: { path: routes.admin.homepage, Component: AdminApp },
  },
  // [APPLY_ONLINE_CUSTOMER]: {
  //   routes: [{ path: routes.customer.root, Component: CustomerRouter }],
  //   rootRoute: { path: routes.customer.root, Component: CustomerRouter },
  // },
}

const App = () => {
  const loggedIn = useSelector(selectIsSessionValid)
  const isLoggingIn = useSelector(selectIsFetchMyProfileLoading)
  const userRoles = useSelector(selectProfileRoles) ?? []

  const userCurrentRole = getUserCurrentRole(['vg_super_admin'], userRoles)

  const dispatch = useDispatch()

  useEffect(() => {
    if (userCurrentRole) {
      // Storing in local storage for Http Client to access and set it as request header
      window.localStorage.setItem('vegaUserCurrentRole', userCurrentRole)
      dispatch(setUserCurrentRole(userCurrentRole))
    }
  }, [dispatch, userCurrentRole])

  useEffect(() => {
    async function fetchProfile() {
      await dispatch(fetchMyProfile())
    }

    fetchProfile()
  }, [dispatch])

  if (isLoggingIn) return <Loading />

  if (!loggedIn) return <PublicApp />

  const permittedRoutes = generateRoutesForAuthenticatedUser(
    userRoles,
    roleToRoutesControl
  )

  const rootUrl = roleToRoutesControl[userCurrentRole]?.rootRoute?.path || 'error'

  return (
    <Router>
      <Switch>
        {permittedRoutes.map(({ path, Component }) => (
          <Route key={path} path={path} component={Component} />
        ))}

        <Route path="/">
          <Redirect to={rootUrl} />
        </Route>
        <Route path="*" component={ErrorPage} />
      </Switch>
    </Router>
  )
}

export default App
