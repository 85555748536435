import React from 'react'
import { styled, s } from '@vega/styled'
import { ReactComponent as LeftIcon } from '../../assets/images/chevron-left.svg'

const Container = styled.div(
  s('mt-5 mx-4 flex justify-between items-center bg-green-50')
)

const Title = styled.h2(s('text-xl text-grey-700 font-semibold'))

const Left = styled.div(s('flex'))

const BackButton = styled.button(
  s('bg-transparent border-0 mr-1', { outline: 'none', cursor: 'pointer' })
)

function ProfilePageHeader({ title, children, onBackClick, ...otherProps }) {
  return (
    <Container {...otherProps}>
      <Left>
        {onBackClick && (
          <BackButton onClick={onBackClick}>
            <LeftIcon />
          </BackButton>
        )}
        <Title>{title}</Title>
      </Left>
      {children}
    </Container>
  )
}

export default ProfilePageHeader
