/* eslint-disable complexity */
import React from 'react'
import { s } from '@vega/styled/v2'
import { Chip, SubTitle, TableCell } from 'components/common'
import Card from 'components/Card'
import { formatCurrency, formatDateString } from 'utils/formatters'
import { repaymentTypes } from 'utils/dropdownLists'

const ObjectivesTable = ({ application }) => {
  // eslint-disable-next-line react/no-multi-comp

  const {
    goals,
    importantDates,
    deposits,
    ratePreferences,
    repaymentPreferences,
    specialFeaturePreferences,
    debtArrears,
    insurance,
    otherObjectives,
  } = application.declarations

  const primaryPurposeRow =
    goals.primaryPurposes.length === 0
      ? []
      : [
          <tr key="primaryPurpose">
            <TableCell>
              <Chip>Primary Purposes</Chip>
            </TableCell>
            <TableCell>
              {goals.primaryPurposes.map(
                (primaryPurpose, index, array) =>
                  `${primaryPurpose}${index < array.length - 1 ? ', ' : ''}`
              )}
            </TableCell>
          </tr>,
        ]

  const specificGoalsRow =
    goals.specificGoals === undefined
      ? []
      : [
          <tr key="specificGoals">
            <TableCell>
              <Chip>Specific Goals</Chip>
            </TableCell>
            <TableCell>{goals.specificGoals}</TableCell>
          </tr>,
        ]

  const importantDatesRow =
    importantDates.dates.length === 0
      ? []
      : [
          <tr key="importantDates">
            <TableCell>
              <Chip>Important Dates</Chip>
            </TableCell>
            <TableCell>
              {importantDates.dates.map(
                (date, index, array) =>
                  `${date.dateType} ${formatDateString(date.date)}${
                    index < array.length - 1 ? ', ' : ''
                  } `
              )}
            </TableCell>
          </tr>,
        ]
  const depositsRow =
    deposits.length === 0
      ? []
      : [
          <tr key="deposits">
            <TableCell>
              <Chip>Deposits</Chip>
            </TableCell>
            <TableCell>
              {deposits.map(
                (deposit, index, array) =>
                  `${deposit.source} ${formatCurrency(deposit.amount)}${
                    index < array.length - 1 ? ', ' : ''
                  } `
              )}
            </TableCell>
          </tr>,
        ]

  const ratePreferencesRow =
    ratePreferences.hasRatePreferences !== 'Yes'
      ? []
      : [
          <tr key="ratePreferences">
            <TableCell>
              <Chip>Rate Preferences</Chip>
            </TableCell>
            <TableCell>
              {repaymentTypes?.find((r) => r.key === ratePreferences.rateType)?.label}
            </TableCell>
          </tr>,
        ]

  const repaymentPreferencesRow =
    repaymentPreferences.hasRepaymentPreferences !== 'Yes'
      ? []
      : [
          <tr key="repaymentPreferences">
            <TableCell>
              <Chip>Repayment Preferences</Chip>
            </TableCell>
            <TableCell>
              {repaymentPreferences.preferenceTypes.map(
                (preferenceType, index, array) =>
                  `${preferenceType}${index < array.length - 1 ? ', ' : ''} `
              )}
            </TableCell>
          </tr>,
        ]

  const specialFeaturePreferencesRow =
    specialFeaturePreferences.hasSpecialFeaturePreferences !== 'Yes'
      ? []
      : [
          <tr key="specialFeaturePreferences">
            <TableCell>
              <Chip>Special Features</Chip>
            </TableCell>
            <TableCell>
              {specialFeaturePreferences.preferenceTypes.map(
                (preferenceType, index, array) =>
                  `${preferenceType}${index < array.length - 1 ? ', ' : ''} `
              )}
            </TableCell>
          </tr>,
        ]

  const previousDebtArrearsRow =
    debtArrears?.previousArrears?.hasArrears !== 'Yes'
      ? []
      : [
          <tr key="previousDebtArreas">
            <TableCell>
              <Chip>Previous Debt Arrears</Chip>
            </TableCell>
            <TableCell>{debtArrears.previousArrears.note}</TableCell>
          </tr>,
        ]

  const currentDebtArrearsRow =
    debtArrears?.currentArrears?.hasArrears !== 'Yes'
      ? []
      : [
          <tr key="currentDebtArreas">
            <TableCell>
              <Chip>Current Debt Arrears</Chip>
            </TableCell>
            <TableCell>{debtArrears.currentArrears.note}</TableCell>
          </tr>,
        ]

  const personalInsuranceRow =
    insurance?.hasFamilyInsurance === undefined
      ? []
      : [
          <tr key="personalInsurance">
            <TableCell>
              <Chip>Has Personal Insurances</Chip>
            </TableCell>
            <TableCell>{insurance?.hasFamilyInsurance}</TableCell>
          </tr>,
        ]

  const homeInsuranceRow =
    insurance?.hasHomeContentsInsurance === undefined
      ? []
      : [
          <tr key="homeInsurance">
            <TableCell>
              <Chip>Has home Insurances</Chip>
            </TableCell>
            <TableCell>{insurance?.hasHomeContentsInsurance}</TableCell>
          </tr>,
        ]

  const otherObjectivesRow =
    otherObjectives?.hasOtherObjectives !== 'Yes'
      ? []
      : [
          <tr key="otherObjectives">
            <TableCell>
              <Chip>Other Objectives</Chip>
            </TableCell>
            <TableCell>{otherObjectives.otherObjectivesNotes}</TableCell>
            <TableCell />
            <TableCell />
            <TableCell />
          </tr>,
        ]

  const rows = [
    ...primaryPurposeRow,
    ...specificGoalsRow,
    ...importantDatesRow,
    ...depositsRow,
    ...ratePreferencesRow,
    ...repaymentPreferencesRow,
    ...specialFeaturePreferencesRow,
    ...previousDebtArrearsRow,
    ...currentDebtArrearsRow,
    ...personalInsuranceRow,
    ...homeInsuranceRow,
    ...otherObjectivesRow,
  ]

  if (rows.length === 0) {
    return (
      <>
        <h3>Objectives Summary</h3>

        <Card>
          <SubTitle>No objectives have been specified</SubTitle>
          <p>Objectives can be specified in the application objectives section.</p>
        </Card>
      </>
    )
  }

  return (
    <>
      <h3>Objectives Summary</h3>
      <table style={s('bg-white p-6 rounded-lg shadow-lg w-full')}>
        <tbody>{rows}</tbody>
      </table>
    </>
  )
}

export default ObjectivesTable
