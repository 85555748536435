import React from 'react'
import { useSelector } from 'react-redux'
import { PersistentDrawer, PersistentNavItem } from 'features/common/NavigationDrawer'
import { ReactComponent as HomeIcon } from '@vega/components/src/assets/images/home.svg'
import { ReactComponent as ExitIcon } from '@vega/components/src/assets/images/exit.svg'
import { selectIsPersistentNavDrawerOpen } from 'modules/ui'
import { s } from '@vega/styled/v2'

const handleSignOut = () => {
  window.localStorage.removeItem('vegaUserCurrentRole')
  window.location.replace(
    `${process.env.REACT_APP_AUTH_WEB_URI}sign-out?redirectApp=APPLY_ONLINE`
  )
}

function AdminNavigationDrawer() {
  const isNavDrawerOpen = useSelector(selectIsPersistentNavDrawerOpen)

  if (!isNavDrawerOpen) return null
  return (
    <PersistentDrawer homeRoute={'/admin/homepage'} style={s('relative z-2')}>
      <PersistentNavItem
        tooltipLabel="Home"
        route={'/admin/homepage'}
        Icon={HomeIcon}
      />
      <PersistentNavItem
        tooltipLabel="Log Out"
        onClick={handleSignOut}
        Icon={ExitIcon}
        style={s('bg-accent-pink w-3 h-3 rounded-lg mt-a mb-16')}
      />
    </PersistentDrawer>
  )
}

export default AdminNavigationDrawer
