import React from 'react'
import { s } from '@vega/styled/v2'
import { Chip, TableCell } from 'components/common'

const TrustRow = ({ trust }) => {
  return (
    <tr>
      <TableCell style={s('text-left')}>
        <Chip>Trust</Chip>
      </TableCell>
      <TableCell>{trust.trustName}</TableCell>
      <TableCell>{`Country Registered: ${trust.countryRegistered}`}</TableCell>
      <TableCell>
        +
        {`${trust?.mobileNumber?.countryCode} ${trust?.mobileNumber?.prefix} ${trust?.mobileNumber?.contactNumber}`}
      </TableCell>
      <TableCell>{trust.officeEmail}</TableCell>
    </tr>
  )
}

export default TrustRow
