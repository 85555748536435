import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Applications from './applications'
import Applicants from './applications/applicants'
import FinancialPosition from './applications/financialPosition'
import FundingDetails from './applications/fundingDetails'
import Objectives from './applications/objectives'
import Notes from './applications/notes'
import Review from './applications/review'
import Error from './error'
import PageWrapper from 'features/common/PageWrapper'
import { routes } from 'routes'

const CustomerApp = () => (
  <PageWrapper>
    <Switch>
      <Route exact path={routes.customer.homepage} component={Applications} />
      <Route
        exact
        path={routes.customer.application.applicants}
        component={Applicants}
      />
      <Route
        exact
        path={routes.customer.application.financialPosition}
        component={FinancialPosition}
      />
      <Route
        exact
        path={routes.customer.application.fundingDetails}
        component={FundingDetails}
      />
      <Route
        exact
        path={routes.customer.application.objectives}
        component={Objectives}
      />
      <Route exact path={routes.customer.application.notes} component={Notes} />
      <Route exact path={routes.customer.application.review} component={Review} />
      <Route path="*" component={Error} />
    </Switch>
  </PageWrapper>
)

export default CustomerApp
