import React from 'react'
import PropTypes from 'prop-types'

import { useField } from 'formik'

import { Checkbox } from './Checkbox'
import { FormMessage } from '@vega/components'

const { string, oneOf } = PropTypes

function CheckboxField({
  id,
  name,
  children,
  containerProps,
  testId,
  readOnly,
  boxColour,
  ...props
}) {
  const [field, { touched, error }] = useField({
    name,
    id,
  })

  const handleChange = (value) => {
    field.onChange({ target: { value, name } })
  }

  return (
    <div {...containerProps}>
      <Checkbox
        hasError={Boolean(error)}
        isTouched={touched}
        isSelected={field?.value ?? false}
        readOnly={readOnly}
        boxColour={boxColour}
        {...field}
        onChange={handleChange}
        testId={testId}
        {...props}
      >
        {children}
      </Checkbox>
      <FormMessage message={error} visible={Boolean(touched && error)} id={name} />
    </div>
  )
}

CheckboxField.propTypes = {
  name: string.isRequired,
  size: oneOf(['sm', 'md']),
  'aria-label': string,
}

export { CheckboxField }
