import React, { useState, forwardRef } from 'react'
import { useDispatch } from 'react-redux'
import { Formik, Form, useFormikContext } from 'formik'
import Card from 'components/Card'
import { s } from '@vega/styled/v2'
import { SubEntityActionButton, CollapseButton, Chip } from 'components/common'
import { formatCurrency, formatStringToNumber } from 'utils/formatters'
import { FundingFields } from './fundingFields'
import { updateFund, removeFund } from 'modules/application'
import * as Yup from 'yup'

export const fundingSchema = Yup.object({
  description: Yup.string().required('Description is required'),
  fundType: Yup.string().required('Fund type is required'),
  value: Yup.number()
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .min(0, 'Value must be positive')
    .required('Value is required'),
})

const AutoDispatcher = ({ index, applicationId }) => {
  const dispatch = useDispatch()

  const { values } = useFormikContext()
  React.useEffect(() => {
    const formattedValues = {
      ...values,
      value: formatStringToNumber(`$${values.value}`),
    }
    dispatch(
      updateFund({ assetIndex: index, asset: formattedValues, id: applicationId })
    )
  }, [dispatch, index, values, applicationId])
  return null
}

const FundingCard = forwardRef(({ asset, index, applicationId }, ref) => {
  const dispatch = useDispatch()
  const [collapsed, setCollapsed] = useState(false)
  const toggleCollapased = () => setCollapsed(!collapsed)

  if (collapsed) {
    return (
      <Card style={s('mb-12')} ref={ref} id={asset._id}>
        <div style={s('flex')}>
          <h1 style={s('m-0 p-0 text-2xl', { flexGrow: 1 })}>
            {asset.description || '-'}
          </h1>

          <SubEntityActionButton
            style={s('bg-accent-red text-white', { width: 'auto' })}
            onClick={() => {
              dispatch(removeFund({ assetIndex: index, id: applicationId }))
            }}
          >
            Remove funding
          </SubEntityActionButton>
        </div>

        <div style={s('flex')}>
          <Chip>{asset.fundType}</Chip>
        </div>

        <p>Value: {asset.value ? formatCurrency(asset.value) : '-'}</p>

        <CollapseButton onClick={toggleCollapased}>+ Expand</CollapseButton>
      </Card>
    )
  }

  return (
    <Card style={s('mb-12')} ref={ref} id={asset._id}>
      <Formik initialValues={asset} enableReinitialize validationSchema={fundingSchema}>
        {({ values, handleChange, handleBlur }) => (
          <Form>
            <div style={s('flex justify-end')}>
              <SubEntityActionButton
                style={s('bg-accent-red text-white', { width: 'auto' })}
                onClick={() => {
                  dispatch(removeFund({ assetIndex: index, id: applicationId }))
                }}
              >
                Remove funding
              </SubEntityActionButton>
            </div>

            <AutoDispatcher index={index} applicationId={applicationId} />

            <h1 style={s('m-0 p-0 text-2xl', { flexGrow: 1 })}>
              {asset.description || '-'}
            </h1>

            <FundingFields
              handleChange={handleChange}
              handleBlur={handleBlur}
              values={values}
            />

            <CollapseButton onClick={toggleCollapased}>- Collapse</CollapseButton>
          </Form>
        )}
      </Formik>
    </Card>
  )
})

FundingCard.displayName = 'FundingCard'

export default FundingCard
