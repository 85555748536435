import { destroyElement, toCSS } from 'belter'
import zoid from 'zoid'

const AUTH_WEB_URI = process.env.REACT_APP_AUTH_WEB_URI

const EVENT = {
  RENDER: 'zoid-render',
  RENDERED: 'zoid-rendered',
  DISPLAY: 'zoid-display',
  ERROR: 'zoid-error',
  CLOSE: 'zoid-close',
  DESTROY: 'zoid-destroy',
  PROPS: 'zoid-props',
  RESIZE: 'zoid-resize',
  FOCUS: 'zoid-focus',
}

const CLASS = {
  VISIBLE: 'zoid-visible',
  INVISIBLE: 'zoid-invisible',
}

// This container template was adapted from Zoid's default container template.
// See https://github.com/krakenjs/zoid/blob/master/src/component/templates/container.js
function containerTemplate({
  uid,
  frame,
  prerenderFrame,
  doc,
  props,
  event,
  dimensions,
}) {
  const { width, height } = dimensions

  if (!frame || !prerenderFrame) {
    return undefined
  }

  const div = doc.createElement('div')
  div.setAttribute('id', uid)
  const style = doc.createElement('style')
  if (props.cspNonce) {
    style.setAttribute('nonce', props.cspNonce)
  }

  style.appendChild(
    doc.createTextNode(`
          #${uid} {
              position: relative;
              width: ${width};
              height: ${height};
          }
          #${uid} > iframe {
              display: inline-block;
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              transition: opacity .2s ease-in-out;
          }
          #${uid} > iframe.${CLASS.INVISIBLE} {
              opacity: 0;
          }
          #${uid} > iframe.${CLASS.VISIBLE} {
              opacity: 1;
      }
      `)
  )

  div.appendChild(frame)
  div.appendChild(prerenderFrame)
  div.appendChild(style)

  prerenderFrame.classList.add(CLASS.VISIBLE)
  frame.classList.add(CLASS.INVISIBLE)

  event.on(EVENT.RENDERED, () => {
    prerenderFrame.classList.remove(CLASS.VISIBLE)
    prerenderFrame.classList.add(CLASS.INVISIBLE)

    frame.classList.remove(CLASS.INVISIBLE)
    frame.classList.add(CLASS.VISIBLE)

    setTimeout(() => {
      destroyElement(prerenderFrame)
    }, 1)
  })

  event.on(EVENT.RESIZE, ({ width: newWidth, height: newHeight }) => {
    if (typeof newWidth === 'number') {
      div.style.width = toCSS(newWidth)
    }

    if (typeof newHeight === 'number') {
      div.style.height = toCSS(newHeight)
    }
  })

  return div
}

const AuthComponent = zoid.create({
  tag: 'auth-component',
  url: `${AUTH_WEB_URI}rpc`,
  dimensions: {
    width: 0,
    height: 0,
  },

  props: {
    onAuthSessionRefreshed: {
      type: 'function',
    },
  },
  containerTemplate,
})

export { AuthComponent }
