import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { s, styled } from '@vega/styled/v2'
import { larger } from '@vega/styled'

import {
  SectionContainer,
  SubTitle,
  FieldContainer,
  Label,
  SubEntityCard,
  SubEntityActionButton,
  Chip,
  CollapseButton,
} from 'components/common'
import {
  formatCurrency,
  formatStringToNumber,
  capitaliseFirstLetter,
} from 'utils/formatters'
import {
  Modal,
  DateField,
  TextField,
  Button,
  NumberField,
  ValocityAddressField,
} from '@vega/components'
import Card from 'components/Card'
import { Formik, Form } from 'formik'
import { ReactComponent as XBase } from '@vega/components/src/assets/images/x.svg'
import { upsertEmployment, removeEmployment } from 'modules/application'
import * as Yup from 'yup'
import { yesNoOptions } from 'utils/dropdownLists'
import { ReactComponent as SearchIconBase } from '@vega/components/src/assets/images/search.svg'
import { ValidatedSelectField } from 'components/SelectField'

const SearchIcon = styled(SearchIconBase)(s('mr-2'))

const ModalCard = styled(Card)(
  s('rounded-none', { height: '100vh', width: '100vw', overflowY: 'scroll' }),
  larger('xl')(s(' h-full rounded-lg', { width: '60rem', maxHeight: '80vh' }))
)

export const X = styled(XBase)(s('text-primary', { cursor: 'pointer' }))

const frequencyFullListOptions = [
  { key: 'daily', label: 'Daily' },
  { key: 'fortnightly', label: 'Fortnightly' },
  { key: 'halfYearly', label: 'Half Yearly' },
  { key: 'monthly', label: 'Monthly' },
  { key: 'oneOff', label: 'One Off' },
  { key: 'quarterly', label: 'Quarterly' },
  { key: 'seasonal', label: 'Seasonal' },
  { key: 'weekly', label: 'Weekly' },
  { key: 'yearly', label: 'Yearly' },
]

const employmentStatusOptions = [
  { key: 'primary', label: 'Primary' },
  { key: 'secondary', label: 'Secondary' },
  { key: 'previous', label: 'Previous' },
]

const payeBasisOptions = [
  { key: 'casual', label: 'Casual' },
  { key: 'commissionOnly', label: 'Commission Only' },
  { key: 'contract', label: 'Contract' },
  { key: 'fullTime', label: 'Full Time' },
  { key: 'partTime', label: 'Part Time' },
  { key: 'seasonal', label: 'Seasonal' },
  { key: 'temporary', label: 'Temporary' },
]

const employmentTypeOptions = [
  { key: 'paye', label: 'PAYE' },
  { key: 'selfEmployed', label: 'Self-Employed' },
  { key: 'retired', label: 'Retired' },
  { key: 'homeDuties', label: 'Home Duties' },
]

export const valocityAddressSchema = Yup.object().shape({
  propertyAddress: Yup.string().required('Property address is required'),
  propertyId: Yup.string().required('Property ID is required'),
})

const employerSchema = Yup.object().shape({
  name: Yup.string().required('Employer name is required'),
  address: valocityAddressSchema,
  phoneNumber: Yup.string().required('Phone number is required'),
})

const employmentValadiationSchema = Yup.object().shape({
  startDate: Yup.string().required('Start date is required'),
  endDate: Yup.string().when('employmentStatus', {
    is: 'Previous',
    then: Yup.string().required('End date is required'),
    otherwise: Yup.string().nullable(),
  }),
  employmentStatus: Yup.string().required('Employment status is required'),
  employmentType: Yup.string().required('Employment type is required'),
  employmentBasis: Yup.string().required('Employment basis is required'),
  amount: Yup.string().required('Amount is required'),
  frequency: Yup.string().required('Frequency is required'),
  onProbation: Yup.string().when('employmentType', {
    is: 'paye',
    then: Yup.string().required('On probation is required'),
    otherwise: Yup.string().notRequired(),
  }),
  employer: Yup.object().when('employmentType', {
    is: 'paye',
    then: employerSchema,
    otherwise: Yup.object().notRequired(),
  }),
  role: Yup.string().required('Role is required'),
})

const selfEmployedCompany = {
  name: 'Self Employed Business',
  address: {
    propertyAddress: '',
    propertyId: '',
  },
  phoneNumber: '',
}

const initialValues = {
  startDate: '',
  endDate: null,
  employmentStatus: '',
  employmentType: '',
  employmentBasis: '',
  amount: '',
  frequency: '',
  onProbation: '',
  employer: {
    name: '',
    address: {
      propertyAddress: '',
      propertyId: '',
    },
    phoneNumber: '',
  },
  role: '',
}

const Employments = ({ applicationId, householdIndex, memberIndex, member }) => {
  const dispatch = useDispatch()
  const { employments } = member
  const [isOpen, setIsOpen] = useState(false)
  const [currentEmployment, setCurrentEmployment] = useState(initialValues)
  const [currentEmploymentIndex, setCurrentEmploymentIndex] = useState(0)
  const [isEditing, setIsEditing] = useState(false)

  const totalEmploymentIncome = employments
    .filter((employment) => employment.employmentStatus !== 'Previous')
    .reduce((acc, curr) => acc + curr.amount, 0)

  const close = () => setIsOpen(false)

  const employmentCards = employments.map((employment, employmentIndex) => (
    <SubEntityCard key={employmentIndex}>
      <div style={s('flex')}>
        <div style={s('text-lg', { flexGrow: 1 })}>
          <div>
            <span style={s('font-semibold')}>{employment.role}</span>
            {' - '}
            {employment.employer.name}
          </div>

          <div style={s('mb-2')}>
            {formatCurrency(employment.amount)} -
            {capitaliseFirstLetter(employment.frequency)}
          </div>

          <div style={s('flex')}>
            <Chip>{employment.employmentStatus}</Chip>
            <Chip>{employment.employmentBasis}</Chip>
          </div>
        </div>

        <div>
          <SubEntityActionButton
            onClick={() => {
              setCurrentEmployment(employment)
              setCurrentEmploymentIndex(employmentIndex)
              setIsEditing(true)
              setIsOpen(true)
            }}
          >
            Edit
          </SubEntityActionButton>

          <SubEntityActionButton
            style={s('bg-accent-red text-white')}
            onClick={() => {
              dispatch(
                removeEmployment({
                  applicationId,
                  householdIndex,
                  memberIndex,
                  employmentIndex,
                })
              )
            }}
          >
            Remove
          </SubEntityActionButton>
        </div>
      </div>
    </SubEntityCard>
  ))

  const handleSubmit = (values) => {
    let formattedValues = {
      ...values,
      amount: formatStringToNumber(values.amount),
    }

    if (values.employmentType === 'selfEmployed') {
      formattedValues = {
        ...formattedValues,
        employer: selfEmployedCompany,
      }
    }

    dispatch(
      upsertEmployment({
        applicationId,
        householdIndex,
        memberIndex,
        employmentIndex: currentEmploymentIndex,
        employment: formattedValues,
      })
    )
    close()
  }

  return (
    <>
      <SectionContainer>
        <SubTitle>Employments - {formatCurrency(totalEmploymentIncome)}</SubTitle>

        {employments.length === 0 && (
          <FieldContainer>
            <Label>No employments added yet</Label>
          </FieldContainer>
        )}

        {employmentCards}

        <CollapseButton
          onClick={() => {
            setCurrentEmployment(initialValues)
            setCurrentEmploymentIndex(employments.length)
            setIsEditing(false)
            setIsOpen(true)
          }}
        >
          + Add employment
        </CollapseButton>
      </SectionContainer>
      <Modal isOpen={isOpen} onClose={close}>
        <ModalCard>
          <div style={s('flex justify-end')}>
            <SubTitle style={s('m-0', { flexGrow: 1 })}>
              {' '}
              {isEditing ? 'Edit' : 'Add'} employment
            </SubTitle>
            <X onClick={close} />
          </div>
          <Formik
            initialValues={currentEmployment}
            onSubmit={handleSubmit}
            validationSchema={employmentValadiationSchema}
          >
            {({ values, handleChange, handleBlur }) => (
              <Form>
                <FieldContainer>
                  <ValidatedSelectField
                    name="employmentStatus"
                    label="Employment status"
                    options={employmentStatusOptions.map((employment) => ({
                      label: employment.label,
                      value: employment.label,
                      key: employment.key,
                    }))}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={'Select status'}
                  />
                </FieldContainer>

                <FieldContainer>
                  <ValidatedSelectField
                    name="employmentType"
                    label="Employment type"
                    options={employmentTypeOptions.map((employment) => ({
                      label: employment.label,
                      value: employment.key,
                      key: employment.key,
                    }))}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={'Select type'}
                  />
                </FieldContainer>

                <FieldContainer>
                  <DateField
                    name="startDate"
                    label="Start date"
                    placeholder="DD/MM/YYYY"
                    inputFormat="dd/MM/yyyy"
                    labelProps={{ style: s('tracking-normal mb-0 text-grey-800') }}
                  />
                </FieldContainer>

                {values.employmentStatus === 'Previous' && (
                  <FieldContainer>
                    <DateField
                      name="endDate"
                      aria-label="End date"
                      placeholder="DD/MM/YYYY"
                      containerStyle={s('border-grey-200')}
                      style={s('text-sm')}
                      inputFormat="dd/MM/yyyy"
                      label={'End date'}
                      labelProps={{ style: s('tracking-normal mb-0 text-grey-800') }}
                    />
                  </FieldContainer>
                )}

                <FieldContainer>
                  <TextField
                    name="role"
                    label="Role"
                    placeholder="Role"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.role}
                    labelStyle={s('text-base')}
                  />
                </FieldContainer>

                <FieldContainer>
                  <ValidatedSelectField
                    name="employmentBasis"
                    label="Employment basis"
                    options={payeBasisOptions.map((employment) => ({
                      label: employment.label,
                      value: employment.label,
                      key: employment.key,
                    }))}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={'Select Basis'}
                  />
                </FieldContainer>

                <FieldContainer>
                  <NumberField
                    name="amount"
                    label="Income Amount"
                    placeholder="Amount"
                    style={s('bg-white')}
                    prefix="$"
                    thousandSeparator
                    labelStyle={s('text-base')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </FieldContainer>

                <FieldContainer>
                  <ValidatedSelectField
                    name="frequency"
                    label="Frequency"
                    options={frequencyFullListOptions.map((employment) => ({
                      label: employment.label,
                      value: employment.label,
                      key: employment.key,
                    }))}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={'Select Frequency'}
                    selectMenuStyles={{
                      height: '300px',
                      overflowY: 'scroll',
                      scrollbarWidth: 'auto',
                      '&::-webkit-scrollbar': {
                        width: '15px',
                        height: 0,
                      },
                      '&::-webkit-scrollbar-track': {
                        background: '#e0e0e0', // Color of the track
                      },
                      '&::-webkit-scrollbar-thumb': {
                        background: '#888', // Color of the thumb
                      },
                    }}
                  />
                </FieldContainer>

                {values.employmentType === 'paye' && (
                  <>
                    <FieldContainer>
                      <ValidatedSelectField
                        name="onProbation"
                        label="On probation"
                        options={yesNoOptions.map((status) => ({
                          label: status.label,
                          value: status.key,
                          key: status.key,
                        }))}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={'Select Option'}
                      />
                    </FieldContainer>
                    <FieldContainer>
                      <TextField
                        name="employer.name"
                        label="Employer name"
                        placeholder="Employer name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.employerName}
                        labelStyle={s('text-base')}
                      />
                    </FieldContainer>

                    <FieldContainer>
                      <ValocityAddressField
                        name="employer.address"
                        label="Employer address"
                        placeholder="Employer address"
                        aria-label="Employer address"
                        style={s('bg-white')}
                        InputPrefixIcon={<SearchIcon />}
                        menuStyles={s('max-h-24 mt-8', {
                          overflowY: 'auto',
                        })}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </FieldContainer>

                    <FieldContainer>
                      <TextField
                        name="employer.phoneNumber"
                        label="Employer phone number"
                        placeholder="Employer phone number"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.employerPhoneNumber}
                        labelStyle={s('text-base')}
                      />
                    </FieldContainer>
                  </>
                )}

                <div style={s('flex mt-4')}>
                  <Button style={s('mr-2')} type="submit">
                    Save
                  </Button>
                  <Button onClick={close} variant="secondary">
                    Cancel
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </ModalCard>
      </Modal>
    </>
  )
}

export default Employments
