/* eslint-disable complexity */
import React, { useState } from 'react'
import {
  SectionContainer,
  SubTitle,
  FieldContainer,
  Label,
  SubEntityCard,
  SubEntityActionButton,
  Chip,
  CollapseButton,
} from 'components/common'
import { useDispatch, useSelector } from 'react-redux'
import { upsertAddress, removeAddress } from 'modules/application'
import { ReactComponent as XBase } from '@vega/components/src/assets/images/x.svg'
import Card from 'components/Card'
import { s, styled } from '@vega/styled/v2'
import { larger } from '@vega/styled'
import { Formik, Form } from 'formik'
import {
  Modal,
  SelectField,
  DateField,
  Button,
  ValocityAddressField,
} from '@vega/components'
import * as Yup from 'yup'
import { ReactComponent as SearchIconBase } from '@vega/components/src/assets/images/search.svg'

const addressTypeListOptions = [
  { key: 'currentAddress', label: 'Current Address' },
  { key: 'previousAddress', label: 'Previous Address' },
]

const housingStatusListOptions = [
  { key: 'boarding', label: 'Boarding' },
  { key: 'caravan', label: 'Caravan' },
  { key: 'other', label: 'Other' },
  { key: 'ownHome', label: 'Own Home' },
  { key: 'ownHomeMortgage', label: 'Own Home Mortgage' },
  { key: 'renting', label: 'Renting' },
  { key: 'suppliedByEmployer', label: 'Supplied by Employer' },
  { key: 'withParents', label: 'With Parents' },
]

const parseDDMMYYYY = (dateString) => {
  if (!dateString) return null
  const [day, month, year] = dateString.split('/')
  const date = new Date(year, month - 1, day) // month is 0-indexed in JS Date
  return Number.isNaN(date.getTime()) ? null : date
}

const isValidDate = (dateString) => {
  return parseDDMMYYYY(dateString) !== null
}

const addressValidationSchema = (historicAddresses, currentAddressIndex) => {
  return Yup.object()
    .shape({
      address: Yup.object({
        propertyAddress: Yup.string().required('Address is required'),
        propertyId: Yup.string().required('Address ID is required'),
      }),
      addressType: Yup.string().required('Address type is required'),
      startDate: Yup.string()
        .required('Start date is required')
        .test('valid-date', 'Invalid date format', isValidDate),
      endDate: Yup.string().when(['addressType', 'startDate'], {
        is: (addressType) => addressType !== 'currentAddress',
        then: Yup.string()
          .required('End date is required')
          .test('valid-date', 'Invalid date format', isValidDate)
          .test(
            'is-after-start-date',
            'End date must be later than start date',
            function (endDate) {
              const { startDate } = this.parent
              const parsedStartDate = parseDDMMYYYY(startDate)
              const parsedEndDate = parseDDMMYYYY(endDate)
              return parsedStartDate && parsedEndDate && parsedEndDate > parsedStartDate
            }
          ),
        otherwise: Yup.string().nullable(),
      }),
      livingArrangement: Yup.string().required('Living arrangement is required'),
    })
    .test(
      'no-overlapping-dates',
      'Date ranges cannot overlap with existing addresses',
      function (values) {
        const { startDate, endDate, addressType } = values

        const newStart = parseDDMMYYYY(startDate)
        const newEnd =
          addressType === 'currentAddress' ? new Date() : parseDDMMYYYY(endDate)

        if (!newStart || (addressType !== 'currentAddress' && !newEnd)) {
          return true
        }

        const isOverlapping = historicAddresses.some((address, index) => {
          if (index === currentAddressIndex) return false
          const addressStart = parseDDMMYYYY(address.startDate)
          const addressEnd =
            address.addressType === 'currentAddress'
              ? new Date()
              : parseDDMMYYYY(address.endDate)

          if (!addressStart || !addressEnd) return false

          return (
            (newStart >= addressStart && newStart < addressEnd) ||
            (newEnd > addressStart && newEnd <= addressEnd) ||
            (newStart <= addressStart && newEnd >= addressEnd)
          )
        })
        return isOverlapping
          ? this.createError({
              path: 'startDate',
              message: 'Date ranges cannot overlap with existing addresses',
            })
          : true
      }
    )
}

const SearchIcon = styled(SearchIconBase)(s('mr-2'))
const WarningText = styled.p(s('text-red text-sm font-normal'))

// TODO: extract modal stuff to common components
const ModalCard = styled(Card)(
  s('rounded-none', { height: '100vh', width: '100vw', overflowY: 'scroll' }),
  larger('xl')(s(' h-full rounded-lg', { width: '60rem' }))
)

const initialValues = {
  address: '',
  addressType: '',
  startDate: '',
  endDate: '',
  livingArrangement: '',
}
export const X = styled(XBase)(s('text-primary', { cursor: 'pointer' }))

const Addresses = ({
  applicationId,
  householdIndex,
  memberIndex,
  member,
  isCurrentAddressValid = false,
}) => {
  const dispatch = useDispatch()
  const { historicAddresses } = member
  const [isOpen, setIsOpen] = useState(false)
  const [currentAddress, setCurrentAddress] = useState(initialValues)
  const [currentAddressIndex, setCurrentAddressIndex] = useState(0)
  const [isEditing, setIsEditing] = useState(false)
  const close = () => setIsOpen(false)

  const application = useSelector((state) => state.application.entities[applicationId])
  const household = application?.households?.[householdIndex] || null

  const filteredAddressTypeListOptions = addressTypeListOptions.filter((option) => {
    if (historicAddresses.some((address) => address.addressType === 'currentAddress')) {
      return option.key !== 'currentAddress'
    }
    return true
  })

  const addressCards = historicAddresses.map((address, addressIndex) => (
    <SubEntityCard key={addressIndex}>
      <div style={s('flex')}>
        <div style={s('text-lg', { flexGrow: 1 })}>
          <div style={s('font-semibold')}>{address.address.propertyAddress}</div>

          <div>{`${address.startDate} - ${address.endDate || 'Current'}`}</div>

          <div style={s('flex')}>
            <Chip>{address.livingArrangement}</Chip>
          </div>
        </div>

        <div>
          <SubEntityActionButton
            onClick={() => {
              setCurrentAddressIndex(addressIndex)
              setCurrentAddress(address)
              setIsEditing(true)
              setIsOpen(true)
            }}
          >
            Edit
          </SubEntityActionButton>

          <SubEntityActionButton
            style={s('bg-accent-red text-white')}
            onClick={() =>
              dispatch(
                removeAddress({
                  applicationId,
                  householdIndex,
                  memberIndex,
                  addressIndex,
                })
              )
            }
          >
            Remove
          </SubEntityActionButton>
        </div>
      </div>
    </SubEntityCard>
  ))

  const handleSubmit = (values) => {
    dispatch(
      upsertAddress({
        applicationId,
        householdIndex,
        memberIndex,
        addressIndex: currentAddressIndex,
        address: values,
      })
    )

    if (values.addressType === 'currentAddress' && household) {
      household.members.forEach((otherMember, otherMemberIndex) => {
        if (otherMemberIndex !== memberIndex) {
          const hasCurrentAddress = otherMember.historicAddresses?.some(
            (addr) => addr.addressType === 'currentAddress'
          )

          if (!hasCurrentAddress) {
            dispatch(
              upsertAddress({
                applicationId,
                householdIndex,
                memberIndex: otherMemberIndex,
                addressIndex: otherMember.historicAddresses?.length || 0,
                address: values,
              })
            )
          }
        }
      })
    }

    close()
  }

  return (
    <>
      <SectionContainer>
        <SubTitle>Addresses*</SubTitle>

        {historicAddresses.length === 0 && (
          <FieldContainer>
            <Label>No addresses added yet</Label>
          </FieldContainer>
        )}

        {!isCurrentAddressValid && (
          <WarningText>
            Please add a current address for this household member.
          </WarningText>
        )}

        {addressCards}

        <CollapseButton
          onClick={() => {
            setCurrentAddressIndex(historicAddresses.length)
            setCurrentAddress(initialValues)
            setIsEditing(false)
            setIsOpen(true)
          }}
        >
          + Add address
        </CollapseButton>
      </SectionContainer>

      <Modal isOpen={isOpen} onClose={close}>
        <ModalCard>
          <div style={s('flex justify-end')}>
            <SubTitle style={s('m-0', { flexGrow: 1 })}>
              {isEditing ? 'Edit' : 'Add'} Address
            </SubTitle>

            <X onClick={close} />
          </div>

          <Formik
            initialValues={currentAddress}
            onSubmit={handleSubmit}
            validationSchema={addressValidationSchema(
              historicAddresses,
              currentAddressIndex
            )}
            validateOnChange={false}
            validateOnBlur={true}
          >
            {({ handleChange, handleBlur, values, errors, touched, setFieldValue }) => (
              <Form>
                <FieldContainer>
                  <SelectField
                    name="addressType"
                    label="Address type"
                    options={filteredAddressTypeListOptions.map((employment) => ({
                      label: employment.label,
                      value: employment.key,
                      key: employment.key,
                    }))}
                    onSelectionChange={(value) => {
                      handleChange('addressType')(value)
                      if (value === 'currentAddress') {
                        setFieldValue('endDate', '')
                      }
                    }}
                    onBlur={handleBlur}
                    placeholder={'Select type'}
                  />
                </FieldContainer>

                <ValocityAddressField
                  name="address"
                  placeholder="Enter the address"
                  aria-label="Property address"
                  style={s('bg-white')}
                  InputPrefixIcon={<SearchIcon />}
                  menuStyles={s('max-h-24 mt-8', {
                    overflowY: 'auto',
                  })}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label={'Address'}
                />

                <FieldContainer>
                  <DateField
                    name="startDate"
                    aria-label="Start date"
                    placeholder="DD/MM/YYYY"
                    containerStyle={s('border-grey-200')}
                    style={s('text-sm')}
                    inputFormat="dd/MM/yyyy"
                    label={'Start date'}
                    labelProps={{ style: s('tracking-normal mt-2 mb-0 text-grey-800') }}
                    error={touched.startDate && errors.startDate}
                  />
                </FieldContainer>
                {values.addressType !== 'currentAddress' && (
                  <FieldContainer>
                    <DateField
                      name="endDate"
                      aria-label="End date"
                      placeholder="DD/MM/YYYY"
                      containerStyle={s('border-grey-200')}
                      style={s('text-sm')}
                      inputFormat="dd/MM/yyyy"
                      label={'End date'}
                      labelProps={{ style: s('tracking-normal mb-0 text-grey-800') }}
                      error={touched.endDate && errors.endDate}
                    />
                  </FieldContainer>
                )}

                <FieldContainer>
                  <SelectField
                    name="livingArrangement"
                    label="Living arrangement"
                    options={housingStatusListOptions.map((employment) => ({
                      label: employment.label,
                      value: employment.label,
                      key: employment.key,
                    }))}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={'Select type'}
                  />
                </FieldContainer>

                <div style={s('flex  mt-4')}>
                  <Button style={s('mr-2')} type="submit">
                    Save
                  </Button>
                  <Button onClick={close} variant="secondary">
                    Cancel
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </ModalCard>
      </Modal>
    </>
  )
}

export default Addresses
