/* eslint-disable complexity */
import React, { useState, forwardRef } from 'react'
import { useDispatch } from 'react-redux'
import { Formik, Form, useFormikContext } from 'formik'
import Card from 'components/Card'
import { updateRealEstateAsset, removeRealEstateAsset } from 'modules/application'
import { RealEstateAssets } from './RealEstateAssets'
import { s } from '@vega/styled/v2'
import { SubEntityActionButton, CollapseButton, Chip } from 'components/common'
import { formatCurrency, formatStringToNumber } from 'utils/formatters'
import * as Yup from 'yup'
import { ownershipSchema } from './AssetCard'

// TODO: move these to one place
export const valocityAddressSchema = Yup.object().shape({
  propertyAddress: Yup.string().required('Property address is required'),
  propertyId: Yup.string().required('Property ID is required'),
})

const rentalIncomeSchema = Yup.object()
  .shape({
    type: Yup.string().required('Income type is required'),
    amount: Yup.number()
      .min(0, 'Amount must be positive')
      .required('Amount is required'),
    frequency: Yup.string().required('Frequency is required'),
  })
  .nullable()

export const realEstateAssetSchema = Yup.object({
  primarySecurity: Yup.string(),
  transaction: Yup.string().required('Transaction is required'),
  securityType: Yup.string(),
  ownerships: Yup.array()
    .of(ownershipSchema)
    .test(
      'ownership-sum-100',
      'Total ownership percentage must equal 100%',
      (ownerships) => {
        const sum = ownerships.reduce((acc, ownership) => acc + ownership.percentage, 0)
        return sum === 100
      }
    ),
  zoning: Yup.object({
    domainType: Yup.string().nullable().required('Domain type is required'),
  }).required('Zoning is required'),
  address: valocityAddressSchema.required('Address is required'),
  primaryPurpose: Yup.string().required('Primary purpose is required'),
  primaryUsage: Yup.string().required('Primary usage is required'),
  holding: Yup.string().required('Holding is required'),
  propertyType: Yup.string().required('Property type is required'),
  valueBasis: Yup.string().required('Value basis is required'),
  value: Yup.number()
    .min(0, 'Value must be positive')
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .required('Value is required'),
  isUsedAsSecurity: Yup.boolean().required('Is used as security is required'),
  rentalIncomes: Yup.array()
    .of(rentalIncomeSchema)
    .default([{}])
    .required('Rental income information is required'),
})

const AutoDispatcher = ({ index, applicationId }) => {
  const dispatch = useDispatch()

  const { values } = useFormikContext()
  React.useEffect(() => {
    const formattedValues = {
      ...values,
      value: formatStringToNumber(values.value),
      rentalIncomes: values.rentalIncomes.map((rentalIncome) => {
        if (rentalIncome) {
          return {
            ...rentalIncome,
            amount: rentalIncome.amount ? formatStringToNumber(rentalIncome.amount) : 0,
          }
        }
        return []
      }),
      existingMortgage: {
        ...values.existingMortgage,
        amount: values?.existingMortgage?.amount
          ? formatStringToNumber(values.existingMortgage.amount)
          : 0,
      },
    }
    dispatch(
      updateRealEstateAsset({
        realEstateAssetIndex: index,
        realEstateAsset: formattedValues,
        id: applicationId,
      })
    )
  }, [dispatch, index, values, applicationId])
  return null
}

export const RealEstateAssetCard = forwardRef(
  ({ asset, index, applicationId, applicants }, ref) => {
    const dispatch = useDispatch()
    const [collapsed, setCollapsed] = useState(false)
    const toggleCollapased = () => setCollapsed(!collapsed)

    if (collapsed) {
      return (
        <Card style={s('mb-12')}>
          <div style={s('flex justify-end')}>
            <SubEntityActionButton
              style={s('bg-accent-red text-white', {
                width: 'auto',
                height: 'fit-content',
              })}
              onClick={() => {
                dispatch(
                  removeRealEstateAsset({
                    realEstateAssetIndex: index,
                    id: applicationId,
                  })
                )
              }}
            >
              Remove Real Estate Asset
            </SubEntityActionButton>
          </div>
          <h1 style={s('m-0 p-0 text-2xl', { flexGrow: 1 })}>
            {asset.address?.propertyAddress || '-'}
          </h1>
          <div style={s('flex')}>
            <Chip>{asset.transaction}</Chip>
          </div>
          <p>Value: {asset.value ? formatCurrency(asset.value) : '-'}</p>

          <CollapseButton onClick={toggleCollapased}>+ Expand</CollapseButton>
        </Card>
      )
    }

    return (
      <Card style={s('mb-12')} ref={ref} id={asset._id}>
        <Formik
          initialValues={asset}
          enableReinitialize
          validationSchema={realEstateAssetSchema}
        >
          {({ values, handleChange, handleBlur, setFieldValue, errors }) => (
            <Form>
              <div style={s('flex justify-end')}>
                <SubEntityActionButton
                  style={s('bg-accent-red text-white', { width: 'auto' })}
                  onClick={() => {
                    dispatch(
                      removeRealEstateAsset({
                        realEstateAssetIndex: index,
                        id: applicationId,
                      })
                    )
                  }}
                >
                  Remove Real Estate Asset
                </SubEntityActionButton>
              </div>
              <AutoDispatcher index={index} applicationId={applicationId} />
              <h1 style={s('m-0 p-0 text-2xl', { flexGrow: 1 })}>
                {asset.address?.propertyAddress || '-'}
              </h1>
              <RealEstateAssets
                handleChange={handleChange}
                handleBlur={handleBlur}
                values={values}
                index={index}
                applicants={applicants}
                setFieldValue={setFieldValue}
                errors={errors}
              />
              <CollapseButton onClick={toggleCollapased}>- Collapse</CollapseButton>
            </Form>
          )}
        </Formik>
      </Card>
    )
  }
)
RealEstateAssetCard.displayName = 'RealEstateAssetCard'
