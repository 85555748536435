import React from 'react'
import PropTypes from 'prop-types'
import { styled, s } from '@vega/styled/v2'

const { string, bool, object } = PropTypes

const Text = styled.p(s('m-0 mt-2 text-xs text-accent-red', { lineHeight: 1.3 }))

function FormMessage({ message, visible = false, id = '', ...otherProps }) {
  if (!visible) return null

  if (typeof message !== 'string') {
    // eslint-disable-next-line no-console
    console.error(
      `Message must be a string. If you see this message, please ensure you pass a string message and make sure all the fields have correct name attribute`
    )
  }

  return (
    <Text {...otherProps}>
      {message}
      <span id={id} style={{ paddingTop: '50vh' }} />
    </Text>
  )
}

FormMessage.propTypes = {
  id: string,
  message: string,
  visible: bool,
  messageStyle: object,
}

export { FormMessage }
