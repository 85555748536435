import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import { normalizeError } from '@vega/services'
import { applyOnlineService } from 'apiService'
import { initialExpensesData } from 'utils/initialExpensesData'

export const fetchAllApplications = createAsyncThunk(
  'application/getAllApplications',
  async ({ searchParams, pageIndex }, { rejectWithValue, signal }) => {
    try {
      const { searchTerm: q, filters = {}, sorting = {}, limit = 20 } = searchParams
      return await applyOnlineService.getAllApplications(
        {
          q,
          filters,
          limit,
          sorting,
          start: limit * pageIndex,
        },
        signal
      )
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

export const fetchApplications = createAsyncThunk(
  'application/getApplicationss',
  async (_, { rejectWithValue }) => {
    try {
      return await applyOnlineService.getApplications()
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

export const fetchApplication = createAsyncThunk(
  'application/getApplication',
  async (id, { rejectWithValue }) => {
    try {
      const application = await applyOnlineService.getApplication(id)
      return application
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

export const createApplication = createAsyncThunk(
  'application/createApplication',
  async () => {
    try {
      return await applyOnlineService.createApplication()
    } catch (err) {
      const error = await normalizeError(err)
      return error
    }
  }
)

export const removeApplication = createAsyncThunk(
  'application/removeApplication',
  async (id, { rejectWithValue }) => {
    try {
      return await applyOnlineService.removeApplication(id)
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

export const saveApplication = createAsyncThunk(
  'application/saveApplication',
  async (payload, { rejectWithValue }) => {
    try {
      return await applyOnlineService.saveApplication(payload, payload._id)
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

export const submitApplication = createAsyncThunk(
  'application/submitApplication',
  async (id, { rejectWithValue }) => {
    try {
      return await applyOnlineService.submitApplication(id)
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

const initialApplicant = {
  applicantType: 'person',
  title: '',
  firstName: '',
  middleName: '',
  lastName: '',
  isGuarantor: '',
  maritalStatus: '',
  gender: '',
  dateOfBirth: '',
  firstHomeBuyer: '',
  citizenship: '',
  permanentNzResident: '',
  residencyStatus: '',
  countryOfResidence: '',
  countryOfBirth: '',
  email: '',
  mobileNumber: {
    contactNumber: '',
    prefix: '',
    countryCode: '',
  },
  employments: [],
  otherIncomes: [],
  historicAddresses: [],
}

const initialHousehold = {
  numberOfDependants: 0,
  expenseGroups: initialExpensesData,
  members: [initialApplicant],
}

const initialCompany = {
  applicantType: 'company',
  countryRegistered: 'NZ',
  licenceNumber: '',
  businessName: '',
  description: '',
  nzbn: '',
  isHoldingCompany: '',
  isTradingCompany: '',
  isGstRegistered: '',
  isLookThroughCompany: '',
  officeAddress: '',
  officeEmail: '',
  mobileNumber: {
    contactNumber: '',
    prefix: '',
    countryCode: '',
  },
}

const initialTrust = {
  applicantType: 'trust',
  countryRegistered: 'NZ',
  trustName: '',
  trustType: '',
  establishmentDate: '',
  isTrustTradingAsCompany: '',
  officeAddress: '',
  officeEmail: '',
  mobileNumber: {
    contactNumber: '',
    prefix: '',
    countryCode: '',
  },
}

export const initialRealEstateAsset = {
  primarySecurity: '',
  transaction: '',
  securityType: '',
  ownerships: [],
  zoning: { domainType: '' || null },
  searchAddress: '', // TODO: Use Valocity
  primaryUsage: '',
  holding: '',
  address: {
    city: 'Auckland',
  },
  primaryPurpose: '',
  propertyType: '',
  valueBasis: '',
  value: 0,
  isUsedAsSecurity: false,
  rentalIncomes: [],
}

const initialAsset = {
  assetType: '',
  ownerships: [],
  description: '',
  institution: '',
  value: 0,
}

const initialLiability = {
  liabilityType: '',
  ownerships: [],
  isAutoPayment: false,
  isClearingFromThisLoan: '',
}

const initialFund = {
  fundType: '',
  description: '',
  value: 0,
}

const initialDeclarations = {
  loanObjective: '',
  importantTimeframes: '',
  depositSource: '',
  ratePreferences: '',
  repaymentPreferences: '',
  specialFeaturePreferences: '',
  hasPreviousDebtArrears: '',
  hasCurrentDebtArrears: '',
  hasBeenDirectorInPastSevenYears: '',
  hasGoodAccountConduct: '',
  hasFamilyInsuranceConfidence: '',
  hasHomeInsuranceConfidence: '',
  hasAdditionalRequirementsOrObjectives: '',
}

const initialValuesEntityType = (entityType) => {
  switch (entityType) {
    case 'person':
      return initialApplicant
    case 'company':
      return initialCompany
    case 'trust':
      return initialTrust
    default:
      return {}
  }
}

export const applicationAdapter = createEntityAdapter({
  selectId: (application) => application._id,
})

const initialState = applicationAdapter.getInitialState()

export const currentApplicationIdSlice = createSlice({
  name: 'currentApplicationId',
  initialState: '',
  reducers: {
    setCurrentApplicationId: (state, action) => {
      return action.payload
    },
  },
})

export const applicationSlice = createSlice({
  name: 'applications',
  initialState,
  reducers: {
    addApplicant: (state, action) => {
      const { id } = action.payload
      const existingApplication = state.entities[id]
      if (existingApplication) {
        existingApplication.applicants.push(initialApplicant)
      }
    },

    addHousehold: (state, action) => {
      const { id } = action.payload
      const existingApplication = state.entities[id]

      if (existingApplication) {
        existingApplication.households.push(initialHousehold)
      }
    },

    addCompany: (state, action) => {
      const { id } = action.payload
      const existingApplication = state.entities[id]

      if (existingApplication) {
        existingApplication.applicants.push(initialCompany)
      }
    },

    addTrust: (state, action) => {
      const { id } = action.payload
      const existingApplication = state.entities[id]

      if (existingApplication) {
        existingApplication.applicants.push(initialTrust)
      }
    },

    addNewHouseholdMember: (state, action) => {
      const { applicationId, householdIndex } = action.payload
      const existingApplication = state.entities[applicationId]

      if (existingApplication) {
        existingApplication.households[householdIndex].members.push(initialApplicant)
      }
    },

    updateHousehold: (state, action) => {
      const { applicationId, householdIndex, household } = action.payload
      const existingApplication = state.entities[applicationId]

      if (existingApplication) {
        state.entities[applicationId].households[householdIndex] = household
      }

      return state
    },

    initializeApplicants: (state, action) => {
      const { id } = action.payload
      state.entities[id].applicants[
        action.payload.applicantIndex
      ] = initialValuesEntityType(action.payload.entityType)
    },

    updateApplicant: (state, action) => {
      const { id } = action.payload
      state.entities[id].applicants[action.payload.index] = action.payload.applicant
      return state
    },

    removeApplicant: (state, action) => {
      const { id } = action.payload
      state.entities[id].applicants = state.entities[id].applicants.filter(
        (_, index) => index !== action.payload.applicantIndex
      )
      return state
    },

    removeHousehold: (state, action) => {
      const { id, householdId } = action.payload
      state.entities[id].households = state.entities[id].households.filter(
        (household) => household._id !== householdId
      )
      return state
    },

    removeSecondMember: (state, action) => {
      const { applicationId, householdIndex, memberId } = action.payload
      state.entities[applicationId].households[householdIndex].members = state.entities[
        applicationId
      ].households[householdIndex].members.filter((member) => member._id !== memberId)
      return state
    },

    upsertEmployment: (state, action) => {
      const {
        applicationId,
        householdIndex,
        memberIndex,
        employmentIndex,
        employment,
      } = action.payload

      state.entities[applicationId].households[householdIndex].members[
        memberIndex
      ].employments[employmentIndex] = employment

      return state
    },

    upsertOtherIncome: (state, action) => {
      const {
        applicationId,
        householdIndex,
        memberIndex,
        otherIncomeIndex,
        otherIncome,
      } = action.payload

      state.entities[applicationId].households[householdIndex].members[
        memberIndex
      ].otherIncomes[otherIncomeIndex] = otherIncome

      return state
    },

    upsertAddress: (state, action) => {
      const {
        applicationId,
        householdIndex,
        memberIndex,
        addressIndex,
        address,
      } = action.payload

      state.entities[applicationId].households[householdIndex].members[
        memberIndex
      ].historicAddresses[addressIndex] = address

      return state
    },

    removeEmployment: (state, action) => {
      const {
        applicationId,
        householdIndex,
        memberIndex,
        employmentIndex,
      } = action.payload

      const currentEmployments =
        state.entities[applicationId].households[householdIndex].members[memberIndex]
          .employments

      const newEmployments = currentEmployments.filter(
        (_, index) => index !== employmentIndex
      )

      state.entities[applicationId].households[householdIndex].members[
        memberIndex
      ].employments = newEmployments

      return state
    },

    removeOtherIncome: (state, action) => {
      const {
        applicationId,
        householdIndex,
        memberIndex,
        otherIncomeIndex,
      } = action.payload

      const currentOtherIncomes =
        state.entities[applicationId].households[householdIndex].members[memberIndex]
          .otherIncomes

      const newOtherIncomes = currentOtherIncomes.filter(
        (_, index) => index !== otherIncomeIndex
      )

      state.entities[applicationId].households[householdIndex].members[
        memberIndex
      ].otherIncomes = newOtherIncomes

      return state
    },

    removeAddress: (state, action) => {
      const {
        applicationId,
        householdIndex,
        memberIndex,
        addressIndex,
      } = action.payload

      const currentAddresses =
        state.entities[applicationId].households[householdIndex].members[memberIndex]
          .historicAddresses

      const newAddresses = currentAddresses.filter((_, index) => index !== addressIndex)

      state.entities[applicationId].households[householdIndex].members[
        memberIndex
      ].historicAddresses = newAddresses

      return state
    },

    addRealEstateAsset: (state, action) => {
      const { id } = action.payload
      state.entities[id].financialPosition.realEstateAssets = state.entities[
        id
      ].financialPosition.realEstateAssets.concat(initialRealEstateAsset)
      return state
    },

    updateRealEstateAsset: (state, action) => {
      const { id, realEstateAssetIndex, realEstateAsset } = action.payload

      state.entities[id].financialPosition.realEstateAssets[
        realEstateAssetIndex
      ] = realEstateAsset

      return state
    },

    removeRealEstateAsset: (state, action) => {
      const { id, realEstateAssetIndex } = action.payload

      state.entities[id].financialPosition.realEstateAssets = state.entities[
        id
      ].financialPosition.realEstateAssets.filter(
        (_, index) => index !== realEstateAssetIndex
      )

      return state
    },

    addPropertyAsset: (state, action) => {
      const { id, transaction, isUsedAsSecurity = 'false' } = action.payload

      state.entities[id].financialPosition.realEstateAssets = state.entities[
        id
      ].financialPosition.realEstateAssets.concat({
        ...initialRealEstateAsset,
        transaction,
        isUsedAsSecurity,
      })

      return state
    },

    addAsset: (state, action) => {
      const { id } = action.payload

      state.entities[id].financialPosition.assets = state.entities[
        id
      ].financialPosition.assets.concat(initialAsset)

      return state
    },

    updateAsset: (state, action) => {
      const { id, assetIndex, asset } = action.payload

      state.entities[id].financialPosition.assets[assetIndex] = asset

      return state
    },

    removeAsset: (state, action) => {
      const { id, assetIndex } = action.payload

      state.entities[id].financialPosition.assets = state.entities[
        id
      ].financialPosition.assets.filter((_, index) => index !== assetIndex)

      return state
    },

    addLiability: (state, action) => {
      const { id } = action.payload

      state.entities[id].financialPosition.liabilities = state.entities[
        id
      ].financialPosition.liabilities.concat(initialLiability)

      return state
    },

    updateLiability: (state, action) => {
      const { id, liabilityIndex } = action.payload

      state.entities[id].financialPosition.liabilities[liabilityIndex] =
        action.payload.liability

      return state
    },

    removeLiability: (state, action) => {
      const { id, liabilityIndex } = action.payload

      state.entities[id].financialPosition.liabilities = state.entities[
        id
      ].financialPosition.liabilities.filter((_, index) => index !== liabilityIndex)

      return state
    },

    updateLivingExpenses: (state, action) => {
      const {
        applicationId,
        householdId,
        expenseGroupIndex,
        expenseGroup,
      } = action.payload

      const householdIndex = state.entities[applicationId].households.findIndex(
        (household) => household._id === householdId
      )
      state.entities[applicationId].households[householdIndex].expenseGroups[
        expenseGroupIndex
      ] = expenseGroup

      return state
    },

    addFund: (state, action) => {
      const { id } = action.payload

      state.entities[id].fundingDetails.availableFunds = state.entities[
        id
      ].fundingDetails.availableFunds.concat(initialFund)

      return state
    },

    updateFund: (state, action) => {
      const { id, assetIndex, asset } = action.payload

      state.entities[id].fundingDetails.availableFunds[assetIndex] = asset

      return state
    },

    removeFund: (state, action) => {
      const { id, assetIndex } = action.payload

      state.entities[id].fundingDetails.availableFunds = state.entities[
        id
      ].fundingDetails.availableFunds.filter((_, index) => index !== assetIndex)

      return state
    },

    updateExpenseGroup: (state, action) => {
      const { id, householdId, expenseGroupIndex, expenseGroup } = action.payload

      state.entities[id].households[householdId].expenseGroups[
        expenseGroupIndex
      ] = expenseGroup

      return state
    },

    updateDeclarations: (state, action) => {
      const { id, declarations } = action.payload

      if (!state.entities[id]) {
        state.entities[id] = {}
      }

      state.entities[id].declarations = declarations

      return state
    },

    resetDeclarations: (state, action) => {
      const { id } = action.payload

      state.entities[id].declarations = initialDeclarations

      return state
    },

    updateNotes: (state, action) => {
      const { id, values } = action.payload

      if (!state.entities[id]) {
        state.entities[id] = {}
      }

      state.entities[id].notesAndSupportdocs = values

      return state
    },

    resetNotes: (state, action) => {
      const { id } = action.payload
      state.entities[id].notesAndSupportdocs.notes = ''
      return state
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchApplications.fulfilled, (state, action) => {
        applicationAdapter.setAll(state, action.payload)
      })
      .addCase(fetchAllApplications.fulfilled, (state, action) => {
        const { items: applications, pagination } = action.payload
        applicationAdapter.setAll(state, applications)
        state.total = pagination.total
      })
      .addCase(createApplication.fulfilled, (state, action) => {
        applicationAdapter.upsertOne(state, action.payload)
      })
      .addCase(fetchApplication.fulfilled, (state, action) => {
        applicationAdapter.upsertOne(state, action.payload)
      })
      .addCase(saveApplication.fulfilled, (state, action) => {
        applicationAdapter.upsertOne(state, action.payload)
      })
      .addCase(removeApplication.fulfilled, (state, action) => {
        applicationAdapter.removeOne(state, action.meta.arg)
      })
  },
})

export const {
  addHousehold,
  addCompany,
  addTrust,
  updateHousehold,
  addApplicant,
  addNewHouseholdMember,
  updateApplicant,
  removeApplicant,
  removeHousehold,
  upsertEmployment,
  upsertOtherIncome,
  upsertAddress,
  removeEmployment,
  removeOtherIncome,
  removeAddress,
  initializeApplicants,
  addRealEstateAsset,
  updateRealEstateAsset,
  removeRealEstateAsset,
  addPropertyAsset,
  addAsset,
  updateAsset,
  removeAsset,
  addLiability,
  updateLiability,
  removeLiability,
  updateLivingExpenses,
  addFund,
  updateFund,
  removeFund,
  resetDeclarations,
  updateDeclarations,
  updateNotes,
  resetNotes,
  removeSecondMember,
} = applicationSlice.actions

export const { setCurrentApplicationId } = currentApplicationIdSlice.actions
const { reducer: applicationReducer } = applicationSlice
const { reducer: currentApplicationIdReducer } = currentApplicationIdSlice
export { applicationReducer, currentApplicationIdReducer }
