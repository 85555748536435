import React from 'react'
import { styled, s } from '@vega/styled'

const MathSquare = styled.sup(s('text-xs'))

function SquareMeterValue({ value, ...otherProps }) {
  return (
    <span {...otherProps}>
      {value}m<MathSquare>2</MathSquare>
    </span>
  )
}

export { SquareMeterValue }
