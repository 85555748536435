import React from 'react'
import { prop, and } from '@neo/ramda-extra'
import { useField } from 'formik'
import { ErrorMessage } from '../ErrorMessage'
import { Textarea } from './Textarea'
import { styled, s } from '@vega/styled'

const Container = styled.div(s('flex flex-column'), prop('containerStyle'))

const Label = styled.label(
  s('text-base font-normal text-grey-700 mb-4', {
    pointerEvents: 'none',
  })
)

function FormTextarea({
  name,
  label,
  labelStyle,
  rows,
  placeholder,
  children,
  containerStyle,
  textareaStyle,
  isOptional,
  ...otherProps
}) {
  const [{ value, onChange }, { touched, error }] = useField({
    name,
  })

  const hasError = and(touched, error)

  return (
    <Container containerStyle={containerStyle}>
      <Label style={labelStyle}>{label}</Label>

      <Textarea
        name={name}
        rows={rows}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        textareaStyle={textareaStyle}
        {...otherProps}
      >
        {children}
      </Textarea>

      {hasError && <ErrorMessage>{error}</ErrorMessage>}
    </Container>
  )
}

export { FormTextarea }
