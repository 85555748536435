export const LOAN_PURPOSE = {
  FirstHome: 'owner_occupied_first_home',
  OwnerOccupied: 'owner_occupied',
  ResidentialInvestment: 'residential_investment',
  CommercialInvestment: 'commercial_investment',
  Refinance: 'refinance',
  VacantLand: 'vacant_land',
  BridgingFinance: 'bridging_finance',
  Construction: 'construction',
  TopUp: 'top_up',
  HolidayHome: 'holiday_home',
  DebtConsolidation: 'debt_consolidation',
  EquityRelease: 'equity_release',
  AssetFinance: 'asset_finance',
  Restructure: 'restructure',
  BusinessFinance: 'business_finance',
  VehicleFinance: 'vehicle_finance',
  PersonalFinance: 'personal_finance',
}

export const LOAN_PURPOSE_DISPLAY_TEXT = {
  [LOAN_PURPOSE.FirstHome]: 'First Home',
  [LOAN_PURPOSE.OwnerOccupied]: 'Owner Occupied',
  [LOAN_PURPOSE.ResidentialInvestment]: 'Residential Investment',
  [LOAN_PURPOSE.CommercialInvestment]: 'Commercial Investment',
  [LOAN_PURPOSE.Refinance]: 'Refinance',
  [LOAN_PURPOSE.VacantLand]: 'Vacant Land',
  [LOAN_PURPOSE.BridgingFinance]: 'Bridging Finance',
  [LOAN_PURPOSE.Construction]: 'Construction',
  [LOAN_PURPOSE.TopUp]: 'Top Up',
  [LOAN_PURPOSE.HolidayHome]: 'Holiday Home',
  [LOAN_PURPOSE.DebtConsolidation]: 'Debt Consolidation',
  [LOAN_PURPOSE.EquityRelease]: 'Equity Release',
  [LOAN_PURPOSE.AssetFinance]: 'Asset Finance',
  [LOAN_PURPOSE.Restructure]: 'Restructure',
  [LOAN_PURPOSE.BusinessFinance]: 'Business Finance',
  [LOAN_PURPOSE.VehicleFinance]: 'Vehicle Finance',
  [LOAN_PURPOSE.PersonalFinance]: 'Personal Finance',
}
