/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { s, styled } from '@vega/styled/v2'
import { ReactComponent as DownloadButtonIcon } from '@vega/components/src/assets/images/download.svg'
import { ReactComponent as PaperClipIconBase } from '@vega/components/src/assets/images/paper-clip.svg'
import { useDispatch } from 'react-redux'
import { createSupportingDocument } from 'modules/supportingDocument'
import { useParams } from 'react-router-dom'

const Container = styled.div(
  s(`
  flex flex-wrap items-start
  -ml-2 -mt-2
`)
)

const DownloadButton = styled.button(
  s(`
  bg-white border-1 border-solid border-primary text-primary 
  text-sm p-1 rounded-lg font-semibold w-2 h- flex-shrink-0
  hover:bg-primary hover:text-white transition-colors duration-200
`),
  {
    cursor: 'pointer',
    letterSpacing: '0.01em',
    lineHeight: 1.5,
  }
)

const FileLabel = styled.p(
  s(`
  m-0 text-grey-900 text-sm font-semibold mb-1 truncate
`),
  {
    letterSpacing: '0.01em',
    lineHeight: 1.5,
  }
)

const FileName = styled.p(
  s(`
  m-0 text-grey-800 text-sm font-normal truncate
`),
  {
    letterSpacing: '0.01em',
    lineHeight: 1.5,
  }
)

const PaperClipIcon = styled(PaperClipIconBase)(s('text-grey-700'))

const PaperClipIconContainer = styled.div(
  s(`
  bg-grey-100 rounded-lg w-2 h-2 p-1 flex-shrink-0
`)
)

const TextContainer = styled.div(
  s(`
  min-w-0 flex-grow basis-full sm:basis-auto
  ml-2 mt-2
`)
)

const IconWrapper = styled.div(
  s(`
  flex items-center
  ml-2 mt-2
`)
)

// adds spaces before capital letters in a joint string
// also caters for acronymns
// e.g. SupportingDocument => Supporting Document
// e.g. LetterFromWINZ => Letter From WINZ
const insertSpaces = (classification) => {
  const stringWithSpaces = classification.replace(/([a-z])([A-Z])/g, '$1 $2')
  return stringWithSpaces.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
}

const SupportingDoc = ({ document = {}, ...props }) => {
  const { id } = useParams()
  const { classification, fileKey, presignedUrl, references = [] } = document

  const dispatch = useDispatch()

  const handelDownloadLogging = async (file, fileType) => {
    await dispatch(
      createSupportingDocument({
        classification: fileType,
        file,
        applicationId: id,
        references,
        source: 'mycrm',
        logDownload: true,
      })
    )
  }

  return (
    <Container {...props}>
      <IconWrapper>
        <a
          href={presignedUrl}
          target="_blank"
          onClick={() => handelDownloadLogging(fileKey, classification)}
        >
          <DownloadButton type="button">
            <DownloadButtonIcon />
          </DownloadButton>
        </a>
        <PaperClipIconContainer style={s('ml-2')}>
          <PaperClipIcon />
        </PaperClipIconContainer>
      </IconWrapper>
      <TextContainer>
        <FileLabel>{insertSpaces(classification) ?? '-'}</FileLabel>
        <FileName>{fileKey ?? '-'}</FileName>
      </TextContainer>
    </Container>
  )
}

export { SupportingDoc }
