import { isNilOrEmpty } from '@neo/ramda-extra'

export const createCustomError = ({ type, description, ...otherErrorProps }) => {
  const isDev = process.env.NODE_ENV === 'development'
  if (isDev) customErrorValidator(type, description)

  return {
    type,
    description,
    meta: otherErrorProps,
    isCustomError: true,
  }
}

const isNotString = (input) => typeof input !== 'string'

function customErrorValidator(type, description) {
  if (isNotString(type)) {
    throw new Error(
      'ERROR [error-standardizer]: createCustomError: type must be in type string'
    )
  }
  if (isNilOrEmpty(type)) {
    throw new Error(
      'ERROR [error-standardizer]: createCustomError: description must not be empty'
    )
  }

  if (isNotString(description)) {
    throw new Error(
      'ERROR [error-standardizer]: createCustomError: description must be in type string'
    )
  }
  if (isNilOrEmpty(description)) {
    throw new Error(
      'ERROR [error-standardizer]: createCustomError: description must not be empty'
    )
  }
}
