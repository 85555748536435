import React from 'react'
import { SubTitle, SectionContainer, FieldContainer } from 'components/common'
import {
  yesNoOptions,
  countryOptions,
  residencyStatusOptions,
} from 'utils/dropdownLists'
import { ValidatedSelectField } from 'components/SelectField'

const ResidencyDetails = ({ memberIndex, handleChange, handleBlur }) => (
  <SectionContainer>
    <SubTitle>Residency details</SubTitle>

    <FieldContainer>
      <ValidatedSelectField
        name={`members[${memberIndex}].citizenship`}
        label="Citizenship*"
        options={countryOptions.map((title) => ({
          label: title.name,
          value: title.code,
          key: title.code,
        }))}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={'Citizenship'}
        selectMenuStyles={{
          height: '300px',
          overflowY: 'scroll',
          scrollbarWidth: 'auto',
          '&::-webkit-scrollbar': {
            width: '15px',
            height: 0,
          },
          '&::-webkit-scrollbar-track': {
            background: '#e0e0e0', // Color of the track
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#888', // Color of the thumb
          },
        }}
      />
    </FieldContainer>

    <FieldContainer>
      <ValidatedSelectField
        name={`members[${memberIndex}].permanentNzResident`}
        label="Is a permanent resident of New Zealand*"
        options={yesNoOptions.map((title) => ({
          label: title.label,
          value: title.key,
          key: title.key,
        }))}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={'Permanant resident of NZ?'}
      />
    </FieldContainer>

    <FieldContainer>
      <ValidatedSelectField
        name={`members[${memberIndex}].residencyStatus`}
        label="Residency status*"
        options={residencyStatusOptions.map((residencyStatus) => ({
          label: residencyStatus.label,
          value: residencyStatus.key,
          key: residencyStatus.key,
        }))}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={'Residency status'}
      />
    </FieldContainer>

    <FieldContainer>
      <ValidatedSelectField
        name={`members[${memberIndex}].countryOfResidence`}
        label="Country of residence*"
        options={countryOptions.map((country) => ({
          label: country.name,
          value: country.code,
          key: country.code,
        }))}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={'Country of residence'}
        selectMenuStyles={{
          height: '300px',
          overflowY: 'scroll',
          scrollbarWidth: 'auto',
          '&::-webkit-scrollbar': {
            width: '15px',
            height: 0,
          },
          '&::-webkit-scrollbar-track': {
            background: '#e0e0e0', // Color of the track
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#888', // Color of the thumb
          },
        }}
      />
    </FieldContainer>

    <FieldContainer>
      <ValidatedSelectField
        name={`members[${memberIndex}].countryOfBirth`}
        label="Country of birth*"
        options={countryOptions.map((country) => ({
          label: country.name,
          value: country.code,
          key: country.code,
        }))}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={'Country of birth'}
        selectMenuStyles={{
          height: '300px',
          overflowY: 'scroll',
          scrollbarWidth: 'auto',
          '&::-webkit-scrollbar': {
            width: '15px',
            height: 0,
          },
          '&::-webkit-scrollbar-track': {
            background: '#e0e0e0', // Color of the track
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#888', // Color of the thumb
          },
        }}
      />
    </FieldContainer>
  </SectionContainer>
)

export default ResidencyDetails
