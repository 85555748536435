import React from 'react'
import { prop } from '@neo/ramda-extra'

import { useOption } from '@react-aria/listbox'

import { s, styled } from '@vega/styled'

const Item = styled.li(
  s('text-grey-800 text-base font-medium'),
  {
    padding: 10,
    paddingLeft: 18,
    cursor: 'pointer',
    lineHeight: 1.5,
  },
  ({ isFocused }) => isFocused && s('bg-green-500'),
  prop('optionStyles')
)

function Option({ item, state, shouldUseVirtualFocus, optionStyles }) {
  const ref = React.useRef()
  const isDisabled = state.disabledKeys.has(item.key)
  const isSelected = state.selectionManager.isSelected(item.key)
  const isFocused = state.selectionManager.focusedKey === item.key

  const { optionProps } = useOption(
    {
      key: item.key,
      isDisabled,
      isSelected,
      shouldSelectOnPressUp: true,
      shouldFocusOnHover: true,
      shouldUseVirtualFocus,
    },
    state,
    ref
  )

  return (
    <Item
      {...optionProps}
      ref={ref}
      isFocused={isFocused}
      isSelected={isSelected}
      isDisabled={isDisabled}
      optionStyles={optionStyles}
    >
      {item.rendered}
    </Item>
  )
}

export { Option }
