import {
  ApplyOnlineService,
  UserService,
  ApplyOnlineSupportingDocumentService,
} from '@vega/services'

const applyOnlineApiUri = process.env.REACT_APP_APPLY_ONLINE_API_URI
const profileApiUri = process.env.REACT_APP_PROFILE_API_URI

const getOptions = (baseUri) => ({ baseUri })

export const applyOnlineService = new ApplyOnlineService(getOptions(applyOnlineApiUri))
export const userService = new UserService(getOptions(profileApiUri))

export const supportingDocumentService = new ApplyOnlineSupportingDocumentService(
  getOptions(applyOnlineApiUri)
)
