import BaseService from '../baseService'
import { QueryBuilder } from '../utils'

import { isNil } from '@neo/ramda-extra'

export class UserService extends BaseService {
  fetchUsers({ q, filters, limit, start }, signal) {
    const searchParams = QueryBuilder()
      .setQ(q)
      .setFilters(filters)
      .setPaging({ start, limit })
      .build()

    return this.client.get(`users/?${searchParams}`, { signal }).json()
  }

  fetchUser(id) {
    if (isNil(id)) throw new Error('Please provide an user id')

    return this.client.get(`users/${id}`).json()
  }

  toggleSuspension({ id, suspended }) {
    if (isNil(id)) throw new Error('Please provide an user id')

    const config = {
      json: { suspended },
    }
    return this.client.patch(`users/${id}`, config).json()
  }
}
