import React from 'react'
import { styled, s } from '@vega/styled'
import { ReactComponent as LeftIcon } from '../assets/images/chevron-left.svg'

const Container = styled.div(s('px-8 flex justify-between items-center bg-green-50'))

const Title = styled.h2(s('text-3xl text-grey-800 font-normal mt-8 mb-3'))

const Left = styled.div(s('flex'))

const BackButton = styled.button(
  s('bg-transparent border-0 mr-4', { outline: 'none', cursor: 'pointer' })
)

function PageHeader({ title, children, onBackClick, ...otherProps }) {
  return (
    <Container {...otherProps}>
      <Left>
        {onBackClick && (
          <BackButton onClick={onBackClick}>
            <LeftIcon />
          </BackButton>
        )}
        <Title>{title}</Title>
      </Left>
      {children}
    </Container>
  )
}

export default PageHeader
