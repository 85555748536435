import React, { useState } from 'react'
import {
  SectionContainer,
  SubTitle,
  FieldContainer,
  Label,
  SubEntityCard,
  SubEntityActionButton,
  Chip,
  CollapseButton,
} from 'components/common'
import { frequencyShortListOptions } from 'utils/dropdownLists'
import { useDispatch } from 'react-redux'
import { upsertOtherIncome, removeOtherIncome } from 'modules/application'

import { isNilOrEmpty } from '@solta/ramda-extra'
import { Formik, Form } from 'formik'
import { Modal, SelectField, NumberField, Button } from '@vega/components'
import { s, styled } from '@vega/styled/v2'
import { larger } from '@vega/styled'
import Card from 'components/Card'
import { ReactComponent as XBase } from '@vega/components/src/assets/images/x.svg'
import { capitaliseFirstLetter, formatStringToNumber } from 'utils/formatters'
import * as Yup from 'yup'

const otherIncomeTypeOptions = [
  { key: 'annuities', label: 'Annuities' },
  { key: 'board', label: 'Board' },
  { key: 'childMaintenance', label: 'Child Maintenance' },
  { key: 'dividends', label: 'Dividends' },
  { key: 'foreignSourced', label: 'Foreign Sourced' },
  { key: 'governmentBenefits', label: 'Government Benefits' },
  { key: 'interestIncome', label: 'Interest Income' },
  { key: 'managedInvestment', label: 'Managed Investment' },
  { key: 'otherIncome', label: 'Other Income' },
  { key: 'privatePension', label: 'Private Pension' },
  { key: 'salarySacrificeContribution', label: 'Salary Sacrifice Contribution' },
  { key: 'superConcessionalContribution', label: 'Super Concessional Contribution' },
  {
    key: 'superExcessConcessionalContribution',
    label: 'Super Excess Concessional Contribution',
  },
  {
    key: 'superNonConcessionalContribution',
    label: 'Super Non Concessional Contribution',
  },
  { key: 'superannuation', label: 'Superannuation' },
  { key: 'workersCompensation', label: 'Workers Compensation' },
]

const proofCodePAYEListOptions = [
  { key: 'employmentContract', label: 'Employment Contract' },
  { key: 'groupCertificate', label: 'Group Certificate' },
  { key: 'letterFromEmployer', label: 'Letter from Employer' },
  { key: 'payslip', label: 'Payslip' },
  { key: 'taxReturn', label: 'Tax Return' },
]

const incomesValadiationSchema = Yup.object().shape({
  incomeType: Yup.string().required('Income Type is required'),
  amount: Yup.string().required('Amount is required'),
  frequency: Yup.string().required('Frequency is required'),
  verificationType: Yup.string().required('Verification Type is required'),
})

// TODO: is this modal stuff duplicated
const ModalCard = styled(Card)(
  s('rounded-none', { height: '100vh', width: '100vw', overflowY: 'scroll' }),
  larger('xl')(s(' h-full rounded-lg', { width: '60rem' }))
)

const X = styled(XBase)(s('text-primary', { cursor: 'pointer' }))

// TODO: figure out why this needs to be inlined!
const formatCurrency = (value, digits = 0, placeholder = '-') => {
  if (isNilOrEmpty(value) || typeof value !== 'number') return placeholder

  return new Intl.NumberFormat('en-NZ', {
    style: 'currency',
    currency: 'NZD',
    maximumFractionDigits: digits,
  }).format(value)
}

// TODO: doesn't the module know about this?
const initialValues = {
  incomeType: '',
  amount: 0,
  frequency: '',
  verificationType: '',
}

// TODO: abstract style
const OtherIncomes = ({ applicationId, householdIndex, memberIndex, member }) => {
  const dispatch = useDispatch()
  const { otherIncomes } = member
  const [isOpen, setIsOpen] = useState(false)
  const [currentOtherIncome, setCurrentOtherIncome] = useState(initialValues)
  const [currentOtherIncomeIndex, setCurrentOtherIncomeIndex] = useState(0)
  const [isEditing, setIsEditing] = useState(false)

  const totalOtherIncome = otherIncomes.reduce((acc, curr) => acc + curr.amount, 0)

  const close = () => setIsOpen(false)

  const handleSubmit = (values) => {
    const formattedValues = {
      ...values,
      amount: formatStringToNumber(values.amount),
    }

    dispatch(
      upsertOtherIncome({
        applicationId,
        householdIndex,
        memberIndex,
        otherIncomeIndex: currentOtherIncomeIndex,
        otherIncome: formattedValues,
      })
    )
    close()
  }

  const otherIncomeCards = otherIncomes.map((otherIncome, otherIncomeIndex) => (
    <SubEntityCard key={otherIncomeIndex}>
      <div style={s('flex')}>
        <div style={s('text-lg', { flexGrow: 1 })}>
          <div style={s('font-semibold')}>{otherIncome.incomeType}</div>

          <div>
            {formatCurrency(otherIncome.amount)}
            {' - '}
            {capitaliseFirstLetter(otherIncome.frequency)}
          </div>

          <div style={s('flex')}>
            <Chip>{otherIncome.verificationType}</Chip>
          </div>
        </div>

        <div>
          <SubEntityActionButton
            onClick={() => {
              setCurrentOtherIncome(otherIncome)
              setCurrentOtherIncomeIndex(otherIncomeIndex)
              setIsEditing(true)
              setIsOpen(true)
            }}
          >
            Edit
          </SubEntityActionButton>

          <SubEntityActionButton
            style={s('bg-accent-red text-white')}
            onClick={() =>
              dispatch(
                removeOtherIncome({
                  applicationId,
                  householdIndex,
                  memberIndex,
                  otherIncomeIndex,
                })
              )
            }
          >
            Remove
          </SubEntityActionButton>
        </div>
      </div>
    </SubEntityCard>
  ))

  return (
    <>
      <SectionContainer>
        <SubTitle>Other incomes - {formatCurrency(totalOtherIncome)}</SubTitle>

        {otherIncomes.length === 0 && (
          <FieldContainer>
            <Label>No employments added yet</Label>
          </FieldContainer>
        )}

        {otherIncomeCards}

        <CollapseButton
          onClick={() => {
            setCurrentOtherIncome(initialValues)
            setCurrentOtherIncomeIndex(otherIncomes.length)
            setIsEditing(false)
            setIsOpen(true)
          }}
        >
          + Add other income
        </CollapseButton>
      </SectionContainer>

      <Modal isOpen={isOpen} onClose={close}>
        <ModalCard>
          <div style={s('flex justify-end')}>
            <SubTitle style={s('m-0', { flexGrow: 1 })}>
              {`${isEditing ? 'Edit' : 'Add'} other income`}
            </SubTitle>
            <X onClick={close} />
          </div>

          <Formik
            initialValues={currentOtherIncome}
            onSubmit={handleSubmit}
            validationSchema={incomesValadiationSchema}
          >
            {({ handleChange, handleBlur }) => (
              <Form>
                <FieldContainer>
                  <SelectField
                    name="incomeType"
                    label="Income type"
                    options={otherIncomeTypeOptions.map((employment) => ({
                      label: employment.label,
                      value: employment.label,
                      key: employment.key,
                    }))}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={'Select type'}
                    selectMenuStyles={{
                      height: '300px',
                      overflowY: 'scroll',
                      scrollbarWidth: 'auto',
                      '&::-webkit-scrollbar': {
                        width: '15px',
                        height: 0,
                      },
                      '&::-webkit-scrollbar-track': {
                        background: '#e0e0e0', // Color of the track
                      },
                      '&::-webkit-scrollbar-thumb': {
                        background: '#888', // Color of the thumb
                      },
                    }}
                  />
                </FieldContainer>

                <FieldContainer>
                  <SelectField
                    name="frequency"
                    label="Frequency"
                    options={frequencyShortListOptions.map((employment) => ({
                      label: employment.label,
                      value: employment.label,
                      key: employment.key,
                    }))}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={'Select frequency'}
                    selectMenuStyles={{
                      height: '300px',
                      overflowY: 'scroll',
                      scrollbarWidth: 'auto',
                      '&::-webkit-scrollbar': {
                        width: '15px',
                        height: 0,
                      },
                      '&::-webkit-scrollbar-track': {
                        background: '#e0e0e0', // Color of the track
                      },
                      '&::-webkit-scrollbar-thumb': {
                        background: '#888', // Color of the thumb
                      },
                    }}
                  />
                </FieldContainer>

                <FieldContainer>
                  <NumberField
                    name="amount"
                    label="Amount"
                    type="number"
                    placeholder="Amount"
                    style={s('bg-white')}
                    prefix="$"
                    thousandSeparator
                    labelStyle={s('text-base')}
                  />
                </FieldContainer>

                <FieldContainer>
                  <SelectField
                    name="verificationType"
                    label="Verification type"
                    options={proofCodePAYEListOptions.map((employment) => ({
                      label: employment.label,
                      value: employment.key,
                      key: employment.key,
                    }))}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={'Select Type'}
                  />
                </FieldContainer>

                <div style={s('flex  mt-4')}>
                  <Button style={s('mr-2')} type="submit">
                    Save
                  </Button>
                  <Button onClick={close} variant="secondary">
                    Cancel
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </ModalCard>
      </Modal>
    </>
  )
}

export default OtherIncomes
