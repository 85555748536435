import React from 'react'
import { s, styled } from '@vega/styled/v2'
import Card from 'components/Card'
import { Form } from 'formik'
import { NumberField, Button } from '@vega/components'
import { ValidatedSelectField } from 'components/SelectField'

const Label = styled.label(s('text-base'))

const activeButtonStyle = s(
  'text-base bg-white text-primary mr-2 rounded-lg px-2 font-semibold border border-primary py-1 mt-2 mb-2',
  { cursor: 'pointer' }
)

const inactiveButtonStyle = s(
  'text-base bg-white text-grey-600 mr-2 rounded-lg px-2 font-semibold border border-grey-600 py-1 mt-2 mb-2',
  { cursor: 'pointer' }
)

const interestOnlyTermOptions = [
  { value: '12', label: '1 year' },
  { value: '24', label: '2 years' },
  { value: '36', label: '3 years' },
  { value: '48', label: '4 years' },
  { value: '60', label: '5 years' },
]

/* eslint-disable complexity */
const RepaymentInputs = ({
  values,
  handleChange,
  handleBlur,
  setFieldValue,
  floatingRate,
}) => (
  <Card>
    <Form>
      <h2>Your loan</h2>

      <Label>Loan Purpose</Label>
      <div style={{ display: 'flex' }}>
        <button
          style={
            values.loanPurpose === 'firstHome'
              ? activeButtonStyle
              : inactiveButtonStyle
          }
          onClick={() => setFieldValue('loanPurpose', 'firstHome')}
        >
          First Home
        </button>

        <button
          style={
            values.loanPurpose === 'nextHome'
              ? activeButtonStyle
              : inactiveButtonStyle
          }
          onClick={() => setFieldValue('loanPurpose', 'nextHome')}
        >
          Next home
        </button>

        <button
          style={
            values.loanPurpose === 'investment'
              ? activeButtonStyle
              : inactiveButtonStyle
          }
          onClick={() => setFieldValue('loanPurpose', 'investment')}
        >
          Investment
        </button>

        <button
          style={
            values.loanPurpose === 'refinance'
              ? activeButtonStyle
              : inactiveButtonStyle
          }
          onClick={() => setFieldValue('loanPurpose', 'refinance')}
        >
          Refinance
        </button>
      </div>

      <NumberField
        name="estimatedPropertyValue"
        label="Estimated property value"
        placeholder="Property value"
        onChange={handleChange}
        onBlur={handleBlur}
        labelStyle={s('text-base')}
        style={s('mb-4 bg-white')}
        prefix="$"
        thousandSeparator
      />

      <NumberField
        name="depositAmount"
        label="Deposit amount"
        placeholder="Deposit amount"
        onChange={handleChange}
        onBlur={handleBlur}
        labelStyle={s('text-base')}
        style={s('mb-4 bg-white')}
        prefix="$"
        thousandSeparator
      />

      <NumberField
        name="loanTerm"
        label="Loan term in years"
        placeholder="Loan term"
        onChange={handleChange}
        onBlur={handleBlur}
        labelStyle={s('text-base')}
        style={s('mb-4 bg-white')}
      />

      <Label>Choose a rate</Label>
      <div style={{ display: 'flex' }}>
        <button
          style={
            values.rateType === 'floating'
              ? activeButtonStyle
              : inactiveButtonStyle
          }
          onClick={() => setFieldValue('rateType', 'floating')}
        >
          Floating rate
        </button>
        <button
          style={
            values.rateType === 'fixed'
              ? activeButtonStyle
              : inactiveButtonStyle
          }
          onClick={() => setFieldValue('rateType', 'fixed')}
        >
          Fixed term
        </button>
      </div>

      {values.rateType === 'floating' && (
        <div
          style={s(
            'mb-4 grid place-items-center bg-grey-100 text-pink font-semibold p-4 w-8 h-6 rounded-lg border border-primary'
          )}
        >
          <div>{floatingRate}%</div>
          <div>per annum</div>
        </div>
      )}
      {
        values.rateType === 'fixed' && (
          <div style={s('mb-4 bg-grey-100 p-4 rounded-lg')}>
            <div style={s('font-semibold mb-1')}>
              For information regarding fixed rate loans please contact us
             </div>
            <Button variant="outlined">Talk to us</Button>
          </div>
        )
      }

      <Label>Repayment type</Label>

      <div style={{ display: 'flex' }}>
        <button
          style={
            values.repaymentType === 'p&i'
              ? activeButtonStyle
              : inactiveButtonStyle
          }
          onClick={() => setFieldValue('repaymentType', 'p&i')}
        >
          Principal & interest
        </button>
        <button
          style={
            values.repaymentType === 'interestOnly'
              ? activeButtonStyle
              : inactiveButtonStyle
          }
          onClick={() => setFieldValue('repaymentType', 'interestOnly')}
        >
          Interest only
        </button>
      </div>

      {values.repaymentType === 'interestOnly' && (
        <ValidatedSelectField
          name={`interestOnlyTerm`}
          label="Interest only loan term "
          placeholder="Interest only term"
          options={interestOnlyTermOptions.map((term) => ({
            label: term.label,
            value: term.value,
            key: term.value,
          }))}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      )}
    </Form>
  </Card>
)

export default RepaymentInputs
