import React from 'react'
import { s, styled } from '@vega/styled/v2'

const Title = styled.div(
  s('text-xl font-bold', {
    color: '#490B2D',
    letterSpacing: '-0.01em',
    lineHeight: 1.2,
  })
)

const SectionTitle = ({ title, ...props }) => {
  return <Title {...props}>{title}</Title>
}

export { SectionTitle }
