import throttle from 'p-throttle'
import BaseService from '../baseService'

export class MapService extends BaseService {
  constructor(options) {
    super(options)
    this.forward = throttle({ limit: 5, interval: 1000 })(async (query) => {
      const searchParams = {
        country: 'NZ',
        type: 'address',
        // eslint-disable-next-line camelcase
        access_token: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
      }

      const config = { searchParams }
      return this.client.get(`geocoding/v5/mapbox.places/${query}.json`, config).json()
    })
  }
}
