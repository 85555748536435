import React from 'react'
import { s } from '@vega/styled/v2'
import { useFormikContext } from 'formik'
import { FieldContainer } from 'components/common'
import BlurNumberField from './BlurNumberField'

export const PhoneNumber = () => {
  const { handleBlur } = useFormikContext()

  return (
    <FieldContainer style={s('flex', { gap: '6px' })}>
      <BlurNumberField
        name="mobileNumber.countryCode"
        label="Phone*"
        placeholder="Country Code"
        onBlur={handleBlur}
        containerStyle={s('w-1/3')}
        labelStyle={s('text-base')}
        prefix="+"
      />
      <BlurNumberField
        name="mobileNumber.prefix"
        placeholder="Prefix i.e.(021)"
        onBlur={handleBlur}
        containerStyle={s('w-1/3', { marginTop: '27px' })}
        aria-label="Prefix"
      />
      <BlurNumberField
        name="mobileNumber.contactNumber"
        placeholder="Contact number"
        onBlur={handleBlur}
        containerStyle={s('', { marginTop: '27px' })}
        aria-label="Contact Number"
      />
    </FieldContainer>
  )
}
