import React from 'react'
import { DottedCircleLoader } from '@vega/components'
import { styled, s } from '@vega/styled'

const Container = styled.div(s('flex h-full'))

const Loading = () => {
  return (
    <Container>
      <DottedCircleLoader size={'lg'} style={s('m-a')} />
    </Container>
  )
}

export { Loading }
