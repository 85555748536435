import React from 'react'
import ContactDetails from './ContactDetails'
import PersonalDetails from './PersonalDetails'
import ResidencyDetails from './ResidencyDetails'
import Employments from './Employments'
import OtherIncomes from './OtherIncomes'
import Addresses from './Addresses'
import { SupportingDocs } from 'features/common/supportingDocs'
import { CollapseButton } from 'components/common'
import { s } from '@vega/styled/v2'
import { DocumentPlaceholder } from './DocumentPlaceholder'

export const PersonFields = ({
  applicationId,
  householdIndex,
  memberIndex,
  values,
  handleChange,
  handleBlur,
  toggleCollapased,
  member,
  filteredDocuments,
  isFormValid,
  isCurrentAddressValid,
}) => (
  <div style={s('bg-grey-100 p-4 rounded-lg my-4')}>
    <ContactDetails
      values={values}
      memberIndex={memberIndex}
      handleChange={handleChange}
      handleBlur={handleBlur}
    />

    <PersonalDetails
      values={values}
      memberIndex={memberIndex}
      handleChange={handleChange}
      handleBlur={handleBlur}
    />

    <ResidencyDetails
      values={values}
      memberIndex={memberIndex}
      handleChange={handleChange}
      handleBlur={handleBlur}
    />

    <Employments
      applicationId={applicationId}
      householdIndex={householdIndex}
      memberIndex={memberIndex}
      member={member}
    />

    <OtherIncomes
      applicationId={applicationId}
      householdIndex={householdIndex}
      memberIndex={memberIndex}
      member={member}
    />

    <Addresses
      applicationId={applicationId}
      householdIndex={householdIndex}
      memberIndex={memberIndex}
      member={member}
      isCurrentAddressValid={isCurrentAddressValid}
    />

    {isFormValid ? (
      <SupportingDocs
        documents={filteredDocuments}
        applicantId={[member._id]}
        applicantType={member.applicantType}
      />
    ) : (
      <DocumentPlaceholder />
    )}

    <CollapseButton onClick={toggleCollapased}>- Collapse</CollapseButton>
  </div>
)
