const LOAN_INFO = {
  TOTAL_LOAN_REQUESTED: 'los-info-total-loan-req',
  LOAN_AMOUNT: 'los-info-loan-amount',
  LOAN_STRUCTURE: 'los-info-loan-structure',
  LOAN_TERM_CYCLE: 'los-info-loan-term-cycle',
  LOAN_TERM_TYPE: 'los-info-loan-term-type',
  LOAN_DURATION: 'los-info-loan-duration',
  LOAN_PROPORTIONS: 'los-info-loan-proportions',
  LOAN_PURPOSE: 'los-info-loan-purpose',
  LOAN_OWNERSHIP: 'los-info-loan-ownership',
  APPLICANT_NAME: 'los-info-app-name',
  APPLICANT_DOB: 'los-info-app-dob',
  APPLICANT_EMAIL: 'los-info-app-email',
  APPLICANT_MARITAL_STATUS: 'los-info-app-marital-status',
  APPLICANT_PHONE_NO: 'los-info-app-phone-no',
  APPLICANT_RESIDENCY_STATUS: 'los-info-app-residential-status',
  APPLICANT_CITIZENSHIP: 'los-info-app-citizenship',
  APPLICANT_ADDRESS: 'los-info-app-address',
  APPLICANT_START_DATE: 'los-info-app-start-date',
  APPLICANT_END_DATE: 'los-info-app-end-date',
  APPLICANT_STATUS: 'los-info-app-status',
  HOUSEHOLD_DEP_NO: 'los-info-house-dep-no',
  HOUSEHOLD_ADULT_NO: 'los-info-house-adult-no',
  NOTE_INFO: 'los-info-note-info',
  BROKER_NAME: 'los-info-broker-name',
  BROKER_COMPANY: 'los-info-broker-company',
  BROKER_NUMBER: 'los-info-broker-no',
  BROKER_EMAIL: 'los-info-broker-email',
  MYCRM_REFERENCE: 'los-info-mycrm-ref',
  BROKER_REF: 'los-info-broker-ref',
}

const SECURITY_INFO = {
  TOTAL_SECURITIES: 'los-sec-total-securities',
  TOTAL_PROPERTIES: 'los-sec-total-properties',
  TOTAL_GUARNATORS: 'los-sec-total-guarantors',
  TOTAL_DECLARED: 'los-sec-total-declared',
  TOTAL_ASSESSED: 'los-sec-total-assessed',
  PROPERTY_ADDRESS: 'los-prop-address',
  VELOCITY_LINK: 'los-prop-velocity-link',
  VELOCITY_NOT_LINK: 'los-prop-velocity-not-link',
  MYCRM_ESTIMATE: 'los-prop-mycrm-est',
  PROPERTY_TYPE: 'los-prop-type',
  PROPERTY_USAGE: 'los-prop-usage',
  USED_AS_SECURITY: 'los-prop-is-security',
  RENTAL_INCOME: 'los-prop-rental-income',
  PROPERTY_OCCUPANCY: 'los-prop-occupancy',
  PRIMARY_PURPOSE: 'los-prop-purpose',
  PROPERTY_HOLDING: 'los-prop-holding',
  PROPERTY_OWNERSHIP: 'los-prop-owners',
  FINAL_ASSET_VALUE: 'los-prop-final-value',
  IVAL_RESULT: 'los-prop-ival-val',
}

export default {
  ...LOAN_INFO,
  ...SECURITY_INFO,
}
