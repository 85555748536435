import React from 'react'
import { styled, s } from '@vega/styled'
import { Filter } from '@vega/components'
import { USER_STATUSES, TEST_IDS } from '@vega/constants'
import { USER_ROLES } from '@neo/constants'
import { ReactComponent as FilterIcon } from '../../assets/images/filter.svg'

const Container = styled.div(s('pt-6 w-full'))
const Heading = styled.div(s('flex items-center mb-4'))
const Title = styled.div(s('text-base font-semi-bold mt-1', { marginLeft: 2 }))

const roleOptions = [
  {
    value: [USER_ROLES.BROKER],
    label: 'Broker',
    testId: TEST_IDS.ADMIN_USR_MANAGEMENT_BROKER_CHECKBOX,
  },
  {
    value: [USER_ROLES.LENDING_ADMIN],
    label: 'Admin',
    testId: TEST_IDS.ADMIN_USR_MANAGEMENT_ADMIN_CHECKBOX,
  },
  {
    value: [USER_ROLES.APPLICANT],
    label: 'Applicant',
    testId: TEST_IDS.ADMIN_USR_MANAGEMENT_APPLICANT_CHECKBOX,
  },
  {
    value: [USER_ROLES.CLIENT],
    label: 'Client',
    testId: TEST_IDS.ADMIN_USR_MANAGEMENT_CLIENT_CHECKBOX,
  },
]

const statusOptions = [
  {
    value: USER_STATUSES.ACTIVE,
    label: 'Active',
    testId: TEST_IDS.ADMIN_USR_MANAGEMENT_ACTIVE_CHECKBOX,
  },
  {
    value: USER_STATUSES.PENDING,
    label: 'Pending',
    testId: TEST_IDS.ADMIN_USR_MANAGEMENT_PENDING_CHECKBOX,
  },
  {
    value: USER_STATUSES.INACTIVE,
    label: 'Inactive',
    testId: TEST_IDS.ADMIN_USR_MANAGEMENT_INACTIVE_CHECKBOX,
  },
]

const suspensdedOptions = [
  {
    value: true,
    label: 'Yes',
    testId: TEST_IDS.ADMIN_USR_MANAGEMENT_SUSPENDED_CHECKBOX,
  },
]

const UserManagementFilters = ({
  onAccessFilter,
  onStatusFilter,
  onSuspensionFilter,
}) => {
  return (
    <Container>
      <Heading>
        <FilterIcon />
        <Title>Filter</Title>
      </Heading>
      <Filter title="Access as" options={roleOptions} onChange={onAccessFilter} />
      <Filter title="Status" options={statusOptions} onChange={onStatusFilter} />
      <Filter
        title="Suspend"
        options={suspensdedOptions}
        onChange={onSuspensionFilter}
      />
    </Container>
  )
}

export default UserManagementFilters
