const mappings = [
  {
    groupName: 'Child Care Support',
    expenses: [
      {
        livingExpenseCategory: 'Childcare',
        subcategory: 'Child Care',
        expenseType: 'livingExpense',
      },
      {
        otherCommitmentCategory: 'Child Maintenance',
        subcategory: 'Child Support Agreement',
        expenseType: 'otherCommitment',
      },
    ]
  },

  {
    groupName: 'Education',
    expenses: [
      {
        livingExpenseCategory: 'Public or Government Primary and Secondary Education',
        subcategory: 'Public School Costs',
        expenseType: 'livingExpense',
      },
      {
        livingExpenseCategory: 'Private Schooling and Tuition',
        subcategory: 'Private School Costs',
        expenseType: 'livingExpense',
      },
      {
        livingExpenseCategory: 'Other',
        subcategory: 'Higher Education & Vocational Training (excluding HECS/HELP)',
        expenseType: 'livingExpense',
      },
    ]
  },

  {
    groupName: 'Groceries',
    expenses: [
      {
        livingExpenseCategory: 'Groceries',
        subcategory: 'Groceries',
        expenseType: 'livingExpense',
      },
    ]
  },

  {
    groupName: 'Primary Residence Expense (excl. Insurance)',
    expenses: [
      {
        livingExpenseCategory: 'Primary Residence Running Costs',
        subcategory: 'Electricity & Gas',
        expenseType: 'livingExpense',
      },
      {
        livingExpenseCategory: 'Primary Residence Running Costs',
        subcategory: 'Council Rates',
        expenseType: 'livingExpense',
      },
      {
        livingExpenseCategory: 'Primary Residence Running Costs',
        subcategory: 'Water & Sewer',
        expenseType: 'livingExpense',
      },
      {
        livingExpenseCategory: 'Primary Residence Running Costs',
        subcategory: 'Body Corporate',
        expenseType: 'livingExpense',
      },
      {
        livingExpenseCategory: 'Primary Residence Running Costs',
        subcategory: 'Home Operation',
        expenseType: 'livingExpense',
      },
      {
        livingExpenseCategory: 'Primary Residence Running Costs',
        subcategory: 'Home Repairs',
        expenseType: 'livingExpense',
      },
      {
        livingExpenseCategory: 'Primary Residence Running Costs',
        subcategory: 'Furnishings & Electrical',
        expenseType: 'livingExpense',
      },
    ]
  },

  {
    groupName: 'Insurance',
    expenses: [
      {
        livingExpenseCategory: 'Investment Property Running Costs',
        subcategory: 'Building Insurance',
        expenseType: 'livingExpense',
      },
      {
        livingExpenseCategory: 'Primary Residence Running Costs',
        subcategory: 'Contents Insurance',
        expenseType: 'livingExpense',
      },
      {
        livingExpenseCategory: 'Health Insurance',
        subcategory: 'Health Insurance',
        expenseType: 'livingExpense',
      },
      {
        livingExpenseCategory: 'General Basic Insurances',
        subcategory: 'Income Protection',
        expenseType: 'livingExpense',
      },
      {
        livingExpenseCategory: 'Sickness and Personal Accident Insurance, Life Insurance',
        subcategory: 'Life Insurance',
        expenseType: 'livingExpense',
      },
      {
        livingExpenseCategory: 'General Basic Insurances',
        subcategory: 'Vehicle Insurance',
        expenseType: 'livingExpense',
      },
    ]
  },

  {
    groupName: 'Investment Property Expense (inc. Insurance)',
    expenses: [
      {
        livingExpenseCategory: 'Investment Property Running Costs',
        subcategory: 'Electricity & Gas',
        expenseType: 'livingExpense',
      },
      {
        livingExpenseCategory: 'Investment Property Running Costs',
        subcategory: 'Council Rates',
        expenseType: 'livingExpense',
      },
      {
        livingExpenseCategory: 'Investment Property Running Costs',
        subcategory: 'Water & Sewer',
        expenseType: 'livingExpense',
      },
      {
        livingExpenseCategory: 'Investment Property Running Costs',
        subcategory: 'Body Corporate',
        expenseType: 'livingExpense',
      },
      {
        livingExpenseCategory: 'Investment Property Running Costs',
        subcategory: 'Repairs & Maintenance',
        expenseType: 'livingExpense',
      },
      {
        livingExpenseCategory: 'Investment Property Running Costs',
        subcategory: 'Running Costs',
        expenseType: 'livingExpense',
      },
      {
        livingExpenseCategory: 'Investment Property Running Costs',
        subcategory: 'Building Insurance',
        expenseType: 'livingExpense',
      },
    ]
  },

  {
    groupName: 'Secondary Residence Expense (inc. Insurance)',
    expenses: [
      {
        livingExpenseCategory: 'Body Corporate Fees, Strata Fees and Land Tax on Owner Occupied Principal Place of Residence',
        subcategory: 'Electricity & Gas',
        expenseType: 'livingExpense',
      },
      {
        livingExpenseCategory: 'Body Corporate Fees, Strata Fees and Land Tax on Owner Occupied Principal Place of Residence',
        subcategory: 'Council Rates',
        expenseType: 'livingExpense',
      },
      {
        livingExpenseCategory: 'Body Corporate Fees, Strata Fees and Land Tax on Owner Occupied Principal Place of Residence',
        subcategory: 'Water & Sewer',
        expenseType: 'livingExpense',
      },
      {
        livingExpenseCategory: 'Body Corporate Fees, Strata Fees and Land Tax on Owner Occupied Principal Place of Residence',
        subcategory: 'Body Corporate',
        expenseType: 'livingExpense',
      },
      {
        livingExpenseCategory: 'Body Corporate Fees, Strata Fees and Land Tax on Owner Occupied Principal Place of Residence',
        subcategory: 'Repairs & Maintenance',
        expenseType: 'livingExpense',
      },
      {
        livingExpenseCategory: 'Body Corporate Fees, Strata Fees and Land Tax on Owner Occupied Principal Place of Residence',
        subcategory: 'Running Costs',
        expenseType: 'livingExpense',
      },
      {
        livingExpenseCategory: 'Body Corporate Fees, Strata Fees and Land Tax on Owner Occupied Principal Place of Residence',
        subcategory: 'Building Insurance',
        expenseType: 'livingExpense',
      },
      {
        livingExpenseCategory: 'Body Corporate Fees, Strata Fees and Land Tax on Owner Occupied Principal Place of Residence',
        subcategory: 'Contents Insurance',
        expenseType: 'livingExpense',
      },
    ]
  },

  {
    groupName: 'Medical',
    expenses: [
      {
        livingExpenseCategory: 'Medical and health',
        subcategory: 'Medical & Health',
        expenseType: 'livingExpense',
      },
      {
        livingExpenseCategory: 'Recreation and entertainment',
        subcategory: 'Natural Therapies',
        expenseType: 'livingExpense',
      },
    ]
  },

  {
    groupName: 'Ongoing Rent',
    expenses: [
      {
        otherCommitmentCategory: 'Rent',
        subcategory: 'Rent',
        expenseType: 'otherCommitment',
      },
      {
        otherCommitmentCategory: 'Board',
        subcategory: 'Board',
        expenseType: 'otherCommitment',
      },
    ]
  },

  {
    groupName: 'Personal Care',
    expenses: [
      {
        livingExpenseCategory: 'Clothing and personal care',
        subcategory: 'Clothing & Footwear',
        expenseType: 'livingExpense',
      },
      {
        livingExpenseCategory: 'Clothing and personal care',
        subcategory: 'Cosmetics',
        expenseType: 'livingExpense',
      },
      {
        livingExpenseCategory: 'Clothing and personal care',
        subcategory: 'Dry Cleaning',
        expenseType: 'livingExpense',
      },
      {
        livingExpenseCategory: 'Clothing and personal care',
        subcategory: 'Personal Care',
        expenseType: 'livingExpense',
      },
    ]
  },

  {
    groupName: 'Recreational Entertainment',
    expenses: [
      {
        livingExpenseCategory: 'Pet Care',
        subcategory: 'Pets',
        expenseType: 'livingExpense',
      },
      {
        livingExpenseCategory: 'Recreation and entertainment',
        subcategory: 'Alcohol / Tobacco',
        expenseType: 'livingExpense',
      },
      {
        livingExpenseCategory: 'Recreation and entertainment',
        subcategory: 'Cinema/Concerts/Memberships',
        expenseType: 'livingExpense',
      },
      {
        livingExpenseCategory: 'Recreation and entertainment',
        subcategory: 'Dining Out',
        expenseType: 'livingExpense',
      },
      {
        livingExpenseCategory: 'Recreation and entertainment',
        subcategory: 'Gym / Sports',
        expenseType: 'livingExpense',
      },
      {
        livingExpenseCategory: 'Recreation and entertainment',
        subcategory: 'Travel & Holidays',
        expenseType: 'livingExpense',
      },
      {
        livingExpenseCategory: 'Recreation and entertainment',
        subcategory: 'Gifts & Miscellaneous',
        expenseType: 'livingExpense',
      },
      {
        livingExpenseCategory: 'Recreation and entertainment',
        subcategory: 'Gambling',
        expenseType: 'livingExpense',
      },
    ]
  },

  {
    groupName: 'Telephone Internet',
    expenses: [
      {
        livingExpenseCategory: 'Telephone, internet, pay TV and media streaming subscriptions',
        subcategory: 'Home/Mobile Phone',
        expenseType: 'livingExpense',
      },
      {
        livingExpenseCategory: 'Telephone, internet, pay TV and media streaming subscriptions',
        subcategory: 'Internet, Pay TV & Media Streaming Subscriptions',
        expenseType: 'livingExpense',
      },
    ]
  },

  {
    groupName: 'Transport',
    expenses: [
      {
        livingExpenseCategory: 'Transport',
        subcategory: 'Petrol',
        expenseType: 'livingExpense',
      },
      {
        livingExpenseCategory: 'Transport',
        subcategory: 'Registration',
        expenseType: 'livingExpense',
      },
      {
        livingExpenseCategory: 'Transport',
        subcategory: 'Vehicle Maintenance',
        expenseType: 'livingExpense',
      },
      {
        livingExpenseCategory: 'Transport',
        subcategory: 'Public Transport',
        expenseType: 'livingExpense',
      },
      {
        livingExpenseCategory: 'Transport',
        subcategory: 'Taxi/Ride Sharing',
        expenseType: 'livingExpense',
      },
      {
        livingExpenseCategory: 'Transport',
        subcategory: 'Tolls/Parking etc',
        expenseType: 'livingExpense',
      },
    ]
  },

  {
    groupName: 'Other',
    expenses: [
      {
        livingExpenseCategory: 'Other',
        subcategory: 'Regular Donations',
        expenseType: 'livingExpense',
      },
      {
        livingExpenseCategory: 'Other',
        subcategory: 'Voluntary Saving',
        expenseType: 'livingExpense',
      },
      {
        livingExpenseCategory: 'Voluntary Superannuation Contributions',
        subcategory: 'Voluntary Super',
        expenseType: 'livingExpense',
      },
      {
        livingExpenseCategory: 'Voluntary Superannuation Contributions',
        subcategory: 'Kiwisaver',
        expenseType: 'livingExpense',
      },
    ]
  },
]

export const initialExpensesData = mappings.map((mapping) => {
  const expenses = mapping.expenses.map((expense) => ({
    livingExpenseCategory: expense.livingExpenseCategory,
    otherCommitmentCategory: expense.otherCommitmentCategory,
    subcategory: expense.subcategory,
    expenseType: expense.expenseType,
    amount: 0,
    frequency: 'Monthly'
  }))

  return { groupName: mapping.groupName, expenses }
})
