import React from 'react'
import { s, styled } from '@vega/styled/v2'
import { Button } from '@vega/components'
import { Chip, TableCell, SubTitle } from 'components/common'
import { formatCurrency } from 'utils/formatters'
import { Link, useParams } from 'react-router-dom'
import { useLargeMediaQuery, Row, Col, Container } from '@vega/styled'
import Card from 'components/Card'

const OuterContainer = styled.div(
  s(
    'bg-white flex flex-row justify-between w-full border-0 border-b-1 border-solid border-grey-200',
    {
      '&:last-child': {
        borderColor: 'transparent',
      },
    }
  )
)
const Root = styled.div(
  s(
    'bg-white flex flex-row justify-between w-full border-0 border-b-1 border-solid border-grey-200 mt-4 mb-4 ',
    {
      '&:last-child': {
        borderColor: 'transparent',
      },
      ':hover': s('bg-secondary rounded-lg', { cursor: 'pointer' }),
    }
  )
)

const Applicants = styled.div(s('text-primary font-semibold text-base mt-1'))

const ExtraLoanInfo = styled.div(s('text-base text-primary'))

const AvailableFunds = ({ application }) => {
  const { id } = useParams()
  const isLargeScreen = useLargeMediaQuery('larger')

  const {
    fundingDetails: { availableFunds },
  } = application

  const rows = availableFunds.map((funding) => {
    const sectionId = funding._id

    if (!isLargeScreen) {
      return (
        <OuterContainer>
          <Root>
            <Container gutter={0}>
              <Row gutter={[0, 0]} style={s('p-2')}>
                <Col span={18}>
                  <Applicants className="sohne">{funding.description}</Applicants>
                  <Chip>{funding.fundType}</Chip>
                  <ExtraLoanInfo>- {formatCurrency(funding.value)}</ExtraLoanInfo>
                </Col>
                <Col span={6}>
                  <div
                    style={s('flex items-center justify-end ml-auto', {
                      marginTop: '45px',
                    })}
                  >
                    <Link
                      style={s('', { textDecoration: 'none' })}
                      to={`/customer/application/${id}/funding-details?scrollTo=${sectionId}`}
                    >
                      <Button variant="outlined">View/Edit</Button>
                    </Link>
                  </div>
                </Col>
              </Row>
            </Container>
          </Root>
        </OuterContainer>
      )
    }

    return (
      <tr key={funding._id} style={s('flex items-center justify-between mb-4')}>
        <TableCell>
          <Chip>{funding.fundType}</Chip>
        </TableCell>
        <TableCell>{funding.description}</TableCell>
        <TableCell>{formatCurrency(funding.value)}</TableCell>
        <TableCell />
        <TableCell />
        <td style={s('flex flex-row-reverse gap-1', { gap: '0.25rem' })}>
          <Link
            style={s('', { textDecoration: 'none' })}
            to={`/customer/application/${id}/funding-details?scrollTo=${sectionId}`}
          >
            <Button variant="outlined">View/Edit</Button>
          </Link>
        </td>
      </tr>
    )
  })

  const totalFunding = availableFunds.reduce((acc, curr) => acc + curr.value, 0)

  if (rows.length === 0) {
    return (
      <>
        <h3>Funds Available - {formatCurrency(totalFunding)}</h3>

        <Card>
          <SubTitle>No available funds have been specified</SubTitle>
          <p>
            Available funds can be specified in the application funding details section.
          </p>
        </Card>
      </>
    )
  }

  return (
    <>
      <h3>Funds Available - {formatCurrency(totalFunding)}</h3>
      <table style={s('bg-white p-6 rounded-lg shadow-lg w-full')}>
        <tbody>{rows}</tbody>
      </table>
    </>
  )
}

export default AvailableFunds
