import React from 'react'
import { styled, s } from '@vega/styled/v2'
import { Button as ButtonBase } from '@vega/components'

import { calcPageNumbers } from './calcPageNumbers'

import { ReactComponent as RightIcon } from '../../assets/images/chevron-right.svg'
import { ReactComponent as LeftIcon } from '../../assets/images/chevron-left.svg'

const Container = styled.div(s('justify-end flex m-4 mr-0 items-center'))
const Button = styled(ButtonBase)(
  s('rounded-md rounded-tr-none ml-2'),
  s('text-grey-800 w-2 h-2'),
  { outline: 'none', lineHeight: 1.14 },
  ({ disabled }) => (disabled ? { pointerEvents: 'none' } : {}),
  ({ isEllipsis }) => isEllipsis && { cursor: 'initial' }
)

const IconBtn = styled(Button)(
  s('border-1 border-solid border-primary bg-white'),
  ({ disabled }) => ({
    border: disabled && '0',
  })
)

const Text = styled.p(
  s('text-sm m-0', {
    paddingBottom: 2,
  }),
  ({ disabled }) => (disabled ? s('text-grey-300') : {}),
  ({ twoDigits }) => (twoDigits ? { paddingRight: 2 } : {})
)

const StyledLeftIcon = styled(LeftIcon)(
  { height: 18, path: { stroke: s('text-primary') } },
  ({ disabled }) =>
    disabled
      ? s('rounded-md bg-grey-200 border-0', {
          path: { stroke: s('text-grey-400').color },
        })
      : s('rounded-md', {
          path: { stroke: s('text-primary').color },
        })
)
const StyledRightIcon = styled(RightIcon)(
  { height: 18, path: { stroke: s('text-primary') } },
  ({ disabled }) =>
    disabled
      ? s('rounded-md bg-grey-200 border-0', {
          path: { stroke: s('text-grey-400').color },
        })
      : s('rounded-md', {
          path: { stroke: s('text-primary').color },
        })
)

export function Pagination({
  gotoPage,
  previousPage,
  nextPage,
  canNextPage,
  canPreviousPage,
  pageCount,
  pageIndex,
}) {
  const currentPageNumber = pageIndex + 1
  const pageNumberList = calcPageNumbers(pageCount, pageIndex)

  return (
    <Container>
      <IconBtn
        variant="text"
        onClick={previousPage}
        disabled={!canPreviousPage}
        style={s('p-0')}
        StartIcon={<StyledLeftIcon disabled={!canPreviousPage} />}
      />

      {pageNumberList.map((pageNumber, index) => {
        const isCurrentPage = pageNumber === currentPageNumber
        const isEllipsis = pageNumber === '...'

        const handleClick = () => {
          if (isEllipsis || isCurrentPage) return

          gotoPage(pageNumber - 1)
        }

        return (
          <Button
            key={index}
            variant={isCurrentPage ? 'contained' : 'text'}
            isEllipsis={isEllipsis}
            onClick={handleClick}
            style={isCurrentPage ? s('bg-white') : s('bg-transparent')}
          >
            <Text twoDigits={pageNumber >= 10}>{pageNumber}</Text>
          </Button>
        )
      })}

      <IconBtn
        variant="text"
        onClick={nextPage}
        disabled={!canNextPage}
        style={s('p-0')}
        EndIcon={<StyledRightIcon disabled={!canNextPage} />}
      />
    </Container>
  )
}
