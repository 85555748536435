// Taken from https://ishadeed.com/article/flexbox-gap/
function isFlexGapSupported() {
  // Create flex container with row-gap set
  const flex = document.createElement('div')
  flex.style.display = 'flex'
  flex.style.flexDirection = 'column'
  flex.style.rowGap = '1px'

  // Create two elements inside it
  flex.appendChild(document.createElement('div'))
  flex.appendChild(document.createElement('div'))

  // Append to the DOM (needed to obtain scrollHeight)
  document.body.appendChild(flex)
  const isSupported = flex.scrollHeight === 1 // Flex container should be 1px high from the row-gap
  flex.parentNode.removeChild(flex)

  return isSupported
}

if (isFlexGapSupported()) {
  document.documentElement.classList.add('flexbox-gap')
} else {
  document.documentElement.classList.add('no-flexbox-gap')
}

export * from './Container'
export * from './Row'
export * from './Col'
