import {
  getHttpClient,
  getPublicHttpClient,
  getNonRedirectingHttpClient,
} from './httpClient'

class BaseService {
  /**
   * @param {import('index').ClientOption} options
   */
  constructor(options) {
    this.client = getHttpClient(options)
    this.publicClient = getPublicHttpClient(options)
    this.nonRedirectingClient = getNonRedirectingHttpClient(options)
  }
}

export default BaseService
