import React from 'react'
import { s } from '@vega/styled/v2'

export const ApplicationStatus = ({ applicationStatus }) => {
  const color = applicationStatus === 'SUBMITTED' ? 'bg-green-100' : 'bg-accent-red'

  return (
    <span
      style={s(
        `rounded-2xl px-3 py-1 h-2 flex items-center justify-center font-semibold ${color}`
      )}
    >
      {applicationStatus}
    </span>
  )
}
