import React from 'react'
import { s, styled } from '@vega/styled/v2'

const DividerLine = styled.div(
  s('border-t-0 border-b-1 border-solid', { borderColor: '#E4E6E6' })
)

const Divider = ({ ...props }) => <DividerLine {...props} />

export { Divider }
