import React, { useState } from 'react'
import {
  SubTitle,
  FieldContainer,
  CollapseButton,
  SubEntityCard,
  SubEntityActionButton,
} from 'components/common'
import { Modal, DateField, TextField, Button } from '@vega/components'
import { s, styled } from '@vega/styled/v2'
import { Formik, Form } from 'formik'
import { ReactComponent as XBase } from '@vega/components/src/assets/images/x.svg'
import Card from 'components/Card'
import { larger } from '@vega/styled'
import { ValidatedSelectField } from 'components/SelectField'
import { importantDateSchema } from './index'

// TODO: duplication
export const X = styled(XBase)(s('text-primary', { cursor: 'pointer' }))

// TODO: duplication
const ModalCard = styled(Card)(
  s('rounded-none', { height: '100vh', width: '100vw', overflowY: 'scroll' }),
  larger('xl')(s(' h-full rounded-lg', { width: '60rem', maxHeight: '80vh' }))
)

// TODO: utils/dropdowns
const yesNoOptions = [
  { key: 'yes', label: 'Yes' },
  { key: 'no', label: 'No' },
]

const WarningText = styled.p(s('text-red text-sm font-normal'))

/* eslint-disable complexity */
export const ImportantDatesFields = ({
  values,
  setFieldValue,
  handleChange,
  handleBlur,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const openModal = () => setIsModalOpen(true)
  const closeModal = () => setIsModalOpen(false)

  const importantDateTypes = [
    'Estimated Settlement Date',
    'Finance Date',
    'Auction Date',
  ]

  const importantDateTypesAvailable = () =>
    importantDateTypes.filter((dateType) => {
      const currentDateTypes = (values.importantDates?.dates || []).map(
        (importantDate) => importantDate.dateType
      )

      return !currentDateTypes.includes(dateType)
    })

  const addImportantDate = (importantDateValues) => {
    const formattedValues = {
      ...importantDateValues,
      date: new Date(importantDateValues.date),
    }

    const resultingDates = (values.importantDates?.dates || []).concat(formattedValues)

    setFieldValue('importantDates.dates', resultingDates)
    closeModal()
  }

  const removeImportantDate = (dateType) => {
    const resultingDates = values.importantDates.dates.filter(
      (importantDate) => importantDate.dateType !== dateType
    )

    setFieldValue('importantDates.dates', resultingDates)
  }

  return (
    <>
      <SubTitle>Important dates</SubTitle>

      <FieldContainer>
        <ValidatedSelectField
          name="importantDates.hasImportantDates"
          options={yesNoOptions.map((status) => ({
            label: status.label,
            value: status.label,
            key: status.label,
          }))}
          placeholder={'Select Option'}
          label="Any important dates*"
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </FieldContainer>

      {values.importantDates?.hasImportantDates === 'Yes' && (
        <>
          <div>
            {(values.importantDates?.dates || []).map((importantDate) => (
              <SubEntityCard key={importantDate.dateType} style={s('flex')}>
                <div style={s('py-1', { flexGrow: 1 })}>
                  <span style={s('font-semibold')}>
                    {`${importantDate.dateType} - `}
                  </span>
                  {new Date(importantDate.date).toDateString()}
                </div>

                <SubEntityActionButton
                  onClick={() => {
                    removeImportantDate(importantDate.dateType)
                  }}
                  style={s('bg-accent-red text-white py-1', { width: 'fit-content' })}
                >
                  Remove
                </SubEntityActionButton>
              </SubEntityCard>
            ))}
            {values.importantDates?.dates?.length === 0 && (
              <WarningText>At least one important date is now required.</WarningText>
            )}
            {importantDateTypesAvailable().length > 0 && (
              <CollapseButton onClick={openModal}>Add date</CollapseButton>
            )}
          </div>

          <TextField
            name="importantDates.notes"
            label="Extra notes"
            placeholder="Extra imporant date notes"
            labelStyle={s('text-base mt-2')}
          />
        </>
      )}

      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <ModalCard>
          <div style={s('flex justify-end')}>
            <SubTitle style={s('m-0', { flexGrow: 1 })}>Add important date</SubTitle>
            <X onClick={closeModal} />
          </div>

          {importantDateTypesAvailable().length === 0 && (
            <>
              <p>Ran out of important dates</p>
              <Button variant="secondary" onClick={closeModal}>
                Close
              </Button>
            </>
          )}

          <Formik
            initialValues={{}}
            onSubmit={addImportantDate}
            validationSchema={importantDateSchema}
            validateOnMount={true}
          >
            {({ handleChange, handleBlur }) =>
              importantDateTypesAvailable().length > 0 && (
                <Form>
                  <FieldContainer>
                    <ValidatedSelectField
                      name="dateType"
                      label="Date type"
                      options={importantDateTypesAvailable().map((dateType) => ({
                        label: dateType,
                        value: dateType,
                        key: dateType,
                      }))}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={'Date type'}
                    />
                  </FieldContainer>

                  <FieldContainer>
                    <DateField
                      name="date"
                      label="Date"
                      placeholder="Date"
                      inputFormat="MM/dd/yyyy"
                      labelProps={{ style: s('tracking-normal mb-0 text-grey-800') }}
                      onBlur={handleBlur}
                    />
                  </FieldContainer>

                  <div style={s('flex mt-4')}>
                    <Button type="submit" style={s('mr-2')}>
                      Add date
                    </Button>
                    <Button onClick={closeModal} variant="secondary">
                      Cancel
                    </Button>
                  </div>
                </Form>
              )
            }
          </Formik>
        </ModalCard>
      </Modal>
    </>
  )
}
