import React from 'react'

import { s } from '../../headwind'
import { styled } from '../../styled'
import { getConfigResolver, toPercentageWidth } from './utils'

const Root = styled('div', {
  shouldForwardProp: (propName) =>
    !['gutter', 'span', 'offset', 'push', 'pull', 'fill'].find(
      (blacklistedProp) => blacklistedProp === propName
    ),
})(
  s('relative flex-shrink-0 flex-grow-0 m-0 p-0 max-w-full min-h-1px'),
  ({ gutter: [, xGutter] }) => ({
    paddingLeft: xGutter / 2,
    paddingRight: xGutter / 2,
  }),
  ({ span }) => {
    const width = toPercentageWidth(span, 0, (span) => span === 0)

    return width !== 0
      ? { flexBasis: width, maxWidth: width, minHeight: '1px' }
      : { display: 'none' }
  },
  ({ offset }) => ({
    marginLeft: toPercentageWidth(offset, 0, (offset) => offset <= 0),
  }),
  ({ push, pull }) =>
    // We need to set `left` and `right` to undefined when the width is 0% to avoid
    // pinning the column to the left or right when `push` or `pull` are not passed as
    // props.
    ({
      left: toPercentageWidth(push, undefined, (push) => !push),
      right: toPercentageWidth(pull, undefined, (pull) => !pull),
    }),
  ({ fill }) => fill && s('h-full')
)

function Col(props) {
  const {
    // From parent row via `React.Children.toArray()`
    gutter,
    currentBreakpoint,

    // From props
    span: mobileSpan,
    sm,
    md,
    lg,
    xl,
    jb,
    offset: mobileOffset = 0,
    push: mobilePush,
    pull: mobilePull,
    fill = false,
    ...otherProps
  } = props

  const breakpointValues = { sm, md, lg, xl, jb }

  const resolveConfig = getConfigResolver(breakpointValues, currentBreakpoint)

  const span = resolveConfig({ prop: 'span', mobileValue: mobileSpan })
  const offset = resolveConfig({ prop: 'offset', mobileValue: mobileOffset })
  const push = resolveConfig({ prop: 'push', mobileValue: mobilePush })
  const pull = resolveConfig({ prop: 'pull', mobileValue: mobilePull })

  return (
    <Root
      gutter={gutter}
      span={span}
      offset={offset}
      push={push}
      pull={pull}
      fill={fill}
      {...otherProps}
    />
  )
}

export { Col }
