import React from 'react'
import { s } from '@vega/styled/v2'

const RepaymentDisclaimers = () => (
  <div style={s('p-4')}>
    <p>
      This calculator summary is intended as a guide/illustration only and is not
      an offer of finance from <a href="https://www.neo.nz/">Neo</a>.
    </p>

    <p style={s('font-semibold')}>
      A few things you should know about this calculation:
    </p>

    <ul>
      <li>
        All amounts entered by you are assumed not to vary and are valid only
        at the time of entry. Calculations are based on a Table repayments term
        loan.
      </li>

      <li>
        Actual loan repayment amounts may vary slightly for fortnightly repayments
        due to rounding or may vary for monthly repayments due to unequal number
        of days in payment period.
      </li>

      <li>
        This calculation is based on the floating rate, however when we assess
        your repayment ability interest rates may have varied.
      </li>

      <li>
        When calculating an interest only payment for monthly loans, we have
        assumed 12 equal monthly payments over the year. Principal and Interest
        payments are based on the number of days in the payment period.
      </li>
    </ul>
  </div>
)

export default RepaymentDisclaimers
