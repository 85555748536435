import BaseService from '../baseService'
import { QueryBuilder } from '../utils'

export class ReportService extends BaseService {
  getLoanApplicationsSummaryReport(years, months) {
    const queryParams = QueryBuilder()
      .setParam('years', years)
      .setParam('months', months)
      .build()

    return this.client.get(`reports/loan-applications/summary?${queryParams}`).json()
  }

  getInProgressApplicationsReport(year, month) {
    const queryParams = QueryBuilder()
      .setParam('year', year)
      .setParam('month', month)
      .build()

    return this.client
      .get(`reports/loan-applications/in-progress?${queryParams}`)
      .json()
  }

  getApplicationsBreakdownReport(year, month) {
    const queryParams = QueryBuilder()
      .setParam('year', year)
      .setParam('month', month)
      .build()

    return this.client.get(`reports/loan-applications/breakdown?${queryParams}`).json()
  }

  getClientsCountReport(year, month) {
    const queryParams = QueryBuilder()
      .setParam('year', year)
      .setParam('month', month)
      .build()

    return this.client.get(`reports/clients/count?${queryParams}`).json()
  }

  getTopClientsReport(year, month, amountOfResult, criterion) {
    const queryParams = QueryBuilder()
      .setParam('year', year)
      .setParam('month', month)
      .setParam('amountOfResult', amountOfResult)
      .setParam('criterion', criterion)
      .build()

    return this.client.get(`reports/clients/top-clients?${queryParams}`).json()
  }

  fetchBankingDashboardReportData() {
    return this.client.get('analytics/bank-dashboard').json()
  }

  fetchPortfolioDashboardAnalytics(year, month, warehouseIds = []) {
    const queryParams = QueryBuilder()
      .setParam('year', year)
      .setParam('month', month)
      .setParam('warehouseIds', warehouseIds)
      .build()

    return this.client.get(`analytics/portfolio-dashboard?${queryParams}`).json()
  }
}
