import { MapService, LoanApplicationService } from '@vega/services'

const mapServiceApiUri = process.env.REACT_APP_MAPBOX_API_URL
const lendingApiUri = process.env.REACT_APP_LENDING_API_URI

const getOptions = (baseUri) => ({ baseUri })

export const mapService = new MapService(getOptions(mapServiceApiUri))
export const loanApplicationService = new LoanApplicationService(
  getOptions(lendingApiUri)
)
