import React from 'react'
import Card from 'components/Card'
import { s } from '@vega/styled/v2'

const Error = () => (
  <Card>
    <h1>Not found</h1>
    <p style={s('text-lg')}>Sorry the page you are trying to reach cannot be found.</p>

    <p style={s('text-lg')}>
      Please click the button below to return to your applications.
    </p>

    <button>View applications</button>
  </Card>
)

export default Error
