import React from 'react'
import { styled, s } from '@vega/styled'
import { Filter } from '@vega/components'
import { ReactComponent as FilterIcon } from '../../assets/images/filter.svg'

const Container = styled.div(s('pt-6', { width: '215px' }))
const Heading = styled.div(s('flex items-center mb-4'))
const Title = styled.div(s('text-base font-normal mt-1', { marginLeft: 2 }))

const numOfClientsOpts = [
  {
    label: '0 - 25',
    value: '0 - 25',
  },
  {
    label: '26 - 50',
    value: '26 - 50',
  },
  {
    label: '50+',
    value: '50+',
  },
]

const totalPropertiesOpts = [
  {
    label: '1 - 10',
    value: '1 - 10',
  },
  {
    label: '11 - 20',
    value: '11 - 20',
  },
  {
    label: '20+',
    value: '20+',
  },
]

const BrokerFilters = ({ onNumOfClientsFilter, onTotalPropertiesFilter }) => {
  return (
    <Container>
      <Heading>
        <FilterIcon />
        <Title>Filter</Title>
      </Heading>
      <Filter
        title="No. clients"
        options={numOfClientsOpts}
        onChange={onNumOfClientsFilter}
      />
      <Filter
        title="Total properties"
        options={totalPropertiesOpts}
        onChange={onTotalPropertiesFilter}
      />
    </Container>
  )
}

export default BrokerFilters
