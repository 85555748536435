import React, { memo } from 'react'
import { s } from '../headwind'
import { styled } from '../styled'
import { useCurrentBreakpoint } from '../breakpoints'

const Root = styled('div', {
  shouldForwardProp: (propName) =>
    !['fill', 'isFlexGapSupported', 'noWrap'].find(
      (blacklistedProp) => blacklistedProp === propName
    ),
})(
  s('flex flex-row m-0 p-0'),
  ({ gutter: [, xGutter] }) => ({
    marginLeft: -(xGutter / 2),
    marginRight: -(xGutter / 2),
  }),
  ({ gutter: [yGutter], isFlexGapSupported }) =>
    isFlexGapSupported
      ? { rowGap: yGutter }
      : {
          marginBottom: -yGutter,
          '& > *': { marginBottom: `${yGutter}px !important` },
        },
  ({ align, justify }) => ({
    alignItems: align,
    justifyContent: justify,
  }),
  ({ noWrap }) => !noWrap && s('flex-wrap'),
  ({ fill }) => fill && s('h-full')
)

function Row({
  gutter = [16, 16],
  align = 'start',
  justify = 'start',
  noWrap = false,
  fill = false,
  children,
  ...props
}) {
  const currentBreakpoint = useCurrentBreakpoint()
  const isFlexGapSupported = document.documentElement.classList.contains('flexbox-gap')

  const columns = React.Children.toArray(children)

  return (
    <Root
      isFlexGapSupported={isFlexGapSupported}
      gutter={gutter}
      align={align}
      justify={justify}
      noWrap={noWrap}
      fill={fill}
      {...props}
    >
      {columns.map((column) => (
        <column.type
          {...column.props}
          key={column.key}
          gutter={gutter}
          currentBreakpoint={currentBreakpoint}
        />
      ))}
    </Root>
  )
}

const Memoized = memo(Row)

export { Memoized as Row }
