import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { Overview } from './homepage/index'
import Review from './Review/index'
import NavigationDrawer from './AdminNavigationDrawer'
import { s, styled } from '@vega/styled/v2'
import AppSwitcher from 'features/common/AppSwitcher'
import { routes } from 'routes'

const Root = styled.div(s('flex flex-column h-auto'), s('bg-green-50'))
const AppContainer = styled.div(s('flex flex-row p-0 '), {
  'overflow-y': 'scroll',
  scrollbarWidth: 'none',
})

const AdminApp = () => (
  <Root>
    <AppSwitcher />

    <AppContainer>
      <NavigationDrawer />
      <div
        style={s('p-12 w-full', {
          height: '100vh',
          'overflow-y': 'scroll',
          backgroundColor: '#F1F1E8',
        })}
      >
        <Switch>
          <Route path={routes.admin.homepage} component={Overview} />
          <Route path={routes.admin.application.overview} component={Review} />
          <Route path="*" component={Error} />
        </Switch>
      </div>
    </AppContainer>
  </Root>
)

export default AdminApp
