/* eslint-disable complexity */
import React from 'react'
import { TextField } from '@vega/components'
import { SubTitle, FieldContainer } from 'components/common'
import { s } from '@vega/styled/v2'
import { ValidatedSelectField } from 'components/SelectField'

// TODO: utils/dropdowns
const yesNoOptions = [
  { key: 'yes', label: 'Yes' },
  { key: 'no', label: 'No' },
]

const arrearsLabel =
  'Are there any previous issues related to debt arrears ' +
  '(including credit cards, personal loans, car loans, short term loans), ' +
  'monies owing to friends /family, education loans, credit declines or ' +
  'bankruptcy?*'

const goodConductLabel =
  'Do you have good account conduct (e.g. no ' +
  'missed payments, dishonours or unarranged overdrafts)?*'

// TODO: DebtArrearsFields
export const DebtArrears = ({ values, errors }) => (
  <>
    <SubTitle>Debt arrears</SubTitle>

    <FieldContainer>
      <ValidatedSelectField
        name="debtArrears.previousArrears.hasArrears"
        options={yesNoOptions.map((status) => ({
          label: status.label,
          value: status.label,
          key: status.label,
        }))}
        placeholder="Previous debt arrears?"
        label={arrearsLabel}
      />
    </FieldContainer>

    {values.debtArrears?.previousArrears?.hasArrears === 'Yes' && (
      <FieldContainer>
        <TextField
          name="debtArrears.previousArrears.note"
          label="Please elaborate*"
          placeholder="Please elaborate"
          labelStyle={s('text-base')}
          inputContainerStyle={
            errors.debtArrears?.previousArrears?.note && { borderColor: '#F35A56' }
          }
        />
        {errors.debtArrears?.previousArrears?.note && (
          <p
            style={{
              margin: 0,
              marginTop: '8px',
              fontSize: '12px',
              color: '#F35A56',
              lineHeight: 1.3,
            }}
          >
            {errors.debtArrears?.previousArrears?.note}
          </p>
        )}
      </FieldContainer>
    )}

    <FieldContainer>
      <ValidatedSelectField
        name="debtArrears.currentArrears.hasArrears"
        options={yesNoOptions.map((status) => ({
          label: status.label,
          value: status.label,
          key: status.label,
        }))}
        placeholder="Any current debts in arrears?"
        label="Any current debts in arrears?*"
      />
    </FieldContainer>

    {values.debtArrears?.currentArrears?.hasArrears === 'Yes' && (
      <FieldContainer>
        <TextField
          name="debtArrears.currentArrears.note"
          label="Please elaborate*"
          placeholder="Please elaborate"
          labelStyle={s('text-base')}
          inputContainerStyle={
            errors.debtArrears?.currentArrears?.note && { borderColor: '#F35A56' }
          }
        />
        {errors.debtArrears?.currentArrears?.note && (
          <p
            style={{
              margin: 0,
              marginTop: '8px',
              fontSize: '12px',
              color: '#F35A56',
              lineHeight: 1.3,
            }}
          >
            {errors.debtArrears?.currentArrears?.note}
          </p>
        )}
      </FieldContainer>
    )}

    <FieldContainer>
      <ValidatedSelectField
        name="debtArrears.hasPastDirectorship"
        options={yesNoOptions.map((status) => ({
          label: status.label,
          value: status.label,
          key: status.label,
        }))}
        placeholder="Have you been a director in the past 7 years?"
        label="Have you been a director in the past 7 years?*"
      />
    </FieldContainer>

    <FieldContainer>
      <ValidatedSelectField
        name="debtArrears.hasGoodConduct"
        options={yesNoOptions.map((status) => ({
          label: status.label,
          value: status.label,
          key: status.label,
        }))}
        placeholder={goodConductLabel}
        label={goodConductLabel}
      />
    </FieldContainer>
  </>
)
