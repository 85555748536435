/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable complexity */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, Link } from 'react-router-dom'
import { s } from '@vega/styled/v2'
import {
  selectApplicationById,
  fetchApplication,
  setCurrentApplicationId,
} from 'modules/application'
import { Chip } from 'components/common'
import ApplicantsTable from './applicants/ApplicantsTable'
import NonRealEstateAssetTable from './NonRealEstateAssetTable'
import LiabilitiesTable from './LiabilitiesTable'
import FundingDetails from './FundingDetails'
import ObjectivesTable from './ObjectivesTable'
import Card from 'components/Card'
import { ReactComponent as ClipBoardIcon } from '@vega/components/src/assets/images/copy.svg'
import { ReactComponent as Check } from '@vega/components/src/assets/images/check.svg'
import { Tooltip } from '@mui/material'
import { copyToClipboard } from 'utils/copyToClipboard'
import { fetchUsers, selectUserById } from 'modules/user'
import { USER_ROLES } from '@vega/constants'
import { UserInfo } from './UserInfo'

const Review = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const application = useSelector(selectApplicationById(id))
  const userId = application?.createdBy
  const userInfo = useSelector((state) => selectUserById(state, userId))

  const { SUPER_ADMIN } = USER_ROLES

  const filteredRoles = [SUPER_ADMIN]

  const [isCopy, setIsCopy] = useState(false)

  useEffect(() => {
    if (id) {
      dispatch(fetchApplication(id))
      dispatch(setCurrentApplicationId(id))
    }
  }, [dispatch, id])

  useEffect(() => {
    dispatch(fetchUsers({ searchParams: { filters: { role: filteredRoles } } }))
  }, [dispatch])

  useEffect(() => {
    let timer
    if (isCopy) {
      timer = setTimeout(() => {
        setIsCopy(false)
      }, 2000)
    }
    return () => clearTimeout(timer)
  }, [isCopy])

  if (!application) {
    return (
      <>
        <h1>Unable to find this application</h1>
        <p>We are having trouble finding this application.</p>
        <p>Please use the link below to go back to the applications screen.</p>
        <Link to="/">Back to applications</Link>
      </>
    )
  }

  return (
    <div style={s('w-full')}>
      <h1>
        Application Overview
        <div style={s('flex items-center')}>
          <Chip style={s('mr-0')}>{application.applicationNumber} </Chip>

          <div
            variant="outlined"
            onClick={() => {
              copyToClipboard(`${application.applicationNumber}`)
              setIsCopy(true)
            }}
            style={s('', {
              cursor: 'pointer',
            })}
          >
            <Tooltip title={`${isCopy ? 'Copied' : 'Copy application number'}`}>
              {isCopy ? (
                <Check width="15" style={s('ml-1 mb-3 text-green')} />
              ) : (
                <ClipBoardIcon width="15" style={s('ml-1 mb-3')} />
              )}
            </Tooltip>
          </div>
        </div>
      </h1>

      <UserInfo userInfo={userInfo} />

      <ApplicantsTable application={application} />

      <h2>Financial position</h2>
      <NonRealEstateAssetTable application={application} />
      <LiabilitiesTable application={application} />

      <FundingDetails application={application} />

      <h2>Objectives</h2>
      <ObjectivesTable application={application} />
      <h2>Notes & documents</h2>
      {application.notesAndSupportdocs === undefined ||
      Object.keys(application.notesAndSupportdocs).length === 0 ? (
        <Card style={s('flex')}>
          <div style={{ flexGrow: 1 }}>No notes or supporting documents</div>
        </Card>
      ) : (
        <Card style={s('flex')}>
          <div style={{ flexGrow: 1 }}>{application?.notesAndSupportdocs?.notes}</div>
        </Card>
      )}
    </div>
  )
}

export default Review
