/* eslint-disable complexity */
import React, { useEffect, useRef, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { saveApplication } from 'modules/application'
import { useField } from 'formik'
import { PersonFields } from './PersonFields'
import { householdSchema } from './HouseholdCard'

const useValidation = (member, name) => {
  const [, meta] = useField(name)

  const hasEmptyRequiredField = useMemo(() => {
    const memberSchema = householdSchema.fields.members.innerType.fields

    return Object.entries(member).some(([key, value]) => {
      const fieldSchema = memberSchema[key]
      if (
        fieldSchema &&
        fieldSchema.tests.some((test) => test.OPTIONS.name === 'required')
      ) {
        if (typeof value === 'string') {
          return value.trim() === ''
        }
        if (typeof value === 'object' && value !== null) {
          return Object.values(value).some(
            (v) => typeof v === 'string' && v.trim() === ''
          )
        }
      }
      return false
    })
  }, [member])

  const isCurrentAddressValid = useMemo(() => {
    return (
      member.historicAddresses &&
      member.historicAddresses.some(
        (address) => address.addressType === 'currentAddress'
      )
    )
  }, [member.historicAddresses])

  return {
    isFormValid: !meta.error && !hasEmptyRequiredField,
    isCurrentAddressValid,
  }
}

const ValidatedPersonFields = React.memo(
  ({ name, application, member, errors, ...props }) => {
    const dispatch = useDispatch()
    const { isFormValid, isCurrentAddressValid } = useValidation(member, name)
    const prevIsValidRef = useRef(isFormValid)

    useEffect(() => {
      if (prevIsValidRef.current !== isFormValid) {
        if (isFormValid) {
          dispatch(saveApplication(application))
        }
        prevIsValidRef.current = isFormValid
      }
    }, [application, dispatch, isFormValid, member])

    return (
      <PersonFields
        {...props}
        name={name}
        member={member}
        isFormValid={isFormValid}
        isCurrentAddressValid={isCurrentAddressValid}
        errors={errors}
      />
    )
  }
)

ValidatedPersonFields.displayName = 'ValidatedPersonFields'
export default ValidatedPersonFields
