/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { s } from '@vega/styled/v2'
import Card from 'components/Card'
import { NumberField, SelectField } from '@vega/components'
import { Formik, Form, FieldArray, useFormikContext } from 'formik'
import { updateLivingExpenses } from 'modules/application'
import { useDispatch } from 'react-redux'
import { formatCurrency, getMemberNames, formatStringToNumber } from 'utils/formatters'
import * as Yup from 'yup'

// TODO: move to utils
const paymentFrequencies = [
  'Daily',
  'Fortnightly',
  'Half Yearly',
  'Monthly',
  'One Off',
  'Quarterly',
  'Seasonal',
  'Weekly',
  'Yearly',
]

const ownershipSchema = Yup.object().shape({
  percentage: Yup.number()
    .required('Ownership percentage is required')
    .min(0, 'Percentage must be positive'),
  owner: Yup.string().required('Owner is required'),
})

const expenseSchema = Yup.object().shape({
  livingExpenseCategory: Yup.string().optional(),
  otherCommitmentCategory: Yup.string().optional(),
  subcategory: Yup.string().required('Subcategory is required'),
  expenseType: Yup.string().required('Expense type is required'),

  amount: Yup.number()
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .required('Amount is required')
    .min(0, 'Amount must be positive'),
  frequency: Yup.string().required('Frequency is required'),
  ownerships: Yup.array().of(ownershipSchema),
})

export const expenseGroupSchema = Yup.object().shape({
  groupName: Yup.string().required('Group name is required'),
  expenses: Yup.array().of(expenseSchema).optional(),
})

// TODO: I wonder if this could take a function and be generic?
const AutoDispatcher = ({
  applicationId,
  householdId,
  expenseGroupIndex,
  household,
}) => {
  const dispatch = useDispatch()
  const { values } = useFormikContext()

  const getOwnerships = () => {
    const onwerships = []
    if (household.members.length > 1) {
      household.members.forEach((member) => {
        onwerships.push({
          percentage: 50,
          owner: member._id,
        })
      })
    } else if (household.members.length === 1) {
      onwerships.push({
        percentage: 100,
        owner: household.members[0]._id,
      })
    }

    return onwerships
  }

  React.useEffect(() => {
    const formattedValues = {
      ...values,
      expenses: values.expenses.map((expense) => {
        return {
          ...expense,
          amount: formatStringToNumber(expense.amount),
          ownerships: getOwnerships(),
        }
      }),
    }

    dispatch(
      updateLivingExpenses({
        applicationId,
        householdId,
        expenseGroupIndex,
        expenseGroup: formattedValues,
      })
    )
  }, [dispatch, applicationId, expenseGroupIndex, values])
  return null
}

const LivingExpensesCard = ({ applicationId, householdId, household }) => {
  return (
    <Card>
      <p>{getMemberNames(household.members)}</p>
      {household.expenseGroups.map((expenseGroup, expenseGroupIndex) => (
        <React.Fragment key={expenseGroup.groupName}>
          <h3>{expenseGroup.groupName}</h3>
          <Formik
            initialValues={expenseGroup}
            enableReinitialize
            validationSchema={expenseGroupSchema}
          >
            {({ values, handleChange, handleBlur }) => (
              <Form>
                <AutoDispatcher
                  applicationId={applicationId}
                  householdId={householdId}
                  expenseGroupIndex={expenseGroupIndex}
                  household={household}
                />

                <table style={s('w-full p-4')}>
                  <tbody>
                    <FieldArray name="expenses">
                      <>
                        {values.expenses.map((expense, index) => (
                          <tr
                            key={index}
                            style={s('flex flex-wrap w-full items-center')}
                          >
                            <td
                              style={s('', {
                                width: '40%',
                                overflowWrap: 'break-word',
                                overflow: 'hidden',
                              })}
                            >
                              {expense.subcategory}
                            </td>
                            <td style={s('', { width: '30%' })}>
                              <NumberField
                                name={`expenses[${index}].amount`}
                                placeholder="Amount"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={expense.amount}
                                aria-label="Amount"
                                prefix="$"
                                thousandSeparator
                              />
                            </td>
                            <td style={s('', { width: '30%' })}>
                              <SelectField
                                name={`expenses[${index}].frequency`}
                                options={paymentFrequencies.map((frequency) => ({
                                  label: frequency,
                                  value: frequency,
                                  key: frequency,
                                }))}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder={'Frequency'}
                                aria-label="Frequency"
                                togglerStyles={{ overflow: 'hidden' }}
                              />
                            </td>
                          </tr>
                        ))}
                      </>
                    </FieldArray>
                  </tbody>
                  <tfoot>
                    <tr style={s('flex justify-end')}>
                      <th />
                      <th />
                      <th style={s('text-right font-semibold text-grey-600')}>
                        {`Total - ${formatCurrency(
                          expenseGroup.expenses.reduce(
                            (acc, curr) => acc + parseFloat(curr.amount || 0),
                            0
                          )
                        )}`}
                      </th>
                    </tr>
                  </tfoot>
                </table>
              </Form>
            )}
          </Formik>
        </React.Fragment>
      ))}
    </Card>
  )
}

export default LivingExpensesCard
