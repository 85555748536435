import React from 'react'
import { SubTitle, FieldContainer, SectionContainer } from 'components/common'
import { TextField, ValocityAddressField, DateField } from '@vega/components'
import { yesNoOptions, trustStructureListOptions } from 'utils/dropdownLists'
import { ReactComponent as SearchIconBase } from '@vega/components/src/assets/images/search.svg'
import { s, styled } from '@vega/styled/v2'
import { ValidatedSelectField } from 'components/SelectField'
import { PhoneNumber } from './PhoneNumber'

const SearchIcon = styled(SearchIconBase)(s('mr-2'))

export const TrustFields = ({ values, handleChange, handleBlur }) => (
  <SectionContainer>
    <SubTitle>Trust details</SubTitle>

    <FieldContainer>
      <TextField
        name="trustName"
        placeholder="Name"
        label="Trust name*"
        containerStyle={{ flexBasis: 0, flexGrow: 1 }}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.email}
        labelStyle={s('text-base')}
      />
    </FieldContainer>

    <FieldContainer>
      <ValidatedSelectField
        name="trustType"
        label="Trust type*"
        options={trustStructureListOptions.map((status) => ({
          label: status.label,
          value: status.label,
          key: status.key,
        }))}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={'Select trust type'}
      />
    </FieldContainer>

    <FieldContainer>
      <DateField
        name="establishmentDate"
        label="Establishment date*"
        placeholder="DD/MM/YYYY"
        inputFormat="MM/dd/yyyy"
        labelProps={{ style: s('tracking-normal mb-0 text-grey-800') }}
        onBlur={handleBlur}
      />
    </FieldContainer>

    <FieldContainer>
      <ValidatedSelectField
        label="Is trust trading as company?*"
        name="isTrustTradingAsCompany"
        options={yesNoOptions.map((status) => ({
          label: status.label,
          value: status.key,
          key: status.key,
        }))}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={'Select trust trading company'}
      />
    </FieldContainer>

    <FieldContainer>
      <ValocityAddressField
        name="officeAddress"
        placeholder="Enter the office address"
        aria-label="Office address"
        style={s('bg-white')}
        InputPrefixIcon={<SearchIcon />}
        menuStyles={s('max-h-24 mt-8', {
          overflowY: 'auto',
        })}
        onChange={handleChange}
        onBlur={handleBlur}
        label={'Office address*'}
      />
    </FieldContainer>

    <FieldContainer>
      <TextField
        name="officeEmail"
        label="Office email*"
        placeholder="Office email"
        containerStyle={{ flexBasis: 0, flexGrow: 1 }}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.email}
        labelStyle={s('text-base')}
      />
    </FieldContainer>

    <PhoneNumber />
  </SectionContainer>
)
