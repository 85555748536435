/* eslint-disable complexity */
import React, { useEffect, useState, useLayoutEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { s, styled } from '@vega/styled/v2'
import WizardButtons from 'features/common/WizardButtons'
import ProgressMeter from 'features/common/ProgressMeter'
import { AddPrimaryEntityButton, Label } from 'components/common'
import {
  addRealEstateAsset,
  addAsset,
  addLiability,
  selectApplicationById,
  fetchApplication,
  setCurrentApplicationId,
} from 'modules/application'
import { RealEstateAssetCard } from './RealEstateAssetCard'
import AssetCard from './AssetCard'
import LiabilityCard from './LiabilityCard'
import LivingExpensesCard from './LivingExpensesCard'
import { useParams, Link, useLocation } from 'react-router-dom'
import { getMemberNames } from 'utils/formatters'
import { routes } from 'routes'
import { scrollTopPage } from 'utils/scrollTopPage'

const SelectHouseHold = styled.select(
  s('rounded-lg py-2 px-4 mb-6 border-grey-400', {
    width: 'fit-content',
    height: '40px',
    fontSize: '16px',
    cursor: 'pointer',
    '& option': {
      backgroundColor: 'white',
      color: 'black',
      padding: '8px 12px',
      appearance: 'none',

      '&:hover': {
        backgroundColor: '#e5e5e5', // Light grey background on hover
      },
    },
  })
)

const FinancialPosition = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const location = useLocation()
  const scrollToRef = useRef(null)
  const application = useSelector(selectApplicationById(id))
  const [houseHoldId, setHouseHoldId] = useState(application?.households[0]?._id)

  const options = application?.households?.map((household) => (
    <option key={household._id} value={household._id}>
      {getMemberNames(household.members)}
    </option>
  ))

  const handleHouseholdChange = (event) => {
    const newHouseHoldId = event.target.value
    if (houseHoldId !== newHouseHoldId) {
      setHouseHoldId(newHouseHoldId)
    }
  }

  useEffect(() => {
    dispatch(fetchApplication(id))
    dispatch(setCurrentApplicationId(id))
  }, [dispatch, id])

  useEffect(() => {
    if (application?.households.length > 0 && !houseHoldId) {
      setHouseHoldId(application.households[0]._id)
    }
  }, [application, houseHoldId])

  const searchParams = new URLSearchParams(location.search)
  const scrollToId = searchParams.get('scrollTo')

  useLayoutEffect(() => {
    if (scrollToId && scrollToRef.current) {
      scrollToRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [scrollToId, application])

  useEffect(() => {
    if (!scrollToId && !scrollToRef.current) {
      scrollTopPage('instant')
    }
  }, [scrollToId])

  if (!application) {
    // TODO: extract this out
    return (
      <div>
        <h1 style={s('font-normal text-2xl')}>2. Financial position</h1>
        <ProgressMeter numberOfSteps={5} currentStep={2} />
        <h2>Could not find application</h2>
        <p>We are having trouble finding this application.</p>
        <p>Please use the link below to go back to the applications screen.</p>
        <Link to="/">Back to applications</Link>
      </div>
    )
  }

  // TODO: does this still need to be like this now the server creates it?
  const {
    financialPosition: { realEstateAssets = [], assets = [], liabilities = [] } = {},
    applicants,
    households,
  } = application || {}

  const householdApplicants = households.flatMap((household) => household.members)

  const allApplicants = householdApplicants.concat(applicants)

  if (allApplicants.length === 0) {
    return (
      <div>
        <h1 style={s('font-normal text-2xl')}>2. Financial position</h1>
        <ProgressMeter numberOfSteps={5} currentStep={2} />
        <h2>Financial position requires at least one applicant</h2>
        <p>
          Currently your application has no applicants saved, applicants are required to
          record your funding details.
        </p>
        <p>Please use the link below to go back to the applicants screen.</p>
        <Link
          to={routes.customer.application.applicants.replace(':id', application._id)}
        >
          Back to applicants
        </Link>
      </div>
    )
  }

  // Owned by all applicants
  const realEstateAssetCards = realEstateAssets?.map((asset, index) => (
    <RealEstateAssetCard
      key={index}
      asset={asset}
      index={index}
      applicationId={id}
      applicants={allApplicants || []}
      ref={asset._id === scrollToId ? scrollToRef : null}
    />
  ))

  // Owned by all applicants
  const assetCards = assets?.map((asset, index) => (
    <AssetCard
      key={index}
      asset={asset}
      index={index}
      applicationId={id}
      applicants={allApplicants || []}
      ref={asset._id === scrollToId ? scrollToRef : null}
    />
  ))

  const liabilityCards = liabilities?.map((liability, index) => (
    <LiabilityCard
      key={index}
      liability={liability}
      index={index}
      applicationId={id}
      applicants={allApplicants || []}
      ref={liability._id === scrollToId ? scrollToRef : null}
    />
  ))

  const livingExpensesCards = application.households
    .filter((household) => houseHoldId === household._id)
    .map((household, index) => (
      <LivingExpensesCard
        key={index}
        applicationId={id}
        householdId={houseHoldId}
        household={household}
      />
    ))

  return (
    <div>
      <h1 style={s('font-normal text-2xl')}>2. Financial Position</h1>
      <ProgressMeter numberOfSteps={5} currentStep={2} />
      <div>
        <h2 style={s('mb-2')}>Real estate assets</h2>
        {realEstateAssetCards}
        <AddPrimaryEntityButton
          style={s('mb-6')}
          onClick={() => dispatch(addRealEstateAsset({ id }))}
        >
          + Add real estate asset
        </AddPrimaryEntityButton>
      </div>

      <div>
        <h2 style={s('mb-2')}>Assets</h2>
        {assetCards}
        <AddPrimaryEntityButton
          style={s('mb-6')}
          onClick={() => dispatch(addAsset({ id }))}
        >
          + Add asset
        </AddPrimaryEntityButton>
      </div>

      <div>
        <h2 style={s('mb-2')}>Liabilities</h2>
        {liabilityCards}
        <AddPrimaryEntityButton
          style={s('mb-6')}
          onClick={() => dispatch(addLiability({ id }))}
        >
          + Add liability
        </AddPrimaryEntityButton>
      </div>

      <div>
        <h2 style={s('mb-2')}>Living expenses</h2>
        <div style={s('flex flex-column')}>
          <Label>Select household</Label>
          {application.households.length > 1 && (
            <SelectHouseHold onChange={handleHouseholdChange}>
              {options}
            </SelectHouseHold>
          )}
        </div>
        {livingExpensesCards}
      </div>

      <WizardButtons
        currentStep={2}
        backRoute={routes.customer.application.applicants.replace(':id', id)}
        nextRoute={routes.customer.application.fundingDetails.replace(':id', id)}
      />
    </div>
  )
}

export default FinancialPosition
