/* eslint-disable complexity */
import React, { forwardRef, useState } from 'react'
import { Formik, Form, FieldArray, useFormikContext } from 'formik'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'

import Card from 'components/Card'
import { s } from '@vega/styled/v2'
import {
  SubEntityActionButton,
  Chip,
  CollapseButton,
  FieldContainer,
  SubTitle,
} from 'components/common'
import { NumberField } from '@vega/components'
import {
  addNewHouseholdMember,
  updateHousehold,
  removeHousehold,
  removeSecondMember,
  upsertAddress,
  setCurrentApplicationId,
} from 'modules/application'
import { expenseGroupSchema } from '../financialPosition/LivingExpensesCard'
import ValidatedPersonFields from './ValidatedPersonFields'

export const householdSchema = Yup.object({
  members: Yup.array().of(
    Yup.object({
      title: Yup.string().required('Title is required'),
      firstName: Yup.string().required('First name is required'),
      middleName: Yup.string().notRequired(),
      lastName: Yup.string().required('Last name is required'),
      email: Yup.string().email().required('Email is required'),
      mobileNumber: Yup.object({
        countryCode: Yup.string().required('Country code is required'),
        prefix: Yup.string()
          .min(2, 'Prefix must be at least 2 numbers')
          .max(4, 'Prefix must be at most 4 numbers')
          .required('Prefix is required'),
        contactNumber: Yup.string()
          .min(7, 'Contact number must be at least 7 numbers')
          .required('Contact number is required'),
      }).required('Mobile number is required'),
      isGuarantor: Yup.string().required('Guarantor status is required'),
      gender: Yup.string().required('Gender is required'),
      dateOfBirth: Yup.string().required('Date of birth is required'),
      firstHomeBuyer: Yup.string().required('Is first home buyer required'),
      maritalStatus: Yup.string().required('Marital status is required'),
      citizenship: Yup.string().required('Citizenship is required'),
      permanentNzResident: Yup.string().required(
        'Permanent NZ residency status is required'
      ),
      residencyStatus: Yup.string().required('Residency status is required'),
      countryOfResidence: Yup.string().required('Country of residence is required'),
      countryOfBirth: Yup.string().required('Country of birth is required'),
    })
  ),
  numberOfDependants: Yup.number().required('Number of dependants is required'),
  expenseGroups: Yup.array().of(expenseGroupSchema),
})

// TODO: I wonder if this could take a function and be generic?
const AutoDispatcher = ({ applicationId, householdIndex, application }) => {
  const dispatch = useDispatch()
  const { values } = useFormikContext()

  React.useEffect(() => {
    const formattedValues = {
      ...values,
      numberOfDependants: Number.parseInt(values.numberOfDependants, 10),
    }

    dispatch(
      updateHousehold({
        applicationId,
        householdIndex,
        household: formattedValues,
      })
    )
  }, [dispatch, applicationId, application, householdIndex, values])
  return null
}

const HouseholdCard = forwardRef(
  (
    {
      noOfHouseholds,
      applicationId,
      application,
      householdIndex,
      household,
      initiallyOpen = true,
      supportingDocuments,
    },
    ref
  ) => {
    const dispatch = useDispatch()
    const [collapsed, setCollapsed] = useState(!initiallyOpen)
    const toggleCollapased = () => setCollapsed(!collapsed)

    const householdName = household.members
      .map((member) => member.firstName || '-')
      .join(' & ')

    if (collapsed) {
      return (
        <Card style={s('mb-12')}>
          <div style={s('flex')}>
            <h1 style={s('m-0 p-0 text-2xl', { flexGrow: 1 })}>{householdName}</h1>

            {noOfHouseholds > 1 && (
              <SubEntityActionButton
                style={s('bg-accent-red text-white', { width: 'auto' })}
                onClick={() => {
                  dispatch(
                    removeHousehold({ householdId: household._id, id: applicationId })
                  )
                }}
              >
                Remove household
              </SubEntityActionButton>
            )}
          </div>

          <div style={s('flex mb-2', { flexGrow: 1 })}>
            <Chip>Household</Chip>
          </div>
          <CollapseButton onClick={toggleCollapased}>+ Expand</CollapseButton>
        </Card>
      )
    }

    React.useEffect(() => {
      dispatch(setCurrentApplicationId(applicationId))
    }, [dispatch, applicationId, application])

    return (
      <Card style={s('mb-12 p-4')} ref={ref} id={household._id}>
        <Formik
          initialValues={household}
          enableReinitialize
          validationSchema={householdSchema}
          onSubmit={() => null}
        >
          {({ values, handleChange, handleBlur, errors }) => (
            <Form>
              <AutoDispatcher
                applicationId={applicationId}
                householdIndex={householdIndex}
              />

              <div style={s('flex')}>
                <h1 style={s('m-0 p-0 text-2xl', { flexGrow: 1 })}>{householdName}</h1>
                {noOfHouseholds > 1 && (
                  <SubEntityActionButton
                    style={s('bg-accent-red text-white', { width: 'auto' })}
                    onClick={() => {
                      dispatch(
                        removeHousehold({
                          householdId: household._id,
                          id: applicationId,
                        })
                      )
                    }}
                  >
                    Remove household
                  </SubEntityActionButton>
                )}
              </div>

              <FieldArray name="members">
                <>
                  {values.members.map((member, index) => {
                    const filteredDocuments = supportingDocuments
                      ? Object.values(supportingDocuments).filter((doc) =>
                          doc.legalEntityIds.includes(member._id)
                        )
                      : []

                    return (
                      <ValidatedPersonFields
                        key={index}
                        name={`members[${index}]`}
                        member={member}
                        values={values}
                        applicationId={applicationId}
                        application={application}
                        householdIndex={householdIndex}
                        memberIndex={index}
                        toggleCollapased={toggleCollapased}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        filteredDocuments={filteredDocuments}
                        errors={errors}
                      />
                    )
                  })}
                </>
              </FieldArray>
              {values.members.length < 2 && (
                <CollapseButton
                  onClick={() => {
                    dispatch(
                      addNewHouseholdMember({
                        applicationId,
                        householdIndex,
                      })
                    )
                    const currentAddressIndex = household.members[0].historicAddresses.findIndex(
                      (address) => address.addressType === 'currentAddress'
                    )
                    if (currentAddressIndex !== -1) {
                      const currentAddress =
                        household.members[0].historicAddresses[currentAddressIndex]
                      dispatch(
                        upsertAddress({
                          applicationId,
                          householdIndex,
                          memberIndex: 1,
                          addressIndex: currentAddressIndex,
                          address: currentAddress,
                        })
                      )
                    }
                  }}
                >
                  Add another person to household
                </CollapseButton>
              )}
              {values.members.length === 2 && (
                <CollapseButton
                  onClick={() =>
                    dispatch(
                      removeSecondMember({
                        applicationId,
                        memberId: values.members[1]._id,
                        householdIndex,
                      })
                    )
                  }
                >
                  Remove second person from household
                </CollapseButton>
              )}

              <SubTitle>Dependants</SubTitle>

              <FieldContainer>
                <NumberField
                  name="numberOfDependants"
                  label="Number of dependants"
                  type="number"
                  placeholder="Number of dependants"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  labelStyle={s('text-base')}
                />
              </FieldContainer>
            </Form>
          )}
        </Formik>
      </Card>
    )
  }
)
HouseholdCard.displayName = 'HouseholdCard'

export default HouseholdCard
