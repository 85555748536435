import React from 'react'
import { s } from '@vega/styled'
import { DateField } from '@vega/components'
import { SubTitle, FieldContainer, SectionContainer } from 'components/common'
import { maritalStatusOptions, yesNoOptions, gendersOptions } from 'utils/dropdownLists'
import { ValidatedSelectField } from 'components/SelectField'

const PersonalDetails = ({ memberIndex, handleChange, handleBlur }) => (
  <SectionContainer>
    <SubTitle>Personal details</SubTitle>

    <FieldContainer>
      <ValidatedSelectField
        name={`members[${memberIndex}].gender`}
        label="Gender*"
        options={gendersOptions.map((gender) => ({
          label: gender.label,
          value: gender.key,
          key: gender.key,
        }))}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={'Select Gender'}
      />
    </FieldContainer>

    <FieldContainer>
      <DateField
        name={`members[${memberIndex}].dateOfBirth`}
        aria-label="Date of birth*"
        label="Date of birth"
        placeholder="DD/MM/YYYY"
        containerStyle={s('border-grey-200')}
        style={s('text-sm')}
        inputFormat="dd/MM/yyyy"
        labelProps={{ style: s('tracking-normal mb-0 text-grey-800') }}
        onBlur={handleBlur}
      />
    </FieldContainer>

    <FieldContainer>
      <ValidatedSelectField
        name={`members[${memberIndex}].firstHomeBuyer`}
        label="First home buyer?*"
        placeholder={'First home buyer?'}
        options={yesNoOptions.map((status) => ({
          label: status.label,
          value: status.key,
          key: status.key,
        }))}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </FieldContainer>

    <FieldContainer>
      <ValidatedSelectField
        name={`members[${memberIndex}].maritalStatus`}
        label="Marital status*"
        placeholder={'Select Marital Status'}
        options={maritalStatusOptions.map((status) => ({
          label: status.label,
          value: status.key,
          key: status.key,
        }))}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </FieldContainer>
  </SectionContainer>
)

export default PersonalDetails
