import React, { useEffect, useLayoutEffect, useState, useRef } from 'react'
import WizardButtons from 'features/common/WizardButtons'
import ProgressMeter from 'features/common/ProgressMeter'
import ApplicantCard from './ApplicantCard'
import HouseholdCard from './HouseholdCard'
import { s, styled } from '@vega/styled/v2'
import { larger } from '@vega/styled'
import { useDispatch, useSelector } from 'react-redux'
import Card from 'components/Card'
import {
  addHousehold,
  selectApplicationById,
  fetchApplication,
  addCompany,
  addTrust,
} from 'modules/application'
import { listSupportingDocuments } from 'modules/supportingDocument'
import { AddPrimaryEntityButton, SubTitle } from 'components/common'
import { useParams, Link, useLocation } from 'react-router-dom'
import { Modal, Button } from '@vega/components'
import { ReactComponent as XBase } from '@vega/components/src/assets/images/x.svg'
import { routes } from 'routes'
import { scrollTopPage } from 'utils/scrollTopPage'

// TODO: duplications
const ModalCard = styled(Card)(
  s('rounded-none', { height: '100vh', width: '100vw', overflowY: 'scroll' }),
  larger('xl')(s(' h-full rounded-lg', { width: '60rem', maxHeight: '80vh' }))
)

export const X = styled(XBase)(s('text-primary', { cursor: 'pointer' }))

const Applicants = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const location = useLocation()
  const scrollToRef = useRef(null)
  const application = useSelector(selectApplicationById(id))
  const [isAddModalOpen, setIsAddModalOpen] = useState(false)
  const openAddModal = () => setIsAddModalOpen(true)
  const closeAddModal = () => setIsAddModalOpen(false)

  const supportingDocuments = useSelector((state) => state.supportingDocuments.entities)
  useEffect(() => {
    if (application) {
      dispatch(
        listSupportingDocuments({
          applicationId: application._id,
        })
      )
    }
  }, [dispatch, application])

  const addNewHousehold = () => {
    dispatch(addHousehold({ id }))
    closeAddModal()
  }

  const addNewCompany = () => {
    dispatch(addCompany({ id }))
    closeAddModal()
  }

  const addNewTrust = () => {
    dispatch(addTrust({ id }))
    closeAddModal()
  }

  useEffect(() => {
    dispatch(fetchApplication(id))
  }, [dispatch, id])

  useEffect(() => {
    if (application?.households?.length === 0) {
      dispatch(addHousehold({ id }))
    }
  }, [application, dispatch, id])

  const searchParams = new URLSearchParams(location.search)
  const scrollToId = searchParams.get('scrollTo')

  useLayoutEffect(() => {
    if (scrollToId && scrollToRef.current) {
      scrollToRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [scrollToId, application])

  useEffect(() => {
    if (!scrollToId && !scrollToRef.current) {
      scrollTopPage('instant')
    }
  }, [scrollToId])

  if (!application) {
    return (
      <div>
        <h1 style={s('font-normal text-2xl')}>1. Applicants</h1>
        <ProgressMeter numberOfSteps={5} currentStep={1} />
        <h2>Could not find application</h2>
        <p>We are having trouble finding this application.</p>
        <p>Please use the link below to go back to the applications screen.</p>
        <Link to={routes.customer.homepage}>Back to applications</Link>
      </div>
    )
  }

  const householdCards = application.households.map((household, index) => (
    <HouseholdCard
      key={index}
      household={household}
      applicationId={id}
      application={application}
      householdIndex={index}
      noOfHouseholds={application.households.length || 0}
      supportingDocuments={supportingDocuments}
      ref={household._id === scrollToId ? scrollToRef : null}
    />
  ))

  const applicantCards = application?.applicants?.map((applicant, index) => (
    <ApplicantCard
      key={index}
      applicant={applicant}
      application={application}
      index={index}
      applicationId={id}
      initiallyOpen={!application?.applicants?.length === 1}
      supportingDocuments={supportingDocuments}
      ref={applicant._id === scrollToId ? scrollToRef : null}
    />
  ))

  return (
    <div>
      <h1 style={s('font-normal text-2xl')}>1. Applicant Details</h1>
      <ProgressMeter numberOfSteps={5} currentStep={1} />
      {householdCards}
      {applicantCards}

      <AddPrimaryEntityButton onClick={openAddModal}>
        + Add another applicant
      </AddPrimaryEntityButton>

      <Modal isOpen={isAddModalOpen} onClose={closeAddModal}>
        <ModalCard>
          <div style={s('flex justify-end')}>
            <SubTitle style={s('m-0', { flexGrow: 1 })}>Add an entity</SubTitle>
            <X onClick={closeAddModal} />
          </div>

          <div style={s('flex')}>
            <Button onClick={addNewHousehold} style={s('mr-2')}>
              Add household
            </Button>

            <Button onClick={addNewCompany} style={s('mr-2')}>
              Add company
            </Button>

            <Button onClick={addNewTrust} style={s('mr-2')}>
              Add trust
            </Button>
          </div>
        </ModalCard>
      </Modal>

      <WizardButtons
        currentStep={1}
        backRoute={routes.customer.homepage}
        nextRoute={routes.customer.application.financialPosition.replace(':id', id)}
      />
    </div>
  )
}

export default Applicants
