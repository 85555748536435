import React from 'react'
import { noop } from '@neo/ramda-extra'
import { styled, s } from '@vega/styled'

const TextareaBase = styled.textarea(
  s('w-full border-1 border-solid border-grey-300 p-4', {
    outline: 'none',
    resize: 'none',
    borderRadius: '12px 12px 12px 12px',
    '&::placeholder': s('text-grey-500'),
  }),
  ({ textareaStyle }) => textareaStyle
)

function Textarea({
  rows = 5,
  placeholder,
  onChange = noop,
  textareaStyle,
  ...otherProps
}) {
  return (
    <TextareaBase
      placeholder={placeholder}
      rows={rows}
      onChange={onChange}
      textareaStyle={textareaStyle}
      {...otherProps}
    />
  )
}

export { Textarea }
