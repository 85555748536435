/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
import React from 'react'
import PropTypes from 'prop-types'
import SelectBase from 'react-select'
import { styled, s } from '@vega/styled'

import { ReactComponent as DropdownIndicator } from '@vega/components/src/assets/images/dropdownArrow.svg'

const { string, bool, object, array } = PropTypes

const selectStyles = ({ controlStyles, valueContainerStyles, menuStyles }) => ({
  container: (provided) => ({
    ...provided,
    ...s('h-full'),
  }),

  control: (_, state) => {
    const basicStyle = s(
      'px-5 h-full flex flex-row border-solid border-0 rounded-md bg-grey-300 text-lg rounded-lg rounded-tr-none'
    )
    const disabledStyle = state.isDisabled
      ? s('bg-grey-200', {
          pointerEvents: 'none',
        })
      : {}

    return { ...basicStyle, ...disabledStyle, ...controlStyles }
  },

  option: (provided, state) => ({
    ...provided,
    ...s('pl-3 bg-white text-xs', { '&:hover': s('bg-grey-light') }),
    ...s(state.isSelected ? 'text-black' : ''),
  }),

  menu: (provided) => ({
    ...provided,
    width: 'calc(100% + 12px)',
    transform: 'translateX(-12px)',
    boxShadow:
      '0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1)',
    ...menuStyles,
  }),

  valueContainer: (provided) => ({
    ...provided,
    ...s('p-0'),
    ...valueContainerStyles,
  }),

  indicatorSeparator: () => s('hidden'),

  singleValue: (provided) => ({
    ...provided,
    ...s('text-black text-base'),
  }),
  placeholder: (provided) => {
    return {
      ...provided,
      ...s('text-grey'),
    }
  },
})

const Root = styled.div(s('h-4 w-8'))

const Label = styled.label(s('mb-2 uppercase tracking-wide text-xs text-grey-dark'))

function Select({
  name,
  id,
  type,
  label,
  isSearchable,
  disabled,
  options,
  controlStyles,
  menuStyles,
  valueContainerStyles,
  containerProps,
  dropdownIndicatorStyles,
  ...otherProps
}) {
  return (
    <Root {...containerProps}>
      {label && <Label>{label}</Label>}
      <SelectBase
        type={type}
        options={options}
        menuPlacement="auto"
        styles={selectStyles({ controlStyles, valueContainerStyles, menuStyles })}
        isDisabled={disabled}
        isSearchable={isSearchable}
        components={{
          DropdownIndicator: () => (
            <DropdownIndicator style={dropdownIndicatorStyles} />
          ),
        }}
        {...otherProps}
      />
    </Root>
  )
}

Select.propTypes = {
  type: string,
  label: string,
  options: array,
  isSearchable: bool,
  disabled: bool,
  controlStyles: object,
  menuStyles: object,
  valueContainerStyles: object,
  dropdownIndicatorStyles: object,
  containerProps: object,
  DropdownIndicator: object,
}

Select.defaultProps = {
  type: 'select',
  isSearchable: false,
}

export { Select }
