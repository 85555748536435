import React from 'react'
import PropTypes from 'prop-types'
import { styled, s } from '@vega/styled'

const Container = styled.a(
  s('flex items-center rounded-lg mb-3', {
    transition: 'width 350ms ease-in-out',
    cursor: 'pointer',
    overflow: 'hidden',
  }),
  ({ leanMode }) => {
    if (leanMode) return s('items-center justify-center h-3 w-3')

    return s('h-3 px-3 w-full')
  }
)

const Label = styled.span(
  s('absolute text-grey-800 font-semibold text-sm', {
    whiteSpace: 'nowrap',
    left: s('ml-12').marginLeft,
  })
)

const IconBtn = styled.div(s('relative flex items-center'))

const iconStyle = ({ leanMode }) =>
  s('text-grey-700', {
    pointerEvents: 'none',
    marginRight: leanMode ? 0 : s('mr-3').marginRight,
  })

export const NavWebsiteLink = ({ label, Icon, leanMode = false, ...otherProps }) => {
  return (
    <Container
      style={s('mt-4')}
      leanMode={leanMode}
      href="https://www.neo.nz/"
      target="_blank"
      {...otherProps}
    >
      <IconBtn>
        <Icon
          stroke={s('text-grey-700').color}
          strokeWidth={2}
          width={21}
          height={21}
          style={iconStyle({ leanMode })}
        />

        <Label>{label}</Label>
      </IconBtn>
    </Container>
  )
}

const { string, bool, object } = PropTypes

NavWebsiteLink.propTypes = {
  route: string,
  label: string,
  leanMode: bool,
  Icon: object,
}
