import React from 'react'
import { TextField } from '@vega/components'
import { SubTitle, FieldContainer } from 'components/common'
import { s } from '@vega/styled/v2'
import { ValidatedSelectField } from 'components/SelectField'

// TODO: utils/dropdowns
const yesNoOptions = [
  { key: 'yes', label: 'Yes' },
  { key: 'no', label: 'No' },
]

const otherObjectivesLabel =
  'Do the applicant(s) have any other requirements ' +
  'and objectives not already stated which may affect whether the loan, for ' +
  'which they propose to apply, is suitable, including whether there are any ' +
  'particular personal circumstances of the applicant(s) not documented in ' +
  'any previous responses in this interview guide that are relevant to the ' +
  'loan features that have been identified as important?*'

// TODO: OtherObjectiveFields
export const OtherObjectives = ({ values, errors }) => (
  <>
    <SubTitle>Other objectives</SubTitle>

    <FieldContainer>
      <ValidatedSelectField
        name="otherObjectives.hasOtherObjectives"
        options={yesNoOptions.map((status) => ({
          label: status.label,
          value: status.label,
          key: status.label,
        }))}
        placeholder="Do you have any other requirements?"
        label={otherObjectivesLabel}
      />
    </FieldContainer>

    {values.otherObjectives?.hasOtherObjectives === 'Yes' && (
      <FieldContainer>
        <TextField
          name="otherObjectives.otherObjectivesNotes"
          label="Please elaborate*"
          placeholder="Please elaborate"
          labelStyle={s('text-base')}
          inputContainerStyle={
            errors.otherObjectives?.otherObjectivesNotes && {
              borderColor: '#F35A56',
            }
          }
        />
        {errors.otherObjectives?.otherObjectivesNotes && (
          <p
            style={{
              margin: 0,
              marginTop: '8px',
              fontSize: '12px',
              color: '#F35A56',
              lineHeight: 1.3,
            }}
          >
            {errors.otherObjectives?.otherObjectivesNotes}
          </p>
        )}
      </FieldContainer>
    )}
  </>
)
