import React from 'react'
import { NumberField, TextField } from '@vega/components'
import { s } from '@vega/styled/v2'
import {
  SubTitle,
  FieldContainer,
  SectionContainer,
  CollapseButton,
} from 'components/common'
import { OwnerShip } from 'features/common/OwnerShip'
import { ValidatedSelectField } from 'components/SelectField'

// const assetTypes = [
const nonRealEstateAssetsOptions = [
  { value: 'boat', label: 'Boat' },
  { value: 'motorVehicle', label: 'Motor Vehicle' },
  { value: 'bonds', label: 'Bonds' },
  { value: 'debentureCharge', label: 'Debenture Charge' },
  { value: 'saving', label: 'Savings' },
  { value: 'managedFunds', label: 'Managed Funds' },
  { value: 'shares', label: 'Shares' },
  { value: 'cash', label: 'Cash' },
  { value: 'cashManagement', label: 'Cash Management' },
  { value: 'chequeAccount', label: 'Cheque Account' },
  { value: 'otherDeposit', label: 'Other Deposit' },
  { value: 'pensionAccount', label: 'Pension Account' },
  { value: 'savingsAccount', label: 'Savings Account' },
  { value: 'termDeposit', label: 'Term Deposit' },
  { value: 'depositPaid', label: 'Deposit Paid' },
  { value: 'chargeOverCash', label: 'Charge Over Cash' },
  { value: 'collections', label: 'Collections' },
  { value: 'gifts', label: 'Gifts' },
  { value: 'guarantee', label: 'Guarantee' },
  { value: 'lifeInsurance', label: 'Life Insurance' },
  { value: 'other', label: 'Other' },
  { value: 'timeShare', label: 'Time Share' },
  { value: 'art', label: 'Art' },
  { value: 'familyLoanBeingRepaid', label: 'Family Loan being Repaid' },
  { value: 'furniture', label: 'Furniture' },
  { value: 'homeContents', label: 'Home Contents' },
  { value: 'jewellery', label: 'Jewellery' },
  { value: 'goodwill', label: 'Goodwill' },
  { value: 'equity', label: 'Equity' },
  { value: 'receivables', label: 'Receivables' },
  { value: 'stockAndMachinery', label: 'Stock and Machinery' },
  { value: 'toolsOfTrade', label: 'Tools of Trade' },
  { value: 'businessEquity', label: 'Business Equity' },
  { value: 'superannuation', label: 'Superannuation' },
  { value: 'kiwisaver', label: 'Kiwisaver' },
]

export const AssetFields = ({
  handleBlur,
  handleChange,
  values,
  applicants,
  setFieldValue,
  errors,
}) => (
  <SectionContainer>
    <SubTitle>Asset details</SubTitle>

    <FieldContainer>
      <ValidatedSelectField
        name="assetType"
        label="Asset type*"
        options={nonRealEstateAssetsOptions.map((status) => ({
          label: status.label,
          value: status.value,
          key: status.value,
        }))}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={'Asset type'}
        selectMenuStyles={{
          height: '300px',
          overflowY: 'scroll',
          scrollbarWidth: 'auto',
          '&::-webkit-scrollbar': {
            width: '15px',
            height: 0,
          },
          '&::-webkit-scrollbar-track': {
            background: '#e0e0e0',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#888',
          },
        }}
      />
    </FieldContainer>

    <FieldContainer>
      <NumberField
        name="value"
        label="Value*"
        placeholder="Value"
        thousandSeparator
        prefix="$"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.value}
        labelStyle={s('text-base font-normal text-grey-800')}
      />
    </FieldContainer>

    <FieldContainer>
      <TextField
        name="description"
        label="Description*"
        placeholder="Description"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.description}
        labelStyle={s('text-base font-normal text-grey-800')}
      />
    </FieldContainer>

    <SubTitle style={s('text-base font-bold text-grey-800')}>Ownership</SubTitle>
    {errors.ownerships && typeof errors.ownerships === 'string' && (
      <div style={{ color: 'red' }}>{errors.ownerships}</div>
    )}
    {values.ownerships.map((ownership, index) => {
      return (
        <OwnerShip
          applicants={applicants}
          handleBlur={handleBlur}
          handleChange={handleChange}
          values={values}
          index={index}
          key={index}
          setFieldValue={setFieldValue}
          errors={errors}
        />
      )
    })}

    {values.ownerships.length < 2 && (
      <CollapseButton
        onClick={() =>
          setFieldValue('ownerships', [
            ...values.ownerships,
            { percentage: 0, owner: '' },
          ])
        }
        style={s('mb-2')}
      >
        + Add Owner
      </CollapseButton>
    )}
  </SectionContainer>
)
