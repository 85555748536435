import React from 'react'
import { styled, s } from '@vega/styled'

const Container = styled.div(s('pb-2'))
const Title = styled.h1(s('text-base text-grey-600 font-medium'))
const Row = styled.div(s('pb-1 flex items-center'))
const Checkbox = styled.input(s('ml-0 mr-3'))

const getOptions = (options, onChange) =>
  options.map(({ value, label, testId }) => (
    <Row key={value}>
      <Checkbox
        type="checkbox"
        value={value}
        onChange={onChange}
        data-test-id={testId}
      />
      <span style={s('text-base text-grey-700 font-medium', { marginTop: 6 })}>
        {label}
      </span>
    </Row>
  ))

const CheckboxFilter = ({ title, options, onChange }) => {
  return (
    <Container>
      <Title>{title}</Title>
      {getOptions(options, (e) => onChange(e.target.value, e.target.checked))}
    </Container>
  )
}

export default CheckboxFilter
