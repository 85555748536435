import React, { useEffect, useLayoutEffect } from 'react'
import WizardButtons from 'features/common/WizardButtons'
import ProgressMeter from 'features/common/ProgressMeter'
import { SupportingDocs } from 'features/common/supportingDocs/SupportingDocs'
import { s, styled } from '@vega/styled/v2'
import { useParams, useLocation } from 'react-router-dom'
import Card from 'components/Card'
import { Formik, Form, useFormikContext } from 'formik'
import {
  SubEntityActionButton,
  SubTitle,
  ContentCard as ContentCardBase,
} from 'components/common'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectApplicationById,
  updateNotes,
  fetchApplication,
  setCurrentApplicationId,
  resetNotes,
} from 'modules/application'
import { routes } from 'routes'
import { scrollTopPage } from 'utils/scrollTopPage'

import { listSupportingDocuments } from 'modules/supportingDocument'

const ContentCard = styled(ContentCardBase)(s('py-6 px-5'))

const Input = styled.textarea(
  s('h-full w-full border-0 bg-transparent py-3 pl-3', { resize: 'none' })
)

const AutoDispatcher = ({ applicationId }) => {
  const dispatch = useDispatch()

  const { values } = useFormikContext()
  React.useEffect(() => {
    dispatch(
      updateNotes({
        values,
        id: applicationId,
      })
    )
  }, [dispatch, values, applicationId])
  return null
}

const Notes = () => {
  const location = useLocation()
  const { id } = useParams()
  const dispatch = useDispatch()
  const application = useSelector(selectApplicationById(id))
  const documents = useSelector((state) => state.supportingDocuments.entities)
  const supportingDocuments = documents ? Object.values(documents) : []
  // if (application) supportingDocuments = useSelector(selectAllSupportingDocuments)
  const { notesAndSupportdocs = {} } = application || {}

  useEffect(() => {
    dispatch(fetchApplication(id))
    dispatch(setCurrentApplicationId(id))
  }, [dispatch, id])

  useEffect(() => {
    if (application && application._id) {
      dispatch(
        listSupportingDocuments({
          applicationId: application._id,
        })
      )
    }
  }, [dispatch, application])

  const searchParams = new URLSearchParams(location.search)
  const scrollToId = searchParams.get('scrollTo')

  useLayoutEffect(() => {
    scrollTopPage('instant')
  }, [scrollToId, application])

  return (
    <div>
      <h1 style={s('font-normal text-2xl')}>
        5. Additional Notes and Supporting Documents
      </h1>
      <ProgressMeter numberOfSteps={5} currentStep={5} />
      <Card style={s('mb-12')}>
        <Formik initialValues={notesAndSupportdocs} enableReinitialize>
          {({ values, handleChange, handleBlur }) => (
            <Form>
              <AutoDispatcher applicationId={id} />

              <SubTitle style={s('mb-4')}>Application Notes:</SubTitle>

              <ContentCard
                style={s(
                  'rounded-lg bg-grey-100 border-1 border-grey-200 bg-primary-background '
                )}
              >
                <Input
                  name="notes"
                  placeholder="Leave a note..."
                  value={values.notes}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </ContentCard>

              <SubEntityActionButton
                style={s('bg-accent-red text-white mt-4', { width: 'auto' })}
                onClick={() => {
                  dispatch(
                    resetNotes({
                      id,
                    })
                  )
                }}
              >
                Reset Notes
              </SubEntityActionButton>
            </Form>
          )}
        </Formik>
      </Card>
      <Card>
        <SupportingDocs documents={supportingDocuments} />
      </Card>
      <WizardButtons
        backRoute={routes.customer.application.objectives.replace(':id', id)}
        nextRoute={routes.customer.application.review.replace(':id', id)}
      />
    </div>
  )
}

export default Notes
