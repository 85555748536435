import React from 'react'
import { useMenuItem } from '@react-aria/menu'
import { mergeProps } from '@react-aria/utils'
import { useFocus } from '@react-aria/interactions'

import { s, styled } from '@vega/styled'

const ListItem = styled.li(
  s('w-full p-4 text-grey-900'),
  s('flex items-center', {
    outline: 'none',
    letterSpacing: '0.01em',
    '&:hover, &:focus': s('bg-grey-100'),
  }),
  ({ isFocused }) => isFocused && { cursor: 'pointer' }
)

function MenuItem({ item, state, onAction, onClose }) {
  const ref = React.useRef(null)
  const { menuItemProps } = useMenuItem(
    {
      key: item.key,
      isDisabled: item.isDisabled,
      onAction,
      onClose,
    },
    state,
    ref
  )

  const [isFocused, setFocused] = React.useState(false)
  const { focusProps } = useFocus({ onFocusChange: setFocused })

  return (
    <ListItem
      {...mergeProps(menuItemProps, focusProps)}
      ref={ref}
      isFocused={isFocused}
    >
      {item.rendered}
    </ListItem>
  )
}

export { MenuItem }
