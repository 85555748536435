import React from 'react'
import { s } from '@vega/styled/v2'
import { Chip, TableCell } from 'components/common'
import { formatCurrency } from 'utils/formatters'

const LiabilitiesTable = ({ application }) => {
  const {
    financialPosition: { liabilities },
  } = application

  const rows = liabilities.map((liability) => {
    return (
      <tr key={liability._id}>
        <TableCell>
          <Chip>{liability.liabilityType}</Chip>
        </TableCell>
        <TableCell>{liability.creditor}</TableCell>
        <TableCell>{liability.paymentType}</TableCell>
        <TableCell>{`Limit: ${formatCurrency(liability.limit)}`}</TableCell>
        <TableCell>{`Current balance: ${formatCurrency(
          liability.currentBalance
        )}`}</TableCell>
      </tr>
    )
  })

  const totalLiabilitiesValue = liabilities.reduce(
    (acc, curr) => acc + curr.currentBalance,
    0
  )

  return (
    <>
      <h3>Liabilities - {formatCurrency(totalLiabilitiesValue)}</h3>
      <table style={s('bg-white p-6 rounded-lg shadow-lg w-full')}>
        <tbody>{rows}</tbody>
      </table>
    </>
  )
}

export default LiabilitiesTable
