import BaseService from '../baseService'
import { QueryBuilder } from '../utils'

export class SupportingDocumentService extends BaseService {
  create(
    classification,
    fileName,
    fileType,
    source,
    versionedLoanApplicationId,
    loanApplicationId,
    references,
    logDownload
  ) {
    const config = {
      json: {
        classification,
        fileName,
        fileType,
        source,
        versionedLoanApplicationId,
        loanApplicationId,
        references,
        logDownload,
      },
    }

    return this.client.post('documents', config).json()
  }

  list(versionedLoanApplicationId) {
    const queryParams = QueryBuilder()
      .setParam('versionedLoanApplicationId', versionedLoanApplicationId)
      .build()

    return this.client.get(`documents?${queryParams}`).json()
  }
}
