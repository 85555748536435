import BaseService from '../baseService'
import { QueryBuilder } from '../utils'

export class ProfileService extends BaseService {
  readMyProfile() {
    return this.nonRedirectingClient.get('my/user-info').json()
  }

  createInvitation({ email, role }) {
    const config = {
      json: { email, role },
    }
    return this.client.post(`invitations`, config).json()
  }

  resendInvitation(invitationCode) {
    return this.client.put(`invitations/${invitationCode}`).json()
  }

  fetchInvitations(email) {
    const queryParams = QueryBuilder().setParam('email', email).build()

    return this.client.get(`invitations?${queryParams}`).json()
  }

  updateMyProfile(id, { firstName, lastName, dateOfBirth, gender, phoneNumber }) {
    const config = {
      json: {
        firstName,
        lastName,
        dateOfBirth,
        gender,
        phoneNumber,
      },
    }

    return this.client.patch(`users/${id}`, config).json()
  }
}
