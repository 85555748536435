import React from 'react'
import { s, styled } from '@vega/styled/v2'

const Container = styled.div()
const Label = styled.div(
  s('text-sm font-normal', {
    color: '#515856',
    letterSpacing: '0.01em',
    lineHeight: 1.5,
  }),
  ({ labelStyle }) => labelStyle
)
const Value = styled.div(
  s('text-base font-semibold', {
    color: '#282C2B',
    letterSpacing: '0.01em',
    lineHeight: 1.5,
  }),
  ({ valueStyle }) => valueStyle
)

const DataDisplay = ({
  label,
  value = '-',
  labelStyle = {},
  valueStyle = {},
  testId,
  ...props
}) => {
  return (
    <Container {...props}>
      <Label labelStyle={labelStyle}>{label}</Label>
      <Value data-test-id={testId} valueStyle={valueStyle}>
        {value || '-'}
      </Value>
    </Container>
  )
}

export { DataDisplay }
