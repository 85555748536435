import React, { useEffect, useLayoutEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, Link, useLocation } from 'react-router-dom'
import WizardButtons from 'features/common/WizardButtons'
import ProgressMeter from 'features/common/ProgressMeter'
import { s } from '@vega/styled/v2'
import {
  selectApplicationById,
  fetchApplication,
  setCurrentApplicationId,
  addPropertyAsset,
  addFund,
} from 'modules/application'
import { RealEstateAssetCard } from '../financialPosition/RealEstateAssetCard'
import { AddPrimaryEntityButton } from 'components/common'
import FundingCard from './FundingCard'
import { routes } from 'routes'
import { scrollTopPage } from 'utils/scrollTopPage'

const FundingDetails = () => {
  const { id } = useParams()
  const location = useLocation()
  const scrollToRef = useRef(null)
  const dispatch = useDispatch()
  const application = useSelector(selectApplicationById(id))

  useEffect(() => {
    dispatch(fetchApplication(id))
    dispatch(setCurrentApplicationId(id))
  }, [dispatch, id])

  const searchParams = new URLSearchParams(location.search)
  const scrollToId = searchParams.get('scrollTo')

  useLayoutEffect(() => {
    if (scrollToId && scrollToRef.current) {
      scrollToRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [scrollToId, application])

  useEffect(() => {
    if (!scrollToId && !scrollToRef.current) {
      scrollTopPage('instant')
    }
  }, [scrollToId])

  if (!application) {
    return (
      <div>
        <h1 style={s('font-normal text-2xl')}>3. Funding Details</h1>
        <ProgressMeter numberOfSteps={5} currentStep={3} />
        <h2>Could not find application</h2>
        <p>We are having trouble finding this application.</p>
        <p>Please use the link below to go back to the applications screen.</p>
        <Link to={routes.customer.homepage}>Back to applications</Link>
      </div>
    )
  }

  const {
    financialPosition: { realEstateAssets },
    fundingDetails: { availableFunds },
    applicants,
    households,
  } = application

  const householdApplicants = households.flatMap((household) => household.members)

  const allApplicants = householdApplicants.concat(applicants)

  if (allApplicants.length === 0) {
    return (
      <div>
        <h1 style={s('font-normal text-2xl')}>3. Funding Details</h1>
        <ProgressMeter numberOfSteps={5} currentStep={3} />
        <h2>Funding requires at least one applicant</h2>
        <p>
          Currently your application has no applicants saved, applicants are required to
          record your funding details.
        </p>
        <p>Please use the link below to go back to the applicants screen.</p>
        <Link
          to={routes.customer.application.applicants.replace(':id', application._id)}
        >
          Back to applicants
        </Link>
      </div>
    )
  }

  const saveApplicants = () => {}

  const propertyPurchaseCards = realEstateAssets
    ?.map((asset, originalIndex) => {
      const filteredAsset = asset.transaction === 'Purchasing' ? asset : null
      return filteredAsset ? (
        <RealEstateAssetCard
          key={originalIndex}
          asset={filteredAsset}
          index={originalIndex}
          applicationId={id}
          applicants={allApplicants}
          ref={filteredAsset._id === scrollToId ? scrollToRef : null}
        />
      ) : null
    })
    .filter(Boolean)

  const propertyRefinaneCards = realEstateAssets
    ?.map((asset, originalIndex) => {
      const filteredAsset =
        asset.transaction === 'Owns Existing Mortgage' ? asset : null
      return filteredAsset ? (
        <RealEstateAssetCard
          key={originalIndex}
          asset={filteredAsset}
          index={originalIndex}
          applicationId={id}
          applicants={allApplicants}
          ref={filteredAsset._id === scrollToId ? scrollToRef : null}
        />
      ) : null
    })
    .filter(Boolean)

  const fundingCards = availableFunds.map((asset, index) => (
    <FundingCard
      key={index}
      asset={asset}
      index={index}
      applicationId={id}
      ref={asset._id === scrollToId ? scrollToRef : null}
    />
  ))

  const propertySecurityCards = realEstateAssets
    ?.map((asset, originalIndex) => {
      const filteredAsset = asset.isUsedAsSecurity === true ? asset : null
      return filteredAsset ? (
        <RealEstateAssetCard
          key={originalIndex}
          asset={filteredAsset}
          index={originalIndex}
          applicationId={id}
          applicants={allApplicants}
          ref={filteredAsset._id === scrollToId ? scrollToRef : null}
        />
      ) : null
    })
    .filter(Boolean)

  return (
    <div>
      <h1 style={s('font-normal text-2xl')}>3. Funding Details</h1>

      <ProgressMeter numberOfSteps={5} currentStep={3} />

      <div>
        <h2 style={s('mb-2')}>Property to Purchase</h2>
        {propertyPurchaseCards}
        <AddPrimaryEntityButton
          style={s('mb-6')}
          onClick={() => dispatch(addPropertyAsset({ id, transaction: 'Purchasing' }))}
        >
          + Add Property for Purchase
        </AddPrimaryEntityButton>
      </div>

      <div>
        <h2 style={s('mb-2')}>Property to Refinance</h2>
        {propertyRefinaneCards}
        <AddPrimaryEntityButton
          style={s('mb-6')}
          onClick={() =>
            dispatch(addPropertyAsset({ id, transaction: 'Owns Existing Mortgage' }))
          }
        >
          + Add Property for refinance
        </AddPrimaryEntityButton>
      </div>

      <div>
        <h2 style={s('mb-2')}>Properties for Security</h2>
        {propertySecurityCards}
        <AddPrimaryEntityButton
          style={s('mb-6')}
          onClick={() =>
            dispatch(
              addPropertyAsset({
                id,
                isUsedAsSecurity: 'true',
                transaction: 'Purchasing',
              })
            )
          }
        >
          + Add Property for Security
        </AddPrimaryEntityButton>
      </div>

      <div>
        <h2 style={s('mb-2')}>Funds Available</h2>
        {fundingCards}
        <AddPrimaryEntityButton
          style={s('mb-6')}
          onClick={() => dispatch(addFund({ id }))}
        >
          + Add Funding
        </AddPrimaryEntityButton>
      </div>

      <WizardButtons
        currentStep={3}
        save={saveApplicants}
        backRoute={routes.customer.application.financialPosition.replace(':id', id)}
        nextRoute={routes.customer.application.objectives.replace(':id', id)}
      />
    </div>
  )
}

export default FundingDetails
