import React, { useEffect } from 'react'
import WizardButtons from 'features/common/WizardButtons'
import ProgressMeter from 'features/common/ProgressMeter'
import { s } from '@vega/styled/v2'
import { useParams, useLocation } from 'react-router-dom'
import {
  selectApplicationById,
  updateDeclarations,
  fetchApplication,
  setCurrentApplicationId,
} from 'modules/application'
import { useSelector, useDispatch } from 'react-redux'
import Card from 'components/Card'
import { Formik, Form, useFormikContext } from 'formik'
import { DeclarationCard } from './DeclarationCard'
import { routes } from 'routes'
import * as Yup from 'yup'
import { scrollTopPage } from 'utils/scrollTopPage'

export const importantDateSchema = Yup.object().shape({
  dateType: Yup.string().required('Date type is required'),
  date: Yup.date().required('Date is required'),
})

export const depositSchema = Yup.object().shape({
  source: Yup.string().required('Deposit source is required'),
  amount: Yup.string().required('Amount is required'),
  description: Yup.string().required('Description is required'),
})

export const objectivesSchema = Yup.object({
  goals: Yup.object({
    primaryPurposes: Yup.array()
      .of(Yup.string())
      .min(1, 'At least one Primary Purpose is required')
      .required('Primary Purpose is required'),
  }),
  importantDates: Yup.object().shape({
    hasImportantDates: Yup.string().required('Important Dates field is required'),
    dates: Yup.array().when('hasImportantDates', {
      is: 'Yes',
      then: Yup.array()
        .of(importantDateSchema)
        .min(1, 'At least one important date is now required.'),
      otherwise: Yup.array().notRequired(),
    }),
  }),

  ratePreferences: Yup.object().shape({
    hasRatePreferences: Yup.string().required('Rate Preference is required'),
    rateType: Yup.string().when('hasRatePreferences', {
      is: 'Yes',
      then: Yup.string().required('Rate Type is required'),
      otherwise: Yup.string().notRequired(),
    }),
    fixedRateDuration: Yup.string().when('rateType', {
      is: (rateType, hasRatePreferences) =>
        hasRatePreferences === 'Yes' && rateType !== 'Basic Variable',
      then: Yup.string().required('Fixed Rate Duration is required'),
      otherwise: Yup.string().notRequired(),
    }),
  }),

  repaymentPreferences: Yup.object().shape({
    hasRepaymentPreferences: Yup.string().required('Repayment Preference is required'),
  }),

  specialFeaturePreferences: Yup.object().shape({
    hasSpecialFeaturePreferences: Yup.string().required(
      'Special Feature Preference is required'
    ),
  }),

  debtArrears: Yup.object({
    previousArrears: Yup.object({
      hasArrears: Yup.string().required('Previous debt arrears is required'),
      note: Yup.string().when('hasArrears', {
        is: 'Yes',
        then: Yup.string().required('Elaboration on the previous arrears is required'),
        otherwise: Yup.string().notRequired(),
      }),
    }),
    currentArrears: Yup.object({
      hasArrears: Yup.string().required('Current debt arrears is required'),
      note: Yup.string().when('hasArrears', {
        is: 'Yes',
        then: Yup.string().required('Elaboration on the current arrears is required'),
        otherwise: Yup.string().notRequired(),
      }),
    }),
    hasPastDirectorship: Yup.string().required('Past directorship is required'),
    hasGoodConduct: Yup.string().required('Account conduct is required'),
  }),

  insurance: Yup.object().shape({
    hasFamilyInsurance: Yup.string().required('Family Insurance is required'),
    hasHomeContentsInsurance: Yup.string().required(
      'House & Contents Insurance is required'
    ),
  }),

  otherObjectives: Yup.object({
    hasOtherObjectives: Yup.string().required('Other objectives is required'),
    otherObjectivesNotes: Yup.string().when('hasOtherObjectives', {
      is: 'Yes',
      then: Yup.string().required('Elaboration on other objectives is required'),
      otherwise: Yup.string().notRequired(),
    }),
  }),
})

const AutoDispatcher = ({ applicationId }) => {
  const dispatch = useDispatch()

  const { values } = useFormikContext()

  React.useEffect(() => {
    dispatch(
      updateDeclarations({
        declarations: values,
        id: applicationId,
      })
    )
  }, [dispatch, values, applicationId])
  return null
}

const Objectives = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { id } = useParams()
  const application = useSelector(selectApplicationById(id))
  const { declarations = {} } = application || {}

  useEffect(() => {
    dispatch(fetchApplication(id))
    dispatch(setCurrentApplicationId(id))
  }, [dispatch, id])

  const searchParams = new URLSearchParams(location.search)
  const scrollToId = searchParams.get('scrollTo')

  useEffect(() => {
    scrollTopPage('instant')
  }, [scrollToId])

  return (
    <div>
      <h1 style={s('font-normal text-2xl')}>4. Objectives and Declarations</h1>
      <ProgressMeter numberOfSteps={5} currentStep={4} />

      <Card style={s('mb-12')}>
        <Formik
          initialValues={declarations}
          enableReinitialize
          validationSchema={objectivesSchema}
        >
          {({ values, handleChange, handleBlur, setFieldValue, errors }) => {
            return (
              <Form>
                <AutoDispatcher applicationId={id} />

                <DeclarationCard
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  values={values}
                  errors={errors}
                />
              </Form>
            )
          }}
        </Formik>
      </Card>

      <WizardButtons
        currentStep={4}
        backRoute={routes.customer.application.fundingDetails.replace(':id', id)}
        nextRoute={routes.customer.application.notes.replace(':id', id)}
      />
    </div>
  )
}

export default Objectives
