import React from 'react'
import { s, styled } from '@vega/styled/v2'
import { Button } from '@vega/components'
import { Chip, TableCell, SubTitle } from 'components/common'
import { formatCurrency } from 'utils/formatters'
import { Link, useParams } from 'react-router-dom'
import { useLargeMediaQuery, Row, Col, Container } from '@vega/styled'
import Card from 'components/Card'

const OuterContainer = styled.div(
  s(
    'bg-white flex flex-row justify-between w-full border-0 border-b-1 border-solid border-grey-200',
    {
      '&:last-child': {
        borderColor: 'transparent',
      },
    }
  )
)
const Root = styled.div(
  s(
    'bg-white flex flex-row justify-between w-full border-0 border-b-1 border-solid border-grey-200 mt-4 mb-4 ',
    {
      '&:last-child': {
        borderColor: 'transparent',
      },
      ':hover': s('bg-secondary rounded-lg', { cursor: 'pointer' }),
    }
  )
)

const Applicants = styled.div(s('text-primary font-semibold text-base mt-1'))

const ExtraLoanInfo = styled.div(s('text-base text-primary'))

const NonRealEstateAssetTable = ({ application }) => {
  const { id } = useParams()
  const isLargeScreen = useLargeMediaQuery('larger')
  const {
    financialPosition: { assets },
    applicants,
    households,
  } = application

  const householdApplicants = households.flatMap((household) => household.members)
  const allApplicants = householdApplicants.concat(applicants)

  const rows = assets.map((asset) => {
    const sectionId = asset._id
    const ownersName = allApplicants
      .filter((owner) =>
        asset.ownerships.map((ownership) => ownership.owner).includes(owner._id)
      )
      .map((applicant) => `${applicant.firstName}`)
      .join(' & ')

    if (!isLargeScreen) {
      return (
        <OuterContainer>
          <Root>
            <Container gutter={0}>
              <Row gutter={[0, 0]} style={s('p-2')}>
                <Col span={18}>
                  <Applicants className="sohne">{asset.description}</Applicants>
                  <Chip>{asset.assetType}</Chip>
                  <ExtraLoanInfo>- Owners: {ownersName}</ExtraLoanInfo>
                  <ExtraLoanInfo>- {formatCurrency(asset.value)}</ExtraLoanInfo>
                </Col>
                <Col span={6}>
                  <div
                    style={s('flex items-center justify-end ml-auto', {
                      marginTop: '45px',
                    })}
                  >
                    <Link
                      style={s('', { textDecoration: 'none' })}
                      to={`/customer/application/${id}/financial-position?scrollTo=${sectionId}`}
                    >
                      <Button variant="outlined">View/Edit</Button>
                    </Link>
                  </div>
                </Col>
              </Row>
            </Container>
          </Root>
        </OuterContainer>
      )
    }

    return (
      <tr key={asset._id} style={s('flex items-center justify-between mb-4')}>
        <TableCell>
          <Chip>{asset.assetType}</Chip>
        </TableCell>
        <TableCell>{asset.description}</TableCell>
        <TableCell>Owners: {ownersName}</TableCell>
        <TableCell>{asset.institution}</TableCell>
        <TableCell>{formatCurrency(asset.value)}</TableCell>
        <td>
          <div style={s('flex justify-end ml-auto mb-4')}>
            <Link
              style={s('', { textDecoration: 'none' })}
              to={`/customer/application/${id}/financial-position?scrollTo=${sectionId}`}
            >
              <Button variant="outlined">View/Edit</Button>
            </Link>
          </div>
        </td>
      </tr>
    )
  })

  const totalAssetsValue = assets.reduce((acc, curr) => acc + curr.value, 0)

  if (rows.length === 0) {
    return (
      <>
        <h3>Assets - {formatCurrency(totalAssetsValue)}</h3>

        <Card>
          <SubTitle>No assets have been specified</SubTitle>
          <p>Assets can be specified in the application financial position section.</p>
        </Card>
      </>
    )
  }

  return (
    <>
      <h3>Assets - {formatCurrency(totalAssetsValue)}</h3>
      <table style={s('bg-white p-6 rounded-lg shadow-lg w-full')}>
        <tbody>{rows}</tbody>
      </table>
    </>
  )
}

export default NonRealEstateAssetTable
