import React, { forwardRef } from 'react'
import { omit } from '@neo/ramda-extra'
import { s } from '@vega/styled/v2'

// eslint-disable-next-line react/display-name
export const Anchor = forwardRef(
  ({ offsetTop = -150, anchorProps, ...otherProps }, ref) => {
    return (
      <div style={s('relative')} {...otherProps}>
        <div
          ref={ref}
          style={{
            position: 'absolute',
            top: offsetTop,
            left: 0,
            ...anchorProps?.style,
          }}
          {...omit(['style'], forwardRef)}
        />
      </div>
    )
  }
)
