import React, { useRef } from 'react'
import { useCheckbox } from '@react-aria/checkbox'
import { useToggleState } from '@react-stately/toggle'
import { VisuallyHidden } from '@react-aria/visually-hidden'
import PropTypes from 'prop-types'

import { s, styled } from '@vega/styled/v2'

const { oneOf, string } = PropTypes

const labelStyleBySize = {
  sm: s('text-xs'),
  md: s('text-base'),
}

const svgStyleBySize = {
  sm: { width: 16, height: 16 },
  md: { width: 24, height: 24 },
}

const Label = styled.label(
  s('text-grey-700 font-normal text-base items-center flex relative'),
  ({ size }) => ({ ...labelStyleBySize[size] }),
  ({ readOnly }) => readOnly && { pointerEvents: 'none' }
)
const Svg = styled.svg(s('mr-2 flex-shrink-0'), { fill: 'none' }, ({ size }) => ({
  ...svgStyleBySize[size],
}))
const Rect = styled.rect(
  { width: 22, height: 22, x: 1, y: 1, rx: 4, strokeWidth: 1 },
  ({ isSelected, boxColour = 'none' }) =>
    isSelected
      ? { fill: s('text-secondary').color, stroke: '#58C6BD' }
      : { fill: boxColour, stroke: '#A4B0C0' },
  ({ hasError, isTouched }) =>
    hasError && isTouched && { fill: 'none', stroke: '#F53244' },
  ({ hasError, isSelected, isTouched }) =>
    hasError && isSelected && isTouched && { fill: '#58C6BD', stroke: '#F53244' }
)

function Checkbox({
  children,
  hasError,
  isTouched,
  size = 'sm',
  checkboxProps,
  readOnly,
  boxColour,
  testId,
  ...props
}) {
  const state = useToggleState(props)
  const { isSelected } = state
  const ref = useRef()
  const { inputProps } = useCheckbox(props, state, ref)

  return (
    <Label size={size} {...props} readOnly={readOnly}>
      <VisuallyHidden>
        <input {...inputProps} ref={ref} />
      </VisuallyHidden>

      <Svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        size={size}
        data-test-id={testId}
        {...checkboxProps}
      >
        <Rect
          isSelected={isSelected}
          hasError={hasError}
          isTouched={isTouched}
          boxColour={boxColour}
        />
        {isSelected && <path d="M5 13l4 4L19 7" stroke="#1E293B" />}
      </Svg>
      {children}
    </Label>
  )
}

Checkbox.propTypes = {
  size: oneOf(['sm', 'md']),
  'aria-label': string,
}

export { Checkbox }
