import BaseService from '../baseService'
import { isNil } from '@neo/ramda-extra'

export class CreditReportService extends BaseService {
  startCreditCheck(entityId, versionedLoanApplicationId) {
    const twoMins = 60 * 2 * 1000
    const config = { json: { entityId, versionedLoanApplicationId }, timeout: twoMins }

    return this.client.post('credit-report', config).json()
  }

  getCreditReport(versionedLoanApplicationId, externalRef) {
    if (isNil(versionedLoanApplicationId)) throw new Error('Invalid Versioned Loan Application Id')
    if (isNil(externalRef)) throw new Error('Invalid External Ref')

    return this.client.get(`credit-report?versionedLoanApplicationId=${versionedLoanApplicationId}&externalRef=${externalRef}`).json()
  }
}
