import {
  InvitationService,
  LoanApplicationService,
  UserService,
  CreditReportService,
  PropertyService,
  ActivityLogService,
  ReportService,
} from '@vega/services'

const profileApiUri = process.env.REACT_APP_PROFILE_API_URI
const lendingApiUri = process.env.REACT_APP_LENDING_API_URI

const getOptions = (baseUri) => ({ baseUri })

export const userService = new UserService(getOptions(profileApiUri))
export const invitationService = new InvitationService(getOptions(profileApiUri))
export const loanApplicationService = new LoanApplicationService(
  getOptions(lendingApiUri)
)
export const creditReportService = new CreditReportService(getOptions(lendingApiUri))
export const propertyService = new PropertyService(getOptions(lendingApiUri))
export const activityLogService = new ActivityLogService(getOptions(lendingApiUri))
export const reportService = new ReportService(getOptions(lendingApiUri))
