import React, { useState } from 'react'

import {
  SubTitle,
  FieldContainer,
  SubEntityCard,
  CollapseButton,
  SubEntityActionButton,
} from 'components/common'
import { Modal, NumberField, TextField, Button } from '@vega/components'
import { s, styled } from '@vega/styled/v2'
import { Formik, Form } from 'formik'
import { ReactComponent as XBase } from '@vega/components/src/assets/images/x.svg'
import Card from 'components/Card'
import { larger } from '@vega/styled'
import { formatCurrency, formatStringToNumber } from 'utils/formatters'
import { depositSchema } from './index'
import { ValidatedSelectField } from 'components/SelectField'

// TODO: duplication
export const X = styled(XBase)(s('text-primary', { cursor: 'pointer' }))

// TODO: duplication
const ModalCard = styled(Card)(
  s('rounded-none', { height: '100vh', width: '100vw', overflowY: 'scroll' }),
  larger('xl')(s(' h-full rounded-lg', { width: '60rem', maxHeight: '80vh' }))
)

const depositSources = [
  'Deposit Paid',
  'First Home Grant',
  'Net Proceeds of Sale',
  'Cash',
  'Savings',
  'Gift',
  'KiwiSaver First Home Withdrawal',
  'Other',
  'Equity another property own names(s)',
  'Equity another property other part names(s)',
  'Vendor finance or rent-to-buy scenario',
  'Existing equity',
  'Trade-In',
]

/* eslint-disable complexity */
export const DepositFields = ({ values, setFieldValue }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const openModal = () => setIsModalOpen(true)
  const closeModal = () => setIsModalOpen(false)

  const depositSourcesAvailable = () =>
    depositSources.filter((depositSource) => {
      const currentDepositSources = (values.deposits || []).map(
        (deposit) => deposit.source
      )

      return !currentDepositSources.includes(depositSource)
    })

  const addDeposit = (depositValues) => {
    const resultingDeposits = (values.deposits || []).concat({
      source: depositValues.source,
      amount: formatStringToNumber(depositValues.amount),
      description: depositValues.description,
    })

    setFieldValue('deposits', resultingDeposits)
    closeModal()
  }

  const removeDeposit = (depositSource) => {
    const resultingDeposits = values.deposits.filter(
      (deposit) => deposit.source !== depositSource
    )

    setFieldValue('deposits', resultingDeposits)
  }

  return (
    <>
      <SubTitle>Deposits</SubTitle>
      {(values.deposits || []).length === 0 && <p>Currently no deposits</p>}

      {(values.deposits || []).length > 0 && (
        <div>
          {values.deposits.map((deposit) => (
            <SubEntityCard key={deposit.source} style={s('flex')}>
              <div style={s('py-1', { flexGrow: 1 })}>
                <span style={s('font-semibold')}>{`${deposit.source} - `}</span>
                {formatCurrency(deposit.amount)}
              </div>
              <SubEntityActionButton
                onClick={() => removeDeposit(deposit.source)}
                style={s('bg-accent-red text-white py-1', { width: 'fit-content' })}
              >
                Remove
              </SubEntityActionButton>
            </SubEntityCard>
          ))}
        </div>
      )}

      <CollapseButton onClick={openModal}>Add deposit</CollapseButton>

      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <ModalCard>
          <div style={s('flex justify-end')}>
            <SubTitle style={s('m-0', { flexGrow: 1 })}>Add deposit</SubTitle>
            <X onClick={closeModal} />
          </div>

          {depositSourcesAvailable().length === 0 && (
            <>
              <p>Ran out of deposit sources</p>
              <Button variant="secondary" onClick={closeModal}>
                Close
              </Button>
            </>
          )}

          <Formik
            initialValues={{}}
            onSubmit={addDeposit}
            validationSchema={depositSchema}
            validateOnMount={true}
          >
            {({ handleChange, handleBlur }) =>
              depositSourcesAvailable().length > 0 && (
                <Form>
                  <FieldContainer>
                    <ValidatedSelectField
                      name="source"
                      label="Deposit source"
                      options={depositSourcesAvailable().map((depositSource) => ({
                        label: depositSource,
                        value: depositSource,
                        key: depositSource,
                      }))}
                      placeholder="Deposit source"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FieldContainer>

                  <FieldContainer>
                    <NumberField
                      name="amount"
                      label="Amount"
                      placeholder="Amount"
                      prefix="$"
                      thousandSeparator
                      labelStyle={s('text-base')}
                      onBlur={handleBlur}
                    />
                  </FieldContainer>

                  <FieldContainer>
                    <TextField
                      name="description"
                      label="Description"
                      placeholder="Description"
                      labelStyle={s('text-base')}
                      onBlur={handleBlur}
                    />
                  </FieldContainer>

                  <div style={s('flex mt-4')}>
                    <Button type="submit" style={s('mr-2')}>
                      Add deposit
                    </Button>
                    <Button onClick={closeModal} variant="secondary">
                      Cancel
                    </Button>
                  </div>
                </Form>
              )
            }
          </Formik>
        </ModalCard>
      </Modal>
    </>
  )
}
