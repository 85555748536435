import React from 'react'
import { styled, s } from '@vega/styled'

const Container = styled.div(
  s('text-grey-600 text-xs px-2 py-2 font-semibold z-3 flex', {
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    '&:hover': s('bg-grey-100'),
  }),
  ({ textColorMap, text }) =>
    textColorMap && textColorMap[text] && s(textColorMap[text])
)

function DropdownItem({ text, ...otherProps }) {
  return (
    <Container {...otherProps} text={text}>
      <span style={s('pt-1')}>{text}</span>
    </Container>
  )
}

export { DropdownItem }
