import React from 'react'
import PageWrapper from 'features/common/PageWrapper'
import { s } from '@vega/styled/v2'
import { Formik } from 'formik'
import { RepaymentsSummary } from './RepaymentsSummary'
import RepaymentInputs from './RepaymentInputs'
import RepaymentDisclaimers from './RepaymentDisclaimers'

const PreApproval = () => {
  const floatingRate = 8.64

  return (
    <PageWrapper>
      <Formik
        initialValues={{
          loanPurpose: 'firstHome',
          estimatedPropertyValue: '600000',
          depositAmount: '150000',
          loanTerm: 30,
          rateType: 'floating',
          repaymentType: 'p&i',
          interestOnlyTerm: '12',
        }}
        enableReinitialize
        // TODO: Add validation
        // validationSchema={householdSchema}
        onSubmit={() => null}
      >
        {({ values, handleChange, handleBlur, setFieldValue }) => (
          <>
            <h1 style={s('font-normal text-2xl')}>Repayment calculator</h1>
            <div style={s('flex', { gap: 12 })}>
              <div style={{ width: '75%' }}>
                <RepaymentInputs
                  values={values}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  floatingRate={floatingRate}
                />
              </div>

              <div style={{ width: '25%'}}>
                <RepaymentsSummary values={values} floatingRate={floatingRate} />
              </div>
            </div>
          </>
        )}
      </Formik>
      <RepaymentDisclaimers />
    </PageWrapper>
  )
}

export default PreApproval
