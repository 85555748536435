import React from 'react'
import PropTypes from 'prop-types'
import { useField, Field } from 'formik'
import { Item } from '@react-stately/collections'

import { Select } from './Select'

const { string, array, bool, object } = PropTypes

function ValidatedSelectField({
  options,
  disabled = false,
  readonly = false,
  selectMenuStyles,
  togglerStyles,
  testId,
  menuId,
  name,
  type = 'select',
  labelContainerStyle,
  ...props
}) {
  const [, , { setValue }] = useField({ name })

  return (
    <Field name={name} type={type}>
      {({ field, meta, form }) => {
        const { value, name } = field
        const { touched, error } = meta

        return (
          <Select
            selectedKey={`${value}`}
            onSelectionChange={setValue}
            disabled={disabled}
            readonly={readonly}
            hasError={touched && error}
            error={error}
            togglerStyles={togglerStyles}
            selectMenuStyles={selectMenuStyles}
            name={name}
            {...props}
            testId={testId}
            menuId={menuId}
            labelContainerStyle={labelContainerStyle}
            onBlur={() => {
              form.setFieldTouched(field.name, true)
            }}
          >
            {options.map(({ value, label }) => (
              <Item key={value}>{label}</Item>
            ))}
          </Select>
        )
      }}
    </Field>
  )
}

ValidatedSelectField.propTypes = {
  name: string.isRequired,
  options: array.isRequired,
  label: string,
  readonly: bool,
  disabled: bool,
  togglerStyles: object,
  selectMenuStyles: object,
}

export { ValidatedSelectField }
