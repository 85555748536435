import React from 'react'
import { s } from '@vega/styled/v2'
import { Chip, TableCell } from 'components/common'
import { formatCurrency } from 'utils/formatters'

const AvailableFunds = ({ application }) => {
  const {
    fundingDetails: { availableFunds },
  } = application

  const rows = availableFunds.map((funding) => {
    return (
      <tr key={funding._id}>
        <TableCell>
          <Chip>{funding.fundType}</Chip>
        </TableCell>
        <TableCell>{funding.description}</TableCell>
        <TableCell>{formatCurrency(funding.value)}</TableCell>
        <TableCell />
        <TableCell />
      </tr>
    )
  })

  const totalFunding = availableFunds.reduce((acc, curr) => acc + curr.value, 0)

  return (
    <>
      <h3>Funds Available - {formatCurrency(totalFunding)}</h3>
      <table style={s('bg-white p-6 rounded-lg shadow-lg w-full')}>
        <tbody>{rows}</tbody>
      </table>
    </>
  )
}

export default AvailableFunds
