import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { styled, s } from '@vega/styled'

import { copyToClipboard } from './utils/copyToClipboard'
import { ReactComponent as CopyIcon } from '@vega/components/src/assets/images/copy.svg'

const Container = styled.div(
  s('flex items-center justify-between bg-grey-100 rounded-lg w-full px-5 pb-3 pt-4'),
  ({ disabled }) => (disabled ? s('text-grey-300', { pointerEvents: 'none' }) : {})
)

const IconContainer = styled.div({ cursor: 'pointer' }, ({ disabled }) => ({
  '& path': { stroke: disabled && s('text-grey-300').color },
}))

function LinkContainer({ email, link, disabled, testId, ...otherProps }) {
  const [linkCopied, setLinkCopied] = useState(false)

  const iconClickHandler = () => {
    copyToClipboard(link)
    setLinkCopied(true)
  }

  return (
    <Container data-test-id={testId} disabled={disabled} {...otherProps}>
      {linkCopied ? (
        `Link has been saved.`
      ) : (
        <>
          {link}
          <IconContainer disabled={disabled}>
            <CopyIcon onClick={iconClickHandler} />
          </IconContainer>
        </>
      )}
    </Container>
  )
}

LinkContainer.propTypes = {
  link: PropTypes.string,
  disabled: PropTypes.bool,
  email: PropTypes.string,
}

LinkContainer.defaultProps = {
  disabled: false,
}

export default LinkContainer
