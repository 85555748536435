import React from 'react'
import SideBar from './SideBar'
import SideBarSmall from './SideBarSmall'
import { s, styled } from '@vega/styled/v2'
import { larger, smaller } from '@vega/styled'
import { Toaster } from '@vega/components'

const PageWrapperContainter = styled.div(
  s('flex bg-primary-background px-3', { height: 'calc(100vh - 115px)' }),
  larger('xl')({ height: '100vh' })
)

const ChildViewport = styled.div(
  s('py-16'),
  smaller('sm')({ width: '23rem' }),
  larger('xl')({ width: '60rem' }),
  larger('jb')({ width: '80rem' })
)

const ResponsiveToaster = styled(Toaster)(
  larger('xl')({ marginLeft: '24rem' }),
  larger('jb')({ width: '24rem' })
)

const PageWrapper = ({ children }) => (
  <>
    <SideBarSmall>
      <PageWrapperContainter>
        <SideBar />
        <div style={s('flex justify-center', { width: '100vw', overflowY: 'scroll' })}>
          <ChildViewport id="customer-app">
            {children}
            <ResponsiveToaster />
          </ChildViewport>
        </div>
      </PageWrapperContainter>
    </SideBarSmall>
  </>
)

export default PageWrapper
