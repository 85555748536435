import React from 'react'
import { styled, s } from '@vega/styled'

const Message = styled.div(s('mt-2 text-error-600 text-sm'))

function ErrorMessage({ children, ...otherProps }) {
  return <Message {...otherProps}>{children}</Message>
}

export { ErrorMessage }
