const routes = /** @type {const} */ ({
  admin: {
    root: '/admin',
    homepage: '/admin/homepage',
    application: {
      overview: '/admin/application/:id',
    },
  },

  customer: {
    root: '/customer',
    homepage: '/customer/homepage',
    application: {
      applicants: '/customer/application/:id/applicants',
      financialPosition: '/customer/application/:id/financial-position',
      fundingDetails: '/customer/application/:id/funding-details',
      objectives: '/customer/application/:id/objectives',
      notes: '/customer/application/:id/notes',
      review: '/customer/application/:id/review',
    },
  },
})

export { routes }
