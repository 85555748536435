import React from 'react'
import NeoLogo from '@vega/components/src/assets/images/neo-logo.png'
import { s, styled } from '@vega/styled/v2'
import { StepNumber } from 'components/common'
import { larger } from '@vega/styled'

const authUri = `${process.env.REACT_APP_AUTH_WEB_URI}sign-in?redirectApp=APPLY_ONLINE`

const LandingContainer = styled.div(s('block '), larger('md')(s('flex')))

const StepButton = styled.button(
  s(
    'bg-accent-pink rounded-lg border-0 px-8 py-4 font-extrabold text-lg w-24 block mb-4'
  )
)

const BookACallButton = styled.button(
  s(
    'bg-transparent rounded-lg border-primary px-8 py-4 font-extrabold text-lg w-24 block mb-4'
  )
)

const LogInButton = styled.a(
  s(
    'bg-transparent text-primary  rounded-lg border-primary px-8 py-4 font-semibold bg-accent-pink w-full block mb-4',
    { textDecoration: 'none' }
  ),
  larger('sm')(s('w-24'))
)

const LandingStepNumber = styled(StepNumber)(s('bg-primary text-white'))

const NumberListItem = styled.li(s('flex text-xl mb-4'))

const LandingColumn = styled.div(
  s('flex flex-col justify-center px-16 py-16', { flexDirection: 'column' }),
  larger('md')(s('w-1/2 py-0', { height: '100vh' }))
)

const Landing = () => (
  <LandingContainer>
    <LandingColumn style={s('bg-secondary')}>
      <img src={NeoLogo} style={s('w-12 h-4')} />
      <h1 style={s('font-black text-6xl mt-3', { fontSize: '3rem ' })}>
        Kickstart your home loan journey today
      </h1>
      <h2>Apply online in 3 easy steps:</h2>

      <ul style={s('pl-0', { listStyleType: 'none' })}>
        <NumberListItem>
          <LandingStepNumber>1</LandingStepNumber>
          Find out your borrowing power with an indicative pre-approval
        </NumberListItem>

        <NumberListItem>
          <LandingStepNumber>2</LandingStepNumber>
          Complete an online application form
        </NumberListItem>

        <NumberListItem style={s('mb-16')}>
          <LandingStepNumber>3</LandingStepNumber>
          Review and submit your application online or book a meeting with one of our
          trusted advisors
        </NumberListItem>
      </ul>
      <LogInButton href={authUri}>Sign in to continue application</LogInButton>
    </LandingColumn>

    <LandingColumn style={s('bg-primary-background text-center')}>
      <h2 style={s('text-3xl font-extrabold')}>Step 1</h2>
      <h3 style={s('text-3xl font-extrabold')}>
        Find out your borrowing power with an indicative pre-approval
      </h3>
      <div style={s('flex items-center', { flexDirection: 'column' })}>
        <StepButton>Im buying a home</StepButton>
        <StepButton>I want to refinance</StepButton>
        <StepButton style={s('mb-16')}>I want to buy a second home</StepButton>
        <BookACallButton>Book a call with an advisor</BookACallButton>
      </div>
    </LandingColumn>
  </LandingContainer>
)

export default Landing
