import React from 'react'
import PropTypes from 'prop-types'
import Switch from './Switch'
import { prop } from '@neo/ramda-extra'
import { styled, s } from '@vega/styled'
import { TEST_IDS } from '@vega/constants'

import { ReactComponent as ListViewIcon } from '@vega/components/src/assets/images/view-list.svg'
import { ReactComponent as GridViewIcon } from '@vega/components/src/assets/images/view-grid.svg'

const { func, oneOf } = PropTypes

const Container = styled.div(s('flex'), prop('containerStyle'))

function ViewSwitcher({ switchToView, selectedView, containerStyle, ...otherProps }) {
  return (
    <Container containerStyle={containerStyle} {...otherProps}>
      <Switch
        data-test-id={TEST_IDS.VIEWSWITCHER_LIST_DIV}
        active={selectedView === 'list'}
        onClick={() => switchToView('list')}
        Icon={<ListViewIcon />}
      />
      <Switch
        data-test-id={TEST_IDS.VIEWSWITCHER_GRID_DIV}
        active={selectedView === 'grid'}
        onClick={() => switchToView('grid')}
        Icon={<GridViewIcon />}
      />
    </Container>
  )
}

ViewSwitcher.propTypes = {
  switchToView: func.isRequired,
  selectedView: oneOf(['list', 'grid']),
}

export default ViewSwitcher
