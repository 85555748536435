/* eslint-disable complexity */
import React, { forwardRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import { Formik, Form, useFormikContext } from 'formik'
import { s } from '@vega/styled/v2'
import Card from 'components/Card'
import {
  updateApplicant,
  removeApplicant,
  setCurrentApplicationId,
  saveApplication,
} from 'modules/application'
import { SubEntityActionButton, Chip, CollapseButton } from 'components/common'
import { CompanyFields } from './CompanyFields'
import { TrustFields } from './TrustFields'
import { SupportingDocs } from 'features/common/supportingDocs'
import * as Yup from 'yup'
import { DocumentPlaceholder } from './DocumentPlaceholder'

export const applicantSchema = Yup.object().shape({
  applicantType: Yup.string()
    .required('Applicant type is required')
    .oneOf(['company', 'trust'], 'Invalid applicant type'),
  countryRegistered: Yup.string().default('NZ'),
  licenceNumber: Yup.string().when('applicantType', {
    is: 'company',
    then: Yup.string().required('Licence number is required'),
    otherwise: Yup.string().notRequired(),
  }),
  businessName: Yup.string().when('applicantType', {
    is: 'company',
    then: Yup.string().required('Business name is required'),
    otherwise: Yup.string().notRequired(),
  }),
  description: Yup.string().when('applicantType', {
    is: 'company',
    then: Yup.string().required('Description is required'),
    otherwise: Yup.string().notRequired(),
  }),
  nzbn: Yup.string().when('applicantType', {
    is: 'company',
    then: Yup.string().required('NZBN is required'),
    otherwise: Yup.string().notRequired(),
  }),
  isHoldingCompany: Yup.string().when('applicantType', {
    is: 'company',
    then: Yup.string().required('Is holding company is required'),
    otherwise: Yup.string().notRequired(),
  }),
  isTradingCompany: Yup.string().when('applicantType', {
    is: 'company',
    then: Yup.string().required('Is trading company is required'),
    otherwise: Yup.string().notRequired(),
  }),
  isGstRegistered: Yup.string().when('applicantType', {
    is: 'company',
    then: Yup.string().required('Is GST registered is required'),
    otherwise: Yup.string().notRequired(),
  }),
  isLookThroughCompany: Yup.string().when('applicantType', {
    is: 'company',
    then: Yup.string().required('Is look through company is required'),
    otherwise: Yup.string().notRequired(),
  }),
  officeAddress: Yup.object().shape({
    propertyAddress: Yup.string().required('Property address is required'),
    propertyId: Yup.string().required('Property ID is required'),
  }),
  officeEmail: Yup.string()
    .email('Please enter a valid email address')
    .required('Office email is required'),
  mobileNumber: Yup.object().shape({
    countryCode: Yup.string().required('Country code is required'),
    contactNumber: Yup.string().required('Contact number is required'),
    prefix: Yup.string().required('Prefix is required'),
  }),
  trustName: Yup.string().when('applicantType', {
    is: 'trust',
    then: Yup.string().required('Trust name is required'),
    otherwise: Yup.string().notRequired(),
  }),
  trustType: Yup.string().when('applicantType', {
    is: 'trust',
    then: Yup.string().required('Trust type is required'),
    // .oneOf(Object.values(TrustStructureList), 'Invalid trust type'),
    otherwise: Yup.string().notRequired(),
  }),
  establishmentDate: Yup.string().when('applicantType', {
    is: 'trust',
    then: Yup.string().required('Establishmant date is required'),
    otherwise: Yup.string().notRequired(),
  }),
  isTrustTradingAsCompany: Yup.string().when('applicantType', {
    is: 'trust',
    then: Yup.string().required('Is trust trading as company is required'),
    otherwise: Yup.string().notRequired(),
  }),
})

// Method bellow is to allow formik to handlechange when values changes
const AutoDispatcher = ({ index, applicationId, application }) => {
  const dispatch = useDispatch()
  const { values } = useFormikContext()
  React.useEffect(() => {
    dispatch(updateApplicant({ id: applicationId, index, applicant: values }))
  }, [dispatch, index, values, applicationId, application])
  return null
}

const ApplicantCard = forwardRef(
  (
    {
      applicant,
      application,
      index,
      applicationId,
      initiallyOpen,
      supportingDocuments,
    },
    ref
  ) => {
    const dispatch = useDispatch()
    const [collapsed, setCollapsed] = useState(initiallyOpen)
    const [applicantType] = useState(applicant.applicantType)
    const toggleCollapased = () => setCollapsed(!collapsed)

    const [isFormValid, setIsFormValid] = useState(!!applicant._id)

    React.useEffect(() => {
      dispatch(setCurrentApplicationId(applicationId))
    }, [dispatch, applicationId, application])

    const filteredDocuments = supportingDocuments
      ? Object.values(supportingDocuments).filter((doc) =>
          doc.legalEntityIds.includes(applicant._id)
        )
      : []

    if (collapsed) {
      return (
        <Card style={s('mb-12')}>
          <div style={s('flex')}>
            <h1 style={s('m-0 p-0 text-2xl', { flexGrow: 1 })}>
              {applicant.firstName ||
                applicant.businessName ||
                applicant.trustName ||
                '-'}
            </h1>
            <SubEntityActionButton
              style={s('bg-accent-red text-white', { width: 'auto' })}
              onClick={() => {
                dispatch(removeApplicant({ applicantIndex: index, id: applicationId }))
              }}
            >
              Remove Applicant
            </SubEntityActionButton>
          </div>
          <div style={s('flex ', { flexGrow: 1 })}>
            <Chip>{applicantType}</Chip>
            {applicant.isGuarantor === 'yes' && <Chip>Guarantor</Chip>}
          </div>
          <p>{applicant.email || applicant.officeEmail || '-'}</p>
          <p>
            +{applicant.mobileNumber.countryCode} {applicant.mobileNumber.prefix}{' '}
            {applicant.mobileNumber.contactNumber}
          </p>
          <CollapseButton onClick={toggleCollapased}>+ Expand</CollapseButton>
        </Card>
      )
    }

    const initialValues = applicant

    return (
      <Card style={s('mb-12')} ref={ref} id={applicant._id}>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={applicantSchema}
        >
          {({ values, handleChange, handleBlur, isValid }) => {
            const prevIsValidRef = React.useRef(isValid)

            React.useEffect(() => {
              if (prevIsValidRef.current !== isValid) {
                const hasEmptyField = Object.values(values).some(
                  (value) =>
                    (typeof value === 'string' && value.trim() === '') ||
                    (typeof value === 'object' &&
                      value !== null &&
                      Object.values(value).some(
                        (v) => typeof v === 'string' && v.trim() === ''
                      ))
                )
                const isFormValid = !hasEmptyField && isValid
                setIsFormValid(isFormValid)

                if (isFormValid) {
                  dispatch(saveApplication(application))
                }
                prevIsValidRef.current = isValid
              }
            }, [isValid, values])

            return (
              <Form>
                <div style={s('flex')}>
                  <div style={s('mr-2 mb-4', { flexGrow: 1 })}>
                    <AutoDispatcher index={index} applicationId={applicationId} />
                  </div>

                  <SubEntityActionButton
                    style={s('bg-accent-red text-white', { width: 'auto' })}
                    onClick={() => {
                      dispatch(
                        removeApplicant({ applicantIndex: index, id: applicationId })
                      )
                    }}
                  >
                    Remove Applicant
                  </SubEntityActionButton>
                </div>

                <h1 style={s('m-0 p-0 text-2xl', { flexGrow: 1 })}>
                  {applicant.businessName || applicant.trustName || '-'}
                </h1>

                {applicantType === 'company' && (
                  <>
                    <CompanyFields
                      values={values}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      toggleCollapased={toggleCollapased}
                    />
                  </>
                )}

                {applicantType === 'trust' && (
                  <TrustFields
                    values={values}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    toggleCollapased={toggleCollapased}
                  />
                )}
              </Form>
            )
          }}
        </Formik>
        {isFormValid ? (
          <SupportingDocs
            documents={filteredDocuments}
            applicantId={[applicant._id]}
            applicantType={applicantType}
          />
        ) : (
          <DocumentPlaceholder />
        )}
        <CollapseButton onClick={toggleCollapased}>- Collapse</CollapseButton>
      </Card>
    )
  }
)
ApplicantCard.displayName = 'ApplicantCard'

export default ApplicantCard
