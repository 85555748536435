import React from 'react'
import { SubTitle, FieldContainer } from 'components/common'
import { MultiSelectField, TextField } from '@vega/components'
import { repaymentTypes } from 'utils/dropdownLists'
import { ValidatedSelectField } from 'components/SelectField'

// TODO: utils/dropdowns
const yesNoOptions = [
  { key: 'yes', label: 'Yes' },
  { key: 'no', label: 'No' },
]

const fixedRateDurations = [
  '6 months',
  '1 year',
  '18 months',
  '2 years',
  '3 years',
  '4 years',
  '5 years',
]

const importancesForRateType = (rateType) => {
  switch (rateType) {
    case 'Fixed':
      return [
        { key: 'Certainty of repayments', label: 'Certainty of repayments' },
        { key: 'Making budgeting easier', label: 'Making budgeting easier' },
        { key: 'Other', label: 'Other' },
      ]
    case 'Basic Variable':
      return [
        {
          key: 'Take advantage of future decreases',
          label: 'Take advantage of future decreases',
        },
        { key: 'Flexibility with repayment', label: 'Flexibility with repayment' },
        { key: 'Other', label: 'Other' },
      ]
    case 'Split':
      return [
        {
          key: 'Take advantage of future decreases',
          label: 'Take advantage of future decreases',
        },
        { key: 'Flexibility with repayment', label: 'Flexibility with repayment' },
        { key: 'Making budgeting easier', label: 'Making budgeting easier' },
        { key: 'Other', label: 'Other' },
      ]
    default:
      return []
  }
}

export const RatePreferenceFields = ({ values }) => (
  <>
    <SubTitle>Rate preferences</SubTitle>

    <FieldContainer>
      <ValidatedSelectField
        name="ratePreferences.hasRatePreferences"
        options={yesNoOptions.map((status) => ({
          label: status.label,
          value: status.label,
          key: status.label,
        }))}
        placeholder={'Any rate preferences?'}
        label="Any rate preferences?*"
      />
    </FieldContainer>

    {values.ratePreferences?.hasRatePreferences === 'Yes' && (
      <>
        <FieldContainer>
          <ValidatedSelectField
            name="ratePreferences.rateType"
            options={repaymentTypes.map((repaymentType) => ({
              label: repaymentType.label,
              value: repaymentType.key,
              key: repaymentType.key,
            }))}
            placeholder="Rate preference"
            label="Rate preference"
          />
        </FieldContainer>

        {values.ratePreferences?.rateType !== 'Basic Variable' && (
          <FieldContainer>
            <ValidatedSelectField
              name="ratePreferences.fixedRateDuration"
              options={fixedRateDurations.map((duration) => ({
                label: duration,
                value: duration,
                key: duration,
              }))}
              placeholder="Fixed rate duration"
              label="Fixed rate duration"
              aria-label="Fixed rate duration"
            />
          </FieldContainer>
        )}

        <FieldContainer>
          <MultiSelectField
            name="ratePreferences.importances"
            items={importancesForRateType(values.ratePreferences.rateType)}
            label="Why is this important to you?"
            placeholder="Why is this important to you?"
          />
        </FieldContainer>

        {(values.ratePreferences?.importances || []).includes('Other') && (
          <FieldContainer>
            <TextField
              name="ratePreferences.otherImportanceNote"
              label="Other reason"
              placeholder="Other reason"
            />
          </FieldContainer>
        )}
      </>
    )}
  </>
)
