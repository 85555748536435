import React, { useState, useEffect } from 'react'
import { useFilter } from '@react-aria/i18n'
import { isNotNilOrEmpty, defaultTo } from '@neo/ramda-extra'
import { Item } from '@react-stately/collections'
import { useField, useFormikContext } from 'formik'

import { ComboBox } from './ComboBox'

import PropTypes from 'prop-types'

const { string, bool, arrayOf, shape, object, oneOfType, number } = PropTypes

function ComboBoxField({
  name,
  optionList,
  disabled = false,
  readOnly = false,
  ...props
}) {
  const [{ value }, { touched, error }] = useField({ name })

  const { setFieldValue } = useFormikContext()

  const [fieldState, setFieldState] = useState(() => {
    const initialSelectedItem = isNotNilOrEmpty(value)
      ? optionList.find((option) => option.value === value)
      : value

    return {
      isOpen: false,
      selectedKey: defaultTo('', initialSelectedItem?.id),
      inputValue: initialSelectedItem?.label ?? '',
      items: optionList,
      currentValue: value,
    }
  })

  const { startsWith } = useFilter({ sensitivity: 'base' })

  const handleSelectionChange = (key) => {
    setFieldState((prevState) => {
      const selectedItem = prevState.items.find((option) => option.id === key)

      return {
        isOpen: false,
        inputValue: selectedItem?.label ?? '',
        selectedKey: key,
        items: optionList.filter((item) =>
          startsWith(item.label, selectedItem?.label ?? '')
        ),
        currentValue: selectedItem?.value,
      }
    })
  }

  const handleInputChange = (value) => {
    setFieldState(() => {
      return {
        isOpen: true,
        inputValue: value,
        selectedKey: null,
        items: optionList.filter((item) => startsWith(item.label, value)),
        currentValue: undefined,
      }
    })
  }

  const handleOpenChange = (isOpen) => {
    setFieldState((prevState) => ({
      isOpen,
      inputValue: prevState.inputValue,
      selectedKey: prevState.selectedKey,
      items: prevState.items,
      currentValue: prevState.currentValue,
    }))
  }

  useEffect(() => {
    setFieldValue(name, fieldState.currentValue)
  }, [fieldState.currentValue, name, setFieldValue])

  return (
    <ComboBox
      items={fieldState.items}
      inputValue={fieldState.inputValue}
      isOpen={fieldState.isOpen && fieldState.items.length > 0}
      selectedKey={fieldState.selectedKey}
      onSelectionChange={handleSelectionChange}
      onInputChange={handleInputChange}
      onOpenChange={handleOpenChange}
      disabled={disabled}
      readOnly={readOnly}
      touched={touched}
      error={error}
      menuTrigger="focus"
      {...props}
    >
      {(item) => <Item textValue={item.label}>{item.label}</Item>}
    </ComboBox>
  )
}

ComboBoxField.propTypes = {
  name: string.isRequired,
  optionList: arrayOf(
    shape({
      id: string.isRequired,
      value: oneOfType([string, number, object]).isRequired,
      label: string.isRequired,
    })
  ).isRequired,
  label: string,
  'aria-label': string,
  placeholder: string,
  disabled: bool,
  readOnly: bool,
  listBoxStyles: object,
  optionStyles: object,
}

export { ComboBoxField }
