import BaseService from '../baseService'
import { QueryBuilder } from '../utils'

export class ApplyOnlineService extends BaseService {
  getAllApplications({ q, start, limit, filters = {} }, signal) {
    const searchParams = QueryBuilder()
      .setQ(q)
      .setPaging({ start, limit })
      .setFilters(filters)
      .build()
    return this.client.get(`all-applications?${searchParams}`, { signal }).json()
  }

  getApplications() {
    return this.client.get(`applications`).json()
  }

  getApplication(id) {
    return this.client.get(`applications/${id}`).json()
  }

  createApplication() {
    return this.client.post('applications').json()
  }

  saveApplication(payload, id) {
    return this.client.put(`applications/${id}`, { json: payload }).json()
  }

  removeApplication(id) {
    return this.client.delete(`applications/${id}`).json()
  }

  submitApplication(id) {
    return this.client.post(`applications/${id}/submit`, { timeout: 40000 }).json()
  }
}
