import React from 'react'
import PropTypes from 'prop-types'
import { useField } from 'formik'
import { Item } from '@react-stately/collections'

import { Select } from './Select'

const { string, array, bool, object } = PropTypes

function SelectField({
  options,
  disabled = false,
  readonly = false,
  selectMenuStyles,
  togglerStyles,
  testId,
  menuId,
  name,
  labelContainerStyle,
  ...props
}) {
  const [{ value }, { touched, error }, { setValue }] = useField({ name })

  return (
    <Select
      selectedKey={`${value}`}
      onSelectionChange={setValue}
      disabled={disabled}
      readonly={readonly}
      hasError={touched && error}
      error={error}
      togglerStyles={togglerStyles}
      selectMenuStyles={selectMenuStyles}
      name={name}
      {...props}
      testId={testId}
      menuId={menuId}
      labelContainerStyle={labelContainerStyle}
    >
      {options.map(({ value, label }) => (
        <Item key={value}>{label}</Item>
      ))}
    </Select>
  )
}

SelectField.propTypes = {
  name: string.isRequired,
  options: array.isRequired,
  label: string,
  readonly: bool,
  disabled: bool,
  togglerStyles: object,
  selectMenuStyles: object,
}

export { SelectField }
