import { isNil } from '@neo/ramda-extra'
import { QueryBuilder } from '../utils'
import BaseService from '../baseService'
import * as CONSTANTS from '@neo/constants'

const {
  PROPERTY: { PROPERTY_STATUS },
} = CONSTANTS

export class PropertyService extends BaseService {
  getPropertyDetails(propertyId) {
    return this.client.get(`properties/search?propertyId=${propertyId}`).json()
  }

  addPropertyToWatchlist(loanApplicationId, propertyId) {
    if (isNil(loanApplicationId)) throw new Error('Invalid Loan Application Id')
    if (isNil(propertyId)) throw new Error('Invalid Property Id')

    const config = {
      json: { loanApplicationId, propertyId },
    }

    return this.client.post('properties/watchlist', config).json()
  }

  getPropertyWatchlist(loanApplicationId, { filters, limit, start }) {
    if (isNil(loanApplicationId)) throw new Error('Invalid Loan Application Id')

    const searchParams = QueryBuilder()
      .setParam('loanApplicationId', loanApplicationId)
      .setFilters(filters)
      .setPaging({ start, limit })
      .build()

    return this.client.get(`properties/watchlist?${searchParams}`).json()
  }

  submitProperty(loanApplicationId, propertyId) {
    if (isNil(loanApplicationId)) throw new Error('Invalid Loan Application Id')
    if (isNil(propertyId)) throw new Error('Invalid Property Id')

    const config = {
      json: { propertyId },
    }

    return this.client
      .post(`loan-applications/${loanApplicationId}/properties`, config)
      .json()
  }

  approveProperty(loanApplicationId, propertyId) {
    if (isNil(loanApplicationId)) throw new Error('Invalid Loan Application Id')
    if (isNil(propertyId)) throw new Error('Invalid Property Id')

    const config = {
      json: { status: PROPERTY_STATUS.APPROVED },
    }

    return this.client
      .put(
        `loan-applications/${loanApplicationId}/properties/${propertyId}/status`,
        config
      )
      .json()
  }

  rejectProperty(loanApplicationId, propertyId, rejectReason) {
    if (isNil(loanApplicationId)) throw new Error('Invalid Loan Application Id')
    if (isNil(propertyId)) throw new Error('Invalid Property Id')

    const config = {
      json: { status: PROPERTY_STATUS.REJECTED, rejectReason },
    }

    return this.client
      .put(
        `loan-applications/${loanApplicationId}/properties/${propertyId}/status`,
        config
      )
      .json()
  }
}
