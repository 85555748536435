import React from 'react'
import { TextField } from '@vega/components'
import { SubTitle, FieldContainer } from 'components/common'
import { s } from '@vega/styled/v2'
import { ValidatedSelectField } from 'components/SelectField'

// TODO: utils/dropdowns
const yesNoOptions = [
  { key: 'yes', label: 'Yes' },
  { key: 'no', label: 'No' },
]

const familyInsuranceLabel =
  'Confident you have adequate insurance cover to ' +
  'protect your family against injury, death, or financial hardship?*'

const homeContentsInsuranceLabel =
  'Confident you have adequate insurance cover ' +
  'to protect your home and contents against accident, damage or theft?*'

export const InsuranceFields = () => (
  <>
    <SubTitle>Insurance</SubTitle>

    <FieldContainer>
      <ValidatedSelectField
        name="insurance.hasFamilyInsurance"
        options={yesNoOptions.map((status) => ({
          label: status.label,
          value: status.label,
          key: status.label,
        }))}
        placeholder="Do you have family insurance?"
        label={familyInsuranceLabel}
      />
    </FieldContainer>

    <FieldContainer>
      <TextField
        name="insurance.familyInsuranceNotes"
        label="Please elaborate"
        placeholder="Please elaborate"
        labelStyle={s('text-base')}
      />
    </FieldContainer>

    <FieldContainer>
      <ValidatedSelectField
        name="insurance.hasHomeContentsInsurance"
        options={yesNoOptions.map((status) => ({
          label: status.label,
          value: status.label,
          key: status.label,
        }))}
        placeholder="Do you have home & contents insurance?"
        label={homeContentsInsuranceLabel}
      />
    </FieldContainer>

    <FieldContainer>
      <TextField
        name="insurance.homeContentsInsuranceNotes"
        label="Please elaborate"
        placeholder="Please elaborate"
        labelStyle={s('text-base')}
      />
    </FieldContainer>
  </>
)
