import React from 'react'
import { TextField } from '@vega/components'
import { SubTitle, FieldContainer } from 'components/common'
import { s } from '@vega/styled/v2'
import { ValidatedMultiSelectField } from 'components/MultiSelectField'

// TODO: utils/dropdowns
const primaryPurposes = [
  { key: 'Owner Occupied', label: 'Owner Occupied' },
  { key: 'Owner Occupied - First home', label: 'Owner Occupied - First home' },
  { key: 'Investment', label: 'Investment' },
  { key: 'Top Up', label: 'Top Up' },
  { key: 'Holiday Home', label: 'Holiday Home' },
  { key: 'Bridging Finance', label: 'Bridging Finance' },
  { key: 'Construction', label: 'Construction' },
  { key: 'Refinance', label: 'Refinance' },
  { key: 'Restructure', label: 'Restructure' },
  { key: 'Debt Consolidation', label: 'Debt Consolidation' },
  { key: 'Business Finance', label: 'Business Finance' },
  { key: 'Asset Finance', label: 'Asset Finance' },
  { key: 'Car Finance', label: 'Car Finance' },
  { key: 'Personal Finance', label: 'Personal Finance' },
]

export const GoalsFields = ({ handleChange, handleBlur }) => (
  <>
    <SubTitle>Goals</SubTitle>

    <FieldContainer>
      <ValidatedMultiSelectField
        name="goals.primaryPurposes"
        items={primaryPurposes}
        label="Primary purpose*"
        placeholder="Primary purpose"
      />
    </FieldContainer>

    <FieldContainer>
      <TextField
        name="goals.specificGoals"
        label="Specific goals"
        placeholder="Specific goals"
        labelStyle={s('text-base')}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </FieldContainer>
  </>
)
