import React from 'react'

import { mergeProps } from '@react-aria/utils'
import { useListBox } from '@react-aria/listbox'
import { useOverlay, DismissButton } from '@react-aria/overlays'
import { prop } from '@neo/ramda-extra'

import { s, styled } from '@vega/styled'

import { Option } from './Option'

const MenuContainer = styled.div(
  s(
    'absolute w-full border-solid border-1 border-grey-400 bg-white z-1 max-h-16 py-3 px-0 mt-2',
    {
      borderRadius: 10,
      borderTopRightRadius: 0,
      overflowY: 'hidden',
      top: '100%',
    }
  )
)

const Menu = styled.ul(
  s('p-0 m-0 max-h-16', {
    listStyle: 'none',
    overflowY: 'scroll',
    maxHeight: 224,
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  }),
  prop('listBoxStyles')
)

function ListBoxPopup(props) {
  const {
    popoverRef,
    listBoxRef,
    state,
    shouldUseVirtualFocus,
    listBoxStyles,
    optionStyles,
    menuId,
    ...otherProps
  } = props

  const { listBoxProps } = useListBox(
    {
      autoFocus: state.focusStrategy,
      disallowEmptySelection: true,
      shouldUseVirtualFocus,
      ...otherProps,
    },
    state,
    listBoxRef
  )

  const { overlayProps } = useOverlay(
    {
      onClose: () => state.close(),
      shouldCloseOnBlur: true,
      isOpen: state.isOpen,
      isDismissable: true,
    },
    popoverRef
  )

  return (
    <div {...overlayProps} ref={popoverRef} style={s('relative')}>
      <MenuContainer data-test-id={menuId}>
        <Menu
          {...mergeProps(listBoxProps, otherProps)}
          ref={listBoxRef}
          listBoxStyles={listBoxStyles}
        >
          {[...state.collection].map((item) => (
            <Option
              shouldUseVirtualFocus
              key={item.key}
              item={item}
              state={state}
              optionStyles={optionStyles}
            />
          ))}
        </Menu>
        <DismissButton onDismiss={() => state.close()} />
      </MenuContainer>
    </div>
  )
}

export { ListBoxPopup }
