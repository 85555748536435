import React from 'react'
import { ToastContainer as ToastContainerBase, toast } from 'react-toastify'
import { ReactComponent as CloseIcon } from '@vega/components/src/assets/images/x.svg'
import { styled, s } from '@vega/styled'

import 'react-toastify/dist/ReactToastify.css'

const ToastContainer = styled(ToastContainerBase)(s('min-w-16'), {
  width: 'unset',
  '.Toastify__toast': s('px-3 py-4 bg-grey-800 text-white'),
})

const CloseButtonContainer = styled.div(s('flex self-center ml-3'))

function Toaster({ ...props }) {
  return (
    <ToastContainer
      position="bottom-left"
      autoClose={5000}
      hideProgressBar
      closeOnClick={false}
      closeButton={({ closeToast }) => (
        <CloseButtonContainer>
          <CloseIcon stroke={s('text-white').color} onMouseDown={closeToast} />
        </CloseButtonContainer>
      )}
      {...props}
    />
  )
}

export default Toaster
export { toast }
