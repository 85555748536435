import { prop } from '@neo/ramda-extra'
import { createSelector } from '@reduxjs/toolkit'
import { createAsyncThunkSelectors } from 'utils/createAsyncThunkSelectors'
import { applicationAdapter } from './module'

export const {
  selectIsPending: selectIsFetchApplicationssPending,
} = createAsyncThunkSelectors('application/getApplications')

export const {
  selectIsPending: selectIsFetchAllApplicationssPending,
} = createAsyncThunkSelectors('application/getAllApplications')

export const {
  selectIsPending: selectIsFetchApplicationPending,
  selectHasFulfilled: selectHasFetchApplicationFulfilled,
} = createAsyncThunkSelectors('application/getApplication')

export const {
  selectAll: selectApplications,
  selectById: selectApplication,
} = applicationAdapter.getSelectors((s) => s.application)

const selectAllApplications = prop('application')
export const selectTotal = createSelector(selectAllApplications, prop('total'))

export const selectApplicationById = (id) => (state) => selectApplication(state, id)

const selectApplicationState = prop('application')

const selectApplicants = createSelector(selectApplicationState, prop('applicants'))

const selectRealEstateAssets = createSelector(
  selectApplicationState,
  (state) => state.financialPosition.realEstateAssets
)

const selectAssets = createSelector(
  selectApplicationState,
  (state) => state.financialPosition.assets
)

const selectLiabilities = createSelector(
  selectApplicationState,
  (state) => state.financialPosition.liabilities
)

export const {
  selectIsPending: selectIsSaveApplicationPending,
  selectHasFulfilled: selectIsSaveApplicationFulfilled,
  selectHasRejected: selectIsSaveApplicationRejected,
} = createAsyncThunkSelectors('application/saveApplication')

export const {
  selectIsPending: selectIsSubmitApplicationPending,
  selectHasFulfilled: selectIsSubmitApplicationFulfilled,
  selectHasRejected: selectIsSubmitApplicationRejected,
  selectError: selectSubmitApplicationError,
} = createAsyncThunkSelectors('application/submitApplication')

export {
  selectApplicationState,
  selectApplicants,
  selectRealEstateAssets,
  selectAssets,
  selectLiabilities,
}
