import React from 'react'
import { SubTitle, FieldContainer } from 'components/common'
import { SelectField, MultiSelectField, TextField } from '@vega/components'
import { ValidatedSelectField } from 'components/SelectField'

// TODO: utils/dropdowns
const yesNoOptions = [
  { key: 'yes', label: 'Yes' },
  { key: 'no', label: 'No' },
]

// TODO: utils/dropdowns
const repaymentPreferences = [
  { key: 'Principal and interest', label: 'Principal and interest' },
  { key: 'Interest only repayments', label: 'Interest only repayments' },
  { key: 'Revolving credit facility', label: 'Revolving credit facility' },
]

// TODO: utils/dropdowns
const repaymentFrequencies = ['Weekly', 'Fortnightly', 'Monthly']

// TODO: utils/dropdowns
const principalAndInterestImportances = [
  {
    key: 'Minimise interest paid over life of loan',
    label: 'Minimise interest paid over life of loan',
  },
  { key: 'Higher lending limit', label: 'Higher lending limit' },
  { key: 'Lower deposit required', label: 'Lower deposit required' },
  { key: 'Build up equity from the start', label: 'Build up equity from the start' },
  { key: 'Other', label: 'Other' },
]

// TODO: utils/dropdowns
const interestOnlyImportances = [
  {
    key: 'Accommodate temporary reduction in income',
    label: 'Accommodate temporary reduction in income',
  },
  {
    key: 'Accommodate anticipated non-recurring expense item',
    label: 'Accommodate anticipated non-recurring expense item',
  },
  {
    key: 'Variable and unpredictable income',
    label: 'Variable and unpredictable income',
  },
  {
    key: 'Recommended by an independent financial adviser/accountant',
    label: 'Recommended by an independent financial adviser/accountant',
  },
  { key: 'Taxation or accounting reasons', label: 'Taxation or accounting reasons' },
  { key: 'Other', label: 'Other' },
]

// TODO: utils/dropdowns
const interestOnlyPeriods = ['1 year', '2 years', '3 years', '4 years', '5 years']

// TODO: utils/dropdowns

const revolvingCreditOptions = [
  {
    key: 'Repayment of loan prior to end of term',
    label: 'Repayment of loan prior to end of term',
  },
  { key: 'Downsizing', label: 'Downsizing' },
  { key: 'Sales of assets', label: 'Sales of assets' },
  { key: 'Savings', label: 'Savings' },
  { key: 'Income from other investments', label: 'Income from other investments' },
  { key: 'Co-applicants income', label: 'Co-applicants income' },
  { key: 'Other', label: 'Other' },
]

const revolvingCreditImportances = [
  {
    key: 'Assists with investment purchases and tax planning',
    label: 'Assists with investment purchases and tax planning',
  },
  { key: 'Anticipated variable cash flows', label: 'Anticipated variable cash flows' },
  {
    key: 'Allows access to funds at any time',
    label: 'Allows access to funds at any time',
  },
  {
    key: 'Flexibility to manage repayments',
    label: 'Flexibility to manage repayments',
  },
  {
    key:
      'Ongoing need for funds - planning to make a few purchases over a period of time',
    label:
      'Ongoing need for funds - planning to make a few purchases over a period of time',
  },
  { key: 'Other', label: 'Other' },
]

/* eslint-disable complexity */
export const RepaymentPreferenceFields = ({ values }) => {
  return (
    <>
      <SubTitle>Repayment preferences</SubTitle>

      <FieldContainer>
        <ValidatedSelectField
          name="repaymentPreferences.hasRepaymentPreferences"
          options={yesNoOptions.map((status) => ({
            label: status.label,
            value: status.label,
            key: status.label,
          }))}
          placeholder={'Any repayment preferences?'}
          label="Any repayment preferences?*"
        />
      </FieldContainer>

      {values.repaymentPreferences?.hasRepaymentPreferences === 'Yes' && (
        <>
          <MultiSelectField
            name="repaymentPreferences.preferenceTypes"
            items={repaymentPreferences}
            label="Repayment preferences"
            placeholder="Repayment prefences"
          />
        </>
      )}

      {(values.repaymentPreferences?.preferenceTypes || []).includes(
        'Principal and interest'
      ) && (
        <>
          <p>Principal and interest</p>
          <FieldContainer>
            <ValidatedSelectField
              name="repaymentPreferences.principalAndInterest.repaymentFrequency"
              options={repaymentFrequencies.map((frequency) => ({
                label: frequency,
                value: frequency,
                key: frequency,
              }))}
              placeholder="Any repayment preferences?"
              label="Any repayment preferences?"
            />
          </FieldContainer>

          <FieldContainer>
            <MultiSelectField
              name="repaymentPreferences.principalAndInterest.importances"
              items={principalAndInterestImportances}
              label="Why is this important to you?"
              placeholder="Why is this important to you?"
            />
          </FieldContainer>

          {(
            values.repaymentPreferences.principalAndInterest.importances || []
          ).includes('Other') && (
            <FieldContainer>
              <TextField
                name="repaymentPreferences.principalAndInterest.otherImportanceNote"
                label="Please elaborate"
                placeholder="Please elaborate"
              />
            </FieldContainer>
          )}
        </>
      )}

      {(values.repaymentPreferences?.preferenceTypes || []).includes(
        'Interest only repayments'
      ) && (
        <>
          <p>Interest only repayments</p>

          <FieldContainer>
            <SelectField
              name="repaymentPreferences.interestOnlyRepayment.repaymentFrequency"
              options={repaymentFrequencies.map((frequency) => ({
                label: frequency,
                value: frequency,
                key: frequency,
              }))}
              placeholder="Any repayment preferences?"
              label="Any repayment preferences?"
            />
          </FieldContainer>

          <FieldContainer>
            <SelectField
              name="repaymentPreferences.interestOnlyRepayment.interestOnlyPeriod"
              options={interestOnlyPeriods.map((period) => ({
                label: period,
                value: period,
                key: period,
              }))}
              placeholder="Interest only period"
              label="Interest only period"
            />
          </FieldContainer>

          <FieldContainer>
            <MultiSelectField
              name="repaymentPreferences.interestOnlyRepayment.importances"
              items={interestOnlyImportances}
              label="Why is this important to you?"
              placeholder="Why is this important to you?"
            />
          </FieldContainer>

          {(
            values.repaymentPreferences.interestOnlyRepayment.importances || []
          ).includes('Other') && (
            <FieldContainer>
              <TextField
                name="repaymentPreferences.interestOnlyRepayment.otherImportanceNote"
                label="Please elaborate"
                placeholder="Please elaborate"
              />
            </FieldContainer>
          )}
        </>
      )}

      {(values.repaymentPreferences?.preferenceTypes || []).includes(
        'Revolving credit facility'
      ) && (
        <>
          <p>Revolving credit facitily</p>

          <FieldContainer>
            <SelectField
              name="repaymentPreferences.revolvingCreditFacility.repaymentOptions"
              options={revolvingCreditOptions.map((option) => ({
                label: option.label,
                value: option.label,
                key: option.key,
              }))}
              placeholder="Select a repayment option?"
              label="Repayment options?"
            />
          </FieldContainer>

          <FieldContainer>
            <MultiSelectField
              name="repaymentPreferences.revolvingCreditFacility.importances"
              items={revolvingCreditImportances}
              label="Why is this important to you?"
              placeholder="Why is this important to you?"
            />
          </FieldContainer>

          {(
            values.repaymentPreferences.revolvingCreditFacility.importances || []
          ).includes('Other') && (
            <FieldContainer>
              <TextField
                name="repaymentPreferences.revolvingCreditFacility.otherImportanceNote"
                label="Please elaborate"
                placeholder="Please elaborate"
              />
            </FieldContainer>
          )}
        </>
      )}
    </>
  )
}
