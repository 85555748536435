import React from 'react'
import { s, styled } from '@vega/styled'

const Step = styled.div(
  s('flex items-center justify-center w-full rounded-lg mb-3 mx-1 shadow-lg', {
    cursor: 'pointer',
    height: '0.5rem',
  }),
  ({ isFilled }) => (isFilled ? s('bg-pink') : s('bg-white'))
)

const ProgressMeter = ({ currentStep, numberOfSteps }) => {
  const steps = [...Array(numberOfSteps).keys()].map((stepNumber) => {
    return <Step key={stepNumber} isFilled={currentStep > stepNumber} />
  })

  return <div style={s('flex w-full')}>{steps}</div>
}

export default ProgressMeter
