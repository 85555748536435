import Card from 'components/Card'
import React from 'react'
import { s } from '@vega/styled/v2'

export const UserInfo = ({ userInfo }) => {
  return (
    <>
      <h2>User Info</h2>
      <Card style={s('w-1/3')}>
        <div>
          <h2>{userInfo?.fullName}</h2>
          <p>Email: {userInfo?.email}</p>
          <p>Phone: {userInfo?.mobileNumber}</p>
          <p>Gender: {userInfo?.gender}</p>
        </div>
      </Card>
    </>
  )
}
