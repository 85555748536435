import React from 'react'
import { s } from '@vega/styled/v2'
import { Chip, TableCell } from 'components/common'

const CompanyRow = ({ company }) => {
  return (
    <tr>
      <TableCell style={s('text-left')}>
        <Chip>Company</Chip>
      </TableCell>
      <TableCell>{company.businessName}</TableCell>
      <TableCell>{`NZBN: ${company.nzbn}`}</TableCell>
      <TableCell>
        +
        {`${company?.mobileNumber?.countryCode} ${company?.mobileNumber?.prefix} ${company?.mobileNumber?.contactNumber}`}
      </TableCell>
      <TableCell>{company.officeEmail}</TableCell>
    </tr>
  )
}

export default CompanyRow
