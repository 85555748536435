import React from 'react'
import { s } from '@vega/styled/v2'

import HouseholdRow from './HouseholdRow'
import CompanyRow from './CompanyRow'
import TrustRow from './TrustRow'

const ApplicantsTable = ({ application }) => {
  const { households, applicants } = application

  const companies = applicants.filter(
    (potentialCompany) => potentialCompany.applicantType === 'company'
  )

  const trusts = applicants.filter(
    (potentialTrust) => potentialTrust.applicantType === 'trust'
  )

  const householdRows = households.map((household) => (
    <HouseholdRow key={household._id} household={household} />
  ))

  const companyRows = companies.map((company) => (
    <CompanyRow key={company._id} company={company} />
  ))

  const trustRows = trusts.map((trust) => <TrustRow key={trust._id} trust={trust} />)

  return (
    <>
      <h2>Applicants</h2>

      <table style={s('bg-white p-6 rounded-lg shadow-lg w-full')}>
        <tbody>
          {householdRows}
          {companyRows}
          {trustRows}
        </tbody>
      </table>
    </>
  )
}

export default ApplicantsTable
