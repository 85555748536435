import React from 'react'
import { s, styled } from '@vega/styled/v2'
import { Button } from '@vega/components'
import { Chip, TableCell } from 'components/common'
import { formatCurrency } from 'utils/formatters'
import { Link, useParams } from 'react-router-dom'
import { useLargeMediaQuery, Row, Col, Container } from '@vega/styled'

const OuterContainer = styled.div(
  s(
    'bg-white flex flex-row justify-between w-full border-0 border-b-1 border-solid border-grey-200',
    {
      '&:last-child': {
        borderColor: 'transparent',
      },
    }
  )
)
const Root = styled.div(
  s(
    'bg-white flex flex-row justify-between w-full border-0 border-b-1 border-solid border-grey-200 mt-4 mb-4 ',
    {
      '&:last-child': {
        borderColor: 'transparent',
      },
      ':hover': s('bg-secondary rounded-lg', { cursor: 'pointer' }),
    }
  )
)

const Applicants = styled.div(s('text-primary font-semibold text-base mt-1'))

const ExtraLoanInfo = styled.div(s('text-base text-primary'))

// TODO: move to utils
const calcualteMonthlyAmount = (amount, frequency) => {
  switch (frequency) {
    case 'Yearly':
      return amount / (365 / 12)

    case 'Monthly':
      return amount

    case 'Weekly':
      return amount * (52 / 12)

    default:
      return amount
  }
}

const HouseholdRow = ({ household }) => {
  const { id } = useParams()
  const isLargeScreen = useLargeMediaQuery('larger')

  const sectionId = household._id
  const householdName = household.members.map((member) => member.firstName).join(' & ')

  const househouseEmploymentIncome = household.members
    .flatMap((member) =>
      member.employments.map((employment) =>
        calcualteMonthlyAmount(employment.amount, employment.frequency)
      )
    )
    .reduce((acc, curr) => acc + curr, 0)

  const househouseOtherIncome = household.members
    .flatMap((member) =>
      member.otherIncomes.map((otherIncome) =>
        calcualteMonthlyAmount(otherIncome.amount, otherIncome.frequency)
      )
    )
    .reduce((acc, curr) => acc + curr, 0)

  const householdExpenses = household.expenseGroups
    .flatMap((expenseGroup) =>
      expenseGroup.expenses.map((expense) =>
        calcualteMonthlyAmount(expense.amount, expense.frequency)
      )
    )
    .reduce((acc, curr) => acc + curr, 0)

  const monthlyHouseholdIncome = househouseEmploymentIncome + househouseOtherIncome

  const dependants = household.numberOfDependants

  if (!isLargeScreen) {
    return (
      <OuterContainer>
        <Root>
          <Container gutter={0}>
            <Row gutter={[0, 0]} style={s('p-2')}>
              <Col span={12}>
                <Applicants className="sohne">{householdName}</Applicants>
                <Chip>Household</Chip>
                <ExtraLoanInfo>
                  - {`${formatCurrency(monthlyHouseholdIncome)} - Monthly income`}
                </ExtraLoanInfo>
                <ExtraLoanInfo>
                  - {`${formatCurrency(householdExpenses)} - Monthly expenses`}
                </ExtraLoanInfo>
              </Col>

              <Col span={12}>
                <div
                  style={s('flex items-center justify-end ml-auto', {
                    marginTop: '45px',
                  })}
                >
                  <Link
                    style={s('', { textDecoration: 'none' })}
                    to={`/customer/application/${id}/applicants?scrollTo=${sectionId}`}
                  >
                    <Button variant="outlined">View/Edit</Button>
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </Root>
      </OuterContainer>
    )
  }
  return (
    <tr style={s('flex items-center justify-between mb-4')}>
      <TableCell style={s('text-left')}>
        <Chip>Household</Chip>
      </TableCell>
      <TableCell style={s('text-left')}>{householdName}</TableCell>
      <TableCell style={s('text-left')}>
        {`${formatCurrency(monthlyHouseholdIncome)} - Monthly income`}
      </TableCell>
      <TableCell style={s('text-left')}>
        {`${formatCurrency(householdExpenses)} - Monthly expenses`}
      </TableCell>
      <TableCell style={s('text-left')}>{`${dependants} dependants`}</TableCell>
      <td>
        <div style={s('flex justify-end ml-auto')}>
          <Link
            style={s('', { textDecoration: 'none' })}
            to={`/customer/application/${id}/applicants?scrollTo=${sectionId}`}
          >
            <Button variant="outlined">View/Edit</Button>
          </Link>
        </div>
      </td>
    </tr>
  )
}

export default HouseholdRow
