import React from 'react'
import { s, styled } from '@vega/styled/v2'
import { safelyParseDate } from '../../../apps/banking/src/utils/date'

const Container = styled.div(
  s(
    'flex flex-column text-center border-solid border-1 border-grey-light rounded-lg ',
    {
      whiteSpace: 'pre-line',
      width: 53,
      height: 53,
    }
  ),
  ({ boxStyle }) => boxStyle
)
const getDay = (date) => {
  return new Date(date).getDate()
}

const getMonth = (date) => {
  return safelyParseDate(new Date(date), 'MMM')
}

const DateBox = ({ date, boxStyle }) => {
  return (
    <Container boxStyle={boxStyle}>
      {`${getDay(date)}
      ${getMonth(date)}`}
    </Container>
  )
}

export { DateBox }
