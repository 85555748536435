import React from 'react'
import { s, styled } from '@vega/styled/v2'
import { Button } from '@vega/components'
import { APP_URLS } from '@vega/constants'
import { ReactComponent as ExitIcon } from '@vega/components/src/assets/images/exit.svg'

const LoggedOutBtn = styled(({ containerStyle = {}, ...otherProps }) => (
  <Button
    StartIcon={<ExitIcon style={s('mr-2')} />}
    containerStyle={s(
      'bg-white border-transparent rounded-lg text-primary font-semibold mr-0 ml-a mt-4 text-xs',
      { borderRadius: 6, ...containerStyle }
    )}
    {...otherProps}
  />
))()

const handleSignOut = () => {
  window.localStorage.removeItem('vegaUserCurrentRole')
  window.location.replace(`${APP_URLS.AUTH}sign-out`)
}

export { LoggedOutBtn, handleSignOut }
