import React from 'react'
import { SectionContainer } from 'components/common'
import { GoalsFields } from './GoalsFields'
import { ImportantDatesFields } from './ImportantDatesFields'
import { DepositFields } from './DepositFields'
import { RatePreferenceFields } from './RatePreferenceFields'
import { RepaymentPreferenceFields } from './RepaymentPreferenceFields'
import { SpecialFeatureFields } from './SpecialFeatureFields'
import { DebtArrears } from './DebtArrears'
import { InsuranceFields } from './InsuranceFields'
import { OtherObjectives } from './OtherObjectives'

export const DeclarationCard = ({
  values,
  setFieldValue,
  handleChange,
  handleBlur,
  errors,
}) => (
  <>
    <SectionContainer>
      <GoalsFields
        handleChange={handleChange}
        handleBlur={handleBlur}
        values={values}
      />

      <ImportantDatesFields
        values={values}
        handleChange={handleChange}
        handleBlur={handleBlur}
        setFieldValue={setFieldValue}
        errors={errors}
      />

      <DepositFields values={values} setFieldValue={setFieldValue} />

      <RatePreferenceFields values={values} />

      <RepaymentPreferenceFields values={values} />

      <SpecialFeatureFields values={values} />

      <DebtArrears values={values} errors={errors} />

      <InsuranceFields />

      <OtherObjectives values={values} errors={errors} />
    </SectionContainer>
  </>
)
