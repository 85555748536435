/* eslint-disable react/no-multi-comp */
import React from 'react'
import { s, styled } from '@vega/styled/v2'
import { larger } from '@vega/styled'
import { Link, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { StepNumber } from 'components/common'
import { saveApplication } from 'modules/application'
import { routes } from 'routes'
import * as Yup from 'yup'
import { householdSchema } from 'features/customer/applications/applicants/HouseholdCard'
import { assetSchema } from 'features/customer/applications/financialPosition/AssetCard'
import { realEstateAssetSchema } from 'features/customer/applications/financialPosition/RealEstateAssetCard'
import { liabilitiesSchema } from 'features/customer/applications/financialPosition/LiabilityCard'
import { applicantSchema } from 'features/customer/applications/applicants/ApplicantCard'

const ViewApplicationsButton = styled(Link)(
  s(
    'bg-secondary text-primary uppercase border-0 text-xl rounded-lg mb-4 text-center',
    {
      textDecoration: 'none',
    }
  )
)

const SideBarContainer = styled.div(
  s('bg-primary text-white p-8 w-24', { flexDirection: 'column', display: 'none' }),
  larger('xl')(s('flex'))
)

const SideBarLink = styled(Link)(
  s('text-white hover:text-yellow-500', {
    textDecoration: 'none',
    '&:hover': s('text-secondary', {}),
  }),
  ({ isActive }) => (isActive ? s('text-secondary', {}) : s('text-white', {}))
)

const NumberListItem = styled.li(s('flex text-xl mb-4 items-center'))

const applicationSchema = Yup.object({
  households: Yup.array().of(householdSchema),
  applicants: Yup.array().of(applicantSchema),
  financialPosition: Yup.object({
    assets: Yup.array().of(assetSchema),
    realEstateAssets: Yup.array().of(realEstateAssetSchema),
    liabilities: Yup.array().of(liabilitiesSchema),
  }),
})

const SideBar = () => {
  const dispatch = useDispatch()
  const currentApplicationId = useSelector((state) => state.currentApplicationId)
  const application = useSelector(
    (state) => state.application.entities[currentApplicationId]
  )
  const location = useLocation()

  let validationResult = true

  try {
    validationResult = applicationSchema.validateSync(application, {
      abortEarly: false,
    })
  } catch (e) {
    validationResult = false
    // console.log('validation error >>> ', e.errors)
  }

  const handleSignOut = () => {
    window.localStorage.removeItem('vegaUserCurrentRole')
    window.location.replace(
      `${process.env.REACT_APP_AUTH_WEB_URI}sign-out?redirectApp=APPLY_ONLINE`
    )
  }

  const save = () => {
    if (!validationResult) return

    dispatch(saveApplication(application))
  }

  return (
    <SideBarContainer>
      <h1 style={s('text-secondary font-extrabold text-4xl')}>
        NEO
        <div style={s('text-white text-sm')}>Apply online</div>
      </h1>

      <ul style={s('pl-0', { listStyleType: 'none' })}>
        <NumberListItem>
          <StepNumber>1</StepNumber>
          Borrowing power & indicative pre-approval
        </NumberListItem>
        <SideBarLink to={routes.customer.homepage}>
          <NumberListItem>
            <StepNumber>2</StepNumber>
            Application
          </NumberListItem>
        </SideBarLink>
        <ul style={s('', { marginLeft: '43px', marginBottom: '10px' })}>
          {currentApplicationId && (
            <>
              <li>
                <SideBarLink
                  to={routes.customer.application.applicants.replace(
                    ':id',
                    currentApplicationId
                  )}
                  onClick={save}
                  isActive={location.pathname.includes('applicants')}
                >
                  Applicant details
                </SideBarLink>
              </li>
              <li>
                <SideBarLink
                  to={routes.customer.application.financialPosition.replace(
                    ':id',
                    currentApplicationId
                  )}
                  onClick={save}
                  isActive={location.pathname.includes('financial-position')}
                >
                  Financial position
                </SideBarLink>
              </li>
              <li>
                <SideBarLink
                  to={routes.customer.application.fundingDetails.replace(
                    ':id',
                    currentApplicationId
                  )}
                  onClick={save}
                  isActive={location.pathname.includes('funding-details')}
                >
                  Funding details
                </SideBarLink>
              </li>
              <li>
                <SideBarLink
                  to={routes.customer.application.objectives.replace(
                    ':id',
                    currentApplicationId
                  )}
                  onClick={save}
                  isActive={location.pathname.includes('objectives')}
                >
                  Objectives
                </SideBarLink>
              </li>
              <li>
                <SideBarLink
                  to={routes.customer.application.notes.replace(
                    ':id',
                    currentApplicationId
                  )}
                  onClick={save}
                  isActive={location.pathname.includes('notes')}
                >
                  Notes
                </SideBarLink>
              </li>
            </>
          )}
        </ul>
        <SideBarLink
          to={routes.customer.application.review.replace(':id', currentApplicationId)}
          onClick={save}
          isActive={location.pathname.includes('review')}
        >
          <NumberListItem>
            <StepNumber>3</StepNumber>
            Review and submit
          </NumberListItem>
        </SideBarLink>
      </ul>

      <div style={{ flexGrow: 1 }} />

      <ViewApplicationsButton to={routes.customer.homepage}>
        View applications
      </ViewApplicationsButton>

      <button
        style={s(
          'bg-accent-pink text-primary uppercase border-0 text-xl rounded-lg mb-4'
        )}
        onClick={handleSignOut}
      >
        Sign out
      </button>
    </SideBarContainer>
  )
}

export default SideBar
