import React from 'react'
import { s } from '@vega/styled/v2'
import AvailableFunds from './AvailableFunds'
import { Chip, TableCell } from 'components/common'
import { formatCurrency } from 'utils/formatters'

const FundingDetails = ({ application }) => {
  const {
    financialPosition: { realEstateAssets },
    applicants,
    households,
  } = application

  const householdApplicants = households.flatMap((household) => household.members)
  const allApplicants = householdApplicants.concat(applicants)

  const purchasingProperties = realEstateAssets.filter(
    (realEstateAsset) => realEstateAsset.transaction === 'Purchasing'
  )

  const refinancingProperties = realEstateAssets.filter(
    (realEstateAsset) => realEstateAsset.transaction === 'Owns Existing Mortgage'
  )

  const securityProperties = realEstateAssets.filter(
    (realEstateAsset) => realEstateAsset.isUsedAsSecurity === true
  )

  const ownedProperties = application.financialPosition.realEstateAssets.filter(
    (realEstateAsset) =>
      realEstateAsset.isUsedAsSecurity === false &&
      realEstateAsset.transaction === 'Owns'
  )

  const rowsForProperties = (properties) =>
    properties.map((realEstateAsset) => {
      const ownersName = allApplicants
        .filter((owner) =>
          realEstateAsset.ownerships
            .map((ownership) => ownership.owner)
            .includes(owner._id)
        )
        .map((applicant) => `${applicant.firstName}`)
        .join(' & ')
      return (
        <tr key={realEstateAsset._id}>
          <TableCell>
            <Chip>{realEstateAsset.primaryPurpose}</Chip>
          </TableCell>
          <TableCell>{realEstateAsset.address.propertyAddress}</TableCell>
          <TableCell>{realEstateAsset.primaryUsage}</TableCell>
          <TableCell>Owners: {ownersName}</TableCell>
          <TableCell>{formatCurrency(realEstateAsset.value)}</TableCell>
        </tr>
      )
    })

  return (
    <>
      <h2>Funding details</h2>
      <h3>Property to Purchase</h3>
      <table style={s('bg-white p-6 rounded-lg shadow-lg w-full')}>
        <tbody>{rowsForProperties(purchasingProperties)}</tbody>
      </table>

      <h3>Property to Refinance</h3>
      <table style={s('bg-white p-6 rounded-lg shadow-lg w-full')}>
        <tbody>{rowsForProperties(refinancingProperties)}</tbody>
      </table>

      <h3>Properties for Security</h3>
      <table style={s('bg-white p-6 rounded-lg shadow-lg w-full')}>
        <tbody>{rowsForProperties(securityProperties)}</tbody>
      </table>

      <h3>Properties Owned</h3>
      <table style={s('bg-white p-6 rounded-lg shadow-lg w-full')}>
        <tbody>{rowsForProperties(ownedProperties)}</tbody>
      </table>
      <AvailableFunds application={application} />
    </>
  )
}

export default FundingDetails
