import React from 'react'

import { styled, s } from '@vega/styled'
import { ReactComponent as ChevronLeftIcon } from '../assets/images/chevron-double-left.svg'
import { ReactComponent as VerticalNeoLogo } from '../assets/images/neo-logo-vertical.svg'
import { TEST_IDS } from '@vega/constants'

const Root = styled.div(
  s('flex flex-column justify-end items-center px-1 py-3 absolute z-2'),
  s('text-base bg-green-100'),
  {
    borderRadius: 4,
    borderTopRightRadius: 0,
    boxShadow:
      '0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1);',
    right: 4,
    top: '33%',
    cursor: 'pointer',
  }
)

const TextContainer = styled.div(s('relative flex flex-row justify-end items-en mt-1'))

const Text = styled.span(s('block text-green-700 font-medium absolute'), {
  transform: 'rotate(270deg)',
  transformOrigin: '100% 100%',
  top: -15,
  right: 5,
  fontSize: 9,
  pointerEvents: 'none',
})

const TextSpacer = styled.div({ width: 8 })

const SwitcherButton = ({ onClick }) => {
  return (
    <Root data-test-id={TEST_IDS.APPSWITCHER_APP_BUTTON} onClick={onClick}>
      <ChevronLeftIcon
        stroke={s('text-green-800').color}
        strokeWidth={2}
        width={17}
        height="auto"
        style={{ marginBottom: 6, marginLeft: -2, marginTop: -4 }}
      />

      <TextContainer>
        <VerticalNeoLogo fill={s('text-green-800').color} width={26} />
        <Text>Workspace</Text>

        {/*
          We originally attempted to verticalize the text by using the `writing-mode`
          value `sideways-lr`. However, this value is only supported on Firefox.
          To create a cross-browser and backwards-compatible implementation, we instead
          use `transform: rotate` to rotate the text. The side affect of this is that
          the text will rotate, but it's width and size in the document flow remains the
          same, so the spacing of the components will be incorrect. To solve this, we
          need to take the rotated text out of document flow using `position: absolute`.
          This solution creates another problem: now the text doesn't have a size at
          all. This is the reason why we have a TextSpacer component, to provide the
          required space for the text to sit vertically without having to set a width
          to the button's container.
        */}
        <TextSpacer />
      </TextContainer>
    </Root>
  )
}
export { SwitcherButton }
