import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Landing from './landing'
import PreApproval from './preApproval'
import Error from './error'

const PublicApp = () => (
  <Switch>
    <Route exact path="/" component={Landing} />
    <Route path="/pre-approval" component={PreApproval} />
    <Route path="/" component={Error} />
  </Switch>
)

export default PublicApp
