/* eslint-disable react/no-multi-comp */
import React, { useState, useRef, useCallback, useEffect } from 'react'
import { isNotNilOrEmpty } from '@neo/ramda-extra'

import { useMenuTriggerState } from '@react-stately/menu'
import { Item } from '@react-stately/collections'
import { useButton } from '@react-aria/button'
import { useMenu, useMenuItem, useMenuTrigger } from '@react-aria/menu'
import { useTreeState } from '@react-stately/tree'
import { mergeProps } from '@react-aria/utils'
import { FocusScope } from '@react-aria/focus'
import { useFocus } from '@react-aria/interactions'
import { useOverlay, DismissButton } from '@react-aria/overlays'

import { FormMessage } from '../FormInput'

import { ReactComponent as DropdownIcon } from '@vega/components/src/assets/images/arrow_drop_down-24px.svg'

import { s, styled } from '@vega/styled/v2'

const Root = styled.div(
  s('relative p-0 m-0 border-0 w-full flex flex-column'),
  s('text-grey-800 text-base font-normal', {
    lineHeight: 1.5,
  })
)

const LabelContainer = styled.div(s('flex justify-between mb-4'))
const OptionalLabel = styled.span(s('flex items-center text-xs text-grey-500'))
const Label = styled.label(
  s('text-base font-normal text-grey-700', {
    pointerEvents: 'none',
    lineHeight: 1.2,
  })
)

const Button = styled.button(
  s('flex px-4 py-3 items-center justify-between', { minHeight: 52 }),
  s('text-base text-grey-800 bg-white rounded-lg'),
  s('border-solid border-1 border-grey-400'),
  { whiteSpace: 'nowrap', outline: 'none', '&:hover': { cursor: 'pointer' } },
  { '&:hover': s('border-1 border-primary') },
  {
    ':focus-within': s('border-1 border-primary', {
      outline: '2px solid',
      outlineColor: s('text-primary').color,
    }),
  },
  { '&:active': s('border-green-400') },

  ({ hasError }) =>
    hasError &&
    s('border-error-400 text-grey-500', {
      outline: 'none !important',
      '&:hover, &:focus': s('border-1 border-error-400 text-grey-500'),
    }),
  ({ disabled }) =>
    disabled &&
    s('bg-grey-200 text-grey-400 border-1 border-transparent', {
      pointerEvents: 'none',
    }),
  ({ readonly }) =>
    readonly && s('bg-grey-100 text-grey-800 border-1 border-transparent'),
  ({ useMinHeight }) => useMinHeight && s('py-0')
)

const ButtonText = styled.span(
  s('h-full p-0 m-0 text-grey-900'),
  { lineHeight: 1.2 },
  ({ isPlaceholderVisible }) => (isPlaceholderVisible ? s('text-grey-500') : {})
)

const List = styled.ul(
  s(
    'absolute w-full py-3 px-0 mt-1 bg-white shadow-md rounded-lg flex flex-column items-center z-2'
  ),
  s('border-solid border-1 border-grey-200'),
  { gap: 4 }
)

const Li = styled.li(
  s('w-full'),
  s('text-base font-lg px-3 py-2 text-grey-800'),
  s('flex items-center', {
    outline: 'none',
    lineHeight: 1,
  }),
  { '&:hover, &:focus': s('bg-grey-100') },
  { '&:active': s('bg-grey-100') },
  { '&:selected': s('bg-grey-100') },
  ({ isFocused }) => isFocused && { cursor: 'pointer' }
)

const Svg = styled.svg(s('flex-shrink-0 mr-2'), { fill: 'none', width: 12, height: 12 })

const Rect = styled.rect(
  {
    width: 22,
    height: 22,
    strokeWidth: 1,
    stroke: s('text-grey-400').color,
    x: 1,
    y: 1,
    rx: 4,
  },
  ({ isSelected }) => isSelected && { fill: s('text-secondary').color }
)

function DefaultTrigger({
  disabled,
  readonly,
  hasError,
  placeholder,
  isPlaceholderVisible,
  selectedKeys = [],
  triggerProps,
  ...otherProps
}) {
  return (
    <Button
      hasError={hasError}
      disabled={disabled || readonly}
      readonly={readonly}
      type="button"
      {...triggerProps}
      {...otherProps}
    >
      <ButtonText isPlaceholderVisible={isPlaceholderVisible}>
        {!isPlaceholderVisible
          ? `${selectedKeys.length} options selected`
          : placeholder}
      </ButtonText>
      <DropdownIcon
        style={s('ml-1', { width: 30, height: 25 })}
        fill={s('text-grey-700').color}
      />
    </Button>
  )
}

const Pill = ({ selectedKey, containerStyles, ...otherProps }) => {
  return (
    <div
      style={s('bg-grey-100 px-3 max-w-12 my-2', {
        borderRadius: 20,
        paddingBlock: 5,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        ...containerStyles,
      })}
      {...otherProps}
    >
      <span style={s('text-grey-100 text-grey-900 text-sm')}>{selectedKey}</span>
    </div>
  )
}

function PillTrigger({
  disabled,
  readonly,
  hasError,
  placeholder,
  isPlaceholderVisible,
  selectedKeys = [],
  triggerProps,
  ...otherProps
}) {
  const hasSelectedKeys = isNotNilOrEmpty(selectedKeys)

  return (
    <Button
      hasError={hasError}
      disabled={disabled || readonly}
      readonly={readonly}
      type="button"
      useMinHeight={hasSelectedKeys}
      {...triggerProps}
      {...otherProps}
    >
      <div style={s('flex flex-wrap')}>
        {hasSelectedKeys ? (
          selectedKeys.map((key) => (
            <Pill key={key} selectedKey={key} containerStyles={s('mr-2')} />
          ))
        ) : (
          <ButtonText isPlaceholderVisible={true}>{placeholder}</ButtonText>
        )}
      </div>

      <DropdownIcon
        style={s('ml-1', { width: 30, height: 25 })}
        fill={s('text-grey-700').color}
      />
    </Button>
  )
}

// eslint-disable-next-line complexity
function MultiSelectInput(props) {
  const defaultTriggerRef = useRef(null)

  const {
    name,
    triggerRef = defaultTriggerRef,
    selectRef,
    overlayRef,
    label,
    required = true,
    items,
    placeholder = 'Select repayment frequencies',
    error,
    disabled,
    readonly,
    value: controlledValue,
    onChange,
    displayTextSelector = (v) => v,
    errorMsgSelector = (err) => err,
    onBlur,
    hasError,
    variant = 'default',
  } = props

  const [uncontrolledValue, setUncontrolledValue] = useState(controlledValue ?? [])
  const selectedKeys = controlledValue ?? uncontrolledValue

  const menuTriggerState = useMenuTriggerState({ align: 'end' })

  const { menuTriggerProps, menuProps } = useMenuTrigger(
    {},
    menuTriggerState,
    triggerRef
  )

  const getSelectedItems = useCallback(
    (keys) => {
      const selectedItems = []

      for (const item of items) {
        if (keys.has(item.key)) {
          selectedItems.push(item.key)
        }
      }

      return selectedItems
    },
    [items]
  )

  const selectionState = useTreeState({
    children: items.map((item) => <Item key={item.key}>{item.label}</Item>),
    selectionMode: 'multiple',
    selectedKeys,
    onSelectionChange: (keys) => {
      const selectedItems = getSelectedItems(keys)

      setUncontrolledValue(selectedItems)

      if (onChange) {
        onChange(getSelectedItems(keys))
      }
    },
  })

  const { buttonProps } = useButton(menuTriggerProps, triggerRef)

  const triggerProps = mergeProps(
    {
      onKeyDown: (e) => {
        if (e.keyCode === 32) {
          menuTriggerState.toggle()
        }
      },
      onBlur,
    },
    buttonProps
  )

  const isPlaceholderVisible = selectedKeys.length === 0

  return (
    <Root>
      {isNotNilOrEmpty(label) && (
        <LabelContainer>
          <Label>{label}</Label>
          {!required && <OptionalLabel>Optional</OptionalLabel>}
        </LabelContainer>
      )}

      {variant === 'default' ? (
        <DefaultTrigger
          triggerProps={triggerProps}
          hasError={hasError}
          readonly={readonly}
          disabled={disabled}
          placeholder={placeholder}
          isPlaceholderVisible={isPlaceholderVisible}
          selectedKeys={displayTextSelector(selectedKeys)}
        />
      ) : (
        <PillTrigger
          triggerProps={triggerProps}
          hasError={hasError}
          readonly={readonly}
          disabled={disabled}
          placeholder={placeholder}
          selectedKeys={displayTextSelector(selectedKeys)}
        />
      )}

      {menuTriggerState.isOpen && (
        <Popup
          {...props}
          triggerRef={triggerRef}
          selectRef={selectRef}
          overlayRef={overlayRef}
          getSelectedItems={getSelectedItems}
          selectionState={selectionState}
          listProps={menuProps}
          onClose={() => menuTriggerState.close()}
        />
      )}

      <FormMessage
        id={name}
        message={errorMsgSelector(error)}
        visible={hasError ?? isNotNilOrEmpty(error)}
      />
    </Root>
  )
}

function Popup(props) {
  const defaultSelectRef = useRef(null)
  const defaultOverlayRef = useRef(null)

  const {
    triggerRef,
    selectRef = defaultSelectRef,
    overlayRef = defaultOverlayRef,
    selectionState,
    getSelectedItems,
    onClose,
    listProps,
  } = props

  const { menuProps } = useMenu(props, selectionState, selectRef)

  const { overlayProps } = useOverlay(
    {
      onClose,
      shouldCloseOnBlur: true,
      isOpen: true,
      isDismissable: true,
    },
    overlayRef
  )

  const selectedKeys = selectionState.selectionManager

  useEffect(() => {
    if (triggerRef.current) {
      triggerRef.current.value = getSelectedItems(selectedKeys)
    }
  }, [getSelectedItems, selectedKeys, triggerRef])

  return (
    <FocusScope restoreFocus>
      <div {...overlayProps} ref={overlayRef}>
        <DismissButton onDismiss={onClose} />

        <List {...mergeProps(menuProps, listProps)} ref={selectRef}>
          {[...selectionState.collection].map((item) => (
            <ListItem
              key={item.key}
              item={item}
              state={selectionState}
              onAction={props.onAction}
              onClose={props.onClose}
            />
          ))}
        </List>

        <DismissButton onDismiss={onClose} />
      </div>
    </FocusScope>
  )
}

function ListItem({ item, state, onAction, onClose }) {
  const ref = useRef(null)
  const { menuItemProps } = useMenuItem(
    {
      key: item.key,
      isDisabled: item.isDisabled,
      onAction,
      onClose,
    },
    state,
    ref
  )

  const [isFocused, setFocused] = useState(false)
  const { focusProps } = useFocus({ onFocusChange: setFocused })
  const isSelected = state.selectionManager.state.selectedKeys.has(item.key)

  return (
    <Li {...mergeProps(menuItemProps, focusProps)} ref={ref} isFocused={isFocused}>
      <Svg aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <Rect isSelected={isSelected} />
        {isSelected && (
          <path strokeWidth="2" d="M5 13l4 4L19 7" stroke={s('text-grey-700').color} />
        )}
      </Svg>

      {item.rendered}
    </Li>
  )
}

export { MultiSelectInput }
