const COLUMN_COUNT = 24

export function toPercentageWidth(value, fallback, shouldFallback) {
  if (shouldFallback(value)) {
    return fallback
  }

  return `${(value / COLUMN_COUNT) * 100}%`
}

export const getConfigResolver = (breakpointConfig, currentBreakpoint) => ({
  prop,
  mobileValue,
}) => {
  if (currentBreakpoint === 'mobile') {
    return mobileValue
  }

  // FIXME: possiblyObjectConfig is always undefined as the breakpointConfig is an object
  const possiblyObjectConfig = breakpointConfig[currentBreakpoint]

  if (hasValidObjectConfig(possiblyObjectConfig, prop)) {
    return possiblyObjectConfig[prop]
  }

  const possiblySpanValue = possiblyObjectConfig

  if (isValidSpanShorthand(possiblySpanValue, prop)) {
    return possiblySpanValue
  }

  const { sm, md, lg, xl, jb } = breakpointConfig
  const breakpointOrder = [
    ['jb', jb],
    ['xl', xl],
    ['lg', lg],
    ['md', md],
    ['sm', sm],
  ]

  const possiblyNextLowestBreakpoint = getNextLowestBreakpoint(
    breakpointOrder,
    currentBreakpoint,
    prop
  )

  return possiblyNextLowestBreakpoint ?? mobileValue
}

function hasValidObjectConfig(breakpoint, prop) {
  return breakpoint && typeof breakpoint === 'object' && prop in breakpoint
}

function isValidSpanShorthand(breakpoint, prop) {
  // For the `span` property, we support a shorthand alternative to the object syntax
  // as the `span` is the most used property when using a responsive grid system.
  // This is why check if we are resolving the `span` prop before returning the
  // integer provided for the current breakpoint.
  return prop === 'span' && Number.isInteger(breakpoint) && breakpoint >= 0
}

function getNextLowestBreakpoint(breakpointOrder, currentBreakpoint, prop) {
  const currentBreakpointIndex = breakpointOrder.findIndex(
    ([breakpoint]) => breakpoint === currentBreakpoint
  )

  return breakpointOrder.reduce((acc, [, objectConfig], breakpointIndex) => {
    // Skip until we reach the next lowest breakpoint relative to the current.
    if (isGreaterOrEqualToCurrentBreakpoint(breakpointIndex, currentBreakpointIndex)) {
      return undefined
    }

    // If we've already found a valid value then we'll skip the rest of remaining
    // breakpoints.
    if (acc) {
      return acc
    }

    if (hasValidObjectConfig(objectConfig, prop)) {
      return objectConfig[prop]
    }

    if (isValidSpanShorthand(objectConfig, prop)) {
      return objectConfig
    }

    return undefined
  }, undefined)
}

function isGreaterOrEqualToCurrentBreakpoint(breakpointIndex, currentBreakpointIndex) {
  return breakpointIndex <= currentBreakpointIndex
}
