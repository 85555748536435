import React from 'react'

import { mergeProps } from '@react-aria/utils'
import { useListBox } from '@react-aria/listbox'
import { useOverlay, DismissButton } from '@react-aria/overlays'
import { ReactComponent as CirclePlus } from '@vega/components/src/assets/images/circle-plus.svg'

import { s, styled } from '@vega/styled'

import { Option } from './Option'
import AddNewApplicant from '../../Button'

const MenuContainer = styled.div(
  s('border-solid border-1 border-grey-400 absolute bg-white w-full z-1', {
    boxSizing: 'border-box',
    borderRadius: 10,
    borderTopRightRadius: 0,
    overflow: 'hidden',
    right: 0,
    top: 'calc(100% + 4px)',
  })
)
const Menu = styled.div(
  s('bg-white w-full', {
    boxSizing: 'border-box',
    listStyle: 'none',
    overflowX: 'hidden',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
    maxHeight: 250,
  }),
  ({ noMatch }) => ({ overflowY: noMatch ? 'none' : 'auto' })
)

const AddApplicantIcon = styled(CirclePlus)(
  s('mr-3', {
    marginTop: 2,
    '& path': { stroke: s('text-grey-500').color },
  })
)

const NoApplicantsFound = styled.p(
  s('text-grey-800 font-semibold text-base ml-5 mt-2 mb-3')
)
const NewApplicantLabel = styled.span(s('text-base font-normal text-grey-800'))

const ListBoxPopup = ({
  popoverRef,
  listBoxRef,
  state,
  shouldUseVirtualFocus,
  menuId,
  addNewApplicant,
  onClose,
  ...otherProps
}) => {
  const { listBoxProps } = useListBox(
    {
      autoFocus: state.focusStrategy,
      disallowEmptySelection: true,
      shouldUseVirtualFocus,
      ...otherProps,
    },
    state,
    listBoxRef
  )

  const { overlayProps } = useOverlay(
    {
      onClose,
      shouldCloseOnBlur: true,
      isOpen: state.isOpen,
      isDismissable: true,
    },
    popoverRef
  )

  const noMatch = state.collection.keyMap.size === 0
  const items = [...state.collection].map((item) => (
    <Option shouldUseVirtualFocus key={item.key} item={item} state={state} />
  ))

  return (
    <div {...overlayProps} ref={popoverRef} data-test-id={menuId}>
      <MenuContainer>
        <AddNewApplicant
          StartIcon={<AddApplicantIcon />}
          containerStyle={s('w-full justify-start pb-3 pl-5', {
            '&:hover': s('bg-primary'),
          })}
          variant="text"
          type="button"
          onClick={() => {
            state.close()
            addNewApplicant()
          }}
        >
          <NewApplicantLabel>A new applicant</NewApplicantLabel>
        </AddNewApplicant>
        <Menu
          {...mergeProps(listBoxProps, otherProps)}
          ref={listBoxRef}
          noMatch={noMatch}
        >
          {noMatch ? <NoApplicantsFound>No Match Found</NoApplicantsFound> : items}
        </Menu>
      </MenuContainer>
      <DismissButton onDismiss={() => state.close()} />
    </div>
  )
}

export { ListBoxPopup }
