/* eslint-disable complexity */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, Link } from 'react-router-dom'
import { s } from '@vega/styled/v2'
import {
  selectApplicationById,
  fetchApplication,
  submitApplication,
  setCurrentApplicationId,
  selectIsSubmitApplicationPending,
  selectIsSubmitApplicationFulfilled,
  selectIsSubmitApplicationRejected,
  selectSubmitApplicationError,
} from 'modules/application'
import { Chip, SubTitle } from 'components/common'
import ApplicantsTable from './applicants/ApplicantsTable'
import NonRealEstateAssetTable from './NonRealEstateAssetTable'
import LiabilitiesTable from './LiabilitiesTable'
import FundingDetails from './FundingDetails'
import ObjectivesTable from './ObjectivesTable'
import DocumentsTable from './DocumentsTable'
import Card from 'components/Card'
import { Button, Modal, toast } from '@vega/components'
import { routes } from 'routes'
import { listSupportingDocuments } from 'modules/supportingDocument'
import { copyToClipboard } from 'utils/copyToClipboard'
import { ReactComponent as ClipBoardIcon } from '@vega/components/src/assets/images/copy.svg'
import { ReactComponent as Check } from '@vega/components/src/assets/images/check.svg'
import { Tooltip } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'

const Review = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const application = useSelector(selectApplicationById(id))
  const documents = useSelector((state) => state.supportingDocuments.entities)
  const supportingDocuments = documents ? Object.values(documents) : []
  const submitPending = useSelector(selectIsSubmitApplicationPending)
  const submitFulfilled = useSelector(selectIsSubmitApplicationFulfilled)
  const submitRejected = useSelector(selectIsSubmitApplicationRejected)
  const submitError = useSelector(selectSubmitApplicationError)
  const [showModal, setShowModal] = React.useState(false)
  const [isCopy, setIsCopy] = useState(false)
  const [isSubmissionChecked, setIsSubmissionChecked] = useState(true)

  useEffect(() => {
    if (id) {
      dispatch(fetchApplication(id))
      dispatch(setCurrentApplicationId(id))
    }
  }, [dispatch, id])

  useEffect(() => {
    if (application && application._id) {
      dispatch(
        listSupportingDocuments({
          applicationId: application._id,
        })
      )
    }
  }, [dispatch, application])

  useEffect(() => {
    if (submitFulfilled) {
      toast('✉️  Your application was successfully submitted to Neo')
    }

    if (submitRejected) {
      toast(`🚫 ${submitError?.context}` ?? '🚫 Something has gone wrong')
    }
  }, [submitFulfilled, submitRejected, submitError])

  useEffect(() => {
    let timer
    if (isCopy) {
      timer = setTimeout(() => {
        setIsCopy(false)
      }, 2000)
    }
    return () => clearTimeout(timer)
  }, [isCopy])

  const handleSubmit = async () => {
    await dispatch(submitApplication(id))
    if (!submitPending) {
      setShowModal(false)
    }
  }

  // TODO: also add a loading state in the if
  if (!application) {
    return (
      <>
        <h1>Unable to find this application</h1>
        <p>We are having trouble finding this application.</p>
        <p>Please use the link below to go back to the applications screen.</p>
        <Link to={routes.customer.homepage}>Back to applications</Link>
      </>
    )
  }

  return (
    <>
      <h1>
        Review and submit your application
        <div style={s('flex items-center')}>
          <Chip style={s('mr-0')}>{application.applicationNumber} </Chip>

          <div
            variant="outlined"
            onClick={() => {
              copyToClipboard(`${application.applicationNumber}`)
              setIsCopy(true)
            }}
            style={s('', {
              cursor: 'pointer',
            })}
          >
            <Tooltip title={`${isCopy ? 'Copied' : 'Copy application number'}`}>
              {isCopy ? (
                <Check width="15" style={s('ml-1 mb-3 text-green')} />
              ) : (
                <ClipBoardIcon width="15" style={s('ml-1 mb-3')} />
              )}
            </Tooltip>
          </div>
        </div>
      </h1>
      <p style={s('text-xl')}>
        Please review your information before submitting your application to Neo.
      </p>

      <ApplicantsTable application={application} />

      <h2>Financial position</h2>
      <NonRealEstateAssetTable application={application} />
      <LiabilitiesTable application={application} />

      <FundingDetails application={application} />

      <h2>Objectives</h2>
      <ObjectivesTable application={application} />
      <h2>Notes</h2>
      {application.notesAndSupportdocs === undefined ||
      Object.keys(application.notesAndSupportdocs).length === 0 ? (
        <Card>
          <SubTitle>No notes have been specified</SubTitle>
          <p>Notes can be specified in the application Notes section.</p>
        </Card>
      ) : (
        <Card style={s('flex')}>
          <div style={{ flexGrow: 1 }}>{application?.notesAndSupportdocs?.notes}</div>
          <Link
            style={s('', { textDecoration: 'none' })}
            to={routes.customer.application.notes.replace(':id', id)}
          >
            <Button variant="outlined">View/Edit</Button>
          </Link>
        </Card>
      )}

      <h2>Supporting Documents</h2>
      <DocumentsTable supportingDocuments={supportingDocuments} />
      <div style={s('flex ')}>
        <Checkbox
          onClick={() => setIsSubmissionChecked(!isSubmissionChecked)}
          sx={s('', {
            padding: '0 6px 0 0',
            '&.Mui-checked': {
              color: '#00FFCD',
            },
            '&:hover': {
              backgroundColor: 'transparent',
            },
          })}
          size="small"
        />
        <p style={s('font-semibold')}>I confirm the details above are correct.</p>
      </div>
      <p style={s('ml-1 mt-2')}>
        Upon submitting your application to Neo it will be procesed by our credit team
        as soon as possible and they contact to discuss next steps.
      </p>

      <>
        <Modal isOpen={showModal}>
          <Card>
            <p>Are you sure you want to re-submit this application?</p>
            <div style={s('flex justify-end')}>
              <Button onClick={handleSubmit} style={s('mr-2')} loading={submitPending}>
                Re-Submit
              </Button>
              <Button onClick={() => setShowModal(false)} variant="outlined">
                Cancel
              </Button>
            </div>
          </Card>
        </Modal>
        <div>
          {application.applicationStatus === 'DRAFT' && (
            <Button
              onClick={handleSubmit}
              loading={submitPending}
              disabled={isSubmissionChecked}
            >
              Submit application to Neo
            </Button>
          )}
          {application.applicationStatus === 'SUBMITTED' && (
            <Button onClick={() => setShowModal(true)} disabled={isSubmissionChecked}>
              Re-Submit application
            </Button>
          )}
        </div>
        <div style={s('h-1')}> </div>
      </>
    </>
  )
}

export default Review
