import React from 'react'
import { s } from '@vega/styled/v2'
import Card from 'components/Card'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setCurrentApplicationId, removeApplication } from 'modules/application'
import { Button } from '@vega/components'
import { formatDateString } from 'utils/formatters'
import { SubEntityCard, Chip, SubEntityActionButton } from 'components/common'
import { routes } from 'routes'

const ApplicationsCard = ({ application }) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const viewApplication = () => {
    dispatch(setCurrentApplicationId(application._id))
    history.push(routes.customer.application.applicants.replace(':id', application._id))
  }

  const handleRemove = () => {
    dispatch(removeApplication(application._id))
  }

  const personNames = application.households.flatMap((household) =>
    household.members.map((member) => member.firstName)
  )

  const trustAndCompanyNames = application.applicants.map(
    (applicant) => applicant.trustName || applicant.businessName
  )

  const applicantNames =
    personNames.concat(trustAndCompanyNames).join(', ') || 'No applicants yet'

  const propertieAddresses = application?.financialPosition?.realEstateAssets?.map(
    (address, addressIndex) => (
      <SubEntityCard key={addressIndex}>
        <div style={s('flex')}>
          <div style={s('text-lg', { flexGrow: 1 })}>
            <div style={s('font-semibold')}>{address.address.propertyAddress}</div>

            <div style={s('flex')}>
              <Chip>{address.transaction}</Chip>
            </div>
          </div>
        </div>
      </SubEntityCard>
    )
  )

  return (
    <Card style={s('mb-4')}>
      <div style={s('flex')}>
        <h2 style={{ flexGrow: 1 }}>
          <span style={s('text-base text-grey-700 font-semibold')}>
            {formatDateString(application.createdAt)}
          </span>
          <br />
          {applicantNames}
          <Chip
            style={
              application.applicationStatus === 'DRAFT'
                ? s('bg-accent-red')
                : s('bg-green-400')
            }
          >
            {application.applicationStatus}
          </Chip>
        </h2>
        {application.applicationStatus === 'DRAFT' && (
          <SubEntityActionButton
            onClick={handleRemove}
            style={s('bg-accent-red text-white', {
              width: 'auto',
              height: 'fit-content',
            })}
          >
            Remove application
          </SubEntityActionButton>
        )}
      </div>

      <span style={s('text-base text-grey-700 font-normal')}>
        Application Number:{' '}
        <span style={s('font-bold')}>{application.applicationNumber}</span>
      </span>

      <div style={s('py-2')}>{propertieAddresses}</div>

      <Button onClick={viewApplication} variant="outlined">
        View application
      </Button>
    </Card>
  )
}

export default ApplicationsCard
