import React, { useMemo, memo } from 'react'
import { s } from '../headwind'
import { styled } from '../styled'
import { breakpoints, only, larger } from '../breakpoints'
import units from 'units-css'

const Fluid = styled('div', {
  shouldForwardProp: (propName) =>
    !['fill', 'fluid', 'gutter'].find(
      (blacklistedProp) => blacklistedProp === propName
    ),
})(
  s('mx-a w-full'),
  ({ gutter }) => ({
    paddingLeft: gutter,
    paddingRight: gutter,
  }),
  ({ fill }) => fill && s('h-full')
)

const Responsive = styled(Fluid)(
  {
    [`@media (max-width): ${units.parse(breakpoints.sm).value - 0.02}px`]: {
      maxWidth: breakpoints.sm,
    },
  },
  only('sm')({ maxWidth: breakpoints.sm }),
  only('md')({ maxWidth: breakpoints.md }),
  only('lg')({ maxWidth: breakpoints.lg }),
  only('xl')({ maxWidth: breakpoints.xl }),
  larger('jb')({ maxWidth: breakpoints.jb })
)

function Container({ gutter = 16, fluid = false, fill = false, ...props }) {
  const Root = useMemo(() => (fluid ? Fluid : Responsive), [fluid])

  return <Root gutter={gutter} fluid={fluid} fill={fill} {...props} />
}

const Memoized = memo(Container)

export { Memoized as Container }
