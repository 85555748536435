import React from 'react'
import { SubTitle, FieldContainer, SectionContainer } from 'components/common'
import { TextField, ValocityAddressField } from '@vega/components'
import { yesNoOptions } from 'utils/dropdownLists'
import { ReactComponent as SearchIconBase } from '@vega/components/src/assets/images/search.svg'
import { s, styled } from '@vega/styled/v2'
import { ValidatedSelectField } from 'components/SelectField'
import { PhoneNumber } from './PhoneNumber'

const SearchIcon = styled(SearchIconBase)(s('mr-2'))

export const CompanyFields = ({ values, handleChange, handleBlur }) => (
  <SectionContainer>
    <SubTitle>Company details</SubTitle>
    <FieldContainer>
      <TextField
        name="licenceNumber"
        label="Licence number*"
        placeholder="Licence number"
        containerStyle={{ flexBasis: 0, flexGrow: 1 }}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.email}
        labelStyle={s('text-base')}
      />
    </FieldContainer>
    <FieldContainer>
      <TextField
        name="businessName"
        label="Business name*"
        placeholder="Business name"
        containerStyle={{ flexBasis: 0, flexGrow: 1 }}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.email}
        labelStyle={s('text-base')}
      />
    </FieldContainer>
    <FieldContainer>
      <TextField
        name="description"
        label="Description*"
        placeholder="Description"
        containerStyle={{ flexBasis: 0, flexGrow: 1 }}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.email}
        labelStyle={s('text-base')}
      />
    </FieldContainer>
    <FieldContainer>
      <TextField
        name="nzbn"
        label="NZBN*"
        placeholder="NZBN"
        containerStyle={{ flexBasis: 0, flexGrow: 1 }}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.email}
        labelStyle={s('text-base')}
      />
    </FieldContainer>
    <FieldContainer>
      <ValidatedSelectField
        name="isHoldingCompany"
        label="Is holding company?*"
        options={yesNoOptions.map((status) => ({
          label: status.label,
          value: status.key,
          key: status.key,
        }))}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={'Select holding company'}
      />
    </FieldContainer>
    <FieldContainer>
      <ValidatedSelectField
        name="isTradingCompany"
        label="Is trading company?*"
        options={yesNoOptions.map((status) => ({
          label: status.label,
          value: status.key,
          key: status.key,
        }))}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={'Select trading company'}
      />
    </FieldContainer>
    <FieldContainer>
      <ValidatedSelectField
        name="isGstRegistered"
        label="Is GST registered?*"
        options={yesNoOptions.map((status) => ({
          label: status.label,
          value: status.key,
          key: status.key,
        }))}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={'Select GST registered'}
      />
    </FieldContainer>
    <FieldContainer>
      <ValidatedSelectField
        name="isLookThroughCompany"
        label="Is Look through Company?*"
        options={yesNoOptions.map((status) => ({
          label: status.label,
          value: status.key,
          key: status.key,
        }))}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={'Select look through company'}
      />
    </FieldContainer>
    <FieldContainer>
      <ValocityAddressField
        name="officeAddress"
        placeholder="Enter the office address"
        aria-label="Office address"
        style={s('bg-white')}
        InputPrefixIcon={<SearchIcon />}
        menuStyles={s('max-h-24 mt-8', {
          overflowY: 'auto',
        })}
        onChange={handleChange}
        onBlur={handleBlur}
        label={'Office address*'}
      />
    </FieldContainer>
    <FieldContainer>
      <TextField
        name="officeEmail"
        label="Office email*"
        placeholder="Office email"
        containerStyle={{ flexBasis: 0, flexGrow: 1 }}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.email}
        labelStyle={s('text-base')}
      />
    </FieldContainer>

    <PhoneNumber />
  </SectionContainer>
)
