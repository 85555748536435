/* eslint-disable camelcase */
import SOLTA_CONSTANTS from '@neo/solta.constants'
import * as LOAN_APPLICATION from './loanApplication'
import TEST_IDS from './testIdentifiers'
import { COUNTRY_OPTIONS } from './countryOptions'
import * as MORTGAGE_ACCOUNT_CONSTANTS from './mortgageAccount'
import { USER_ROLES, TERRITORIAL_AUTHORITY, PROPERTY } from '@neo/constants'
import * as PRODUCT_CONSTANTS from './product'
import * as PAYMENT_CONSTANTS from './paymentCenter'
import * as AFFORDABILITY_ASSESSMENT_CONSTANTS from './affordabilityAssessment'
import { LEGAL_ENTITY } from './legalEntity'
import { FINANCIAL } from './financial'
import * as LOAN from './loan'
import * as GOOGLE_CONSTANTS from './googleLetters'

const INVITATION_STATUSES = /** @type {const} */ ({
  ACCEPTED: 'vg_invitation_status_accepted',
  INVITED: 'vg_invitation_status_invited',
  SENT: 'vg_invitation_status_sent',
  REVOKED: 'vg_invitation_status_revoked',
  EXPIRED: 'vg_invitation_status_expired',
  REINVITED: 'vg_invitation_reinvited',
})

const APP_SERVICES = /** @type {const} */ ({
  VEGA_LEND_ADMIN: 'vega_lend_admin',
  VEGA_LEND_BROKER: 'vega_lend_broker',
  VEGA_LEND_CLIENT: 'vega_lend_client',
  VEGA_LEND_CREDIT_ASSESSOR: 'vega_lend_credit_assessor',
  VEGA_BANKING_ADMIN: 'vega_banking_admin',
  VEGA_BANKING_CUSTOMER: 'vega_banking_customer',
  VEGA_PORTFOLIO_MANAGEMENT: 'vega_portfolio_management',
  VEGA_LOAN_ORIGINATION: 'vega_loan_origination',
  VEGA_TREASURY_MANAGEMENT: 'vega_treasury_management',
  VEGA_APPLY_ONLINE_ADMIN: 'vega_apply_online_admin',
})

const APPS = /** @type {const} */ ({
  AUTH: 'AUTH',
  LENDING: 'LENDING',
  BANKING: 'BANKING',
  BANKING_PORTFOLIO: 'BANKING_PORTFOLIO',
  LOAN_ORIGINATION: 'LOAN_ORIGINATION',
  TREASURY: 'TREASURY',
  APPLY_ONLINE: 'APPLY_ONLINE',
})

const APP_URLS = /** @type {const} */ ({
  [APPS.AUTH]: process.env.REACT_APP_AUTH_WEB_URI,
  [APPS.LENDING]: process.env.REACT_APP_LENDING_WEB_URI,
  [APPS.BANKING]: process.env.REACT_APP_BANKING_WEB_URI,
  [APPS.BANKING_PORTFOLIO]: process.env.REACT_APP_BANKING_PORTFOLIO_WEB_URI,
  [APPS.LOAN_ORIGINATION]: process.env.REACT_APP_LOAN_ORIGINATION_WEB_URI,
  [APPS.TREASURY]: process.env.REACT_APP_TREASURY_WEB_URI,
  [APPS.APPLY_ONLINE]: process.env.REACT_APP_APPLY_ONLINE_WEB_URI,
})

const lookUpAppRouteByRole = (role = USER_ROLES.CLIENT) => {
  return /** @type {const} */ ({
    [USER_ROLES.LENDING_ADMIN]: `${APP_URLS.LENDING}admin`,
    [USER_ROLES.APPLICANT]: `${APP_URLS.LENDING}client`,
    [USER_ROLES.CLIENT]: `${APP_URLS.LENDING}client`,
    [USER_ROLES.BROKER]: `${APP_URLS.LENDING}broker`,
    [USER_ROLES.BANKING_ADMIN]: `${APP_URLS.BANKING}admin`,
    [USER_ROLES.BANKING_CLIENT]: `${APP_URLS.BANKING}customer`,
    [USER_ROLES.LENDING_CUSTOMER_SUPPORT]: '#',
    [USER_ROLES.SUPER_ADMIN]: `${APP_URLS.LENDING}admin`,
    [USER_ROLES.BANKING_PORTFOLIO_ADMIN]: `${APP_URLS.BANKING_PORTFOLIO}`,
    [USER_ROLES.CREDIT_ASSESSOR]: APP_URLS.LOAN_ORIGINATION,
    [USER_ROLES.LEAD_ASSESSOR]: APP_URLS.LOAN_ORIGINATION,
    [USER_ROLES.TREASURY_ADMIN]: APP_URLS.TREASURY,
    [USER_ROLES.CLIENT]: APP_URLS.APPLY_ONLINE,
  })[role]
}

const PROFILE_EVENTS = /** @type {const} */ ({
  INVITATION: {
    CREATED: 'profile_invitation_created',
  },
})

const ASYNC_STATUSES = /** @type {const} */ ({
  PENDING: 'pending',
  FULFILLED: 'fulfilled',
  REJECTED: 'rejected',
})

const INVITATION_URL = /** @type {const} */ ({
  HOST: process.env.REACT_APP_AUTH_WEB_URI,
})

const USER_STATUSES = /** @type {const} */ ({
  ACTIVE: 'active',
  PENDING: 'pending',
  INACTIVE: 'inactive',
})

const helpers = { lookUpAppRouteByRole }

const AUTH = /** @type {const} */ ({
  AUTH_COOKIE_KEY: 'wallet.authSession',
  CREDENTIALS_COOKIE_KEY: 'wallet.credentials',
  AUTH_ID_TOKEN_KEY: 'wallet.idToken',
})

const CUSTOMER_STATUS = /** @type {const} */ ({
  APPROVED: 'approved',
  PENDING: 'pending',
})

const LOAN_GROUP_STATUS = /** @type {const} */ ({
  APPROVED: 'approved',
  PENDING: 'pending',
})

const PAYMENT_METHOD_TYPE = /** @type {const} */ ({
  CREDIT_CARD: 'credit_card',
  DIRECT_DEBIT: 'direct_debit',
})

const DIRECT_DEBIT_BANK_TYPE = /** @type {const} */ ({
  ANZ: 'anz',
  ASB: 'asb',
  BANK_OF_NEW_ZEALAND: 'bank_of_new_zealand',
  CO_OPERATIVE: 'co_operative_bank',
  KIWI: 'kiwi_bank',
  RABOBANK_NEW_ZEALAND: 'rabobank_new_zealand',
  SBS: 'sbs banks',
  WESTPAC: 'westpac_new_zealand',
})

const TRANSACTION_STATUS = /** @type {const} */ ({
  PAID: 'paid',
  FAILED: 'failed',
})

const PAYMENT_STATUS = /** @type {const} */ ({
  ONSCHEDULE: 'scheduled',
  OVERDUE: 'overdue',
})

const PAYMENT_TYPE = /** @type {const} */ ({
  RECURRING_PAYMENT: 'recurring_payment',
  LUMP_SUM: 'lump_sum',
  LATE_FEE: 'late_fee',
  OTHER: 'other',
})

const ALPHABETICAL_SORT_ORDER = /** @type {const} */ ({
  'A-Z': 'asc',
  'Z-A': 'desc',
})

const SORT_ORDER = {
  ASC: 'asc',
  DESC: 'desc',
}

const GENDERS = {
  MALE: 'male',
  FEMALE: 'female',
  UNSPECIFIED: 'unspecified',
}

const REPAYMENT_TYPES = {
  INTEREST_ONLY: 'interest_only',
  PRINCIPAL_AND_INTEREST: 'principal_and_interest',
  INTEREST_IN_ADVANCE: 'interest_in_advance',
  LINE_OF_CREDIT: 'line_of_credit',
}

const REPAYMENT_TYPE_DISPLAY_TEXT = {
  [REPAYMENT_TYPES.INTEREST_ONLY]: 'IO',
  [REPAYMENT_TYPES.PRINCIPAL_AND_INTEREST]: 'P&I',
  [REPAYMENT_TYPES.INTEREST_IN_ADVANCE]: 'IiA',
  [REPAYMENT_TYPES.LINE_OF_CREDIT]: 'LoC',
}

const INTEREST_TYPES = {
  FIXED: 'fixed',
  VARIABLE: 'variable',
}

const USER_ACTIVITY = {
  INACTIVITY_TIME_ALLOWED_IN_MS: 240000,
  INACTIVITY_TIME_AUTO_SIGNOUT_IN_MS: 60000,
}

const ASSET_TYPES = {
  REAL_ESTATE_ASSET: 'real_estate_asset',
  NON_REAL_ESTATE_ASSET: 'non_real_estate_asset',
}

export {
  TEST_IDS,
  SOLTA_CONSTANTS,
  LOAN_APPLICATION,
  INVITATION_STATUSES,
  SORT_ORDER,
  // ROLES,
  PROFILE_EVENTS,
  ASYNC_STATUSES,
  INVITATION_URL,
  APP_SERVICES,
  APPS,
  APP_URLS,
  USER_STATUSES,
  helpers,
  AUTH,
  COUNTRY_OPTIONS,
  CUSTOMER_STATUS,
  PAYMENT_METHOD_TYPE,
  DIRECT_DEBIT_BANK_TYPE,
  MORTGAGE_ACCOUNT_CONSTANTS,
  TRANSACTION_STATUS,
  PAYMENT_TYPE,
  PRODUCT_CONSTANTS,
  PAYMENT_CONSTANTS,
  AFFORDABILITY_ASSESSMENT_CONSTANTS,
  ALPHABETICAL_SORT_ORDER,
  TERRITORIAL_AUTHORITY,
  USER_ROLES,
  GENDERS,
  REPAYMENT_TYPES,
  REPAYMENT_TYPE_DISPLAY_TEXT,
  INTEREST_TYPES,
  LOAN_GROUP_STATUS,
  USER_ACTIVITY,
  PAYMENT_STATUS,
  LEGAL_ENTITY,
  ASSET_TYPES,
  FINANCIAL,
  LOAN,
  GOOGLE_CONSTANTS,
  PROPERTY,
}
