import React from 'react'
import { s, styled } from '@vega/styled/v2'
import { TextField } from '@vega/components'
import { SubTitle, FieldContainer, SectionContainer } from 'components/common'
import { larger } from '@vega/styled'
import { titlesOptions, yesNoOptions } from 'utils/dropdownLists'
import { ValidatedSelectField } from 'components/SelectField'
import BlurNumberField from './BlurNumberField'

// TODO: see if this can be extracted out to be more generic
const NameFields = styled.div(
  s('flex flex-column mb-2'),
  { gap: '8px' },
  larger('xl')(s('flex-row'))
)

const ContactDetails = ({ memberIndex, handleChange, handleBlur }) => (
  <SectionContainer>
    <SubTitle>Contact details</SubTitle>

    <NameFields style={s('flex')}>
      <div style={s('mr-2')}>
        <ValidatedSelectField
          name={`members[${memberIndex}].title`}
          label="Title*"
          placeholder="Title"
          options={titlesOptions.map((title) => ({
            label: title.label,
            value: title.key,
            key: title.key,
          }))}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>

      <div style={s('w-full mr-2')}>
        <TextField
          name={`members[${memberIndex}].firstName`}
          label="First name*"
          placeholder="First name"
          onChange={handleChange}
          onBlur={handleBlur}
          labelStyle={s('text-base')}
        />
      </div>

      <div style={s('w-full mr-2')}>
        <TextField
          name={`members[${memberIndex}].middleName`}
          label="Middle name"
          placeholder="Middle name"
          onChange={handleChange}
          onBlur={handleBlur}
          labelStyle={s('text-base')}
        />
      </div>

      <div style={s('w-full')}>
        <TextField
          name={`members[${memberIndex}].lastName`}
          label="Last name*"
          placeholder="Last name"
          onChange={handleChange}
          onBlur={handleBlur}
          labelStyle={s('text-base')}
        />
      </div>
    </NameFields>

    <FieldContainer>
      <TextField
        name={`members[${memberIndex}].email`}
        label="Email*"
        placeholder="Email"
        containerStyle={{ flexBasis: 0, flexGrow: 1 }}
        onChange={handleChange}
        onBlur={handleBlur}
        labelStyle={s('text-base')}
      />
    </FieldContainer>

    <FieldContainer
      style={s('grid', { gap: '6px', gridTemplateColumns: '1fr 1fr 2fr' })}
    >
      <BlurNumberField
        name={`members[${memberIndex}].mobileNumber.countryCode`}
        label="Mobile*"
        placeholder="Country Code"
        onChange={handleChange}
        onBlur={handleBlur}
        containerStyle={s('w-full')}
        labelStyle={s('text-base font-normal text-grey-800')}
        prefix={'+'}
      />
      <BlurNumberField
        name={`members[${memberIndex}].mobileNumber.prefix`}
        placeholder="Prefix i.e.(021)"
        onChange={handleChange}
        onBlur={handleBlur}
        containerStyle={s('w-full', { marginTop: '27px' })}
        aria-label="Prefix"
      />

      <BlurNumberField
        name={`members[${memberIndex}].mobileNumber.contactNumber`}
        placeholder="Contact number"
        onChange={handleChange}
        onBlur={handleBlur}
        containerStyle={s('w-full', { marginTop: '27px' })}
        aria-label="Contact number"
      />
    </FieldContainer>

    <FieldContainer>
      <ValidatedSelectField
        name={`members[${memberIndex}].isGuarantor`}
        label="Guarantor?*"
        options={yesNoOptions.map((title) => ({
          label: title.label,
          value: title.key,
          key: title.key,
        }))}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={'Guarantor'}
      />
    </FieldContainer>
  </SectionContainer>
)

export default ContactDetails
