import React from 'react'
import { SubTitle, FieldContainer } from 'components/common'
import { MultiSelectField, TextField } from '@vega/components'
import { ValidatedSelectField } from 'components/SelectField'

// TODO: utils/dropdowns
const yesNoOptions = [
  { key: 'yes', label: 'Yes' },
  { key: 'no', label: 'No' },
]

// TODO: utils/dropdowns
const specialFeatures = [
  { key: 'Offset account', label: 'Offset account' },
  { key: 'Redraw', label: 'Redraw' },
]

// TODO: utils/dropdowns
const offsetAccountPreferences = [
  { key: 'Quicker Loan Repayment', label: 'Quicker Loan Repayment' },
  { key: 'Access to Funds', label: 'Access to Funds' },
  { key: 'Tax', label: 'Tax' },
  { key: 'Other', label: 'Other' },
]

// TODO: utils/dropdowns
const redrawPreferences = [
  { key: 'Access to prepaid funds', label: 'Access to prepaid funds' },
  { key: 'Other', label: 'Other' },
]

/* eslint-disable complexity */
export const SpecialFeatureFields = ({ values }) => (
  <>
    <SubTitle>Special features</SubTitle>

    <FieldContainer>
      <ValidatedSelectField
        name="specialFeaturePreferences.hasSpecialFeaturePreferences"
        options={yesNoOptions.map((status) => ({
          label: status.label,
          value: status.label,
          key: status.label,
        }))}
        placeholder="Any special feature preferences?"
        label="Any special feature references?*"
      />
    </FieldContainer>

    {values.specialFeaturePreferences?.hasSpecialFeaturePreferences === 'Yes' && (
      <>
        <FieldContainer>
          <MultiSelectField
            name="specialFeaturePreferences.preferenceTypes"
            items={specialFeatures}
            label="Special features"
            placeholder="Special features"
          />
        </FieldContainer>

        {(values.specialFeaturePreferences?.preferenceTypes || []).includes(
          'Offset account'
        ) && (
          <>
            <FieldContainer>
              <MultiSelectField
                name="specialFeaturePreferences.offsetAccount.importances"
                items={offsetAccountPreferences}
                label="Offest account preferences"
                placeholder="Offset account preferences"
              />
            </FieldContainer>

            {(
              values.specialFeaturePreferences?.offsetAccount?.importances || []
            ).includes('Other') && (
              <>
                <FieldContainer>
                  <TextField
                    name="specialFeaturePreferences.offsetAccount.otherImportanceNote"
                    label="Other reason"
                    placeholder="Other reason"
                  />
                </FieldContainer>
              </>
            )}
          </>
        )}

        {(values.specialFeaturePreferences?.preferenceTypes || []).includes(
          'Redraw'
        ) && (
          <>
            <FieldContainer>
              <MultiSelectField
                name="specialFeaturePreferences.redraw.importances"
                items={redrawPreferences}
                label="Redraw preferences"
                placeholder="Redraw preferences"
              />
            </FieldContainer>

            {(values.specialFeaturePreferences?.redraw?.importances || []).includes(
              'Other'
            ) && (
              <>
                <FieldContainer>
                  <TextField
                    name="specialFeaturePreferences.redraw.otherImportanceNote"
                    label="Other reason"
                    placeholder="Other reason"
                  />
                </FieldContainer>
              </>
            )}
          </>
        )}
      </>
    )}
  </>
)
