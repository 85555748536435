import BaseService from '../baseService'
import { QueryBuilder } from '../utils'

export class ApplyOnlineSupportingDocumentService extends BaseService {
  create(
    classification,
    fileName,
    fileType,
    source,
    applicationId,
    references,
    logDownload,
    legalEntityIds
  ) {
    const config = {
      json: {
        classification,
        fileName,
        fileType,
        source,
        applicationId,
        references,
        logDownload,
        legalEntityIds,
      },
    }

    return this.client.post('documents', config).json()
  }

  list(applicationId) {
    const queryParams = QueryBuilder().setParam('applicationId', applicationId).build()

    return this.client.get(`documents?${queryParams}`).json()
  }
}
