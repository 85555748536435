import React from 'react'

import { useOption } from '@react-aria/listbox'

import { s, styled } from '@vega/styled'

const Item = styled.li(s('text-grey-800 text-base font-medium'), {
  padding: 10,
  paddingLeft: 18,
  cursor: 'pointer',
  '&:hover': s('bg-green-500'),
})

const Option = ({ item, state, shouldUseVirtualFocus }) => {
  const ref = React.useRef()
  const isDisabled = state.disabledKeys.has(item.key)
  const isSelected = state.selectionManager.isSelected(item.key)
  const isFocused = state.selectionManager.focusedKey === item.key

  const { optionProps } = useOption(
    {
      key: item.key,
      isDisabled,
      isSelected,
      shouldSelectOnPressUp: true,
      shouldFocusOnHover: true,
      shouldUseVirtualFocus,
    },
    state,
    ref
  )

  return (
    <Item
      {...optionProps}
      ref={ref}
      isFocused={isFocused}
      isSelected={isSelected}
      isDisabled={isDisabled}
    >
      {item.rendered}
    </Item>
  )
}

export { Option }
