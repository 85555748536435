import React from 'react'
import { s, styled } from '@vega/styled/v2'

export const SubTitle = styled.h3(
  s('text-primary font-semibold mt-4 mb-0 text-grey-700')
)

export const Label = styled.label(s('text-sm font-semibold text-grey-600'))

export const FieldContainer = styled.div(s('mb-2'))

export const SectionContainer = styled.div(s('mb-12'))

export const SubEntityCard = styled.div(s('p-4 bg-grey-100 mb-2 rounded-lg'))

export const SubEntityActionButton = styled.button(
  s(
    'block w-full bg-white rounded-lg py-2 text-xs font-semibold mb-1 border-0 shadow-lg uppercase',
    { cursor: 'pointer' }
  )
)

export const Chip = styled.div(
  s(
    'bg-grey-600 text-white rounded-full w-fit px-4 border-0 mr-2 font-semibold text-sm uppercase',
    { width: 'fit-content' }
  )
)

export const AddPrimaryEntityButton = styled.button(
  s(
    'bg-secondary text-lg text-white px-4 py-2 border-0 text-primary rounded-lg shadow-lg'
  )
)

export const CollapseButton = styled.button(
  s('bg-white font-semibold border-primary rounded-lg px-2', { cursor: 'pointer' })
)

export const StepNumber = styled.div(
  s(
    'rounded-full bg-primary-background text-primary w-3 h-3 text-xl flex items-center justify-center mr-4 font-semibold',
    { flex: 'none' }
  )
)

const Container = styled.div(
  s('rounded-lg bg-white p-8', {
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
  })
)

export const ContentCard = ({ children, ...props }) => {
  return <Container {...props}>{children}</Container>
}

export const TableCell = styled.td(
  s('text-left', {
    width: '200px',
    verticalAlign: 'top',
  })
)
