import React from 'react'
import { isNotNilOrEmpty } from '@neo/ramda-extra'
import PropTypes from 'prop-types'
import { styled, s } from '@vega/styled'

const { string, func, object, bool } = PropTypes

const Root = styled.div(
  ({ hasLabel }) =>
    s(
      'bg-white border-transparent px-4 w-full rounded-b-lg rounded-tl-lg border-1 border-solid border-grey-200 flex justify-between items-center',
      {
        transition: 'all 150ms ease-in-out',
        height: hasLabel ? 64 : 55,
      }
    ),
  ({ containerStyle }) => containerStyle
)

const InputContainer = styled.div(
  s('relative h-full flex-1', {
    overflow: 'hidden',
  })
)

const Label = styled.label(
  s('absolute text-grey-500 text-base', {
    bottom: '22px',
    left: 0,
    pointerEvents: 'none',
    transition: 'transform 150ms ease-in-out ',
  })
)

const InputField = styled.input(
  s('border-0 h-full w-full bg-transparent text-grey-900 pb-2 text-base', {
    '&::placeholder': s('text-transparent', {
      userSelect: 'none',
    }),
    '&:focus, &:not(:placeholder-shown)': {
      outline: 'none',
      '& + label': {
        transform: 'translateY(-11px)',
      },
    },
  }),
  ({ hasLabel }) => (hasLabel ? s('pt-8') : s('pt-0'))
)

const IconContainer = styled.div(
  s('ml-1', {
    cursor: 'pointer',
  })
)

function Input({
  label,
  name,
  value,
  type = 'text',
  disabled = false,
  readonly = false,
  onInputChange,
  children,
  Icon,
  containerStyle,
  inputProps,
  testId,
  ...otherProps
}) {
  const hasLabel = isNotNilOrEmpty(label)

  return (
    <Root hasLabel={hasLabel} containerStyle={containerStyle} {...otherProps}>
      <InputContainer>
        <InputField
          data-test-id={testId}
          placeholder="placeholder"
          name={name}
          onChange={onInputChange}
          hasLabel={hasLabel}
          value={value}
          type={type}
          disabled={disabled || readonly}
          {...inputProps}
        />
        <Label>{label}</Label>
      </InputContainer>

      {Icon && <IconContainer>{Icon}</IconContainer>}

      {children}
    </Root>
  )
}

Input.propTypes = {
  label: string,
  name: string,
  value: string,
  type: string,
  disabled: bool,
  readonly: bool,
  onInputChange: func,
  Icon: object,
  containerStyle: object,
  inputProps: object,
}

export { Input }
