import React, { useEffect } from 'react'
import { s, styled } from '@vega/styled/v2'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import {
  saveApplication,
  selectIsSaveApplicationPending,
  selectIsSaveApplicationFulfilled,
  selectIsSaveApplicationRejected,
} from 'modules/application'
import { Button, toast } from '@vega/components'
import * as Yup from 'yup'
import { householdSchema } from 'features/customer/applications/applicants/HouseholdCard'
import { assetSchema } from 'features/customer/applications/financialPosition/AssetCard'
import { realEstateAssetSchema } from 'features/customer/applications/financialPosition/RealEstateAssetCard'
import { liabilitiesSchema } from 'features/customer/applications/financialPosition/LiabilityCard'
import { applicantSchema } from 'features/customer/applications/applicants/ApplicantCard'
import { fundingSchema } from 'features/customer/applications/fundingDetails/FundingCard'
import { objectivesSchema } from 'features/customer/applications/objectives/index'

const NavLink = styled(Link)(
  s(
    'text-primary border-solid border-primary rounded-lg p-2 border-2 font-semibold bg-white',
    {
      textDecoration: 'none',
    }
  )
)

const SaveButton = styled(Button)(
  s('text-white bg-primary rounded-lg p-2 font-semibold mx-2', {
    '&:hover:not([disabled])': s('bg-primary', {}),
  })
)

const getValidationSchema = (currentStep) => {
  switch (currentStep) {
    case 1:
      return Yup.object({
        households: Yup.array().of(householdSchema),
        applicants: Yup.array().of(applicantSchema),
      })
    case 2:
      return Yup.object({
        financialPosition: Yup.object({
          assets: Yup.array().of(assetSchema),
          realEstateAssets: Yup.array().of(realEstateAssetSchema),
          liabilities: Yup.array().of(liabilitiesSchema),
        }),
      })
    case 3:
      return Yup.object({
        fundingDetails: Yup.object({
          availableFunds: Yup.array().of(fundingSchema),
        }),
      })
    case 4:
      return Yup.object({
        declarations: objectivesSchema,
      })
    default:
      return Yup.object({})
  }
}

const WizardButtons = ({ backRoute, nextRoute, currentStep }) => {
  const { id } = useParams()
  const dispatch = useDispatch()

  const application = useSelector((state) => state.application.entities[id])
  const isSaveApplicationPending = useSelector(selectIsSaveApplicationPending)
  const isSaveApplicationFulfilled = useSelector(selectIsSaveApplicationFulfilled)
  const isSaveApplicationRejected = useSelector(selectIsSaveApplicationRejected)
  let validationResult = true

  const validationSchema = getValidationSchema(currentStep)

  try {
    validationResult = validationSchema.validateSync(application, {
      abortEarly: false,
    })
  } catch (e) {
    validationResult = false
    // console.log('validation error >>> ', e.errors)
  }

  const save = () => {
    dispatch(saveApplication(application))
  }

  useEffect(() => {
    if (isSaveApplicationFulfilled && validationResult) {
      toast('✅ Application saved successful')
    }

    if (isSaveApplicationRejected) {
      toast('🚫 Application failed to save')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaveApplicationFulfilled, isSaveApplicationRejected])

  return (
    <>
      <div
        style={s('flex justify-center py-8 sticky bg-primary-background ', {
          bottom: 0,
          border: '15px solid rgba(241, 241, 232)',
          boxShadow:
            '15px 0 0 -5px rgba(241, 241, 232), -15px 0 0 -5px rgba(241, 241, 232)',
        })}
      >
        <NavLink to={backRoute} onClick={save}>
          &lt; Back
        </NavLink>
        <SaveButton
          onClick={save}
          loading={isSaveApplicationPending}
          disabled={validationResult === false}
        >
          Save & Continue Later
        </SaveButton>
        <NavLink to={nextRoute} onClick={save} disabled={validationResult === false}>
          Next &gt;{' '}
        </NavLink>
      </div>
      <div style={s('h-1')}> </div>
    </>
  )
}

export default WizardButtons
