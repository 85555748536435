import React from 'react'
import { Link as LinkBase, useLocation } from 'react-router-dom'
import { styled, s } from '@vega/styled'
import { includes } from '@neo/ramda-extra'

const Link = styled(LinkBase, {
  shouldForwardProp: (prop) => prop !== 'active',
})(
  s('p-2 py-3 flex flex-row items-center'),
  s('rounded-tl-lg rounded-b-lg text-green-800 no-decoration'),
  ({ active }) => active && s('bg-green-100', { pointerEvents: 'none' })
)
const IconContainer = styled.div(s('flex flex-row self-center mr-3'))
const Text = styled.p(
  s('m-0 pb-1px text-base font-medium text-grey-600'),
  ({ active }) => active && s('text-green-800')
)

function DrawerItem({ name, path, Icon, ...props }) {
  const { pathname } = useLocation()
  const active = includes(path, pathname)

  const iconStroke = (active ? s('text-green-700') : s('text-grey-500')).color

  return (
    <Link active={active} key={name} to={path} {...props}>
      <IconContainer>
        {<Icon stroke={iconStroke} strokeWidth={2} width={24} height={24} />}
      </IconContainer>

      <Text active={active}>{name}</Text>
    </Link>
  )
}

export { DrawerItem }
