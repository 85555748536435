/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react'
// import { map } from '@neo/ramda-extra'
import { s, styled } from '@vega/styled/v2'
import { Tooltip } from '@mui/material'
import { formatDateString } from 'utils/formatters'
import { isNilOrEmpty } from '@solta/ramda-extra'
import { ApplicationStatus } from 'components/ApplicationStatus'

const headerStyleBase = (customStyles) =>
  s(' inline-block text-grey-600 text-left text-xs font-semibold', {
    ...customStyles,
  })

const cellStyleBase = (customStyles) =>
  s(' inline-block text-grey-800 text-sm text-left flex items-center', {
    ...customStyles,
  })

const TextOverflowEllipsis = styled.span({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
})

const buildApplicantsNames = (application = {}) => {
  if (isNilOrEmpty(application)) return '-'

  const individualNames = application.households.flatMap((household) => {
    const { members } = household
    if (isNilOrEmpty(members)) return '-'

    return members.map((member) => `${member.firstName} ${member.lastName}`).join(' , ')
  })

  const trustAndCompanyNames = application?.applicants?.map(
    (applicant) => applicant.trustName || applicant.businessName
  )

  const MAX_AMOUNT_OF_DISPLAY_APPLICANTS = 3

  const applicantsNames = individualNames
    .concat(trustAndCompanyNames)
    .filter((value) => value !== '-')
    ?.slice(0, MAX_AMOUNT_OF_DISPLAY_APPLICANTS)
    ?.join(', ')

  const shouldAppendEllipsis =
    individualNames.concat(trustAndCompanyNames).length >
    MAX_AMOUNT_OF_DISPLAY_APPLICANTS
  if (shouldAppendEllipsis) return `${applicantsNames}...`

  return applicantsNames
}

export const Columns = () => {
  return React.useMemo(
    () => [
      {
        Header: 'Added Date',
        accessor: (row) => formatDateString(row.createdAt),
        tableHeaderStyles: headerStyleBase({ width: '15%' }),
        tableCellStyles: cellStyleBase({ width: '15%' }),
        Cell: ({ value }) => (
          <Tooltip title={value}>
            <TextOverflowEllipsis>{value}</TextOverflowEllipsis>
          </Tooltip>
        ),
      },
      {
        Header: 'User',
        accessor: 'userFullName',
        tableHeaderStyles: headerStyleBase({ width: '25%' }),
        tableCellStyles: cellStyleBase({ width: '25%' }),
        Cell: ({ value }) => (
          <Tooltip title={value}>
            <TextOverflowEllipsis>{value}</TextOverflowEllipsis>
          </Tooltip>
        ),
      },
      {
        Header: 'Application Number',
        accessor: 'applicationNumber',
        tableHeaderStyles: headerStyleBase({ width: '25%' }),
        tableCellStyles: cellStyleBase({ width: '25%' }),
        Cell: ({ value }) => (
          <Tooltip title={value}>
            <TextOverflowEllipsis>{value}</TextOverflowEllipsis>
          </Tooltip>
        ),
      },
      {
        Header: 'Applicants',
        accessor: 'applicantsName',
        tableHeaderStyles: headerStyleBase({ width: '40%' }),
        tableCellStyles: cellStyleBase({ width: '40%' }),
        Cell: ({ value }) => (
          <Tooltip title={value}>
            <TextOverflowEllipsis>{value}</TextOverflowEllipsis>
          </Tooltip>
        ),
      },
      {
        Header: 'Status',
        accessor: 'applicationStatus',
        tableHeaderStyles: headerStyleBase({ width: '20%' }),
        tableCellStyles: cellStyleBase({ width: '20%' }),
        Cell: ({ value }) => <ApplicationStatus applicationStatus={value} />,
      },
    ],
    []
  )
}

export const TableData = ({ applications, users }) => {
  // console.log('applications', applications)
  return React.useMemo(
    () =>
      applications?.map((application) => {
        const applicantsName = buildApplicantsNames(application)
        const userFullName = users?.find(({ id }) => id === application?.createdBy)
          .fullName
        return {
          ...application,
          id: application._id,
          applicantsName,
          userFullName,
        }
      }, applications),
    [applications, users]
  )
}
