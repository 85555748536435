import { defaultTo, isNotNil } from '@neo/ramda-extra'

export const normalizeError = async (err) => {
  const isCustomError = isNotNil(err?.isCustomError)
  const isServerError = isNotNil(err?.options?.prefixUrl)
  const isDev = process.env.NODE_ENV === 'development'

  if (isCustomError) return err

  if (isServerError) {
    const errorFromHttpClient = await err.response.json()
    /* add error into default as the err response will have error property
    and createCustomError expects to receive an error property */
    const defaultServerError = {
      name: 'UnknownError',
      error: 'UnknownError',
      message: 'Something went wrong',
    }
    const serverError = defaultTo(defaultServerError, errorFromHttpClient)

    if (isDev) logErrorBeforeReturn(serverError)

    return serverError
  }

  if (isDev) {
    logErrorBeforeReturn(err)
  }

  return {
    name: 'RuntimeError',
    error: 'RuntimeError',
    message: 'Something went wrong',
  }
}

function logErrorBeforeReturn(err) {
  // eslint-disable-next-line no-console
  console.log(err)
}
