const LOAN_OVERVIEW = {
  LOAN_STATUS: 'banking-loan-details-loan-status',
  LOAN_GROUP_NO: 'banking-loan-group-no',
  LOAN_NO: 'banking-loan-no',
  NUMBER_OF_ENTITIES: 'banking-loan-details-no-entities',
  PRIMARY_ENTITY_NAME: 'banking-loan-details-primary-entity',
  ENTITIY_DETAIL_VIEW_BTN: 'banking-loan-details-entity-detail-view-btn',
  TOTAL_SECURITY_VALUE: 'banking-loan-details-total-security-value',
  NUMBER_OF_SECURITIES: 'banking-loan-details-number-of-securities',
  SECURITY_DETAIL_VIEW_BTN: 'banking-loan-details-security-detail-view-btn',
  LOAN_SIZE: 'banking-loan-details-loan-size',
  CURRENT_LOAN_BALANCE: 'banking-loan-details-current-balance',
  REPAYMENT_AMOUNT: 'banking-loan-details-repayment-amount',
  ADD_NEW_ENTRY_BTN: 'banking-loan-details-add-new-entry-btn',
  APPROVE_LOAN_BTN: 'banking-loan-details-approve-loan-btn',
  APPROVE_LOAN_MODAL_CLOSE: 'banking-loan-details-approve-modal-close',
  APPROVE_CONFIRM_INPUT: 'banking-loan-details-approve-confirm-input',
  APPROVE_ALL_LOANS_BTN: 'banking-loan-details-approve-all-loans-btn',
  TRANSACTION_HISTORY_TABLE: 'banking-loan-details-transaction-history-table',
}

const ENTITIY_DETAILS = {
  ENTITIY_CUSTOMER_NO: 'banking-entity-details-customer-no',
  ENTITY_NAV_BTN: 'banking-entity-details-entity-nav-btn',
  TRUSTS_NAV_BTN: 'banking-entity-details-trusts-nav-btn',
  BUSINESS_NAV_BTN: 'banking-entity-details-business-nav-btn',
}

const PRODUCT = {
  PRODUCT_DETAILS_NAME_INPUT: 'banking-product-details-name-input',
}

const ACCOUNT = {
  ACCOUNT_FIRST_NAME_INPUT: 'banking-account-first-name-input',
  ACCOUNT_EMAIL_INPUT: 'banking-account-email-input',
}

export default {
  ...LOAN_OVERVIEW,
  ...ENTITIY_DETAILS,
  ...PRODUCT,
  ...ACCOUNT,
}
