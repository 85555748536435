import React from 'react'
import { s } from '@vega/styled/v2'
import Card from 'components/Card'

const NoApplicationsCard = () => {
  return (
    <Card>
      <h2>No applications</h2>
      <p style={s('text-lg text-primary')}>
        You currently have no applications, please start an application to continue.
      </p>
    </Card>
  )
}

export default NoApplicationsCard
