import React from 'react'
import { s } from '@vega/styled/v2'
import { Chip, TableCell } from 'components/common'
import { formatCurrency } from 'utils/formatters'

// TODO: move to utils
const calcualteMonthlyAmount = (amount, frequency) => {
  switch (frequency) {
    case 'Yearly':
      return amount / (365 / 12)

    case 'Monthly':
      return amount

    case 'Weekly':
      return amount * (52 / 12)

    default:
      return amount
  }
}

const HouseholdRow = ({ household }) => {
  const householdName = household.members.map((member) => member.firstName).join(' & ')

  const househouseEmploymentIncome = household.members
    .flatMap((member) =>
      member.employments.map((employment) =>
        calcualteMonthlyAmount(employment.amount, employment.frequency)
      )
    )
    .reduce((acc, curr) => acc + curr, 0)

  const househouseOtherIncome = household.members
    .flatMap((member) =>
      member.otherIncomes.map((otherIncome) =>
        calcualteMonthlyAmount(otherIncome.amount, otherIncome.frequency)
      )
    )
    .reduce((acc, curr) => acc + curr, 0)

  const householdExpenses = household.expenseGroups
    .flatMap((expenseGroup) =>
      expenseGroup.expenses.map((expense) =>
        calcualteMonthlyAmount(expense.amount, expense.frequency)
      )
    )
    .reduce((acc, curr) => acc + curr, 0)

  const monthlyHouseholdIncome = househouseEmploymentIncome + househouseOtherIncome

  const dependants = household.numberOfDependants

  return (
    <tr>
      <TableCell style={s('text-left')}>
        <Chip>Household</Chip>
      </TableCell>
      <TableCell style={s('text-left')}>{householdName}</TableCell>
      <TableCell style={s('text-left')}>
        {`${formatCurrency(monthlyHouseholdIncome)} - Monthly income`}
      </TableCell>
      <TableCell style={s('text-left')}>
        {`${formatCurrency(householdExpenses)} - Monthly expenses`}
      </TableCell>
      <TableCell style={s('text-left')}>{`${dependants} dependants`}</TableCell>
    </tr>
  )
}

export default HouseholdRow
