import React, { useState } from 'react'
import { s, styled } from '@vega/styled/v2'
import { ContentCard as ContentCardBase, SubTitle } from 'components/common'
import { SupportingDoc } from './SupportingDoc'
import { UploadSupportingDoc } from './UploadSupportingDoc'

const Container = styled.div({ marginBottom: 52 })
const ContentCard = styled(ContentCardBase)(s('py-6 px-5'))
const FileSection = styled.div()
const LabelHeader = styled.div(s('flex justify-between mb-8'))
const SectionLabel = styled.p(
  s('m-0 mb-8 text-grey-900 text-xs font-semibold', {
    letterSpacing: '0.01em',
    lineHeight: 1.5,
    marginRight: '5px',
  })
)

const DocumentRow = styled.div(s('flex flex-wrap justify-between'))

const UploadButton = styled.button(
  s(
    'bg-white rounded-lg py-2 text-xs font-semibold mb-1 border-0 shadow-lg uppercase h-1/3',
    { cursor: 'pointer' }
  )
)

export const SupportingDocs = ({
  documents = [],
  applicantId = [],
  applicantType = 'person',
  ...props
}) => {
  const [isModalOpened, setIsModalOpened] = useState(false)

  const openModal = () => setIsModalOpened(true)
  const closeModal = () => setIsModalOpened(false)

  return (
    <>
      <Container id="supporting-docs" {...props}>
        <SubTitle style={s('mb-2')}>Supporting Docs</SubTitle>
        <p
          style={s('m-0 mb-2 text-grey-900 text-xs', {
            letterSpacing: '0.01em',
            lineHeight: 1.5,
            marginRight: '5px',
          })}
        >
          *Providing full and complete supporting documentation will help ensure a
          speedy assessment of your application.
        </p>
        <ContentCard
          style={s(
            'rounded-lg bg-grey-100 border-1 border-grey-200 bg-primary-background'
          )}
        >
          <FileSection>
            <LabelHeader>
              <SectionLabel style={s('font-normal mb-0 text-grey-800')}>
                For each applicant please make sure supporting documents include:
                <br />
                • Proof of identity (e.g. drivers licence and/or passport)
                <br />
                • Proof of employment and income (e.g. employment contract, pay slips,
                etc)
                <br />• Proof of organisation (e.g. trust deed, etc)
              </SectionLabel>
              <UploadButton onClick={openModal}>Upload</UploadButton>
            </LabelHeader>

            <DocumentRow>
              {documents.map((document, index) => (
                <SupportingDoc style={s('m-2')} document={document} key={index} />
              ))}
            </DocumentRow>
          </FileSection>
        </ContentCard>
      </Container>

      <UploadSupportingDoc
        closeModal={closeModal}
        isOpen={isModalOpened}
        applicantId={applicantId}
        applicantType={applicantType}
      />
    </>
  )
}

export default SupportingDocs
