import React from 'react'
import { SelectField, NumberField, DateField } from '@vega/components'
import { s } from '@vega/styled/v2'

import {
  SubTitle,
  FieldContainer,
  SectionContainer,
  CollapseButton,
} from 'components/common'
import { OwnerShip } from 'features/common/OwnerShip'
import { ValidatedSelectField } from 'components/SelectField'
import Checkbox from '@mui/material/Checkbox'

// TODO: move to utils
const paymentFrequencies = [
  'Daily',
  'Fortnightly',
  'Half Yearly',
  'Monthly',
  'One Off',
  'Quarterly',
  'Seasonal',
  'Weekly',
  'Yearly',
]

const generateTimeframeOptions = (length, unit) =>
  [...Array(length + 1).keys()].map((timeframe) => ({
    key: timeframe,
    label: `${timeframe} ${unit}`,
  }))

const yearFields = generateTimeframeOptions(30, 'years')
const monthFields = generateTimeframeOptions(12, 'months')

const yesNoOptions = [
  { key: 'yes', label: 'Yes' },
  { key: 'no', label: 'No' },
]

const paymentTypes = ['Principal and Interest', 'Interest Only']

const financialInstitutions = [
  'ANZ Bank',
  'ASB Bank',
  'Bank of Baroda',
  'Bank of China',
  'Bank of India',
  'Bank of New Zealand',
  'Bank of Tokyo-Mitsubishi',
  'Brighten Home Loans',
  'China Construction Bank',
  'Citibank',
  'Citywide Home Loans',
  'Commonwealth Bank',
  'Deutsche Bank',
  'Heartland Bank',
  'International Commercial Bank of China',
  'JPMorgan Chase Bank',
  'Kiwibank',
  'Kookmin Bank',
  'Other',
  'Pepper New Zealand',
  'Rabobank Nederland',
  'Rabobank New Zealand',
  'Southland Building Society',
  'TSB Bank',
  'The Co-operative Bank',
  'The Hongkong and Shanghai Banking Corporation',
  'Westpac Bank',
]

// TODO: remove key and move this to utils someplace
const liabilityTypeListValues = [
  { key: 'amortisingHomeLoan', label: 'Amortising Home Loan' },
  { key: 'bridgingFinance', label: 'Bridging Finance' },
  { key: 'carLoan', label: 'Car Loan' },
  { key: 'commercialBill', label: 'Commercial Bill' },
  { key: 'contingentLiability', label: 'Contingent Liability' },
  { key: 'creditCard', label: 'Credit Card' },
  { key: 'hecsHelp', label: 'HECS-HELP' },
  { key: 'hirePurchase', label: 'Hire Purchase' },
  { key: 'invoiceFinancingLoan', label: 'Invoice Financing Loan' },
  { key: 'lease', label: 'Lease' },
  { key: 'lineOfCredit', label: 'Line of Credit' },
  { key: 'lineOfCreditHomeLoan', label: 'Line of Credit Home Loan' },
  { key: 'loanAsGuarantor', label: 'Loan as Guarantor' },
  { key: 'marginLoan', label: 'Margin Loan' },
  { key: 'mortgageLoan', label: 'Mortgage Loan' },
  { key: 'other', label: 'Other' },
  { key: 'otherLoan', label: 'Other Loan' },
  { key: 'outstandingTaxation', label: 'Outstanding Taxation' },
  { key: 'overdraft', label: 'Overdraft' },
  { key: 'personalLoan', label: 'Personal Loan' },
  { key: 'storeCard', label: 'Store Card' },
  { key: 'studentLoan', label: 'Student Loan' },
  { key: 'termLoan', label: 'Term Loan' },
  { key: 'tradeFinanceLoan', label: 'Trade Finance Loan' },
]

const hasTermDetails = (liabilityType) => {
  const liabilityTypesWithTermDetails = [
    'Line of Credit',
    'Loan as Guarantor',
    'Mortgage',
    'Personal loan',
    'Car Loan',
    'Home Loan',
    'Business Loan',
  ]

  return liabilityTypesWithTermDetails.includes(liabilityType)
}

// eslint-disable-next-line complexity
const LiabalityFields = ({
  handleBlur,
  handleChange,
  values,
  applicants,
  setFieldValue,
  errors,
}) => (
  <SectionContainer>
    <SubTitle>Liability details</SubTitle>

    <FieldContainer>
      <ValidatedSelectField
        name="liabilityType"
        label="Liabality type*"
        options={liabilityTypeListValues.map((status) => ({
          label: status.label,
          value: status.label,
          key: status.label,
        }))}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={'Liabality type'}
        selectMenuStyles={{
          height: '300px',
          overflowY: 'scroll',
          scrollbarWidth: 'auto',
          '&::-webkit-scrollbar': {
            width: '15px',
            height: 0,
          },
          '&::-webkit-scrollbar-track': {
            background: '#e0e0e0', // Color of the track
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#888', // Color of the thumb
          },
        }}
      />
    </FieldContainer>

    <SubTitle style={s('text-base font-bold text-grey-800')}>Ownership</SubTitle>
    {errors.ownerships && typeof errors.ownerships === 'string' && (
      <div style={{ color: 'red' }}>{errors.ownerships}</div>
    )}
    {values.ownerships.map((ownership, index) => {
      return (
        <OwnerShip
          applicants={applicants}
          handleBlur={handleBlur}
          handleChange={handleChange}
          values={values}
          index={index}
          key={index}
          setFieldValue={setFieldValue}
        />
      )
    })}

    {values.ownerships.length < 2 && (
      <CollapseButton
        onClick={() =>
          setFieldValue('ownerships', [
            ...values.ownerships,
            { percentage: 0, owner: '' },
          ])
        }
        style={s('mb-2')}
      >
        + Add Owner
      </CollapseButton>
    )}

    <FieldContainer>
      <ValidatedSelectField
        name="creditor"
        label="Creditor*"
        options={financialInstitutions.map((institute) => ({
          label: institute,
          value: institute,
          key: institute,
        }))}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={'Creditor'}
        selectMenuStyles={{
          height: '300px',
          overflowY: 'scroll',
          scrollbarWidth: 'auto',
          '&::-webkit-scrollbar': {
            width: '15px',
            height: 0,
          },
          '&::-webkit-scrollbar-track': {
            background: '#e0e0e0', // Color of the track
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#888', // Color of the thumb
          },
        }}
      />
    </FieldContainer>

    <FieldContainer>
      <ValidatedSelectField
        name="isClearingFromThisLoan"
        label="Clearing from this loan?*"
        options={yesNoOptions.map((status) => ({
          label: status.label,
          value: status.label,
          key: status.label,
        }))}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={'Clearing from this loan?'}
      />
    </FieldContainer>

    {hasTermDetails(values.liabilityType) && (
      <>
        <SubTitle style={s('mb-2')}>Term details</SubTitle>

        <FieldContainer>
          <DateField
            name="loanStartDate"
            label="Loan start date"
            placeholder="DD/MM/YYYY"
            containerStyle={s('border-grey-200')}
            style={s('text-sm')}
            inputFormat="dd/MM/yyyy"
            labelProps={{ style: s('tracking-normal mb-0 text-grey-800') }}
          />
        </FieldContainer>

        <FieldContainer style={s('w-full')}>
          <div style={s('flex')}>
            <SelectField
              name="loanTermYears"
              label="Loan term years"
              style={s('mr-2')}
              options={yearFields.map((yearField) => ({
                label: yearField.label,
                value: yearField.key,
                key: yearField.key,
              }))}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={'Loan term years'}
            />
            <SelectField
              name="loanTermMonths"
              label="Loan term months"
              options={monthFields.map((monthField) => ({
                label: monthField.label,
                value: monthField.key,
                key: monthField.key,
              }))}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={'Loan term months'}
            />
          </div>
        </FieldContainer>

        <FieldContainer>
          <DateField
            name="interestOnlyStartDate"
            label="Interest only start date"
            placeholder="DD/MM/YYYY"
            containerStyle={s('border-grey-200')}
            style={s('text-sm')}
            inputFormat="dd/MM/yyyy"
            labelProps={{ style: s('tracking-normal mb-0 text-grey-800') }}
          />
        </FieldContainer>

        <FieldContainer style={s('w-full')}>
          <div style={s('flex')}>
            <SelectField
              name="interestOnlyTermYears"
              label="Interest only term years"
              style={s('mr-2')}
              options={yearFields.map((yearField) => ({
                label: yearField.label,
                value: yearField.key,
                key: yearField.key,
              }))}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={'Interest only term years'}
            />
            <SelectField
              name="interestOnlyTermMonths"
              label="Interest only term months"
              options={monthFields.map((monthField) => ({
                label: monthField.label,
                value: monthField.key,
                key: monthField.key,
              }))}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={'Interest only term months'}
            />
          </div>
        </FieldContainer>

        <FieldContainer>
          <SelectField
            name="isRevolvingCreditRedraw"
            label="Revolving credit/ redraw"
            options={yesNoOptions.map((status) => ({
              label: status.label,
              value: status.label,
              key: status.label,
            }))}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={'Revolving credit/ redraw'}
          />
        </FieldContainer>
      </>
    )}

    <SubTitle style={s('mb-2')}>Payment details</SubTitle>

    <FieldContainer>
      <NumberField
        name="currentBalance"
        label="Current balance*"
        placeholder="Current balance"
        aria-label="Current balance"
        thousandSeparator
        prefix="$"
        onChange={handleChange}
        onBlur={handleBlur}
        value={Number.isNaN(values.currentBalance) ? '' : values.currentBalance}
        labelStyle={s('text-base font-normal text-grey-800')}
      />
    </FieldContainer>

    <FieldContainer>
      <NumberField
        name="limit"
        label="Limit*"
        placeholder="Limit"
        thousandSeparator
        prefix="$"
        onChange={handleChange}
        onBlur={handleBlur}
        value={Number.isNaN(values.limit) ? '' : values.limit}
        labelStyle={s('text-base font-normal text-grey-800')}
      />
    </FieldContainer>

    {values.liabilityType === 'Credit Card' && (
      <FieldContainer>
        <div style={s('flex items-center')}>
          <Checkbox
            name="isAutoPayment"
            checked={values.isAutoPayment}
            onChange={(event) => {
              const isChecked = event.target.checked
              if (isChecked) {
                setFieldValue('repaymentUnit', 'Monthly')
              }
              handleChange(event)
            }}
            sx={s('', {
              padding: '0 6px 0 0',
              '&.Mui-checked': {
                color: '#00FFCD',
              },
              '&:hover': {
                backgroundColor: 'transparent',
              },
            })}
            size="small"
          />
          <label htmlFor="isAutoPayment" style={s('font-semibold')}>
            Card Balance is Auto-paid
          </label>
        </div>
      </FieldContainer>
    )}

    <FieldContainer>
      <div style={s('flex ')}>
        <NumberField
          key={values.isAutoPayment ? 'auto' : 'manual'}
          name="repaymentAmount"
          label={
            values.isAutoPayment
              ? 'Estimate Typical Auto-Payment Amount*'
              : 'Repayment amount*'
          }
          placeholder="Repayment"
          thousandSeparator
          prefix="$"
          onChange={handleChange}
          onBlur={handleBlur}
          value={Number.isNaN(values.repaymentAmount) ? '' : values.repaymentAmount}
          labelStyle={s('text-base font-normal text-grey-800')}
        />

        <ValidatedSelectField
          name="repaymentUnit"
          label={
            values.isAutoPayment
              ? 'Repayment frequency - Auto Paid*'
              : 'Repayment frequency*'
          }
          style={s('ml-2')}
          options={
            values.isAutoPayment
              ? [{ label: 'Auto paid', value: 'Monthly', key: 'Monthly' }]
              : paymentFrequencies.map((frequency) => ({
                  label: frequency,
                  value: frequency,
                  key: frequency,
                }))
          }
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={
            values.isAutoPayment ? 'Auto Paid' : 'How often is the repayment?'
          }
          disabled={values.isAutoPayment}
        />
      </div>
    </FieldContainer>

    <FieldContainer>
      <NumberField
        name="interestRate"
        label="Interest rate*"
        placeholder="Interest rate"
        suffix="%"
        onChange={handleChange}
        onBlur={handleBlur}
        value={Number.isNaN(values.interestRate) ? '' : values.interestRate}
        labelStyle={s('text-base font-normal text-grey-800')}
      />
    </FieldContainer>

    {values.liabilityType !== 'Credit Card' && (
      <>
        <FieldContainer>
          <ValidatedSelectField
            name="isTaxDeductible"
            label="Tax deductible?"
            options={yesNoOptions.map((status) => ({
              label: status.label,
              value: status.label,
              key: status.label,
            }))}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={'Tax deductible?'}
          />
        </FieldContainer>

        <FieldContainer>
          <ValidatedSelectField
            name="hasArrears"
            label="In arrears?"
            options={yesNoOptions.map((status) => ({
              label: status.label,
              value: status.label,
              key: status.label,
            }))}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={'In arrears?'}
          />
        </FieldContainer>

        <FieldContainer>
          <ValidatedSelectField
            name="paymentType"
            label="Payment type"
            options={paymentTypes.map((paymentType) => ({
              label: paymentType,
              value: paymentType,
              key: paymentType,
            }))}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={'Payment type'}
          />
        </FieldContainer>
      </>
    )}
  </SectionContainer>
)

export default LiabalityFields
