import React from 'react'
import PropTypes from 'prop-types'
import { styled, s } from '@vega/styled'
import { Link } from 'react-router-dom'

import neoLogo from '../assets/images/neo-logo.png'

const { object } = PropTypes

const Container = styled.div(s('flex flex-column h-full shadow-md bg-white z-1'), {
  minWidth: 220,
})

const Img = styled.img(s('w-5'))
const ImageContainer = styled(Link)(
  s('w-full mt-8 mb-12 no-decoration flex justify-center self-center')
)

const ItemList = styled.div(s('flex-1 flex flex-column px-3'), {
  '& > :not(:last-child)': s('mb-3'),
})

const NavigationDrawer = ({ homeRoute, children, ...otherProps }) => {
  return (
    <Container {...otherProps}>
      <ImageContainer to={homeRoute}>
        <Img src={neoLogo} />
      </ImageContainer>

      <ItemList>{children}</ItemList>
    </Container>
  )
}

NavigationDrawer.propTypes = {
  otherProps: object,
}

export { NavigationDrawer }
