/* eslint-disable complexity */
/* eslint-disable react/no-multi-comp */
import React from 'react'
import { s, styled } from '@vega/styled/v2'
import { Button } from '@vega/components'
import AvailableFunds from './AvailableFunds'
import { Chip, TableCell, SubTitle } from 'components/common'
import { Link, useParams } from 'react-router-dom'
import { useLargeMediaQuery, Row, Col, Container } from '@vega/styled'
import { formatCurrency } from 'utils/formatters'
import Card from 'components/Card'

const OuterContainer = styled.div(
  s(
    'bg-white flex flex-row justify-between w-full border-0 border-b-1 border-solid border-grey-200',
    {
      '&:last-child': {
        borderColor: 'transparent',
      },
    }
  )
)
const Root = styled.div(
  s(
    'bg-white flex flex-row justify-between w-full border-0 border-b-1 border-solid border-grey-200 mt-4 mb-4 ',
    {
      '&:last-child': {
        borderColor: 'transparent',
      },
      ':hover': s('bg-secondary rounded-lg', { cursor: 'pointer' }),
    }
  )
)

const Applicants = styled.div(s('text-primary font-semibold text-base mt-1'))

const ExtraLoanInfo = styled.div(s('text-base text-primary'))

const NoPropertyCard = ({ propertyType }) => (
  <>
    <SubTitle> No {propertyType} properties have been specified</SubTitle>
    <p>
      Properties can be specified in the application financial position or funding
      details sections.
    </p>
  </>
)

const FundingDetails = ({ application }) => {
  const { id } = useParams()
  const isLargeScreen = useLargeMediaQuery('larger')

  const {
    financialPosition: { realEstateAssets },
    applicants,
    households,
  } = application

  const householdApplicants = households.flatMap((household) => household.members)
  const allApplicants = householdApplicants.concat(applicants)

  const purchasingProperties = realEstateAssets.filter(
    (realEstateAsset) => realEstateAsset.transaction === 'Purchasing'
  )

  const refinancingProperties = realEstateAssets.filter(
    (realEstateAsset) => realEstateAsset.transaction === 'Owns Existing Mortgage'
  )

  const securityProperties = realEstateAssets.filter(
    (realEstateAsset) => realEstateAsset.isUsedAsSecurity === true
  )

  const ownedProperties = application.financialPosition.realEstateAssets.filter(
    (realEstateAsset) =>
      realEstateAsset.isUsedAsSecurity === false &&
      realEstateAsset.transaction === 'Owns'
  )

  const rowsForProperties = (properties) =>
    properties.map((realEstateAsset) => {
      const sectionId = realEstateAsset._id
      const ownersName = allApplicants
        .filter((owner) =>
          realEstateAsset.ownerships
            .map((ownership) => ownership.owner)
            .includes(owner._id)
        )
        .map((applicant) => `${applicant.firstName}`)
        .join(' & ')

      if (!isLargeScreen) {
        return (
          <OuterContainer>
            <Root>
              <Container gutter={0}>
                <Row gutter={[0, 0]} style={s('p-2')}>
                  <Col span={18}>
                    <Applicants className="sohne">
                      {realEstateAsset.address.propertyAddress}
                    </Applicants>
                    <Chip>{realEstateAsset.primaryPurpose}</Chip>
                    <ExtraLoanInfo>- {realEstateAsset.primaryUsage}</ExtraLoanInfo>
                    <ExtraLoanInfo>- Owners: {ownersName}</ExtraLoanInfo>
                    <ExtraLoanInfo>
                      - {formatCurrency(realEstateAsset.value)}
                    </ExtraLoanInfo>
                  </Col>
                  <Col span={6}>
                    <div
                      style={s('flex items-center justify-end ml-auto', {
                        marginTop: '45px',
                      })}
                    >
                      <Link
                        style={s('', { textDecoration: 'none' })}
                        to={
                          realEstateAsset.transaction !== 'Owns'
                            ? `/customer/application/${id}/funding-details?scrollTo=${sectionId}`
                            : `/customer/application/${id}/financial-position?scrollTo=${sectionId}`
                        }
                      >
                        <Button variant="outlined">View/Edit</Button>
                      </Link>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Root>
          </OuterContainer>
        )
      }

      return (
        <tr
          key={realEstateAsset._id}
          style={s('flex items-center justify-between mb-4')}
        >
          <TableCell>
            <Chip>{realEstateAsset.primaryPurpose}</Chip>
          </TableCell>
          <TableCell>{realEstateAsset.address.propertyAddress}</TableCell>
          <TableCell>{realEstateAsset.primaryUsage}</TableCell>
          <TableCell>Owners: {ownersName}</TableCell>
          <TableCell>{formatCurrency(realEstateAsset.value)}</TableCell>
          <td>
            <div style={s('flex justify-end ml-auto mb-4')}>
              <Link
                style={s('', { textDecoration: 'none' })}
                to={
                  realEstateAsset.transaction !== 'Owns'
                    ? `/customer/application/${id}/funding-details?scrollTo=${sectionId}`
                    : `/customer/application/${id}/financial-position?scrollTo=${sectionId}`
                }
              >
                <Button variant="outlined">View/Edit</Button>
              </Link>
            </div>
          </td>
        </tr>
      )
    })

  if (
    purchasingProperties.length === 0 &&
    refinancingProperties.length === 0 &&
    securityProperties.length === 0 &&
    ownedProperties.length === 0
  ) {
    return (
      <>
        <h2>Funding details</h2>
        <Card>
          <NoPropertyCard />
        </Card>
      </>
    )
  }

  return (
    <>
      <h2>Funding details</h2>
      <h3>Property to Purchase</h3>
      <table style={s('bg-white p-6 rounded-lg shadow-lg w-full')}>
        <tbody>
          {purchasingProperties.length !== 0 ? (
            rowsForProperties(purchasingProperties)
          ) : (
            <NoPropertyCard propertyType="purchase" />
          )}
        </tbody>
      </table>

      <h3>Property to Refinance</h3>
      <table style={s('bg-white p-6 rounded-lg shadow-lg w-full')}>
        <tbody>
          {refinancingProperties.length !== 0 ? (
            rowsForProperties(refinancingProperties)
          ) : (
            <NoPropertyCard propertyType="refinance" />
          )}
        </tbody>
      </table>

      <h3>Properties for Security</h3>
      <table style={s('bg-white p-6 rounded-lg shadow-lg w-full')}>
        <tbody>
          {securityProperties.length !== 0 ? (
            rowsForProperties(securityProperties)
          ) : (
            <NoPropertyCard propertyType="security" />
          )}
        </tbody>
      </table>

      <h3>Properties Owned</h3>
      <table style={s('bg-white p-6 rounded-lg shadow-lg w-full')}>
        <tbody>
          {ownedProperties.length !== 0 ? (
            rowsForProperties(ownedProperties)
          ) : (
            <NoPropertyCard propertyType="owned" />
          )}
        </tbody>
      </table>
      <AvailableFunds application={application} />
    </>
  )
}

export default FundingDetails
