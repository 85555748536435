import React from 'react'
import { NumberField, TextField } from '@vega/components'
import { SubTitle, FieldContainer, SectionContainer } from 'components/common'
import { ValidatedSelectField } from 'components/SelectField'
import { s } from '@vega/styled/v2'

const contributionFundsTypeListValues = [
  { key: 'cash', label: 'Cash' },
  { key: 'existingEquity', label: 'Existing Equity' },
  { key: 'firstHomeOwnerGrant', label: 'First Home Owner Grant' },
  { key: 'genuineSavings', label: 'Genuine Savings' },
  { key: 'gift', label: 'Gift' },
  { key: 'inheritance', label: 'Inheritance' },
  { key: 'kaingaOraGrant', label: 'Kainga Ora Grant' },
  { key: 'kiwisaver', label: 'KiwiSaver' },
  { key: 'netProceedsFromSaleOfProperty', label: 'Net Proceeds from Sale of Property' },
  { key: 'other', label: 'Other' },
  { key: 'redraw', label: 'Redraw' },
  { key: 'saleOfNonRealEstateAsset', label: 'Sale of Non Real Estate Asset' },
  { key: 'securedVisa', label: 'Secured VISA' },
  { key: 'superannuation', label: 'Superannuation' },
  { key: 'termDeposit', label: 'Term Deposit' },
  { key: 'unsecuredBorrowing', label: 'Unsecured Borrowing' },
]

// TODO: filename
export const FundingFields = ({ handleBlur, handleChange, values }) => {
  return (
    <SectionContainer>
      <SubTitle>Funding details</SubTitle>

      <FieldContainer>
        <ValidatedSelectField
          name="fundType"
          label="Funding type?*"
          options={contributionFundsTypeListValues.map((status) => ({
            label: status.label,
            value: status.label,
            key: status.key,
          }))}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={'Fund type'}
          selectMenuStyles={{
            height: '300px',
            overflowY: 'scroll',
            scrollbarWidth: 'auto',
            '&::-webkit-scrollbar': {
              width: '15px',
              height: 0,
            },
            '&::-webkit-scrollbar-track': {
              background: '#e0e0e0', // Color of the track
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#888', // Color of the thumb
            },
          }}
        />
      </FieldContainer>

      <FieldContainer>
        <NumberField
          name="value"
          label="Value*"
          placeholder="Value"
          thousandSeparator
          prefix="$"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.value}
          labelStyle={s('text-base font-normal text-grey-800')}
        />
      </FieldContainer>

      <FieldContainer>
        <TextField
          name="description"
          label="Description*"
          placeholder="Description"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.description}
          labelStyle={s('text-base font-normal text-grey-800')}
        />
      </FieldContainer>
    </SectionContainer>
  )
}
