import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from './Tooltip'
import { styled, s } from '@vega/styled/v2'

const Container = styled.div(s('relative'))
const IconButton = styled.a(
  s('flex items-center justify-center w-3 h-3 rounded-lg mb-3', {
    cursor: 'pointer',
    backgroundColor: '#F9C2E8',
  })
)

export const NavWebsiteLink = ({ tooltipLabel, Icon, ...otherProps }) => {
  const [showTooltip, setShowTooltip] = React.useState(false)

  return (
    <Container {...otherProps}>
      <IconButton
        onPointerEnter={() => setShowTooltip(true)}
        onPointerOut={() => setShowTooltip(false)}
        href="https://www.neo.nz/"
        target="_blank"
      >
        <Icon
          stroke={s('text-primary').color}
          strokeWidth={2}
          width={24}
          height={24}
          style={s('text-primary', { pointerEvents: 'none' })}
        />
      </IconButton>

      <Tooltip isActive={showTooltip}>{tooltipLabel}</Tooltip>
    </Container>
  )
}

const { string, object } = PropTypes

NavWebsiteLink.propTypes = {
  tooltipLabel: string,
  Icon: object,
}
