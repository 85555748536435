import React from 'react'
import ReactDOM from 'react-dom'
import { prop, noop, and, isNil } from '@neo/ramda-extra'
import { ReactComponent as ChevronLeft } from '@vega/components/src/assets/images/chevron-left.svg'
import { ReactComponent as ChevronRight } from '@vega/components/src/assets/images/chevron-right.svg'
import { DefaultToggler } from './DefaultToggler'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import PropTypes from 'prop-types'
import { styled, s } from '@vega/styled'

const { object, func } = PropTypes

const Container = styled.div(s('relative inline-block'))
const PickerContainer = styled.div(
  s(
    'absolute pin-tl-0 flex flex-column justify-between justify-center bg-white border-1 border-solid border-grey-300 px-6 py-8 shadow-md',
    {
      top: 140,
      userSelect: 'none',
      width: 328,
      height: 236,
      borderRadius: 12,
      opacity: 0,
      transform: 'scale(0)',
      transition: 'transform 100ms ease-in-out, opacity 30ms ease-in-out 20ms',
    }
  ),
  ({ isOpened }) => ({
    transform: isOpened && 'scale(100%)',
    opacity: isOpened && 1,
  }),
  prop('pickerStyle')
)

const YearSelector = styled.div(s('flex justify-between items-center', { height: 24 }))
const LeftArrowBtn = styled(ChevronLeft)(s('', { cursor: 'pointer' }))
const RightArrowBtn = styled(ChevronRight)(s(''), ({ disabled }) => ({
  cursor: disabled ? 'not-allowed' : 'pointer',
}))
const DisplayYear = styled.span(s('text-grey-800 font-bold'))

const Line = styled.div(
  s('border-1 border-solid border-grey-100', {
    height: 1,
    marginTop: 14,
    marginBottom: 14,
  })
)

const MonthSelector = styled.div(
  s('grid', {
    gridAutoColumns: 70,
    gridAutoRows: 40,
    gridTemplateAreas: `
      "Jan Feb Mar Apr"
      "May Jun Jul Aug"
      "Sep Oct Nov Dec"
      `,
  })
)
const MonthBtn = styled.div(
  s('rounded-lg text-grey-800 text-sm grid', {
    cursor: 'pointer',
    placeItems: 'center',
  }),
  ({ isSelected, disabled }) => ({
    '&:hover': {
      background: and(!isSelected, !disabled) && s('bg-green-200').backgroundColor,
      color: and(!isSelected, !disabled) && 'white',
    },
  }),
  ({ isSelected }) => isSelected && s('bg-green-600 text-white'),
  ({ disabled }) => disabled && s('text-grey-500', { cursor: 'initial' }),
  prop('gridArea')
)

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

const generateMonthId = (year, month) => `${year}-${month}`

const generateRandomString = () => Math.random().toString(36).substr(2, 20)

const currentYear = new Date().getFullYear()
const currentMonth = new Date().getMonth() + 1

const CLOSE_PICKER_ANIMATION_TIME_MS = 150
const waitClosePickerAnimationFinishedThen = (actionCallback) => {
  setTimeout(() => {
    actionCallback()
  }, CLOSE_PICKER_ANIMATION_TIME_MS)
}

const MonthPicker = ({
  Toggler,
  onTogglerClicked = noop,
  onMonthChanged = noop,
  togglerStyle,
  pickerStyle,
}) => {
  const [containerId, setContainerId] = React.useState()
  const [isPickerOpened, setIsPickerOpened] = React.useState(false)
  // picker selector year is only for the year displayed in the picker year selector
  // selected year is the actual selected year
  const [pickerSelectorYear, setPickerSelectorYear] = React.useState(currentYear)
  const [selectedYear, setSelectedYear] = React.useState(currentYear)
  const [selectedMonth, setSelectedMonth] = React.useState(currentMonth)
  // Month Id is used to identify actual selected month from other months in different years (2021-7 and 2020-7)
  const [selectedMonthId, setSelectedMonthId] = React.useState(
    generateMonthId(selectedYear, currentMonth)
  )

  const pickerRef = React.useRef()

  const togglePicker = () => setIsPickerOpened(!isPickerOpened)
  const onPickerClose = () => {
    setIsPickerOpened(false)

    waitClosePickerAnimationFinishedThen(() => setPickerSelectorYear(selectedYear))
  }

  // Users are not allowed to select months for future year
  const shouldDisableToNextYear = pickerSelectorYear >= currentYear

  // const pickerRef = React.useRef()
  useOnClickOutside(pickerRef, onPickerClose, [document.getElementById(containerId)])

  const onMonthSelected = (monthIdx) => {
    setSelectedYear(pickerSelectorYear)
    setSelectedMonth(monthIdx)
    setSelectedMonthId(`${pickerSelectorYear}-${monthIdx}`)
    onMonthChanged({ year: pickerSelectorYear, month: monthIdx })

    setIsPickerOpened(false)
  }

  React.useLayoutEffect(() => {
    if (isNil(containerId)) setContainerId(generateRandomString())
  }, [containerId])

  return (
    <Container id={containerId}>
      {Toggler ? (
        <Toggler
          onTogglerClicked={onTogglerClicked}
          togglePicker={togglePicker}
          togglerStyle={togglerStyle}
          selectedYear={selectedYear}
          selectedMonth={selectedMonth}
        />
      ) : (
        <DefaultToggler
          onTogglerClicked={onTogglerClicked}
          togglePicker={togglePicker}
          togglerStyle={togglerStyle}
          selectedYear={selectedYear}
          selectedMonth={selectedMonth}
        />
      )}

      {ReactDOM.createPortal(
        <PickerContainer
          ref={pickerRef}
          isOpened={isPickerOpened}
          pickerStyle={pickerStyle}
        >
          <YearSelector>
            <LeftArrowBtn
              onClick={() =>
                setPickerSelectorYear(
                  (prevPickerDisplayYear) => prevPickerDisplayYear - 1
                )
              }
            />{' '}
            <DisplayYear>{pickerSelectorYear}</DisplayYear>{' '}
            <RightArrowBtn
              onClick={() => {
                if (!shouldDisableToNextYear)
                  setPickerSelectorYear(
                    (prevPickerDisplayYear) => prevPickerDisplayYear + 1
                  )
              }}
              disabled={shouldDisableToNextYear}
            />
          </YearSelector>

          <Line />

          <MonthSelector>
            {months.map((month, index) => {
              const monthIdx = index + 1
              const displayingMonthId = generateMonthId(pickerSelectorYear, monthIdx)
              const isSelectingInCurrentYear = pickerSelectorYear >= currentYear
              const isLaterMonth = monthIdx > currentMonth
              // User should not be able to select future months
              const shouldDisableMonth = and(isSelectingInCurrentYear, isLaterMonth)

              return (
                <MonthBtn
                  key={month}
                  gridArea={month}
                  isSelected={selectedMonthId === displayingMonthId}
                  onClick={() => {
                    if (!shouldDisableMonth) onMonthSelected(monthIdx)
                  }}
                  disabled={shouldDisableMonth}
                >
                  {month}
                </MonthBtn>
              )
            })}
          </MonthSelector>
        </PickerContainer>,
        document.body
      )}
    </Container>
  )
}

MonthPicker.propTypes = {
  Toggler: func,
  onTogglerClicked: func,
  onMonthChanged: func,
  togglerStyle: object,
  pickerStyle: object,
}

export default MonthPicker
