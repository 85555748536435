import React from 'react'
import { NumberField } from '@vega/components'
import { s, styled } from '@vega/styled/v2'
import { applicantName } from 'utils/formatters'
import { FieldContainer } from 'components/common'
import { ReactComponent as trashIcon } from '@vega/components/src/assets/images/trash.svg'
import { ValidatedSelectField } from 'components/SelectField'
import { useLargeMediaQuery } from '@vega/styled'

const TrashIcon = styled(trashIcon)(
  s(' bg-accent-red rounded-lg p-1 ml-1', {
    cursor: 'pointer',
    color: 'white',
    height: '52px',
    width: '37px',
    marginTop: '29px',
  })
)

export const OwnerShip = ({
  applicants,
  values,
  handleChange,
  handleBlur,
  index,
  setFieldValue,
}) => {
  const isLargeScreen = useLargeMediaQuery('larger')

  const removeOwner = (index, values) => {
    const newOwnerships = [...values.ownerships]
    newOwnerships.splice(index, 1)
    setFieldValue('ownerships', newOwnerships)
  }

  return (
    <FieldContainer style={s('flex items-center ')}>
      <div style={s('flex w-full')}>
        <NumberField
          name={`ownerships[${index}].percentage`}
          label={isLargeScreen ? 'Percentage' : 'Pct%'}
          placeholder="Percentage"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.percentage}
          style={s('w-1/4 mr-2')}
          suffix={'%'}
          labelStyle={s('text-base font-normal text-grey-800')}
        />
        <ValidatedSelectField
          name={`ownerships[${index}].owner`}
          label="Owner"
          options={applicants?.map((applicant) => ({
            label: applicantName(applicant),
            value: applicant._id,
          }))}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={'Select Person'}
          style={s('w-full')}
        />
      </div>
      <div style={s('flex')}>
        {values.ownerships.length > 1 && (
          <TrashIcon
            onClick={() => {
              removeOwner(index, values)
            }}
          >
            Remove
          </TrashIcon>
        )}
      </div>
    </FieldContainer>
  )
}
