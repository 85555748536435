/* eslint-disable complexity */
import React, { useState } from 'react'
import { useComboBox } from '@react-aria/combobox'
import { Item } from '@react-stately/collections'
import { useComboBoxState } from '@react-stately/combobox'
import { prop, isNotNilOrEmpty } from '@neo/ramda-extra'
import PropTypes from 'prop-types'

import { useField } from 'formik'

import { useGeocoding } from './helpers/useGeocoding'
import { styled, s } from '@vega/styled'

import { ListBoxPopup } from './ListBoxPopup'
import { FormMessage } from '@vega/components'

const Root = styled.div(
  s('inline-flex flex-column w-full', { boxSizing: 'border-box' })
)
const Label = styled.label(s('text-grey-600 font-normal text-base mb-4'))
const InputContainer = styled.div(
  s('border-1 border-solid border-grey-200 bg-transparent relative'),
  s('p-4 w-full h-full'),
  {
    boxSizing: 'border-box',
    borderRadius: 6,
    borderTopRightRadius: 0,
    ':focus-within': s('border-green-400', { boxShadow: '0 0 0 1px #58C6BD' }),
    ':hover': s('border-green-400'),
  },
  ({ isTouched }) => isTouched && s('border-green-400'),
  ({ hasError, isTouched }) =>
    hasError &&
    isTouched &&
    s('border-error-400', {
      ':hover': s('border-error-400'),
      ':focus-within': s('border-green-400', { boxShadow: '0 0 0 1px #58C6BD' }),
    }),
  ({ isFilled }) => isFilled && s('border-grey-300'),
  ({ hasError, isFilled }) =>
    hasError &&
    isFilled &&
    s('border-error-400', {
      ':focus-within': s('border-error-400', { boxShadow: '0 0 0 1px #F53244' }),
    }),

  ({ isReadOnly }) =>
    isReadOnly &&
    s('bg-grey-100 border-1 border-solid border-grey-200', { pointerEvents: 'none' }),
  ({ isDisabled }) => isDisabled && s('bg-grey-200', { pointerEvents: 'none' })
)

const Input = styled.input(
  s('h-full w-full border-0 bg-transparent'),
  s('text-grey-800 text-base font-medium'),
  {
    outline: 'none',
    '&::placeholder': s('text-grey-500'),
  },
  ({ isReadOnly }) => isReadOnly && s('text-grey-800'),
  ({ isDisabled }) => isDisabled && s('text-grey-400')
)

const { string, bool } = PropTypes
function AddressField({ id, name, label, placeholder, disabled, readOnly, ...props }) {
  const [
    { onChange: onFieldChange, onBlur: onFieldBlur, value: currentValue },
    { touched, error },
  ] = useField({
    name,
    id,
  })

  const [selectedResult, setSelectedResult] = useState(currentValue)
  const [searchQuery, setSearchQuery] = useState('')
  const [isGeocoding, setIsGeocoding] = useState(false)

  const { results, forwardGeocode, resetResults } = useGeocoding()

  const handleInputChange = (value) => {
    setSelectedResult(undefined)

    if (isNotNilOrEmpty(value)) {
      setIsGeocoding(true)
      forwardGeocode(value)
      onFieldChange({ target: { value: undefined, name } })
      setIsGeocoding(false)
    }

    setSearchQuery(value)
  }

  const handleSelectionChange = (key) => {
    const selectedResult = results.find((result) => prop('id')(result) === key)

    setSelectedResult(selectedResult)
    setSearchQuery(selectedResult?.place_name ?? '')
    onFieldChange({ target: { value: selectedResult, name } })
  }

  function handleBlur(...args) {
    resetResults()
    onFieldBlur(...args)
  }

  // Create state based on the incoming props and the filter function
  const noFilter = () => true
  const state = useComboBoxState({
    ...props,
    /* eslint-disable-next-line */
    children: (item) => <Item key={item.id}>{item.place_name}</Item>,

    id: id ?? name,

    isOpen: !selectedResult && (results.length > 0 || isGeocoding),

    isDisabled: disabled,
    isReadOnly: readOnly,

    items: results,

    inputValue: selectedResult ? selectedResult.place_name : searchQuery,
    onInputChange: handleInputChange,

    defaultInputValue: currentValue?.place_name,

    selectedKey: prop('key')(selectedResult),
    onSelectionChange: handleSelectionChange,

    defaultFilter: noFilter,
    disallowEmptySelection: false,

    autoFocus: false,
  })

  const triggerRef = React.useRef()
  const inputRef = React.useRef()
  const listBoxRef = React.useRef()
  const popoverRef = React.useRef()

  const { inputProps, listBoxProps, labelProps } = useComboBox(
    {
      ...props,
      placeholder,
      label,
      inputRef,
      // We must pass in a button ref, otherwise useComboBox() will fail.
      buttonRef: triggerRef,
      listBoxRef,
      popoverRef,
      menuTrigger: 'input',
      isDisabled: disabled,
      isReadOnly: readOnly,
    },
    state
  )

  return (
    <Root {...props}>
      {label && <Label {...labelProps}>{label}</Label>}
      <InputContainer
        isFilled={Boolean(currentValue)}
        isDisabled={disabled}
        isReadOnly={readOnly}
        isTouched={touched}
        hasError={Boolean(error)}
      >
        <Input
          {...inputProps}
          type="search"
          ref={inputRef}
          isDisabled={disabled}
          isReadOnly={readOnly}
          onBlur={handleBlur}
        />
        {state.isOpen && (
          <ListBoxPopup
            {...listBoxProps}
            shouldUseVirtualFocus
            listBoxRef={listBoxRef}
            popoverRef={popoverRef}
            state={state}
          />
        )}
      </InputContainer>
      <FormMessage message={error} visible={Boolean(touched && error)} />
    </Root>
  )
}

AddressField.propTypes = {
  name: string.isRequired,
  label: string,
  placeholder: string,
  disabled: bool,
  readOnly: bool,
}

export { AddressField }
