import { useMemo } from 'react'
import { makeStyledBreakpoints } from 'styled-breakpoints/core'
import { useTheme } from './styled'
import useCurrentBreakpoint from 'use-breakpoint'
import units from 'units-css'

const breakpoints = {
  sm: '640px',
  md: '768px',
  lg: '1024px',
  xl: '1280px',
  jb: '1536px',
}

const internals = makeStyledBreakpoints({
  defaultMediaQueries: breakpoints,
})

function createStyles(deriveMediaQuery, ...args) {
  return (...styles) => (props) => ({
    [deriveMediaQuery(...args)(props)]: styles.reduce((acc, style = {}) => {
      return {
        ...acc,
        ...style,
      }
    }, {}),
  })
}

function smaller(breakpoint) {
  return createStyles(internals.down, breakpoint)
}

function larger(breakpoint) {
  return createStyles(internals.up, breakpoint)
}

function between(lower, upper) {
  return createStyles(internals.between, lower, upper)
}

function only(breakpoint) {
  return createStyles(internals.only, breakpoint)
}

const useBreakpoints = () => {
  const { breakpoints } = useTheme()
  return breakpoints
}

const toValue = (cssUnit) => {
  return units.parse(cssUnit).value
}

const useCurrentBreakpointWrapped = () => {
  const breakpointsFromTheme = useBreakpoints()
  const memoizedBreakpoints = useMemo(
    () => ({
      mobile: 0,
      sm: toValue(breakpointsFromTheme.sm),
      md: toValue(breakpointsFromTheme.md),
      lg: toValue(breakpointsFromTheme.lg),
      xl: toValue(breakpointsFromTheme.xl),
      jb: toValue(breakpointsFromTheme.jb),
    }),
    [breakpointsFromTheme]
  )

  const { breakpoint } = useCurrentBreakpoint(memoizedBreakpoints)

  return breakpoint
}

export { internals }
export {
  breakpoints,
  useBreakpoints,
  useCurrentBreakpointWrapped as useCurrentBreakpoint,
}
export { smaller, larger, between, only }
