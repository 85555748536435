export const personFileTypes = [
  {
    label: 'Acknowledgment Of Independent Legal Advice',
    value: 'AcknowledgmentOfIndependentLegalAdvice',
  },
  { label: 'Application Recommendations', value: 'ApplicationRecommendations' },
  { label: 'BAS Statement', value: 'BASStatement' },
  { label: 'Balance Sheet', value: 'BalanceSheet' },
  { label: 'Bank Statement', value: 'BankStatement' },
  { label: 'Birth Certificate', value: 'BirthCertificate' },
  { label: 'Builders Risk Insurance Policy', value: 'BuildersRiskInsurancePolicy' },
  { label: 'Building Consent', value: 'BuildingConsent' },
  { label: 'Building Contract', value: 'BuildingContract' },
  {
    label: 'Building Plans And Specifications',
    value: 'BuildingPlansAndSpecifications',
  },
  { label: 'Building Schedule', value: 'BuildingSchedule' },
  { label: 'Certificate Of Identity', value: 'CertificateOfIdentity' },
  { label: 'Certificate Of Incorporation', value: 'CertificateOfIncorporation' },
  { label: 'Certificate Of Registration', value: 'CertificateOfRegistration' },
  { label: 'Company Appendix', value: 'CompanyAppendix' },
  { label: 'Company Extract', value: 'CompanyExtract' },
  { label: 'Contract Works Insurance Policy', value: 'ContractWorksInsurancePolicy' },
  { label: 'Council Approved Plans', value: 'CouncilApprovedPlans' },
  { label: 'Court Order', value: 'CourtOrder' },
  { label: 'Credit Card', value: 'CreditCard' },
  { label: 'Currency Conversion Receipt', value: 'CurrencyConversionReceipt' },
  { label: 'Debit Card', value: 'DebitCard' },
  { label: 'Deed Of Gift', value: 'DeedOfGift' },
  { label: 'Deed Poll', value: 'DeedPoll' },
  { label: 'Descent Certificate', value: 'DescentCertificate' },
  { label: 'Discharge Request Authority', value: 'DischargeRequestAuthority' },
  { label: 'Dividend Statement', value: 'DividendStatement' },
  { label: 'Dun And Bradstreet Credit Report', value: 'DunAndBradstreetCreditReport' },
  { label: 'Electricity Bill', value: 'ElectricityBill' },
  {
    label: 'Electronic Disclosures And Signatures Consent',
    value: 'ElectronicDisclosuresAndSignaturesConsent',
  },
  {
    label: 'Electronic Signature Certificate Of Completion',
    value: 'ElectronicSignatureCertificateOfCompletion',
  },
  { label: 'Emergency Travel Document', value: 'EmergencyTravelDocument' },
  { label: 'Employer Letter', value: 'EmployerLetter' },
  { label: 'Employment Contract', value: 'EmploymentContract' },
  { label: 'Equifax Credit Report', value: 'EquifaxCreditReport' },
  { label: 'Evidence Of Business Debts', value: 'EvidenceOfBusinessDebts' },
  { label: 'Evidence Of Credit Issue', value: 'EvidenceOfCreditIssue' },
  { label: 'Evidence Of Funds Statement', value: 'EvidenceOfFundsStatement' },
  { label: 'Evidence Of Salary Crediting', value: 'EvidenceOfSalaryCrediting' },
  { label: 'Exit Strategy Document', value: 'ExitStrategyDocument' },
  { label: 'Foreign Birth Certificate', value: 'ForeignBirthCertificate' },
  { label: 'Foreign Citizenship Certificate', value: 'ForeignCitizenshipCertificate' },
  { label: 'Gas Bill', value: 'GasBill' },
  { label: 'Government Benefits Notice', value: 'GovernmentBenefitsNotice' },
  { label: 'Health Care Card', value: 'HealthCareCard' },
  { label: 'IRD Income Return Assessment', value: 'IRDIncomeReturnAssessment' },
  { label: 'International Driver License', value: 'InternationalDriverLicense' },
  { label: 'International Passport', value: 'InternationalPassport' },
  { label: 'Internet Account Summary', value: 'InternetAccountSummary' },
  { label: 'Internet Service Provider Bill', value: 'InternetServiceProviderBill' },
  { label: 'Internet Transaction Statement', value: 'InternetTransactionStatement' },
  { label: 'Investment Statement', value: 'InvestmentStatement' },
  { label: 'Landline Phone Bill', value: 'LandlinePhoneBill' },
  {
    label: 'Lenders Mortgage Insurance Policy',
    value: 'LendersMortgageInsurancePolicy',
  },
  { label: 'Letter From Accountant', value: 'LetterFromAccountant' },
  {
    label: 'Letter From Body Corporate Manager',
    value: 'LetterFromBodyCorporateManager',
  },
  { label: 'Letter From CSA', value: 'LetterFromCSA' },
  { label: 'Letter From Gift Provider', value: 'LetterFromGiftProvider' },
  { label: 'Letter From IRD', value: 'LetterFromIRD' },
  { label: 'Letter From Kiwi Saver Provider', value: 'LetterFromKiwiSaverProvider' },
  { label: 'Letter From Real Estate Agent', value: 'LetterFromRealEstateAgent' },
  { label: 'Letter From WINZ', value: 'LetterFromWINZ' },
  { label: 'Licensed Builder Offer', value: 'LicensedBuilderOffer' },
  { label: 'Lo Doc Declaration', value: 'LoDocDeclaration' },
  { label: 'Loan Statement', value: 'LoanStatement' },
  { label: 'Maintenance Agreement', value: 'MaintenanceAgreement' },
  { label: 'Managed Fund Statement', value: 'ManagedFundStatement' },
  { label: 'Management Statement', value: 'ManagementStatement' },
  { label: 'Market Rent Information', value: 'MarketRentInformation' },
  { label: 'Marriage Certificate', value: 'MarriageCertificate' },
  { label: 'Medicare Card', value: 'MedicareCard' },
  { label: 'National Identity Card', value: 'NationalIdentityCard' },
  { label: 'New Zealand Birth Certificate', value: 'NewZealandBirthCertificate' },
  {
    label: 'New Zealand Certificate Of Identity',
    value: 'NewZealandCertificateOfIdentity',
  },
  {
    label: 'New Zealand Citizenship Certificate',
    value: 'NewZealandCitizenshipCertificate',
  },
  { label: 'New Zealand Driver Licence', value: 'NewZealandDriverLicence' },
  { label: 'New Zealand Firearms Licence', value: 'NewZealandFirearmsLicence' },
  { label: 'New Zealand Passport', value: 'NewZealandPassport' },
  {
    label: 'New Zealand Refugee Travel Document',
    value: 'NewZealandRefugeeTravelDocument',
  },
  {
    label: 'New Zealand Responsible Lending Declaration',
    value: 'NewZealandResponsibleLendingDeclaration',
  },
  { label: 'Offer And Acceptance', value: 'OfferAndAcceptance' },
  { label: 'Other', value: 'Other' },
  { label: 'Outstanding Taxation Statement', value: 'OutstandingTaxationStatement' },
  { label: 'Partnership Agreement', value: 'PartnershipAgreement' },
  { label: 'Payment Receipt', value: 'PaymentReceipt' },
  { label: 'Payslip', value: 'Payslip' },
  { label: 'Pension Card', value: 'PensionCard' },
  { label: 'Power Of Attorney', value: 'PowerOfAttorney' },
  {
    label: 'Print Form Copy Of The Application',
    value: 'PrintFormCopyOfTheApplication',
  },
  { label: 'Profit And Loss Statement', value: 'ProfitAndLossStatement' },
  { label: 'Proof Of Age Card', value: 'ProofOfAgeCard' },
  { label: 'Proof Of Foreign Income', value: 'ProofOfForeignIncome' },
  { label: 'Proof Of Other Income', value: 'ProofOfOtherIncome' },
  { label: 'Proof Of Private Pension', value: 'ProofOfPrivatePension' },
  {
    label: 'Public Liability Insurance Policy',
    value: 'PublicLiabilityInsurancePolicy',
  },
  { label: 'Purchase And Sales Contract', value: 'PurchaseAndSalesContract' },
  { label: 'Rates Notice', value: 'RatesNotice' },
  { label: 'Rental Appraisal', value: 'RentalAppraisal' },
  { label: 'Rental Statement', value: 'RentalStatement' },
  {
    label: 'Request For Additional Information/Clarity',
    value: 'RequestForAdditionalInformation/Clarity',
  },
  { label: 'Serviceability Result Document', value: 'ServiceabilityResultDocument' },

  { label: 'Serviceability Spreadsheet', value: 'ServiceabilitySpreadsheet' },
  { label: 'Settlement Statement', value: 'SettlementStatement' },
  { label: 'Share Certificate', value: 'ShareCertificate' },
  { label: 'Shareholder Statement', value: 'ShareholderStatement' },
  { label: 'Solicitor Letter', value: 'SolicitorLetter' },
  { label: 'Statement Of Advice', value: 'StatementOfAdvice' },
  { label: 'Statement Of Position', value: 'StatementOfPosition' },
  { label: 'Statutory Declaration', value: 'StatutoryDeclaration' },
  { label: 'Store Card Statement', value: 'StoreCardStatement' },
  { label: 'Tax Assessment', value: 'TaxAssessment' },
  { label: 'Tax Return', value: 'TaxReturn' },
  { label: 'Tenancy Agreement', value: 'TenancyAgreement' },
  {
    label: 'Tentative On Completion Valuation',
    value: 'TentativeOnCompletionValuation',
  },
  { label: 'Term Deposit Statement', value: 'TermDepositStatement' },
  { label: '18+ Card', value: '18+ Card' },
  { label: 'Transfer Of Land Approval', value: 'TransferOfLandApproval' },
  { label: 'Trust Appendix', value: 'TrustAppendix' },
  { label: 'Valuation Report', value: 'ValuationReport' },
  { label: 'Visa', value: 'Visa' },
  { label: 'Water Utility Bill', value: 'WaterUtilityBill' },
  { label: 'Zoning Certificate', value: 'ZoningCertificate' },
]

export const trustFileTypes = [
  { label: 'Bank Statement', value: 'BankStatement' },
  { label: 'Other', value: 'Other' },
  { label: 'Purchase And Sales Contract', value: 'PurchaseAndSalesContract' },
  { label: 'Trust Deed', value: 'TrustDeed' },
  { label: 'Trust Schedule', value: 'TrustSchedule' },
  { label: 'Trust Appendix', value: 'TrustAppendix' },
  { label: 'Valuation Report', value: 'ValuationReport' },
]

export const companyFileTypes = [
  { label: 'Bank Statement', value: 'BankStatement' },
  { label: 'Company Extract', value: 'CompanyExtract' },
  { label: 'Company Appendix', value: 'CompanyAppendix' },
  { label: 'Other', value: 'Other' },
  { label: 'Purchase And Sales Contract', value: 'PurchaseAndSalesContract' },
  { label: 'Valuation Report', value: 'ValuationReport' },
]
