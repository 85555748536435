import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { s } from '@vega/styled/v2'
import { Loading } from '@vega/components'
import NoApplicationsCard from './NoApplicationsCard'
import ApplicationsCard from './ApplicationsCard'
import { useHistory } from 'react-router-dom'
import {
  createApplication,
  fetchApplications,
  selectApplications,
  setCurrentApplicationId,
} from 'modules/application'
import { routes } from 'routes'

const Applications = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const applications = useSelector(selectApplications)
  const [hasFirstFetchFulfilled, setHasFirstFetchFulfilled] = useState(false)

  const fetchApplicationsCallback = useCallback(() => dispatch(fetchApplications()), [
    dispatch,
  ])

  useEffect(() => {
    const fetchApplicationsOnMount = async () => {
      await fetchApplicationsCallback()
      setHasFirstFetchFulfilled(true)
      dispatch(setCurrentApplicationId(''))
    }

    fetchApplicationsOnMount()
  }, [dispatch, fetchApplicationsCallback])

  if (!hasFirstFetchFulfilled) {
    return (
      <div style={s('flex justify-center items-center h-full')}>
        <Loading />
      </div>
    )
  }

  const handleStartApplication = async () => {
    const {
      payload: { _id },
    } = await dispatch(createApplication())
    dispatch(setCurrentApplicationId(_id))
    history.push(routes.customer.application.applicants.replace(':id', _id))
  }

  const startApplicationButton = (
    <button
      onClick={handleStartApplication}
      style={s(
        'bg-secondary border-0 px-4 py-2 rounded-lg text-primary font-semibold text-xl mb-4'
      )}
    >
      Start a new application
    </button>
  )

  return (
    <>
      <div>
        <h1>Your applications</h1>
        {startApplicationButton}
      </div>

      {applications.length === 0 && <NoApplicationsCard />}

      {applications?.map((application) => (
        <ApplicationsCard key={application._id} application={application} />
      ))}

      {applications.length > 5 && startApplicationButton}
    </>
  )
}

export default Applications
