import React from 'react'
import { s, styled } from '@vega/styled/v2'
import { ContentCard as ContentCardBase, SubTitle } from 'components/common'

const Container = styled.div({ marginBottom: 52 })
const ContentCard = styled(ContentCardBase)(s('py-6 px-5'))
const FileSection = styled.div()
const LabelHeader = styled.div(s('flex justify-between mb-8'))
const SectionLabel = styled.p(
  s('m-0 mb-8 text-grey-900 text-xs font-semibold', {
    letterSpacing: '0.01em',
    lineHeight: 1.5,
    marginRight: '5px',
  })
)

const WarningText = styled.p(s('text-red text-sm font-normal'))

export const DocumentPlaceholder = () => (
  <>
    <Container id="supporting-docs">
      <SubTitle style={s('mb-2')}>Supporting Docs</SubTitle>
      <WarningText>
        Applicant details must be completed before uploading documents.
      </WarningText>
      <p
        style={s('m-0 mb-2 text-grey-900 text-xs', {
          letterSpacing: '0.01em',
          lineHeight: 1.5,
          marginRight: '5px',
        })}
      >
        *Providing full and complete supporting documentation will help ensure a speedy
        assessment of your application.
      </p>
      <ContentCard
        style={s(
          'rounded-lg bg-grey-100 border-1 border-grey-200 bg-primary-background'
        )}
      >
        <FileSection>
          <LabelHeader>
            <SectionLabel style={s('font-normal mb-0 text-grey-800')}>
              For each applicant please make sure supporting documents include:
              <br />
              • Proof of identity (e.g. drivers licence and/or passport)
              <br />
              • Proof of employment and income (e.g. employment contract, pay slips,
              etc)
              <br />• Proof of organisation (e.g. trust deed, etc)
            </SectionLabel>
          </LabelHeader>
        </FileSection>
      </ContentCard>
    </Container>
  </>
)

export default DocumentPlaceholder
