import React from 'react'
import { s } from '@vega/styled/v2'
import Card from 'components/Card'
import { formatStringToNumber, formatCurrency } from 'utils/formatters'
import { Button } from '@vega/components'

const calculateInterestOnlyLoanDetails = ({
  loanAmount,
  annualInterestRate,
  loanTermInYears,
  interestOnlyTermInMonths,
}) => {
  const monthlyRate = annualInterestRate / 12 / 100

  const monthlyPayment = loanAmount * monthlyRate

  const interestOnlyTotalInterestPaid = monthlyPayment * interestOnlyTermInMonths

  const numberOfRemainingPayments = loanTermInYears * 12 - interestOnlyTermInMonths

  let remainingMonthlyPayment
  if (monthlyRate < 1e-8) {
    // Threshold for considering rate as effectively zero
    // If rate is essentially zero, treat as simple division of principal
    remainingMonthlyPayment = loanAmount / numberOfRemainingPayments
  } else {
    remainingMonthlyPayment =
      (loanAmount * (monthlyRate * (1 + monthlyRate) ** numberOfRemainingPayments)) /
      ((1 + monthlyRate) ** numberOfRemainingPayments - 1)
  }

  const principalInterestRemainingTotalInterestPaid =
    remainingMonthlyPayment * numberOfRemainingPayments - loanAmount

  const totalInterestPaid =
    interestOnlyTotalInterestPaid + principalInterestRemainingTotalInterestPaid

  return {
    monthlyPayment: Math.round(monthlyPayment * 100) / 100,
    totalInterestPaid: Math.round(totalInterestPaid * 100) / 100,
  }
}

const calculatePrincipalInterestLoanDetails = ({
  loanAmount,
  annualInterestRate,
  loanTermInYears,
}) => {
  const monthlyRate = annualInterestRate / 12 / 100

  const numberOfPayments = loanTermInYears * 12

  const monthlyPayment =
    (loanAmount * (monthlyRate * ((1 + monthlyRate) ** numberOfPayments))) /
    (((1 + monthlyRate) ** numberOfPayments) - 1)

  const totalAmountPaid = monthlyPayment * numberOfPayments

  const totalInterestPaid = totalAmountPaid - loanAmount

  return {
    monthlyPayment: Math.round(monthlyPayment * 100) / 100,
    totalInterestPaid: Math.round(totalInterestPaid * 100) / 100,
  }
}
const calculateLoanDetails = ({
  loanAmount,
  annualInterestRate,
  loanTermInYears,
  repaymentType,
  interestOnlyTermInMonths,
}) => {
  if (repaymentType === 'p&i') {
    return calculatePrincipalInterestLoanDetails({
      loanAmount,
      annualInterestRate,
      loanTermInYears,
    })
  }

  return calculateInterestOnlyLoanDetails({
    loanAmount,
    annualInterestRate,
    loanTermInYears,
    interestOnlyTermInMonths,
  })
}

export const RepaymentsSummary = ({ values, floatingRate }) => {
  const {
    estimatedPropertyValue,
    depositAmount,
    loanTerm,
    repaymentType,
    interestOnlyTerm,
  } = values

  const loanAmount =
    formatStringToNumber(estimatedPropertyValue) - formatStringToNumber(depositAmount)
  const remainingPrincipalInterestTerm =
    (loanTerm * 12 - parseInt(interestOnlyTerm, 10)) / 12

  const { monthlyPayment, totalInterestPaid } = calculateLoanDetails({
    loanAmount,
    annualInterestRate: floatingRate,
    loanTermInYears: loanTerm,
    repaymentType,
    interestOnlyTermInMonths: interestOnlyTerm,
  })

  const {
    monthlyPayment: exampleMonthlyRepayment,
  } = calculatePrincipalInterestLoanDetails({
    loanAmount,
    annualInterestRate: floatingRate,
    loanTermInYears: remainingPrincipalInterestTerm,
  })

  return (
    <Card style={s('mb-8')}>
      <h3>You ongoing repayments</h3>
      <h2>
        {formatCurrency(monthlyPayment)} monthly
        <div style={s('text-base text-grey-600 font-medium')}>
          on Housing Variable floating rate of <b>{floatingRate}% p.a.</b>
        </div>
      </h2>

      {repaymentType === 'interestOnly' && (
        <p>
          After the interest only term our Housing Variable Rate will apply to the
          remaining {remainingPrincipalInterestTerm} years (unless you choose to refix
          at a different rate). As an example, based on our current Housing Variable
          Rate of <b>{floatingRate}% p.a.</b> (which is subject to change at any time)
          your repayment amount would be{' '}
          <b>{formatCurrency(exampleMonthlyRepayment)}</b> monthly.
        </p>
      )}

      <p style={s('font-semibold text-grey-600 mb-0')}>Your loan</p>
      <table style={s('w-full', { borderSpacing: 0 })}>
        <tbody>
          <tr>
            <td style={s('border-b-1 border-grey-light pt-2', { borderBottomStyle: 'solid' })}>
              Loan amount
            </td>
            <td style={s('font-semibold text-lg border-b-1 border-grey-light text-right pt-2', { borderBottomStyle: 'solid' })}>
              {formatCurrency(loanAmount)}
            </td>
          </tr>

          <tr>
            <td style={s('border-b-1 border-grey-light pt-2', { borderBottomStyle: 'solid' })}>
              Deposit amount
            </td>
            <td style={s('font-semibold text-lg border-b-1 border-grey-light text-right pt-2', { borderBottomStyle: 'solid' })}>
              {formatCurrency(formatStringToNumber(depositAmount))}
            </td>
          </tr>

          <tr>
            <td style={s('border-b-1 border-grey-light pt-2', { borderBottomStyle: 'solid' })}>
              Total interest
            </td>
            <td style={s('font-semibold text-lg border-b-1 border-grey-light text-right pt-2', { borderBottomStyle: 'solid' })}>
              {formatCurrency(totalInterestPaid)}
            </td>
          </tr>
        </tbody>
      </table>

      <div style={s('flex gap-2 mt-6 row-reverse', { gap: 4 })}>
        <Button variant="">Talk to us</Button>
        <Button>{"Let's get started"}</Button>
      </div>
    </Card>
  )
}
