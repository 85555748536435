/* eslint-disable complexity */
import { range } from '@neo/ramda-extra'

export const calcPageNumbers = (pageCount, pageIndex) => {
  const currentPage = pageIndex + 1
  const totalBlocks = 6
  const isEvenPage = currentPage % 2 === 0
  const hasLeftSpill = currentPage > 4
  const hasRightSpill = pageCount - currentPage > 2

  if (pageCount <= totalBlocks) return range(1, pageCount + 1)
  switch (true) {
    case !hasLeftSpill:
      return [1, 2, 3, 4, '...', pageCount]
    case !hasRightSpill:
      return [pageCount - 6, '...', ...range(pageCount - 3, pageCount + 1)]
    case isEvenPage:
      return [...range(currentPage - 3, currentPage + 1), '...', pageCount]
    default:
      return [...range(currentPage - 2, currentPage + 2), '...', pageCount]
  }
}
