export const searchOptions = [
  {
    key: 'applicationNumber',
    label: 'Application Number',
    searchLabel: 'Search  application number',
  },
  {
    key: 'names',
    label: 'Applicant Name',
    searchLabel: 'Search applicant name',
  },
  {
    key: 'user',
    label: 'User',
    searchLabel: 'Search user',
  },
]

export const warehousesOptions = [
  {
    key: '',
    label: 'All',
  },
  {
    key: 'DRAFT',
    label: 'Draft',
  },
  {
    key: 'SUBMITTED',
    label: 'Submitted',
  },
]
