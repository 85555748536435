/* eslint-disable complexity */
import React from 'react'
import { useParams, Link } from 'react-router-dom'
import { s, styled } from '@vega/styled/v2'
import { SubTitle, Chip } from 'components/common'
import Card from 'components/Card'
import { Button } from '@vega/components'
import { useLargeMediaQuery, Row, Col, Container } from '@vega/styled'

const OuterContainer = styled.div(
  s(
    'bg-white flex flex-row justify-between w-full border-0 border-b-1 border-solid border-grey-200',
    {
      '&:last-child': {
        borderColor: 'transparent',
      },
    }
  )
)
const Root = styled.div(
  s(
    'bg-white flex flex-row justify-between w-full border-0 border-b-1 border-solid border-grey-200 mt-4 mb-4 ',
    {
      '&:last-child': {
        borderColor: 'transparent',
      },
      ':hover': s('bg-secondary rounded-lg', { cursor: 'pointer' }),
    }
  )
)

const Applicants = styled.div(s('text-primary font-semibold text-sm mt-1'))

const DocumentsTable = ({ supportingDocuments }) => {
  const { id } = useParams()
  const isLargeScreen = useLargeMediaQuery('larger')

  if (!supportingDocuments || supportingDocuments.length === 0) {
    return (
      <>
        <h3>Documents Summary</h3>

        <Card>
          <SubTitle>No supporting documents have been uploaded</SubTitle>
          <p>Documents can be uploaded in the Notes section or for each applicant.</p>
        </Card>
      </>
    )
  }
  const documents = supportingDocuments.map((document) => {
    if (!isLargeScreen) {
      return (
        <OuterContainer key={document._id}>
          <Root>
            <Container gutter={0}>
              <Row gutter={[0, 0]} style={s('p-2')}>
                <Col span={18}>
                  <Chip>{document.classification}</Chip>
                  <Applicants className="sohne">{document.fileKey}</Applicants>
                </Col>
                <Col span={6}>
                  <div style={s('flex justify-end mt-4')}>
                    <Link
                      style={(s('no-underline'), { textDecoration: 'none' })}
                      to={`/customer/application/${id}/notes`}
                    >
                      <Button variant="outlined">View/Edit</Button>
                    </Link>
                  </div>
                </Col>
              </Row>
            </Container>
          </Root>
        </OuterContainer>
      )
    }

    return (
      <>
        <div style={s('mb-4 flex')} key={document._id}>
          <div style={s('flex flex-column w-full')}>
            <div style={s('flex mb-2 text-sm font-semibold text-grey-600')}>
              <div style={s('w-1/2 ')}>Classification</div>
              <div style={s('w-1/2 ')}>File Name</div>
            </div>

            <div key={document.fileKey} style={s('flex mb-2 text-sm ')}>
              <div style={s('w-1/2  ')}>{document.classification}</div>
              <div>{document.fileKey}</div>
            </div>
          </div>
          <div style={s('flex justify-end w-1/2')}>
            <Link
              style={(s('no-underline'), { textDecoration: 'none' })}
              to={`/customer/application/${id}/notes`}
            >
              <Button variant="outlined">View/Edit</Button>
            </Link>
          </div>
        </div>
      </>
    )
  })

  return <Card>{documents}</Card>
}

export default DocumentsTable
