import React, { useState, forwardRef } from 'react'
import { useDispatch } from 'react-redux'
import { Formik, Form, useFormikContext } from 'formik'
import Card from 'components/Card'
import { s } from '@vega/styled/v2'
import { SubEntityActionButton, CollapseButton } from 'components/common'
import { formatCurrency, formatStringToNumber } from 'utils/formatters'
import LiabilityFields from './LiabilityFields'
import { updateLiability, removeLiability } from 'modules/application'
import { ownershipSchema } from './AssetCard'
import * as Yup from 'yup'

export const liabilitiesSchema = Yup.object({
  liabilityType: Yup.string().required('Liability type is required'),
  // .oneOf(LiabilityTypeList, 'Invalid liability type'),
  ownerships: Yup.array()
    .of(ownershipSchema)
    .test(
      'ownership-sum-100',
      'Total ownership percentage must equal 100%',
      // eslint-disable-next-line prefer-arrow-callback
      function (ownerships) {
        const sum = ownerships.reduce((acc, ownership) => acc + ownership.percentage, 0)
        return sum === 100
      }
    ),
  creditor: Yup.string().required('Creditor is required'),
  isClearingFromThisLoan: Yup.string().required('Clearing from this loan is required'),
  currentBalance: Yup.number()
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .required('Current balance is required')
    .min(0, 'Current balance must be positive'),
  repaymentAmount: Yup.number()
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .required('Repayment amount is required')
    .min(0, 'Repayment amount must be positive'),
  repaymentUnit: Yup.string().required('Repayment unit is required'),
  limit: Yup.number()
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .required('Limit is required')
    .min(0, 'Limit must be positive'),
  interestRate: Yup.number()
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .required('An interest rate is required')
    .min(0, 'Interest rate must be positive'),
  hasArrears: Yup.string().optional(),
  creditCardType: Yup.string().optional(),
  isTaxDeductible: Yup.string().optional(),
  paymentType: Yup.string().optional(),
  loanStartDate: Yup.string().optional(),
  loanTermYears: Yup.number()
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .optional()
    .min(0, 'Loan term years must be positive'),
  loanTermMonths: Yup.number().optional().min(0, 'Loan term months must be positive'),
  interestOnlyStartDate: Yup.string().optional(),
  interestOnlyTermYears: Yup.number()
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .optional()
    .min(0, 'Interest only term years must be positive'),
  interestOnlyTermMonths: Yup.number()
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .optional()
    .min(0, 'Interest only term months must be positive'),
  isRevolvingCreditRedraw: Yup.string().optional(),
  sAutoPayment: Yup.boolean().optional(),
})

// TODO: I wonder if this could take a function and be generic?
const AutoDispatcher = ({ index, applicationId }) => {
  const dispatch = useDispatch()

  const { values } = useFormikContext()
  React.useEffect(() => {
    const formattedValues = {
      ...values,
      currentBalance: formatStringToNumber(`$${values.currentBalance}`),
      limit: formatStringToNumber(`$${values.limit}`),
      repaymentAmount: formatStringToNumber(`$${values.repaymentAmount}`),
      interestRate: formatStringToNumber(`$${values.interestRate}`),
      loanTermYears: Number.parseInt(values.loanTermYears, 10),
      loantermMonths: Number.parseInt(values.loantermMonths, 10),
      interestOnlyTermYears: Number.parseInt(values.interestOnlyTermYears, 10),
      interestOnlyTermMonths: Number.parseInt(values.interestOnlyTermMonths, 10),
    }

    dispatch(
      updateLiability({
        liabilityIndex: index,
        liability: formattedValues,
        id: applicationId,
      })
    )
  }, [dispatch, index, values, applicationId])
  return null
}

const LiabilityCard = forwardRef(
  ({ liability, index, applicationId, applicants }, ref) => {
    const dispatch = useDispatch()
    const [collapsed, setCollapsed] = useState(false)
    const toggleCollapased = () => setCollapsed(!collapsed)

    // TODO: Add a chip for asset type
    if (collapsed) {
      return (
        <Card style={s('mb-12')}>
          <div style={s('flex')}>
            <h1 style={s('m-0 p-0 text-2xl', { flexGrow: 1 })}>
              {liability.liabilityType || '-'}
            </h1>
            <SubEntityActionButton
              style={s('bg-accent-red text-white', { width: 'auto' })}
              onClick={() => {
                dispatch(removeLiability({ liabilityIndex: index, id: applicationId }))
              }}
            >
              Remove liability
            </SubEntityActionButton>
          </div>

          <p>
            Current Balance:{' '}
            {liability.currentBalance ? formatCurrency(liability.currentBalance) : '-'}
          </p>

          <CollapseButton onClick={toggleCollapased}>+ Expand</CollapseButton>
        </Card>
      )
    }

    return (
      <Card style={s('mb-12')} ref={ref} id={liability._id}>
        <Formik
          initialValues={{
            ...liability,
          }}
          enableReinitialize
          validationSchema={liabilitiesSchema}
        >
          {({ values, handleChange, handleBlur, setFieldValue, errors }) => (
            <Form>
              <div style={s('flex justify-end')}>
                <SubEntityActionButton
                  style={s('bg-accent-red text-white', { width: 'auto' })}
                  onClick={() => {
                    dispatch(
                      removeLiability({ liabilityIndex: index, id: applicationId })
                    )
                  }}
                >
                  Remove liability
                </SubEntityActionButton>
              </div>

              <AutoDispatcher index={index} applicationId={applicationId} />

              <h1 style={s('m-0 p-0 text-2xl', { flexGrow: 1 })}>
                {liability.liabilityType || '-'}
              </h1>

              <LiabilityFields
                handleChange={handleChange}
                handleBlur={handleBlur}
                values={values}
                applicants={applicants}
                setFieldValue={setFieldValue}
                errors={errors}
              />
              <CollapseButton onClick={toggleCollapased}>- Collapse</CollapseButton>
            </Form>
          )}
        </Formik>
      </Card>
    )
  }
)
LiabilityCard.displayName = 'LiabilityCard'

export default LiabilityCard
