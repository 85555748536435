import React, { useState } from 'react'
import { s, styled } from '@vega/styled/v2'
import { larger } from '@vega/styled'
import { ReactComponent as HamburgerIcon } from '@vega/components/src/assets/images/hamburger.svg'
// import { ReactComponent as LogoutIcon } from '@vega/components/src/assets/images/exit.svg'
import ProgressMeter from './ProgressMeter'
import { Link, useLocation } from 'react-router-dom'
import { useOnClickOutside } from '@vega/components'
import { routes } from 'routes'

const routeStepMapping = {
  applicants: { step: 1, title: '1. Applicants' },
  'financial-position': { step: 2, title: '2. Financial Position' },
  'funding-details': { step: 3, title: '3. Funding Details' },
  objectives: { step: 4, title: '4. Objectives' },
  notes: { step: 5, title: '5. Notes' },
  review: { step: 6, title: 'Review and Submit' },
}

const SideBarSmallContainer = styled.div(
  s('flex bg-primary text-white w-full h-4 items-center'),
  larger('xl')({ display: 'none' })
)

const IconButton = styled.button(s('h-4 w-4 border-0 bg-transparent text-white'))

const Container = styled.div(
  s(
    'absolute pin-bl-0 z-3 flex flex-column bg-primary text-white w-full shadow-md pt-4 px-4'
  ),
  {
    transition: 'all 350ms ease-in-out',
    boxShadow:
      '0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1)',
  },
  ({ visible }) => ({
    transform: visible ? 'translateY(0)' : 'translateY(50px)',
  }),
  {
    minWidth: 80,
  },
  larger('xl')({ display: 'none' })
)

const SideBarSmall = ({ children }) => {
  const location = useLocation()
  const currentRoute = location.pathname.split('/').pop()

  const currentStep = routeStepMapping[currentRoute] || { step: 0, title: '' }
  const drawerRef = React.useRef()
  const [isOpen, setIsOpen] = useState(false)

  const closeNavDrawer = () => setIsOpen(false)
  useOnClickOutside(drawerRef, closeNavDrawer)

  const handleSignOut = () => {
    window.localStorage.removeItem('vegaUserCurrentRole')
    window.location.replace(
      `${process.env.REACT_APP_AUTH_WEB_URI}sign-out?redirectApp=APPLY_ONLINE`
    )
  }

  const toggleBottomBar = () => {
    if (
      currentStep.step !== undefined &&
      currentStep.step >= 1 &&
      currentStep.step <= 6
    )
      setIsOpen(!isOpen)
  }

  return (
    <>
      <SideBarSmallContainer>
        <IconButton
          onClick={toggleBottomBar}
          style={s('h-4 w-4 border-0 bg-transparent text-white')}
        >
          <HamburgerIcon style={s('h-2 w-2')} />
        </IconButton>

        <div
          style={s('text-secondary font-extrabold text-2xl my-2 items-center', {
            flexGrow: 1,
          })}
        >
          NEO
        </div>

        <button
          style={s('bg-accent-pink border-0 h-2 rounded-lg mr-2 font-semibold px-2')}
          onClick={handleSignOut}
        >
          Logout
        </button>
      </SideBarSmallContainer>

      {children}

      <Container visible={isOpen} ref={drawerRef}>
        <div> {currentStep.title}</div>
        {currentStep.step !== 6 && (
          <ProgressMeter
            numberOfSteps={5}
            currentStep={currentStep.step}
            style={s('')}
          />
        )}

        <Link
          to={routes.customer.homepage}
          style={s(
            'bg-secondary text-primary uppercase border-0 rounded-lg text-xl my-4 px-4 flex justify-center',
            { textDecoration: 'none' }
          )}
        >
          View applications
        </Link>
      </Container>
    </>
  )
}

export default SideBarSmall
