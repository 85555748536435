/* eslint-disable react/no-multi-comp */
import React from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { styled, s } from '@vega/styled'
import { ReactComponent as CloseIcon } from '@vega/components/src/assets/images/x.svg'
import neoLogo from '@vega/components/src/assets/images/neo-logo.png'
import { useOnClickOutside, ScreenOverlay as OverlayBase } from '@vega/components'
import { selectTemporaryNavDrawer, setTemporaryNavDrawerState } from 'modules/ui'

const Container = styled.div(
  s('absolute pin-tl-0 z-3 flex flex-column h-full shadow-md bg-white pt-4 px-4'),
  {
    transition: 'all 350ms ease-in-out',
    boxShadow:
      '0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1)',
  },
  ({ visible }) => ({
    transform: visible ? 'translateX(0)' : 'translateX(-500px)',
  }),
  ({ leanMode }) => ({
    minWidth: leanMode ? 80 : 250,
  })
)
const Header = styled.div(s('flex justify-between items-center mt-8 mb-6'))
const Row = styled.div(s('flex items-center'))

const CloseIconBtn = styled(CloseIcon)(s('', { cursor: 'pointer' }))

const Img = styled.img(s('w-3'))
const ImageContainer = styled(Link)(s('no-decoration flex justify-center self-center'))

const VerticalLine = styled.div(
  s('border-0 border-l-2 border-solid border-grey-200', {
    height: 20,
  })
)

const Overlay = styled(OverlayBase)(s('z-3'))

const TemporaryDrawer = ({ homeRoute, children, ...otherProps }) => {
  const dispatch = useDispatch()

  const { isOpen, leanMode } = useSelector(selectTemporaryNavDrawer)

  const drawerRef = React.useRef()
  const closeNavDrawer = () => dispatch(setTemporaryNavDrawerState({ isOpen: false }))
  useOnClickOutside(drawerRef, closeNavDrawer)

  const renderHeader = (leanMode) =>
    leanMode ? (
      <Header style={s('justify-center')}>
        <CloseIconBtn onClick={closeNavDrawer} />
      </Header>
    ) : (
      <Header>
        <Row>
          <CloseIconBtn onClick={closeNavDrawer} style={s('mr-2')} />
          <VerticalLine />
        </Row>

        <ImageContainer to={homeRoute}>
          <Img src={neoLogo} />
        </ImageContainer>
      </Header>
    )

  return (
    <>
      {isOpen && <Overlay />}

      <Container visible={isOpen} leanMode={leanMode} ref={drawerRef} {...otherProps}>
        {renderHeader(leanMode)}

        {children}
      </Container>
    </>
  )
}

export { TemporaryDrawer }
