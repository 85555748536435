import { configureStore } from '@reduxjs/toolkit'
import { asyncStateReducer } from '@vega/redux.async'
import { uiReducer } from '../modules/ui'
import { profileReducer } from '@vega/redux.profile'
import { applicationReducer, currentApplicationIdReducer } from '../modules/application'
import { supportingDocumentReducer } from 'modules/supportingDocument'
import { userReducer } from 'modules/user'

export default configureStore({
  reducer: {
    asyncState: asyncStateReducer,
    profile: profileReducer,
    ui: uiReducer,
    application: applicationReducer,
    currentApplicationId: currentApplicationIdReducer,
    supportingDocuments: supportingDocumentReducer,
    user: userReducer,
  },
})
