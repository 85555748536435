import React from 'react'
import { s } from '@vega/styled/v2'
import { Chip, TableCell } from 'components/common'
import { formatCurrency } from 'utils/formatters'

const NonRealEstateAssetTable = ({ application }) => {
  const {
    financialPosition: { assets },
    applicants,
    households,
  } = application

  const householdApplicants = households.flatMap((household) => household.members)
  const allApplicants = householdApplicants.concat(applicants)

  const rows = assets.map((asset) => {
    const ownersName = allApplicants
      .filter((owner) =>
        asset.ownerships.map((ownership) => ownership.owner).includes(owner._id)
      )
      .map((applicant) => `${applicant.firstName}`)
      .join(' & ')
    return (
      <tr key={asset._id}>
        <TableCell>
          <Chip>{asset.assetType}</Chip>
        </TableCell>
        <TableCell>{asset.description}</TableCell>
        <TableCell>Owners: {ownersName}</TableCell>
        <TableCell>{asset.institution}</TableCell>
        <TableCell>{formatCurrency(asset.value)}</TableCell>
      </tr>
    )
  })

  const totalAssetsValue = assets.reduce((acc, curr) => acc + curr.value, 0)

  return (
    <>
      <h3>Assets - {formatCurrency(totalAssetsValue)}</h3>
      <table style={s('bg-white p-6 rounded-lg shadow-lg w-full')}>
        <tbody>{rows}</tbody>
      </table>
    </>
  )
}

export default NonRealEstateAssetTable
