import React from 'react'
import { s, styled } from '@vega/styled/v2'
import { Chip, TableCell } from 'components/common'
import { Button } from '@vega/components'
import { Link, useParams } from 'react-router-dom'
import { useLargeMediaQuery, Row, Col, Container } from '@vega/styled'

const OuterContainer = styled.div(
  s(
    'bg-white flex flex-row justify-between w-full border-0 border-b-1 border-solid border-grey-200',
    {
      '&:last-child': {
        borderColor: 'transparent',
      },
    }
  )
)
const Root = styled.div(
  s(
    'bg-white flex flex-row justify-between w-full border-0 border-b-1 border-solid border-grey-200 mt-4 mb-4 ',
    {
      '&:last-child': {
        borderColor: 'transparent',
      },
      ':hover': s('bg-secondary rounded-lg', { cursor: 'pointer' }),
    }
  )
)

const Applicants = styled.div(s('text-primary font-semibold text-base mt-1'))

const ExtraLoanInfo = styled.div(s('text-base text-primary'))

const TrustRow = ({ trust }) => {
  const { id } = useParams()
  const sectionId = trust._id
  const isLargeScreen = useLargeMediaQuery('larger')

  if (!isLargeScreen) {
    return (
      <OuterContainer>
        <Root>
          <Container gutter={0}>
            <Row gutter={[0, 0]} style={s('p-2')}>
              <Col span={18}>
                <Applicants className="sohne">{trust.trustName}</Applicants>
                <Chip>Trust</Chip>
                <ExtraLoanInfo>
                  - {`Country Registered: ${trust.countryRegistered}`}
                </ExtraLoanInfo>
                <ExtraLoanInfo>- {trust.officeEmail}</ExtraLoanInfo>
                <ExtraLoanInfo>
                  -
                  {` ${trust?.mobileNumber?.prefix} ${trust?.mobileNumber?.contactNumber}`}
                </ExtraLoanInfo>
              </Col>

              <Col span={6}>
                <div
                  style={s('flex items-center justify-end ml-auto', {
                    marginTop: '45px',
                  })}
                >
                  <Link
                    style={s('', { textDecoration: 'none' })}
                    to={`/customer/application/${id}/applicants?scrollTo=${sectionId}`}
                  >
                    <Button variant="outlined">View/Edit</Button>
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </Root>
      </OuterContainer>
    )
  }

  return (
    <tr style={s('flex items-center justify-between mb-4')}>
      <TableCell style={s('text-left')}>
        <Chip>Trust</Chip>
      </TableCell>
      <TableCell>{trust.trustName}</TableCell>
      <TableCell>{`Country Registered: ${trust.countryRegistered}`}</TableCell>
      <TableCell>
        +
        {`${trust?.mobileNumber?.countryCode} ${trust?.mobileNumber?.prefix} ${trust?.mobileNumber?.contactNumber}`}
      </TableCell>
      <TableCell>{trust.officeEmail}</TableCell>
      <td>
        <div style={s('flex justify-end ml-auto')}>
          <Link
            style={s('', { textDecoration: 'none' })}
            to={`/customer/application/${id}/applicants?scrollTo=${sectionId}`}
          >
            <Button variant="outlined">View/Edit</Button>
          </Link>
        </div>
      </td>
    </tr>
  )
}

export default TrustRow
