import React from 'react'
import { NumberField, ValocityAddressField } from '@vega/components'
import {
  SubTitle,
  FieldContainer,
  SectionContainer,
  CollapseButton,
  SubEntityActionButton,
} from 'components/common'
import Card from 'components/Card'
import { s, styled } from '@vega/styled/v2'
import { ReactComponent as SearchIconBase } from '@vega/components/src/assets/images/search.svg'
import { OwnerShip } from 'features/common/OwnerShip'
import { ValidatedSelectField } from 'components/SelectField'

const SearchIcon = styled(SearchIconBase)(s('mr-2'))

// TODO: utils dropdowns maybe?
const primaryPurposeRealEstateOptions = [
  { key: 'investment', label: 'Investment' },
  { key: 'business', label: 'Business' },
  { key: 'ownerOccupied', label: 'Owner Occupied' },
]

// Boarder & Flatmate Income are options in MyCRm but not currently handled by LoS loan Origination app.
const rentalIncomeTypeOptions = [
  { label: 'Rental Income', key: 'rentalIncome' },
  { label: 'Boarder Income', key: 'boarderIncome' },
  { label: 'Flatmate Income', key: 'flatmateIncome' },
]

// TODO: utils dropdowns maybe?
const primaryUsageOptions = [
  { key: 'residential', label: 'Residential' },
  { key: 'commercial', label: 'Commercial' },
  { key: 'industrial', label: 'Industrial' },
  { key: 'rural', label: 'Rural' },
]

// TODO: utils dropdowns maybe?
const holdingOptions = [
  { key: 'sole', label: 'Sole' },
  { key: 'JointTenants', label: 'Joint Tenants' },
  { key: 'companyShare', label: 'Company Share' },
  { key: 'tennantCommonInEqualShares', label: 'Tennant Common in Equal Shares' },
  { key: 'tennantCommonInSharesOf', label: 'Tennant Common in Shares of' },
  { key: 'timeShare', label: 'Time Share' },
]

// TODO: utils dropdowns maybe?
export const propertyTypeOptions = [
  { key: 'stdresidential', label: 'Std Residential' },
  { key: 'crosslease', label: 'Cross Lease' },
  { key: 'residentialleasehold', label: 'Residential Leasehold' },
  { key: 'stdapartment', label: 'Std Apartment' },
  { key: 'bedsitterbachelor', label: 'Bedsitter Bachelor' },
  { key: 'boardinghouse', label: 'Boarding House' },
  { key: 'combishopresidence', label: 'Combi Shop Residence' },
  { key: 'commercial', label: 'Commercial' },
  { key: 'companytitleunit', label: 'Company Title Unit' },
  { key: 'convertedcommercialproperty', label: 'Converted Commercial Property' },
  { key: 'convertedeindustrialproperty', label: 'Converted Industrial Property' },
  { key: 'convertedmotelunits', label: 'Converted Motel Units' },
  { key: 'convertedproperty', label: 'Converted Property' },
  { key: 'displayhome', label: 'Display Home' },
  { key: 'duplex', label: 'Duplex' },
  { key: 'fullydetachedhouse', label: 'Fully Detached House' },
  { key: 'govtrentalguarantee', label: 'Govt Rental Guarantee' },
  { key: 'hobbyfarm', label: 'Hobby Farm' },
  { key: 'holidayhome', label: 'Holiday Home' },
  { key: 'holidayrental', label: 'Holiday Rental' },
  { key: 'industrial', label: 'Industrial' },
  { key: 'innercityapartment', label: 'Inner City Apartment' },
  { key: 'kithome', label: 'Kit Home' },
  {
    key: 'licensedbuilderhouseconstruction',
    label: 'Licensed Builder House Construction',
  },
  { key: 'luxuryhouse', label: 'Luxury House' },
  { key: 'luxuryother', label: 'Luxury Other' },
  { key: 'multipleontitle', label: 'Multiple On Title' },
  { key: 'newstratatitleunit', label: 'New Strata Title Unit' },
  { key: 'nursinghome', label: 'Nursing Home' },
  { key: 'ownerbuilderhouseconstruction', label: 'Owner Builder House Construction' },
  { key: 'propertydevelopment', label: 'Property Development' },
  { key: 'relocatablehome', label: 'Relocatable Home' },
  { key: 'rentalguarantee', label: 'Rental Guarantee' },
  { key: 'resortunit', label: 'Resort Unit' },
  { key: 'retirementunit', label: 'Retirement Unit' },
  { key: 'semidetachedhouse', label: 'SemiDetached House' },
  { key: 'servicedapt', label: 'Serviced Apt' },
  { key: 'singlebedroomless50m2', label: 'Single Bedroom Less 50m2' },
  { key: 'snowlease', label: 'Snow lease' },
  { key: 'stratatitleunit', label: 'Strata Title Unit' },
  { key: 'studentaccommodation', label: 'Student Accommodation' },
  { key: 'studiowarehouseapt', label: 'Studio Warehouse Apt' },
  { key: 'terrace', label: 'Terrace' },
  { key: 'timeshare', label: 'Timeshare' },
  { key: 'townhouse', label: 'Town house' },
  { key: 'transportablehome', label: 'Transportable Home' },
  { key: 'unitstudentccom', label: 'Unit Student Ccom' },
  { key: 'vacantland', label: 'Vacant Land' },
  { key: 'villa', label: 'Villa' },
  { key: 'warehouseconversion', label: 'Warehouse Conversion' },
  { key: 'attachedunit', label: 'Attached Unit' },
  { key: 'lifestyle', label: 'Lifestyle' },
  { key: 'maoriland', label: 'Maori Land' },
]

// TODO: Add all values matching Lixi lending and LOS securities which current differ to each other
export const estimateBasis = [
  { key: 'certifiedvaluation', label: 'Certified Valuation' },
  // { key: 'marketassessment', label: 'Market Assessment' },
  { key: 'applicantestimate', label: 'Applicant Estimate' },
  // { key: 'actualvalue', label: 'Actual Value' },
  // { key: 'purchaseprice', label: 'Purchase Price' },
  // { key: 'governmentvaluation', label: 'Government Valuation' },
]

// TODO: utils dropdowns maybe?
const yesNoOptions = [
  { key: true, label: 'Yes' },
  { key: false, label: 'No' },
]

// TODO: utils dropdowns maybe?
const frequencyFullListOptions = [
  { key: 'daily', label: 'Daily' },
  { key: 'weekly', label: 'Weekly' },
  { key: 'fortnightly', label: 'Fortnightly' },
  { key: 'halfYearly', label: 'Half Yearly' },
  { key: 'monthly', label: 'Monthly' },
  { key: 'oneOff', label: 'One Off' },
  { key: 'quarterly', label: 'Quarterly' },
  { key: 'seasonal', label: 'Seasonal' },
  { key: 'yearly', label: 'Yearly' },
]

// TODO: utils dropdowns maybe?
const transactionPurpose = [
  { key: 'purchasing', label: 'Purchasing' },
  { key: 'refinance', label: 'Owns Existing Mortgage' },
  { key: 'owns', label: 'Owns' },
]

// TODO: RealEstateAssetsFields?
export const RealEstateAssets = ({
  handleBlur,
  handleChange,
  values,
  applicants,
  setFieldValue,
  errors,
}) => {
  const addRentalIncome = () => {
    setFieldValue('rentalIncomes', [
      ...values.rentalIncomes,
      { type: '', amount: '', frequency: '' },
    ])
  }

  const removeRentalIncome = (index) => {
    const newRentalIncomes = [...values.rentalIncomes]
    newRentalIncomes.splice(index, 1)
    setFieldValue('rentalIncomes', newRentalIncomes)
  }

  return (
    <SectionContainer>
      <SubTitle>Real Estate Asset details</SubTitle>

      <FieldContainer>
        <ValidatedSelectField
          name="transaction"
          label="Transaction Purpose*"
          options={transactionPurpose.map((purposes) => ({
            label: purposes.label,
            value: purposes.label,
            key: purposes.key,
          }))}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={'Select Transaction Purpose'}
        />
      </FieldContainer>

      {values.transaction === 'Purchasing' && (
        <FieldContainer>
          <ValidatedSelectField
            name="primarySecurity"
            label="Primary security?*"
            options={yesNoOptions.map((status) => ({
              label: status.label,
              value: status.label,
              key: status.key,
            }))}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={'Select used as primary security'}
          />
        </FieldContainer>
      )}
      <SubTitle style={s('text-base font-bold text-grey-800')}>Ownership</SubTitle>
      {errors.ownerships && typeof errors.ownerships === 'string' && (
        <div style={{ color: 'red' }}>{errors.ownerships}</div>
      )}
      {values.ownerships.map((ownership, index) => {
        return (
          <OwnerShip
            applicants={applicants}
            handleBlur={handleBlur}
            handleChange={handleChange}
            values={values}
            index={index}
            key={index}
            setFieldValue={setFieldValue}
          />
        )
      })}

      {values.ownerships.length < 2 && (
        <CollapseButton
          onClick={() =>
            setFieldValue('ownerships', [
              ...values.ownerships,
              { percentage: 0, owner: '' },
            ])
          }
          style={s('mb-2')}
        >
          + Add Owner
        </CollapseButton>
      )}

      <FieldContainer>
        <ValidatedSelectField
          name={'zoning.domainType'}
          label="Zoning*"
          options={primaryUsageOptions?.map((option) => ({
            label: option.label,
            value: option.label,
            key: option.key,
          }))}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={'Select Zoning'}
          style={s('w-full')}
        />
      </FieldContainer>

      <FieldContainer>
        <ValocityAddressField
          name="address"
          label="Address*"
          placeholder="Enter the address"
          aria-label="Property address"
          style={s('bg-white')}
          InputPrefixIcon={<SearchIcon />}
          menuStyles={s('max-h-24 mt-8', {
            overflowY: 'auto',
          })}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </FieldContainer>

      <FieldContainer>
        <ValidatedSelectField
          name="primaryPurpose"
          label="Primary Purpose*"
          options={primaryPurposeRealEstateOptions.map((purposes) => ({
            label: purposes.label,
            value: purposes.label,
            key: purposes.key,
          }))}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={'Select Primary Purpose'}
        />
      </FieldContainer>

      <FieldContainer>
        <ValidatedSelectField
          name="holding"
          label="Holding*"
          options={holdingOptions.map((holding) => ({
            label: holding.label,
            value: holding.label,
            key: holding.key,
          }))}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={'Select Holding'}
        />
      </FieldContainer>

      <FieldContainer>
        <ValidatedSelectField
          name="primaryUsage"
          label="Primary Usage*"
          options={primaryUsageOptions.map((usage) => ({
            label: usage.label,
            value: usage.label,
            key: usage.key,
          }))}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={'Select Primary Usage'}
        />
      </FieldContainer>

      <FieldContainer>
        <ValidatedSelectField
          name="propertyType"
          label="Property Type*"
          options={propertyTypeOptions.map((purpose) => ({
            label: purpose.label,
            value: purpose.label,
            key: purpose.key,
          }))}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={'Select Property Type'}
          selectMenuStyles={{
            height: '300px',
            overflowY: 'scroll',
            scrollbarWidth: 'auto',
            '&::-webkit-scrollbar': {
              width: '15px',
              height: 0,
            },
            '&::-webkit-scrollbar-track': {
              background: '#e0e0e0',
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#888',
            },
          }}
        />
      </FieldContainer>

      <FieldContainer>
        <ValidatedSelectField
          name="valueBasis"
          label="Value Basis*"
          options={estimateBasis.map((basis) => ({
            label: basis.label,
            value: basis.label,
            key: basis.key,
          }))}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={'Select Value Basis'}
        />
      </FieldContainer>

      <FieldContainer>
        <NumberField
          name="value"
          label="Value*"
          placeholder="Value"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.value}
          prefix="$"
          thousandSeparator
          labelStyle={s('text-base')}
        />
      </FieldContainer>

      <FieldContainer>
        <ValidatedSelectField
          name="isUsedAsSecurity"
          label="Used as Security?*"
          options={yesNoOptions.map((status) => ({
            label: status.label,
            value: status.key,
            key: status.label,
          }))}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={'Select used as security'}
        />
      </FieldContainer>

      <SubTitle>Rental Incomes</SubTitle>
      <FieldContainer>
        {values.rentalIncomes.map((income, index) => (
          <div key={index}>
            <Card style={s('p-4')}>
              <div style={s('flex justify-end')}>
                <SubEntityActionButton
                  style={s('bg-accent-red text-white', {
                    width: 'auto',
                  })}
                  onClick={() => removeRentalIncome(index)}
                >
                  Remove
                </SubEntityActionButton>
              </div>
              <div style={s('flex w-full')}>
                <ValidatedSelectField
                  name={`rentalIncomes[${index}].type`}
                  label="Income Type*"
                  options={rentalIncomeTypeOptions.map((type) => ({
                    label: type.label,
                    value: type.label,
                    key: type.key,
                  }))}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder={'Select Type'}
                />
              </div>
              <div style={s('flex w-full mt-2', { gap: '8px' })}>
                <NumberField
                  name={`rentalIncomes[${index}].amount`}
                  label="Amount*"
                  placeholder="Amount"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={income.amount}
                  prefix="$"
                  thousandSeparator
                  labelStyle={s('text-base')}
                />
                <ValidatedSelectField
                  name={`rentalIncomes[${index}].frequency`}
                  label="Frequency*"
                  options={frequencyFullListOptions.map((frequency) => ({
                    label: frequency.label,
                    value: frequency.label,
                    key: frequency.key,
                  }))}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder={'Select Frequency'}
                />
              </div>
            </Card>
          </div>
        ))}

        <CollapseButton type="button" style={s('mt-2')} onClick={addRentalIncome}>
          + Add Rental Income
        </CollapseButton>
      </FieldContainer>
    </SectionContainer>
  )
}
