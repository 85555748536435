/* eslint-disable react/no-multi-comp */
/* eslint-disable no-nested-ternary */
/* eslint-disable complexity */
import React from 'react'
import { s, styled } from '@vega/styled/v2'
import { Button } from '@vega/components'
import { Chip, SubTitle } from 'components/common'
import Card from 'components/Card'
import { formatCurrency, formatDateString } from 'utils/formatters'
import { repaymentTypes } from 'utils/dropdownLists'
import { Link, useParams } from 'react-router-dom'
import { routes } from 'routes'
import { useLargeMediaQuery } from '@vega/styled'

const OuterContainer = styled.div(
  s(
    'bg-white flex flex-row justify-between w-full border-0 border-b-1 border-solid border-grey-200',
    {
      '&:last-child': {
        borderColor: 'transparent',
      },
    }
  )
)
const Root = styled.div(
  s(
    'bg-white flex flex-row justify-between w-full border-0 border-b-1 border-solid border-grey-200 mt-4 mb-4 ',
    {
      '&:last-child': {
        borderColor: 'transparent',
      },
      ':hover': s('bg-secondary rounded-lg', { cursor: 'pointer' }),
    }
  )
)

const Applicants = styled.div(s('text-primary font-semibold text-base mt-1 ml-2'))

const ObjectivesTable = ({ application }) => {
  const { id } = useParams()
  const isLargeScreen = useLargeMediaQuery('larger')

  // eslint-disable-next-line react/no-multi-comp
  const EditButtons = () => (
    <td style={s('flex flex-row-reverse gap-1', { gap: '0.25rem' })}>
      <Link
        style={s('', { textDecoration: 'none' })}
        to={routes.customer.application.objectives.replace(':id', id)}
      >
        <Button variant="outlined">View/Edit</Button>
      </Link>
    </td>
  )

  const MobileEditButtons = () => (
    <Link
      style={s('', { textDecoration: 'none' })}
      to={routes.customer.application.objectives.replace(':id', id)}
    >
      <Button variant="outlined">View/Edit</Button>
    </Link>
  )

  const {
    goals,
    importantDates,
    deposits,
    ratePreferences,
    repaymentPreferences,
    specialFeaturePreferences,
    debtArrears,
    insurance,
    otherObjectives,
  } = application.declarations

  const primaryPurposeRow =
    goals.primaryPurposes.length === 0
      ? []
      : !isLargeScreen
      ? [
          <OuterContainer key="primaryPurpose" style={s('', { width: '20rem' })}>
            <Root>
              <div style={s('flex flex-column')}>
                <Chip>Primary Purposes</Chip>
                <Applicants className="sohne">
                  {goals.primaryPurposes.map(
                    (primaryPurpose, index, array) =>
                      `${primaryPurpose}${index < array.length - 1 ? ', ' : ''}`
                  )}
                </Applicants>
              </div>
              <MobileEditButtons />
            </Root>
          </OuterContainer>,
        ]
      : [
          <tr key="primaryPurpose">
            <td>
              <Chip>Primary Purposes</Chip>
            </td>
            <td>
              {goals.primaryPurposes.map(
                (primaryPurpose, index, array) =>
                  `${primaryPurpose}${index < array.length - 1 ? ', ' : ''}`
              )}
            </td>
            <EditButtons />
          </tr>,
        ]

  const specificGoalsRow =
    goals.specificGoals === undefined
      ? []
      : !isLargeScreen
      ? [
          <OuterContainer key="specificGoals" style={s('', { width: '20rem' })}>
            <Root>
              <div style={s('flex flex-column')}>
                <Chip>Specific Goals</Chip>
                <Applicants className="sohne">{goals.specificGoals}</Applicants>
              </div>
              <MobileEditButtons />
            </Root>
          </OuterContainer>,
        ]
      : [
          <tr key="specificGoals">
            <td>
              <Chip>Specific Goals</Chip>
            </td>
            <td>{goals.specificGoals}</td>
            <EditButtons />
          </tr>,
        ]

  const importantDatesRow =
    importantDates.dates.length === 0
      ? []
      : !isLargeScreen
      ? [
          <OuterContainer key="importantDates" style={s('', { width: '20rem' })}>
            <Root>
              <div style={s('flex flex-column')}>
                <Chip>Important Dates</Chip>
                <Applicants className="sohne">
                  {importantDates.dates.map(
                    (date, index, array) =>
                      `${date.dateType} ${formatDateString(date.date)}${
                        index < array.length - 1 ? ', ' : ''
                      } `
                  )}
                </Applicants>
              </div>
              <MobileEditButtons />
            </Root>
          </OuterContainer>,
        ]
      : [
          <tr key="importantDates">
            <td>
              <Chip>Important Dates</Chip>
            </td>
            <td>
              {importantDates.dates.map(
                (date, index, array) =>
                  `${date.dateType} ${formatDateString(date.date)}${
                    index < array.length - 1 ? ', ' : ''
                  } `
              )}
            </td>
            <EditButtons />
          </tr>,
        ]

  const depositsRow =
    deposits.length === 0
      ? []
      : !isLargeScreen
      ? [
          <OuterContainer key="deposits" style={s('', { width: '20rem' })}>
            <Root>
              <div style={s('flex flex-column')}>
                <Chip>Important Dates</Chip>
                <Applicants className="sohne">
                  {deposits.map(
                    (deposit, index, array) =>
                      `${deposit.source} ${formatCurrency(deposit.amount)}${
                        index < array.length - 1 ? ', ' : ''
                      } `
                  )}
                </Applicants>
              </div>
              <MobileEditButtons />
            </Root>
          </OuterContainer>,
        ]
      : [
          <tr key="deposits">
            <td>
              <Chip>Deposits</Chip>
            </td>
            <td>
              {deposits.map(
                (deposit, index, array) =>
                  `${deposit.source} ${formatCurrency(deposit.amount)}${
                    index < array.length - 1 ? ', ' : ''
                  } `
              )}
            </td>
            <EditButtons />
          </tr>,
        ]

  const ratePreferencesRow =
    ratePreferences.hasRatePreferences !== 'Yes'
      ? []
      : !isLargeScreen
      ? [
          <OuterContainer key="ratePreferences" style={s('', { width: '20rem' })}>
            <Root>
              <div style={s('flex flex-column')}>
                <Chip>Rate Preferences</Chip>
                <Applicants className="sohne">
                  {
                    repaymentTypes?.find((r) => r.key === ratePreferences.rateType)
                      ?.label
                  }
                </Applicants>
              </div>
              <MobileEditButtons />
            </Root>
          </OuterContainer>,
        ]
      : [
          <tr key="ratePreferences">
            <td>
              <Chip>Rate Preferences</Chip>
            </td>
            <td>
              {repaymentTypes?.find((r) => r.key === ratePreferences.rateType)?.label}
            </td>
            <EditButtons />
          </tr>,
        ]

  const repaymentPreferencesRow =
    repaymentPreferences.hasRepaymentPreferences !== 'Yes'
      ? []
      : !isLargeScreen
      ? [
          <OuterContainer key="repaymentPreferences" style={s('', { width: '20rem' })}>
            <Root>
              <div style={s('flex flex-column')}>
                <Chip>Repayment Preferences</Chip>
                <Applicants className="sohne">
                  {repaymentPreferences.preferenceTypes.map(
                    (preferenceType, index, array) =>
                      `${preferenceType}${index < array.length - 1 ? ', ' : ''} `
                  )}
                </Applicants>
              </div>
              <MobileEditButtons />
            </Root>
          </OuterContainer>,
        ]
      : [
          <tr key="repaymentPreferences">
            <td>
              <Chip>Repayment Preferences</Chip>
            </td>
            <td>
              {repaymentPreferences.preferenceTypes.map(
                (preferenceType, index, array) =>
                  `${preferenceType}${index < array.length - 1 ? ', ' : ''} `
              )}
            </td>
            <EditButtons />
          </tr>,
        ]

  const specialFeaturePreferencesRow =
    specialFeaturePreferences.hasSpecialFeaturePreferences !== 'Yes'
      ? []
      : !isLargeScreen
      ? [
          <OuterContainer
            key="specialFeaturePreferences"
            style={s('', { width: '20rem' })}
          >
            <Root>
              <div style={s('flex flex-column')}>
                <Chip>Special Features</Chip>
                <Applicants className="sohne">
                  {specialFeaturePreferences.preferenceTypes.map(
                    (preferenceType, index, array) =>
                      `${preferenceType}${index < array.length - 1 ? ', ' : ''} `
                  )}
                </Applicants>
              </div>
              <MobileEditButtons />
            </Root>
          </OuterContainer>,
        ]
      : [
          <tr key="specialFeaturePreferences">
            <td>
              <Chip>Special Features</Chip>
            </td>
            <td>
              {specialFeaturePreferences.preferenceTypes.map(
                (preferenceType, index, array) =>
                  `${preferenceType}${index < array.length - 1 ? ', ' : ''} `
              )}
            </td>
            <EditButtons />
          </tr>,
        ]

  const previousDebtArrearsRow =
    debtArrears?.previousArrears?.hasArrears !== 'Yes'
      ? []
      : !isLargeScreen
      ? [
          <OuterContainer key="previousDebtArreas" style={s('', { width: '20rem' })}>
            <Root>
              <div style={s('flex flex-column')}>
                <Chip>Previous Debt Arrears</Chip>
                <Applicants className="sohne">
                  {debtArrears.previousArrears.note}
                </Applicants>
              </div>
              <MobileEditButtons />
            </Root>
          </OuterContainer>,
        ]
      : [
          <tr key="previousDebtArreas">
            <td>
              <Chip>Previous Debt Arrears</Chip>
            </td>
            <td>{debtArrears.previousArrears.note}</td>
            <EditButtons />
          </tr>,
        ]

  const currentDebtArrearsRow =
    debtArrears?.currentArrears?.hasArrears !== 'Yes'
      ? []
      : !isLargeScreen
      ? [
          <OuterContainer key="currentDebtArreas" style={s('', { width: '20rem' })}>
            <Root>
              <div style={s('flex flex-column')}>
                <Chip>Current Debt Arrears</Chip>
                <Applicants className="sohne">
                  {debtArrears.currentArrears.note}
                </Applicants>
              </div>
              <MobileEditButtons />
            </Root>
          </OuterContainer>,
        ]
      : [
          <tr key="currentDebtArreas">
            <td>
              <Chip>Current Debt Arrears</Chip>
            </td>
            <td>{debtArrears.currentArrears.note}</td>
            <EditButtons />
          </tr>,
        ]

  const personalInsuranceRow =
    insurance?.hasFamilyInsurance === undefined
      ? []
      : !isLargeScreen
      ? [
          <OuterContainer key="personalInsurance" style={s('', { width: '20rem' })}>
            <Root>
              <div style={s('flex flex-column')}>
                <Chip> Personal Insurances</Chip>
                <Applicants className="sohne">
                  {insurance?.hasFamilyInsurance}
                </Applicants>
              </div>
              <MobileEditButtons />
            </Root>
          </OuterContainer>,
        ]
      : [
          <tr key="personalInsurance">
            <td>
              <Chip>Personal Insurances</Chip>
            </td>
            <td>{insurance?.hasFamilyInsurance}</td>
            <EditButtons />
          </tr>,
        ]

  const homeInsuranceRow =
    insurance?.hasHomeContentsInsurance === undefined
      ? []
      : !isLargeScreen
      ? [
          <OuterContainer key="homeInsurance" style={s('', { width: '20rem' })}>
            <Root>
              <div style={s('flex flex-column')}>
                <Chip>Has home Insurances</Chip>
                <Applicants className="sohne">
                  {insurance?.hasHomeContentsInsurance}
                </Applicants>
              </div>
              <MobileEditButtons />
            </Root>
          </OuterContainer>,
        ]
      : [
          <tr key="homeInsurance">
            <td>
              <Chip>Has home Insurances</Chip>
            </td>
            <td>{insurance?.hasHomeContentsInsurance}</td>
            <EditButtons />
          </tr>,
        ]

  const otherObjectivesRow =
    otherObjectives?.hasOtherObjectives !== 'Yes'
      ? []
      : !isLargeScreen
      ? [
          <OuterContainer key="otherObjectives" style={s('', { width: '20rem' })}>
            <Root>
              <div style={s('flex flex-column')}>
                <Chip>Has home Insurances</Chip>
                <Applicants className="sohne">
                  {otherObjectives.otherObjectivesNotes}
                </Applicants>
              </div>
              <MobileEditButtons />
            </Root>
          </OuterContainer>,
        ]
      : [
          <tr key="otherObjectives">
            <td>
              <Chip>Other Objectives</Chip>
            </td>
            <td>{otherObjectives.otherObjectivesNotes}</td>
            <EditButtons />
          </tr>,
        ]

  const rows = [
    ...primaryPurposeRow,
    ...specificGoalsRow,
    ...importantDatesRow,
    ...depositsRow,
    ...ratePreferencesRow,
    ...repaymentPreferencesRow,
    ...specialFeaturePreferencesRow,
    ...previousDebtArrearsRow,
    ...currentDebtArrearsRow,
    ...personalInsuranceRow,
    ...homeInsuranceRow,
    ...otherObjectivesRow,
  ]

  if (rows.length === 0) {
    return (
      <>
        <h3>Objectives Summary</h3>

        <Card>
          <SubTitle>No objectives have been specified</SubTitle>
          <p>Objectives can be specified in the application objectives section.</p>
        </Card>
      </>
    )
  }

  return (
    <>
      <h3>Objectives Summary</h3>
      <table style={s('bg-white p-6 rounded-lg shadow-lg w-full')}>
        <tbody>{rows}</tbody>
      </table>
    </>
  )
}

export default ObjectivesTable
