const PRODUCT_STATUS = /** @type {const} */ ({
  ACTIVE: 'active',
  ARCHIVED: 'archived',
})

const ASSET_TYPE = /** @type {const} */ ({
  ESTABLISHED: 'established',
  NEW_BUILDING: 'new_building',
  OFF_THE_PLAN: 'off_the_plan',
  VACANT_LAND: 'vacant_land',
})

const OCCUPANCY_TYPE = /** @type {const} */ ({
  OWNER_OCCUPIED: 'owner_occupied',
  INVESTMENT: 'investment',
  BUSINESS: 'business',
})

const OCCUPANCY_TYPE_DISPLAY_TEXT = {
  [OCCUPANCY_TYPE.BUSINESS]: 'Business',
  [OCCUPANCY_TYPE.INVESTMENT]: 'Investment',
  [OCCUPANCY_TYPE.OWNER_OCCUPIED]: 'Owner Occupied',
}

const DOCUMENTATION_LEVEL = /** @type {const} */ ({
  FULL: 'full_documentation',
  LOW: 'low_documentation',
  NONE: 'no_documentation',
})

const INTEREST_TYPE = /** @type {const} */ ({
  FIXED: 'fixed',
  VARIABLE: 'variable',
  COMBO: 'combo',
})

const REPAYMENT_TYPE = /** @type {const} */ ({
  INTEREST_ONLY: 'interest_only',
  PRINCIPAL_AND_INTEREST: 'principal_and_interest',
  INTEREST_IN_ADVANCE: 'interest_in_advance',
  LINE_OF_CREDIT: 'line_of_credit',
})

const REPAYMENT_FREQUENCY = /** @type {const} */ ({
  // ONE_OFF: 'one_off',
  // DAILY: 'daily',
  WEEKLY: 'weekly',
  FORTNIGHTLY: 'fortnightly',
  MONTHLY: 'monthly',
  // QUARTERLY: 'quarterly',
  // BIANNUALLY: 'biannually',
  // ANNUALLY: 'annually',
  // SEASONAL: 'seasonal',
})

export {
  PRODUCT_STATUS,
  ASSET_TYPE,
  OCCUPANCY_TYPE,
  OCCUPANCY_TYPE_DISPLAY_TEXT,
  DOCUMENTATION_LEVEL,
  INTEREST_TYPE,
  REPAYMENT_TYPE,
  REPAYMENT_FREQUENCY,
}
