import React from 'react'
import { styled, s, Row, Col } from '@vega/styled'
import terminal from '@vega/components/src/assets/images/terminal.svg'

const Container = styled.div(
  s('static center pr-10 pt-16', {
    width: '100%',
    flexShrink: 0,
    paddingLeft: 55,
  })
)

const Title = styled.h2(s('m-0 font-bold text-grey-900 text-xl'))

const Subtitle = styled.span(s('text-grey-800 font-normal text-base block mt-5'))

const UnsupportedScreenPage = ({ app }) => {
  return (
    <Container>
      <Row justify="center">
        <Col align="center">
          <img
            alt="Terminal"
            src={terminal}
            width={48}
            height={42.67}
            style={s('mb-12 mt-24 text-lg')}
          />
          <Title>This screen size is not supported yet</Title>
          <Subtitle>
            Please switch to your desktop/laptop or larger screen to use {app}
          </Subtitle>
        </Col>
      </Row>
    </Container>
  )
}

export { UnsupportedScreenPage }
