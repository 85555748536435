import React from 'react'
import { s, styled } from '@vega/styled/v2'
import { Button } from '@vega/components'
import { Chip, TableCell, SubTitle } from 'components/common'
import { formatCurrency } from 'utils/formatters'
import { Link, useParams } from 'react-router-dom'
import { useLargeMediaQuery, Row, Col, Container } from '@vega/styled'
import Card from 'components/Card'

const OuterContainer = styled.div(
  s(
    'bg-white flex flex-row justify-between w-full border-0 border-b-1 border-solid border-grey-200',
    {
      '&:last-child': {
        borderColor: 'transparent',
      },
    }
  )
)
const Root = styled.div(
  s(
    'bg-white flex flex-row justify-between w-full border-0 border-b-1 border-solid border-grey-200 mt-4 mb-4 ',
    {
      '&:last-child': {
        borderColor: 'transparent',
      },
      ':hover': s('bg-secondary rounded-lg', { cursor: 'pointer' }),
    }
  )
)

const Applicants = styled.div(s('text-primary font-semibold text-base mt-1'))

const ExtraLoanInfo = styled.div(s('text-base text-primary'))

const LiabilitiesTable = ({ application }) => {
  const { id } = useParams()
  const isLargeScreen = useLargeMediaQuery('larger')

  const {
    financialPosition: { liabilities },
  } = application

  const rows = liabilities.map((liability) => {
    const sectionId = liability._id

    if (!isLargeScreen) {
      return (
        <OuterContainer>
          <Root>
            <Container gutter={0}>
              <Row gutter={[0, 0]} style={s('p-2')}>
                <Col span={18}>
                  <Chip>{liability.liabilityType}</Chip>
                  <Applicants className="sohne">{liability.description}</Applicants>
                  <ExtraLoanInfo>- {liability.creditor}</ExtraLoanInfo>
                  <ExtraLoanInfo>- {liability.paymentType}</ExtraLoanInfo>
                  <ExtraLoanInfo>
                    - {`Limit: ${formatCurrency(liability.limit)}`}
                  </ExtraLoanInfo>
                  <ExtraLoanInfo>
                    - {`Current balance: ${formatCurrency(liability.currentBalance)}`}
                  </ExtraLoanInfo>
                </Col>
                <Col span={6}>
                  <div
                    style={s('flex items-center justify-end ml-auto', {
                      marginTop: '45px',
                    })}
                  >
                    <Link
                      style={s('', { textDecoration: 'none' })}
                      to={`/customer/application/${id}/financial-position?scrollTo=${sectionId}`}
                    >
                      <Button variant="outlined">View/Edit</Button>
                    </Link>
                  </div>
                </Col>
              </Row>
            </Container>
          </Root>
        </OuterContainer>
      )
    }

    return (
      <tr key={liability._id} style={s('flex items-center justify-between mb-4')}>
        <TableCell>
          <Chip>{liability.liabilityType}</Chip>
        </TableCell>
        <TableCell>{liability.creditor}</TableCell>
        <TableCell>{liability.paymentType}</TableCell>
        <TableCell>{`Limit: ${formatCurrency(liability.limit)}`}</TableCell>
        <TableCell>{`Current balance: ${formatCurrency(
          liability.currentBalance
        )}`}</TableCell>
        <td>
          <div style={s('flex justify-end ml-auto mb-4')}>
            <Link
              style={s('', { textDecoration: 'none' })}
              to={`/customer/application/${id}/financial-position?scrollTo=${sectionId}`}
            >
              <Button variant="outlined">View/Edit</Button>
            </Link>
          </div>
        </td>
      </tr>
    )
  })

  const totalLiabilitiesValue = liabilities.reduce(
    (acc, curr) => acc + curr.currentBalance,
    0
  )

  if (rows.length === 0) {
    return (
      <>
        <h3>Liabilities - {formatCurrency(totalLiabilitiesValue)}</h3>

        <Card>
          <SubTitle>No liabilities have been specified</SubTitle>
          <p>
            Liabilities can be specified in the application financial position section.
          </p>
        </Card>
      </>
    )
  }

  return (
    <>
      <h3>Liabilities - {formatCurrency(totalLiabilitiesValue)}</h3>
      <table style={s('bg-white p-6 rounded-lg shadow-lg w-full')}>
        <tbody>{rows}</tbody>
      </table>
    </>
  )
}

export default LiabilitiesTable
