import React from 'react'

import { styled, s } from '@vega/styled'

const Container = styled.div(s('px-4 flex justify-between items-center bg-green-50'))

const TabsContainer = styled.div(s('flex'))
const Tab = styled.div(
  s('flex justify-center mx-4 py-1 font-normal', { cursor: 'pointer' }),
  ({ selected }) => ({
    ...s(
      '',
      selected
        ? {
            borderBottom: '3px solid #22635E',
          }
        : {}
    ),
  })
)

function Tabs({ tabs, selectedTab }) {
  return (
    <Container>
      <TabsContainer>
        {tabs.map((tab) => (
          <Tab key={tab.id} selected={selectedTab === tab.id} onClick={tab.onClick}>
            {tab.title}
          </Tab>
        ))}
      </TabsContainer>
    </Container>
  )
}

export default Tabs
